import React, { Component } from "react";
import ReactTable from "react-table";

import { toast } from "react-toastify";
import {
  Header,
  Checkbox,
  Popup,
  Icon,
  Divider,
  Menu,
  Form
} from "semantic-ui-react";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import Money from "../Shared/Money";

import io from "../socketConnection";
import moment from "moment";
import _ from "lodash";
import ConfirmTransactionCheckIns from "../Shared/ConfirmTransactionCheckIns";
import { checkInTransactions } from "../lib/apiCalls";
import { OptionsContext } from "../OptionsContext";

class TransactionsTable extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      datesRange: `${moment().format("MM-DD-YYYY")} - ${moment().format(
        "MM-DD-YYYY"
      )}`,
      pages: 0,
      totals: {},
      loading: false,
      selection: [],

      sorted: [{ id: "transactionDate", desc: true }],
      filtered: [],
      page: 0,
      pageSize: window.innerHeight > 1000 ? 25 : 10
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.getTransactions();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    // Get the transactions when sorting, filtering or the page was changed
    if (
      !_.isEqual(
        {
          sorted: prevState.sorted,
          filtered: prevState.filtered,
          page: prevState.page,
          pageSize: prevState.pageSize,
          datesRange: prevState.datesRange
        },
        {
          sorted: this.state.sorted,
          filtered: this.state.filtered,
          page: this.state.page,
          pageSize: this.state.pageSize,
          datesRange: this.state.datesRange
        }
      )
    ) {
      this.getTransactions();
    }
  }
  getTransactions = _.debounce(() => {
    if (this.state.loading === true) return;
    this.setState({ loading: true });

    // Created a filtered component to fix the filtered transaction date
    let filtered = this.state.filtered ? [...this.state.filtered] : [];

    let startDate = this.state.startDate;
    let endDate = this.state.endDate;

    let splitDates = this.state.datesRange.split(" - ");

    if (this.state.filtered) {
      // Find the new filter for transaction date if there is one
      let transactionDateFilter = _.find(filtered, {
        id: "transactionDate"
      });
      if (transactionDateFilter) {
        // Remove the key from filtered
        delete filtered[_.findIndex(filtered, { id: "transactionDate" })];

        // Split the start and end data into different variables
        splitDates = transactionDateFilter.value.split(" - ");
      }
    }
    startDate = splitDates && splitDates[0];
    endDate = splitDates && splitDates[1];

    io.socket.get(
      "/api/v4/transactions/getAll",
      {
        startDate: startDate || new Date(),
        endDate: endDate,
        page: this.state.page || 0,
        pageSize: this.state.pageSize,
        sorted: this.state.sorted || [],
        filtered: filtered
      },
      (body, jwr) => {
        if (this._isMounted) {
          if (jwr.statusCode !== 200) {
            toast.error("Failed to retrieve all transactions.");
            this.setState({ loading: false });
          }
          this.setState({
            data: body.rows,
            totals: body.totals || {},
            pages: body.pages,
            loading: false
          });
        }
      }
    );
  }, 300);
  onRowClick(state, rowInfo, column) {
    if (this.state.loading === true) return;
    // Default to pushing to path
    if (rowInfo.original.Customer && rowInfo.original.Customer.User) {
      this.props.history.push(
        "/dashboard/transactions/" +
          rowInfo.original.ID +
          "/user/" +
          rowInfo.original.Customer.User.ID
      );
    } else {
      this.props.history.push("/dashboard/transactions/" + rowInfo.original.ID);
    }
  }
  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };
  toggle = (e, data) => {
    let selection = [...this.state.selection];
    let id = parseInt(data.name.split("_")[1]);
    const keyIndex = selection.indexOf(id);
    if (keyIndex >= 0) {
      selection.splice(keyIndex, 1);
    } else {
      selection.push(id);
    }
    this.setState({ selection: selection });
  };
  checkInSelected = () => {
    checkInTransactions({ transactionIds: this.state.selection }, error => {
      // Get the transactions again for any changes
      this.setState({ selection: [] });
      this.getTransactions();
    });
  };
  getSelectedTotals = () => {
    let { selection, data } = this.state;

    let totals = {
      "Transaction Balance": 0,
      "Credit Amount": 0,
      Charged: 0,
      "Service Fee": 0,
      Gratuity: 0,
      "Other Fees": 0,
      Paid: 0,
      "Credit Card Amount": 0,
      "Cash Amount": 0,
      "Check Amount": 0,
      "Gift Card Amount": 0
    };

    selection.forEach(element => {
      let transaction = _.find(data, { ID: element });
      if (transaction) {
        _.forOwn(transaction, (value, key) => {
          let actualKey = _.startCase(key);
          if (totals[actualKey] !== undefined) {
            totals[actualKey] += parseFloat(value);
          }
        });
      }
    });
    return totals;
  };
  columnProps = (state, rowInfo, column, instance) => {
    let selection = this.state.selection;
    let returning = {};
    if (typeof column.Header === "string") {
      if (rowInfo && rowInfo.original.ID != null) {
        returning.style = { cursor: "pointer" };
      }

      returning.onClick = (e, handleOriginal) =>
        this.onRowClick(state, rowInfo, column);
    }
    if (rowInfo && selection.includes(rowInfo.original.ID)) {
      returning.className = "selected-row";
    }
    return returning;
  };
  render() {
    let {
      selection,
      data,
      pages,
      datesRange,
      loading,
      page,
      pageSize,
      sorted,
      filtered,
      totals
    } = this.state;

    return (
      <div>
        <Menu text stackable className="responsive-header">
          <Menu.Item>
            <Header as="h2">Transactions</Header>
          </Menu.Item>
          <Menu.Item>
            <DatesRangeInput
              animation="none"
              dateFormat="MM-DD-YYYY"
              name="datesRange"
              iconPosition="left"
              placeholder="From - To"
              value={datesRange}
              style={{ minWidth: "220px" }}
              onChange={this.handleChange}
            />
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <ConfirmTransactionCheckIns
                onConfirm={this.checkInSelected}
                totals={this.getSelectedTotals()}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Divider hidden fitted clearing />
        <ReactTable
          data={data}
          filterable
          loading={loading}
          className="-striped -highlight"
          manual
          page={page}
          sorted={sorted}
          filtered={filtered}
          pages={pages}
          pageSize={pageSize}
          // Callbacks
          onPageChange={pageIndex => {
            if (this.state.loading === true) return;
            this.setState({ page: pageIndex });
          }} // Called when the page index is changed by the user
          onPageSizeChange={(pageSize, pageIndex) => {
            if (this.state.loading === true) return;
            this.setState({ page: pageIndex, pageSize: pageSize });
          }} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
          onSortedChange={(newSorted, column, shiftKey) => {
            if (this.state.loading === true) return;
            this.setState({ sorted: newSorted });
          }} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
          onFilteredChange={(filtered, column) => {
            this.setState({ filtered: filtered });
          }} // Called when a user enters a value into a filter input field or the value passed to the onFiltersChange handler by the Filter option.
          columns={[
            {
              Header: "",
              columns: [
                {
                  Header: (
                    <Popup
                      inverted
                      trigger={<Icon name="check" />}
                      content="Checked-In"
                    />
                  ),
                  width: 40,
                  className: "checkbox-column",
                  filterable: false,
                  accessor: "checkedIn",
                  Cell: props => {
                    if (!props.original.appointmentId) return "";
                    if (props.value !== true) {
                      let id = props.original.ID;

                      return (
                        <Checkbox
                          checked={selection.includes(id)}
                          onChange={this.toggle}
                          name={"checkedIn_" + (props.original ? id : "")}
                        />
                      );
                    }
                    return <Icon name="check" />;
                  }
                }
              ]
            },
            {
              Header: "Grand Totals",
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Stylist",
                  accessor: data => {
                    return `${
                      data.Appointment &&
                      data.Appointment.Employee &&
                      data.Appointment.Employee.User
                        ? `${data.Appointment.Employee.User.firstName ||
                            ""} ${data.Appointment.Employee.User.lastName ||
                            ""}`
                        : ""
                    }`;
                  },
                  id: "employeeId",
                  filterable: true,
                  getProps: this.columnProps,
                  minWidth: 150,
                  headerClassName: "dropdown-filter",
                  Filter: ({ filter, onChange }) => (
                    <Form size="small">
                      <Form.Select
                        className="filter-select"
                        fluid
                        clearable
                        search
                        name="employeeId"
                        placeholder="Select Stylist"
                        options={this.props.stylists.map(stylist => {
                          let fullName =
                            stylist && stylist.User
                              ? `${stylist.User.firstName || ""} ${stylist.User
                                  .lastName || ""}`
                              : `${stylist.EmployeeFirstName ||
                                  ""} ${stylist.EmployeeLastName || ""}`;
                          return {
                            key: stylist.ID,
                            value: stylist.ID,
                            text: fullName
                          };
                        })}
                        value={filter ? filter.value : null}
                        onChange={(event, { name, value }) => onChange(value)}
                      />
                    </Form>
                  )
                }
              ]
            },

            {
              Header: () => <Money value={totals.totalTransactionBalance} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Transaction Balance",
                  accessor: "transactionBalance",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column important-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalOpeningBalance} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Opening Balance",
                  accessor: "openingBalance",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column important-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalCreditAmount} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Credit Given",
                  accessor: "creditAmount",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalCharged} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Total Charged",
                  accessor: "charged",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column important-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalServiceFee} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Service Fee",
                  accessor: "serviceFee",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalGratuity} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Gratuity",
                  accessor: "gratuity",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalOtherFees} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Other Fees",
                  accessor: "otherFees",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalPaid} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Total Paid",
                  accessor: "paid",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column important-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalCreditCardAmount} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Credit Card Amount",
                  accessor: "creditCardAmount",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalCashAmount} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Cash Amount",
                  accessor: "cashAmount",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalCheckAmount} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Check Amount",
                  accessor: "checkAmount",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalGiftCardAmount} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Gift Card Amount",
                  accessor: "giftCardAmount",
                  getProps: this.columnProps,
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            }
          ]}
        />
      </div>
    );
  }
}
TransactionsTable.defaultProps = {
  stylists: []
};

export default props => (
  <OptionsContext.Consumer>
    {({ stylists }) => <TransactionsTable {...props} stylists={stylists} />}
  </OptionsContext.Consumer>
);
