import React, { Component } from "react";
import CustomerDetails from "../Forms/CustomerDetails";
import Balance from "./Balance";

import Authorize from "./Authorize";

import CommentGroup from "./CommentGroup";
import RatingArea from "./RatingArea";
import { getUserDetails } from "../lib/apiCalls";
import { toast } from "react-toastify";

class CustomerOverview extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }
  componentDidMount() {
    this._isMounted = true;
    let { match } = this.props;
    if (match && match.params.userId) {
      getUserDetails({ userId: match.params.userId }, (error, response) => {
        if (error) {
          toast.error("Failed to retrieve user");
          return;
        }
        if (this._isMounted) {
          this.setState({ user: response });
        }
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let { user } = this.state;
    let customer = user.Customer ? user.Customer : {};
    return (
      <div>
        <CustomerDetails
          key={"customerDetails_" + customer.ID}
          customerId={customer.ID}
        />
        <Balance key={"balance_" + user.ID} userId={user.ID} />

        <Authorize permission="EditCustomers">
          {/* Show Customer rating and comments */}
          <CommentGroup
            key={"comments_" + user.ID}
            commentGroupId={user.commentGroupId}
          />
          <RatingArea key={"ratings_" + user.ID} userId={user.ID} />
        </Authorize>
      </div>
    );
  }
}

export default CustomerOverview;
