import React, { Component } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../UserContext";

/**
 * Retrieves the user component and determines if the user has the permission.
 * @prop {element} else Component to display if failing the permission check
 * @prop {string} permission Permission to check if the user has
 * @prop {[string]} permissions Returns true if any permissions in array pass
 * @prop {string} userRole User type of customer to check for
 */
class Authorize extends Component {
  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => {
          if (user === undefined) {
            return <div>ERROR: NO USER</div>;
          }
          if (
            this.props.userRole &&
            user.userType &&
            this.props.userRole === user.userType
          ) {
            return this.props.children;
          }
          if (
            user.permissions &&
            this.props.permissions &&
            Array.isArray(this.props.permissions) &&
            this.props.permissions.some(
              permission => user.permissions[permission]
            )
          ) {
            return this.props.children;
          }
          if (
            this.props.permission &&
            user.permissions &&
            user.permissions[this.props.permission] === true
          ) {
            return this.props.children;
          } else if (this.props.else !== null) {
            return this.props.else;
          } else {
            return null;
          }
        }}
      </UserContext.Consumer>
    );
  }
}

Authorize.defaultProps = {
  else: null,
  permission: null,
  permissions: null,
  userRole: null
};

Authorize.propTypes = {
  else: PropTypes.element,
  permission: PropTypes.string,
  permissions: PropTypes.array,
  userRole: PropTypes.string
};

export default Authorize;
