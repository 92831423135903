import React, { Component } from "react";
import { Container, Image, Segment } from "semantic-ui-react";
import { getPrivacyPolicy } from "../lib/apiCalls";
import { toast } from "react-toastify";

class PrivacyPolicy extends Component {
  componentDidMount() {
    getPrivacyPolicy(null, (error, response) => {
      if (error) {
        console.error(error);
        toast.error("Failed to retrieve Privacy Policy");
      } else if (response) {
        let div = document.getElementById("privacy-policy");
        div.innerHTML = response.trim();
      }
    });
  }
  render() {
    return (
      <Container text>
        <Segment basic>
          <Image src="/images/pet_love_logo.png" centered />
        </Segment>
        <div id="privacy-policy"></div>
      </Container>
    );
  }
}

export default PrivacyPolicy;
