import React, { Component } from "react";

import { Modal, Button, Segment, Table, Divider } from "semantic-ui-react";
import Money from "./Money";
import _ from "lodash";

class ConfirmTransactionCheckIns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  toggleOpen = () => {
    this.setState(state => ({ open: !state.open }));
  };
  render() {
    let tableRows = [];
    _.forOwn(this.props.totals, (value, key) => {
      tableRows.push(
        <Table.Row key={key}>
          <Table.Cell>
            <b>{key}</b>
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Money value={value} />
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Modal
        size="tiny"
        trigger={
          <Button
            content="Check-In Selected Transactions"
            color="blue"
            fluid
            onClick={this.toggleOpen}
          />
        }
        open={this.state.open}
      >
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Content>
          <div>
            Checking in transactions cannot be undone. Are you sure you want to
            check in the selected transactions?
            <Divider hidden />
            <center>
              <Segment basic>
                <Table compact="very" singleLine basic="very">
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell colSpan="2">Totals</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{tableRows}</Table.Body>
                </Table>
              </Segment>
            </center>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" key="cancel" onClick={this.toggleOpen} />
          <Button
            content="Check-In Selected"
            key="confirm"
            positive
            onClick={() => {
              this.props.onConfirm();
              this.toggleOpen();
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ConfirmTransactionCheckIns.defaultProps = {
  onConfirm: () => {},
  totals: {
    "Transaction Balance": 0,
    "Total Charged": 0,
    "Service Fee": 0,
    Gratuity: 0,
    "Other Fees": 0,
    "Total Paid": 0,
    "Credit Card Amount": 0,
    "Cash Amount": 0,
    "Check Amount": 0,
    "Gift Card Amount": 0
  }
};

export default ConfirmTransactionCheckIns;
