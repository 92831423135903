import React, { Component } from "react";
import {
  Modal,
  Form,
  Button,
  Icon,
  List,
  Header,
  Segment,
  Select
} from "semantic-ui-react";
import Authorize from "./Authorize";
import {
  sendTransactionSummary,
  getTransactionSummary,
  getCustomerInvoiceInfo
} from "../lib/apiCalls";
import { toast } from "react-toastify";
import Money from "./Money";
import Input from "./Input";
import _ from "lodash";
import moment from "moment";
import { moneyFormat } from "../lib/format";
import Validation from "./Validation";

class TransactionSummaryModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      transactionSummary: null,
      emailAddress: "",
      customerBalance: 0,
      emailAddresses: [],
      loading: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.customerId) return;
    getCustomerInvoiceInfo(
      { customerId: this.props.customerId },
      (error, response) => {
        if (error) {
          toast.error(
            "Failed to retrieve customer's balance and email addresses"
          );
          return;
        }
        if (this._isMounted) {
          let defaultEmail = _.find(response.emailAddresses, {
            isPrimary: true
          });
          this.setState({
            customerBalance: response.balance,
            emailAddresses: response.emailAddresses,
            emailAddress: defaultEmail ? defaultEmail.emailAddress : ""
          });
        }
      }
    );
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleDateChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    this.getTransactionSummary();
  };
  handleAddition = (e, { value }) => {
    this.setState(prevState => ({
      emailAddresses: [
        { emailAddress: value, key: value },
        ...prevState.emailAddresses
      ]
    }));
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  getTransactionSummary = _.debounce(() => {
    let { startDate, endDate } = this.state;
    if (!startDate || startDate === "" || !endDate || endDate === "") {
      return;
    }
    if (
      !moment(startDate, "MM-DD-YYYY").isValid() ||
      !moment(endDate, "MM-DD-YYYY").isValid()
    ) {
      return;
    }
    this.setState({ loading: true });
    getTransactionSummary(
      {
        startDate: startDate,
        endDate: endDate,
        customerId: this.props.customerId
      },
      (error, response) => {
        if (error) {
          toast.error("Failed to retrieve the transaction summary");
          this.setState({ loading: false });
          return;
        }
        if (this._isMounted) {
          this.setState({ transactionSummary: response, loading: false });
        }
      }
    );
  }, 400);
  handleSend = () => {
    let { startDate, endDate, emailAddress } = this.state;
    if (!startDate || startDate === "") {
      toast.info("A start date must be selected to send a transaction summary");
      return;
    }
    if (!endDate || endDate === "") {
      toast.info(
        "An end date must be selected to send a invtransaction summaryoice"
      );
      return;
    }
    if (!emailAddress || emailAddress === "") {
      toast.info("An email address is required to send a transaction summary");
      return;
    }
    let emailRegEx = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!emailRegEx.test(emailAddress)) {
      toast.info("Please enter a valid email. Example: john.doe@example.com");
      return;
    }
    sendTransactionSummary(
      {
        startDate: startDate,
        endDate: endDate,
        emailAddress: emailAddress,
        customerId: this.props.customerId
      },
      (error, response) => {
        if (error) {
          toast.error("Failed to send the transaction summary");
          return;
        }
        if (this._isMounted) {
          this.props.onClose();
          this.setState({
            startDate: "",
            endDate: "",
            transactionSummary: null,
            emailAddress: ""
          });
        }
        toast.success("Successfully sent transaction summary to customer");
      }
    );
  };
  cancel = () => {
    this.setState({
      startDate: "",
      endDate: "",
      transactionSummary: null,
      emailAddress: ""
    });
    this.props.onClose();
  };
  render() {
    let {
      startDate,
      endDate,
      customerBalance,
      emailAddress,
      transactionSummary,
      loading
    } = this.state;
    let { open, userName } = this.props;

    let totalServices = 0;
    let totalPayment = 0;
    let creditUsed = 0;
    if (transactionSummary) {
      totalServices = (
        parseFloat(transactionSummary.serviceFeeGroom || 0) +
        parseFloat(transactionSummary.serviceFeeTrip || 0) +
        parseFloat(transactionSummary.serviceFeeGenerator || 0) +
        parseFloat(transactionSummary.serviceFeeFuel || 0) -
        parseFloat(transactionSummary.serviceDiscountTotal || 0) +
        parseFloat(transactionSummary.serviceFeeOther || 0) +
        parseFloat(transactionSummary.serviceFeeSalesTax || 0)
      ).toFixed(2);
      totalPayment = (
        parseFloat(transactionSummary.cashAmount || 0) +
        parseFloat(transactionSummary.creditCardAmount || 0) +
        parseFloat(transactionSummary.giftCardAmount || 0) +
        parseFloat(transactionSummary.checkAmount || 0)
      ).toFixed(2);
      // Check if credit was given and if there was still money owed to use credit
      if (
        parseFloat(transactionSummary.creditAmount || 0) > 0 &&
        parseFloat(totalServices) +
          parseFloat(transactionSummary.gratuity || 0) >
          parseFloat(totalPayment)
      ) {
        // Get the max amount of credit to be used and apply towards the total payment
        let leftToPay = (
          parseFloat(totalServices) +
          parseFloat(transactionSummary.gratuity || 0) -
          parseFloat(totalPayment)
        ).toFixed(2);
        if (
          parseFloat(transactionSummary.creditAmount || 0) >
          parseFloat(leftToPay)
        ) {
          // Set credit used to left to pay when there is enough credit
          creditUsed = parseFloat(leftToPay);
        } else {
          // Set credit used to
          creditUsed = parseFloat(transactionSummary.creditAmount || 0);
        }
      }
    }

    let totalDue = (
      parseFloat(totalServices) +
      parseFloat(transactionSummary ? transactionSummary.gratuity || 0 : 0) -
      parseFloat(totalPayment) -
      parseFloat(transactionSummary ? transactionSummary.creditAmount || 0 : 0)
    ).toFixed(2);

    let emailAddressOptions = _.uniqBy(
      this.state.emailAddresses,
      "emailAddress"
    ).map(email => ({
      key: email.emailAddress,
      text: email.emailAddress,
      value: email.emailAddress
    }));
    // Add the email address value to the dropdown if not included
    if (emailAddress !== "") {
      let selectedEmail = _.find(emailAddressOptions, { value: emailAddress });
      if (!selectedEmail) {
        emailAddressOptions.push({
          key: emailAddress,
          text: emailAddress,
          value: emailAddress
        });
      }
    }

    return (
      <div>
        <Modal
          closeOnDimmerClick={false}
          open={open}
          onClose={this.props.onClose}
        >
          <Modal.Header style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>Transaction Summary</div>
            <div style={{ textAlign: "right", width: "50%" }}>
              Current Customer Balance:{" "}
              <span
                style={{
                  color: parseFloat(customerBalance) < 0 ? "red" : "green"
                }}
              >
                <Money value={customerBalance} />
              </span>
            </div>
          </Modal.Header>
          <Modal.Content
            style={{ borderBottom: "1px solid rgba(34,36,38,.15)" }}
          >
            <Form>
              <Form.Group>
                <Input
                  label="Start Date"
                  validation="date"
                  icon={false}
                  placeholder="Start Date"
                  isEditing
                  required
                  value={startDate}
                  name="startDate"
                  onChange={this.handleDateChange}
                />
                <Input
                  label="End Date"
                  validation="date"
                  icon={false}
                  placeholder="End Date"
                  isEditing
                  required
                  value={endDate}
                  name="endDate"
                  onChange={this.handleDateChange}
                />{" "}
                <Validation
                  validation={emailAddress ? "email" : null}
                  value={emailAddress || ""}
                  required
                >
                  {error => {
                    return (
                      <Form.Field error={error} required>
                        <label>Email</label>
                        <Select
                          search
                          clearable
                          required
                          error={error}
                          allowAdditions
                          name="emailAddress"
                          value={emailAddress}
                          onChange={this.handleChange}
                          options={emailAddressOptions}
                          onAddItem={this.handleChange}
                          placeholder="Choose Email"
                          noResultsMessage="Type to add new"
                        />
                      </Form.Field>
                    );
                  }}
                </Validation>
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Content scrolling>
            {transactionSummary ? (
              <Segment loading={loading} placeholder={loading}>
                <Header style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    Transaction Summary {userName ? " for " + userName : ""}
                  </div>
                  <div style={{ textAlign: "right", width: "50%" }}>
                    {parseFloat(totalDue) < 0 ? "Credit Gained" : "Due"} $
                    {parseFloat(Math.abs(moneyFormat(totalDue))).toFixed(2)}
                  </div>
                </Header>

                <Header content="Services" block attached="top" />
                <Segment attached="bottom">
                  <List divided verticalAlign="middle">
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.serviceFeeGroom} />
                      </List.Content>
                      <List.Content>Service Fee</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.serviceFeeTrip} />
                      </List.Content>
                      <List.Content>Trip Fee</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.serviceFeeGenerator} />
                      </List.Content>
                      <List.Content>Generator Fee</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.serviceFeeFuel} />
                      </List.Content>
                      <List.Content>Fuel Fee</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money
                          value={transactionSummary.serviceDiscountTotal}
                        />
                      </List.Content>
                      <List.Content>Discounts</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.serviceFeeOther} />
                      </List.Content>
                      <List.Content>Other</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.serviceFeeSalesTax} />
                      </List.Content>
                      <List.Content>Tax</List.Content>
                    </List.Item>
                    <List.Item>
                      <strong>
                        <List.Content floated="right">
                          <Money value={transactionSummary.serviceFee} />
                        </List.Content>
                        <List.Content>Services Total</List.Content>
                      </strong>
                    </List.Item>
                    <List.Item>
                      <strong>
                        <List.Content floated="right">
                          <Money value={transactionSummary.gratuity} />
                        </List.Content>
                        <List.Content>Gratuity</List.Content>
                      </strong>
                    </List.Item>
                  </List>
                </Segment>

                <Header content="Credit Given" block attached="top" />
                <Segment attached="bottom">
                  <List divided verticalAlign="middle">
                    <List.Item>
                      <strong>
                        <List.Content floated="right">
                          <Money value={transactionSummary.creditAmount} />
                        </List.Content>
                        <List.Content>Credited</List.Content>
                      </strong>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={creditUsed} />
                      </List.Content>
                      <List.Content>Used</List.Content>
                    </List.Item>
                  </List>
                </Segment>

                <Header content="Payments Received" block attached="top" />
                <Segment attached="bottom">
                  <List divided verticalAlign="middle">
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.cashAmount} />
                      </List.Content>
                      <List.Content>Cash</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.creditCardAmount} />
                      </List.Content>
                      <List.Content>Credit Card</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.checkAmount} />
                      </List.Content>
                      <List.Content>Check</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        <Money value={transactionSummary.giftCardAmount} />
                      </List.Content>
                      <List.Content>Gift Card</List.Content>
                    </List.Item>
                    <List.Item>
                      <strong>
                        <List.Content floated="right">
                          <Money value={totalPayment} />
                        </List.Content>
                        <List.Content>Payment Total</List.Content>
                      </strong>
                    </List.Item>
                  </List>
                </Segment>

                <Header content="Transaction Summary" block attached="top" />
                <Segment attached="bottom">
                  <List divided verticalAlign="middle">
                    <List.Item>
                      <strong>
                        <List.Content floated="right">
                          $
                          {parseFloat(Math.abs(moneyFormat(totalDue))).toFixed(
                            2
                          )}
                        </List.Content>
                        <List.Content>
                          {parseFloat(totalDue) < 0
                            ? "Credit Gained"
                            : "Amount Due"}
                        </List.Content>
                      </strong>
                    </List.Item>
                  </List>
                </Segment>
              </Segment>
            ) : (
              <Segment loading={loading} placeholder>
                <Header icon>
                  <Icon name="info" />
                  Select a start and end date to view the transaction summary to
                  send to the customer.
                </Header>
              </Segment>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Send Transaction Summary"
              color="green"
              onClick={this.handleSend}
            />
            <Button content="Cancel" onClick={this.cancel} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default props => (
  <Authorize permission="SendTransactionSummaries">
    <TransactionSummaryModal {...props} />
  </Authorize>
);
