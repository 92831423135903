import React, { Component } from "react";
import { Header, Form, Label, Grid, Button } from "semantic-ui-react";
import Input from "./Input";
import _ from "lodash";

class GratuitySlider extends Component {
  constructor(props) {
    super(props);
    let defaultValue = 0;
    let defaultPercent = props.defaultPercent;
    if (props.value) {
      defaultValue = props.value;
      if (props.price) {
        defaultPercent =
          (parseFloat(props.value) / parseFloat(props.price)).toFixed(2) / 0.01;
      }
    } else {
      defaultValue = props.price
        ? (props.price * (props.defaultPercent * 0.01)).toFixed(2)
        : 0;
    }
    this.state = {
      percent: defaultPercent,
      value: props.value || defaultValue
    };
    this.onChange = this.onChange.bind(this);
    this.onSlide = this.onSlide.bind(this);
    this.updateValue = this.updateValue.bind(this);

    // Debounce the update value for slider use
    this.updateValue = _.debounce(this.updateValue, 300);
  }
  componentDidMount() {
    this.props.onChange(this.state.value, null);
  }
  componentWillUnmount() {
    this.updateValue.cancel();
  }
  updateValue(percent) {
    let value = (percent / 100) * this.props.price;
    this.setState({ value: value.toFixed(2) });
    this.props.onChange(value, this.props.method);
  }
  onChange(e, { value }) {
    let percent = parseFloat(value / this.props.price).toFixed(2);
    this.setState({
      percent: percent * 100,
      value: value
    });
    this.props.onChange(value, this.props.method);
  }
  onMethodChange = (e, { value }) => {
    this.setState({
      method: value
    });
    this.props.onChange(this.props.value, value);
  };
  onSlide(e) {
    this.setState({
      percent: e.target.value
    });
    this.updateValue(e.target.value);
  }

  render() {
    let { percent, value } = this.state;
    let { method, acceptedGratuityMethods } = this.props;

    return (
      <div>
        <Header dividing>Gratuity </Header>
        <Form.Group>
          <Input
            width="4"
            label="Dollars"
            type="number"
            format="moneyInput"
            validation="money"
            isEditing
            value={value}
            placeholder="0.00"
            min="0.00"
            onChange={this.onChange}
          />
          <Form.Field width="12">
            <label>Percent</label>

            <Grid>
              <Grid.Row>
                <Grid.Column width="1">0%</Grid.Column>
                <Grid.Column width="13">
                  <span>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={percent}
                      className="slider"
                      onChange={this.onSlide}
                      list="slider-ticks"
                    />
                  </span>
                </Grid.Column>
                <Grid.Column textAlign="right" width="1">
                  100%
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <center>
              <Label
                content={parseFloat(percent).toFixed() + "%"}
                color="purple"
              />
            </center>
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>How would you like to pay the gratuity?</label>
          <Button
            content="Cash"
            toggle
            active={method === "cash"}
            value="cash"
            onClick={this.onMethodChange}
          />
          {acceptedGratuityMethods.includes("creditCard") && (
            <Button
              content="Credit Card"
              toggle
              active={method === "creditCard"}
              value="creditCard"
              onClick={this.onMethodChange}
            />
          )}
          {acceptedGratuityMethods.includes("check") && (
            <Button
              content="Check"
              toggle
              active={method === "check"}
              value="check"
              onClick={this.onMethodChange}
            />
          )}
          <Button
            content="Account Credit"
            toggle
            active={method === "credit"}
            value="credit"
            onClick={this.onMethodChange}
          />
        </Form.Field>
      </div>
    );
  }
}

GratuitySlider.defaultProps = {
  price: 0,
  method: "",
  defaultPercent: null,
  defaultDollar: null,
  acceptedGratuityMethods: [],
  onChange: () => {}
};

export default GratuitySlider;
