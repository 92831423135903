import React, { Component } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import Estimate from "../Shared/Estimate";
import ErrorBoundary from "../Shared/ErrorBoundary";
import Authorize from "../Shared/Authorize";

import EstimatesTable from "../Tables/Estimates";
import { UserContext } from "../UserContext";
import CustomerEstimate from "../Containers/CustomerEstimate";

class Estimates extends Component {
  render() {
    const { match } = this.props;
    return (
      <Authorize
        permission="ViewEstimates"
        else={
          <Authorize permission="ViewProfile">
            <ErrorBoundary>
              <Switch>
                {/* Render estimates table for user */}
                <Route
                  exact
                  path={`${match.path}/`}
                  render={props => (
                    <UserContext.Consumer>
                      {({ user }) => (
                        <EstimatesTable
                          {...props}
                          header={"Estimates"}
                          userId={user.userId}
                        />
                      )}
                    </UserContext.Consumer>
                  )}
                />
                {/* Render existing estimate for existing user */}
                <Route
                  path={`${match.path}/:id(\\d+)`}
                  component={CustomerEstimate}
                />
                <Redirect to={`${match.path}/`} />
              </Switch>
            </ErrorBoundary>
          </Authorize>
        }
      >
        <Authorize permission="EditEstimates">
          <ErrorBoundary>
            <Switch>
              {/* Render new estimate for existing user */}
              <Route
                path={`${match.path}/user/:userId(\\d+)`}
                component={Estimate}
              />
              {/* Render new estimate for new user */}
              <Route
                path={`${match.path}/user/new`}
                component={Estimate}
                state={{ newEstimate: true }}
              />
              {/* Render existing estimate for existing user */}
              <Route
                path={`${match.path}/:estimateId(\\d+)/user/:userId(\\d+)`}
                component={Estimate}
              />
              <Redirect to={`${match.path}/user/new`} />
            </Switch>
          </ErrorBoundary>
        </Authorize>
      </Authorize>
    );
  }
}

export default Estimates;
