import React, { Component } from "react";
import PropTypes from "prop-types";

import { FilePond, registerPlugin } from "react-filepond";
import { getToken } from "../lib/csrfToken";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import "filepond/dist/filepond.min.css";
import Axios from "axios";
import { toast } from "react-toastify";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileMetadata,
  FilePondPluginFileValidateType,

  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: this.props.files,
      confirmModalOpen: false,
      removeFileData: null,
      shouldRemoveFile: false
    };
  }
  handleRemovePicture = async file => {
    let { handleRemoveURL, data } = this.props;
    if (!handleRemoveURL) return;
    try {
      await Axios.delete(handleRemoveURL, {
        params: { fileName: file.filename, ...data },
        headers: { "X-CSRF-Token": getToken() }
      });
    } catch (error) {
      toast.error("Failed to delete picture.");
      console.error(error);
    }
  };

  render() {
    let { files, server, ...props } = this.props;
    return (
      <div>
        <FilePond
          name="file"
          allowRevert={false}
          onremovefile={this.handleRemovePicture}
          allowMultiple
          maxFileSize="5MB"
          itemInsertLocation="after"
          {...props}
          ref={ref => (this.pond = ref)}
          files={this.state.files}
          onupdatefiles={fileItems => {
            this.setState({ files: fileItems.map(fileItem => fileItem.file) });
          }}
          server={{
            ...server,
            process: {
              ...server.process,
              headers: { "X-CSRF-Token": getToken() }
            }
          }}
          fileMetadataObject={{
            ...this.props.data
          }}
          data={{
            ...props.data
          }}
          acceptedFileTypes={props.acceptedFileTypes}
          fileValidateTypeLabelExpectedTypes="Expects {allTypes}"
        />
      </div>
    );
  }
}
FileUploader.defaultProps = {
  data: {},
  files: [],
  maxFiles: 5,
  acceptedFileTypes: ["image/*"],
  handleRemoveURL: null,
  onRemovePicture: () => {}
};
FileUploader.propTypes = {
  data: PropTypes.object,
  files: PropTypes.array,
  maxFiles: PropTypes.number,
  acceptedFileTypes: PropTypes.array,
  handleRemoveURL: PropTypes.string
};
export default FileUploader;
