import React, { Component } from "react";
import PropTypes from "prop-types";

import { Header, List } from "semantic-ui-react";
import _ from "lodash";
import { makeAddressString, removeMapsco } from "../lib/helperFunctions";

/**
 * Displays customer information in a card like format
 */
class EstimateConfirmationCustomer extends Component {
  render() {
    let { isNewUser, user, newUser, estimate } = this.props;
    let tempUser = { ...user };
    let tempNewUser = { ...newUser };
    let mergedUser = _.merge(tempNewUser, tempUser);
    let userName = "";
    // Format an existing user's data
    if (!isNewUser && mergedUser) {
      if (mergedUser.Name) {
        userName = `${mergedUser.Name.firstName} ${mergedUser.Name.lastName}`;
      }
    }

    return (
      <div>
        <Header block>Customer Information</Header>
        <List celled size="large">
          <List.Item>
            <List.Content floated="right">
              {isNewUser
                ? `${mergedUser.Name.firstName} ${mergedUser.Name.lastName}`
                : userName}
            </List.Content>
            <List.Header>Name</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {isNewUser
                ? mergedUser.phoneNumber + ""
                : estimate.phoneNumberString}
            </List.Content>
            <List.Header>Phone Number</List.Header>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {isNewUser ? mergedUser.emailAddress + "" : estimate.emailString}
            </List.Content>
            <List.Header>Email Address</List.Header>
          </List.Item>
          <List.Item>
            <List.Header>Address</List.Header>
            <List.Content>
              {isNewUser
                ? removeMapsco(makeAddressString(mergedUser.Address)).address +
                  ""
                : estimate.addressString}
            </List.Content>
          </List.Item>
        </List>
      </div>
    );
  }
}

EstimateConfirmationCustomer.defaultProps = {
  user: {},
  newUser: {},
  estimate: {},
  isNewUser: false
};

EstimateConfirmationCustomer.propTypes = {
  user: PropTypes.object,
  newUser: PropTypes.object,
  estimate: PropTypes.object,
  isNewUser: PropTypes.bool
};

export default EstimateConfirmationCustomer;
