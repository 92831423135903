import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Dropdown,
  Button,
  Modal,
  Header,
  Loader,
  Segment,
  Icon
} from "semantic-ui-react";
import { toast } from "react-toastify";
import _ from "lodash";

import Axios from "axios";
import { getToken } from "../lib/csrfToken";

import Authorize from "../Shared/Authorize";
import { OptionsContext } from "../OptionsContext";

import ConfirmCancelModal from "../Shared/ConfirmCancelModal";
import { isNumber } from "../lib/validation";

class UserTypePermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTypes: [],
      selectedUserType: this.props.selectedUserType,
      hasChanged: false,
      waitingUserType: null
    };
  }
  componentDidMount() {
    this.getAllUserTypes(userTypes => {
      if (userTypes.length > 0)
        this.setState({ selectedUserType: userTypes[0] });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.userTypes.length < 1 && this.props.userTypes.length > 0) {
      this.getAllUserTypes(userTypes => {
        if (userTypes.length > 0)
          this.setState({ selectedUserType: userTypes[0] });
      });
    }
  }
  /**
   * Calls api for all user types, maps them to option format and saves them to state
   * @param {function} callback Function called with returned user type options
   */
  getAllUserTypes = callback => {
    if (!this.props.userTypes || this.props.userTypes.length < 1) {
      callback([]);
      return;
    }
    // Retrieve all user types
    // Map to option format and save permissions as separate values
    const userTypes = this.props.userTypes.map(userType => {
      const { ID, UserType, ...rest } = userType;
      return {
        key: ID,
        text: UserType,
        value: ID,
        permissions: rest
      };
    });
    // Set initial state and initial selected user type
    this.setState({ userTypes: userTypes });
    if (callback) callback(userTypes);
  };
  getPermissions = permissions => {
    let options = [];
    _.forEach(permissions, (value, key) => {
      options.push(
        <Form.Checkbox
          checked={value}
          key={key}
          label={key.replace(/([A-Z])/g, " $1").trim()}
          name={key}
          onChange={this.handlePermissionChange}
        />
      );
    });
    return options;
  };
  getSelectedUserTypeIndex = id => {
    return _.findIndex(this.state.userTypes, ["key", id]);
  };
  handleAddition = (e, { value }) => {
    if (this.state.hasChanged) {
      return;
    }
    let newUserType = {
      text: value,
      value: value,
      key: value,
      // Grab first user type permission keys and map false to every entry
      permissions: _.mapValues(this.state.userTypes[0].permissions, () => false)
    };
    this.setState({
      userTypes: [newUserType, ...this.state.userTypes],
      selectedUserType: newUserType,
      hasChanged: true
    });
  };
  handleChange = (e, { value }) => {
    const { userTypes, hasChanged, selectedUserType } = this.state;
    let userType = _.find(userTypes, ["value", value]);
    // Should not change if the selected permission is the same permission that was previously selected
    // or the user type does not exists in the list
    if (selectedUserType.value === value) return;

    // If the user type has been edited, update the user type waiting to change,
    // Otherwise, update the usertype and say it hasn't been edited yet
    if (!isNumber(value)) {
      this.handleNewUserType(value);
      return;
    }
    if (!hasChanged) {
      this.setState({
        selectedUserType: userType,
        hasChanged: false
      });
    } else {
      this.setState({ waitingUserType: userType });
    }
  };
  handleNewUserType = value => {
    if (!isNumber(this.state.selectedUserType.value)) {
      this.setState({
        waitingUserType: {
          text: value,
          value: value,
          key: value,
          // Grab first user type permission keys and map false to every entry
          permissions: _.mapValues(
            this.state.userTypes[0].permissions,
            () => false
          )
        }
      });
      return;
    }
    this.setState({
      selectedUserType: {
        text: value,
        value: value,
        key: value,
        // Grab first user type permission keys and map false to every entry
        permissions: _.mapValues(
          this.state.userTypes[0].permissions,
          () => false
        )
      }
    });
  };
  handlePermissionChange = (e, { name, checked }) => {
    // Make a copy of selected user type to alter
    let selectedUserType = { ...this.state.selectedUserType };
    // Update the permission for the currently selected user type
    let updatedUserType = {
      ...selectedUserType,
      permissions: { ...selectedUserType.permissions, [name]: checked }
    };
    // Updated selected user values and let ui know the values have changed
    this.setState({
      selectedUserType: updatedUserType,
      hasChanged: true
    });
  };
  handleCancel = () => {
    const { selectedUserType, userTypes } = this.state;
    // Reverts the state back to initial values of the selected user
    this.setState({
      selectedUserType: _.find(userTypes, ["key", selectedUserType.key]),
      hasChanged: false,
      waitingUserType: null
    });
  };
  handleDelete = async () => {
    const { selectedUserType, replacingID } = this.state;
    try {
      // Call api for delete function
      let response = await Axios.post(
        `/api/v4/management/deleteUserType`,
        {
          ID: selectedUserType.key,
          ReplacingID: replacingID
        },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );

      // Set new selected user type when successfully deleted
      if (response.status === 200) {
        this.getAllUserTypes(userTypes => {
          this.setState({
            selectedUserType: userTypes[0],
            confirmDelete: false
          });
        });
        toast.success("Deleted user type: " + selectedUserType.text);
      }
    } catch (error) {
      toast.error("Failed to delete user type: " + selectedUserType.text);
      this.setState({ confirmDelete: false });
      console.error(error);
    }
  };
  handleNo = () => {
    const { waitingUserType } = this.state;
    // Remove the unsaved user type from options if not saving
    let tempUserTypes = [...this.state.userTypes];
    if (!isNumber(this.state.selectedUserType.value)) {
      tempUserTypes.shift();
    }
    if (!isNumber(waitingUserType.value)) {
      tempUserTypes.unshift(waitingUserType);
    }
    this.setState({
      selectedUserType: waitingUserType,
      userTypes: tempUserTypes,
      waitingUserType: null,
      hasChanged: !isNumber(waitingUserType.value) ? true : false
    });
  };
  handleSubmit = async () => {
    const { selectedUserType, hasChanged, waitingUserType } = this.state;
    if (hasChanged) {
      try {
        // Call api for create or update function
        let isNew = !isNumber(selectedUserType.key);
        let response = await Axios.post(
          `/api/v4/management/user-type/${isNew ? "create" : "update"}`,
          {
            ID: isNew ? null : selectedUserType.key,
            UserType: selectedUserType.text,
            Permissions: selectedUserType.permissions
          },
          {
            headers: { "X-CSRF-Token": getToken() }
          }
        );
        this.setState({ hasChanged: false });
        // Retrieve the updated user type values from the database once a new user type has been added in
        if (selectedUserType.key < 0) {
          this.getAllUserTypes(userTypes => {
            this.setState({
              selectedUserType: userTypes[userTypes.length - 1]
            });
          });
        }
        // If the submit button clicked from the modal, update the selected user type
        if (waitingUserType) {
          this.setState({ selectedUserType: waitingUserType });
        }
        if (response.status === 200) {
          toast.success("Saved permissions for " + selectedUserType.text);
          if (isNew) {
            let tempUserTypes = [...this.state.userTypes];
            tempUserTypes[0] = _.merge(tempUserTypes[0], {
              key: response.data.ID,
              value: response.data.ID
            });
            this.setState({ userTypes: tempUserTypes });
          }
        }
      } catch (error) {
        this.setState({ waitingUserType: null });
        toast.error(
          "Failed to save permissions for user type: " + selectedUserType.text
        );
        console.error(error);
      }
    }
  };
  render() {
    const { selectedUserType, userTypes, hasChanged } = this.state;
    if (selectedUserType !== "" && selectedUserType != null) {
      var permissions = this.getPermissions(selectedUserType.permissions);
    }
    return (
      <>
        <Header as="h3">
          User Type Permissions
          {/* Popup to display a tooltip when hovered for permissions dropdown*/}
          <Modal
            size="mini"
            trigger={
              <Button floated="right" size="mini">
                <Icon fitted name="info" />
              </Button>
            }
            header="Heres a tip!"
            content="To create a new user type, simply type in the dropdown and select the Add [New User Type] option."
            actions={[{ key: "done", content: "Done", positive: true }]}
          />
        </Header>

        <Form>
          {/* Select a user type (role) */}
          <Dropdown
            options={userTypes}
            placeholder="Choose User Type"
            search
            fluid
            selection
            allowAdditions
            value={selectedUserType.value}
            onAddItem={this.handleAddition}
            onChange={this.handleChange}
          />

          <br />

          {/* While loading, show loading icon */}
          {permissions || (
            <Segment basic>
              <Loader inverted size="medium">
                Loading
              </Loader>
            </Segment>
          )}

          {/* Show clear changes when altering an exisiting selected user type */}
          {isNumber(selectedUserType.key) && (
            <Button content="Clear Changes" onClick={this.handleCancel} />
          )}

          {/* Button to handle saving the user type */}
          <Button
            color="purple"
            disabled={!hasChanged}
            content={
              isNumber(selectedUserType.key) ? "Save" : "Save New User Type"
            }
            onClick={this.handleSubmit}
          />

          {isNumber(selectedUserType.key) && userTypes.length > 1 && (
            <Button
              content="Delete"
              color="red"
              onClick={() => this.setState({ confirmDelete: true })}
            />
          )}

          {/* Modal for warning about deleting a user type */}
          <Modal
            open={this.state.confirmDelete}
            onClose={() => this.setState({ confirmDelete: false })}
          >
            <Modal.Header content="Warning: Permanent Change" />
            <Modal.Content
              content={
                <>
                  To permanently delete user type <b>{selectedUserType.text}</b>
                  , select a user type to convert users to
                  <br />
                  {/* Select a user type (role) to replace the user type to be deleted */}
                  <Dropdown
                    options={_.filter(userTypes, function(type) {
                      return type.text !== selectedUserType.text;
                    })}
                    placeholder="Choose User Type"
                    search
                    selection
                    onChange={(e, { value }) => {
                      this.setState({ replacingID: value });
                    }}
                  />
                </>
              }
            />
            <Modal.Actions>
              <Button
                icon="chevron left"
                content="Cancel"
                onClick={() => this.setState({ confirmDelete: false })}
              />
              <Button
                icon="trash alternate"
                content="Delete"
                color="red"
                onClick={this.handleDelete}
              />
            </Modal.Actions>
          </Modal>

          {/* Modal for warning about unsaved changes */}
          <ConfirmCancelModal
            handleCancel={() => this.setState({ waitingUserType: null })}
            open={this.state.waitingUserType !== null}
            handleNo={this.handleNo}
            handleSave={this.handleSubmit}
            content={
              <>
                Do you want to save changes to user type
                <b>{" " + selectedUserType.text}</b>?
              </>
            }
          />
        </Form>
      </>
    );
  }
}

UserTypePermissions.defaultProps = {
  userTypes: [],
  permissions: [],
  selectedUserType: {}
};

UserTypePermissions.propTypes = {
  userTypes: PropTypes.array,
  permissions: PropTypes.array,
  selectedUserType: PropTypes.object
};

export default props => (
  <Authorize permission="EditPermissions">
    <OptionsContext.Consumer>
      {({ userTypes }) => {
        return <UserTypePermissions {...props} userTypes={userTypes} />;
      }}
    </OptionsContext.Consumer>
  </Authorize>
);
