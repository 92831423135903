import React, { Component } from "react";

import {
  Rating as SemanticRating,
  Segment,
  Button,
  Header,
  Form,
  Modal
} from "semantic-ui-react";
import TextArea from "../Shared/TextArea";
import { submitAppointmentRating } from "../lib/apiCalls";
import { toast } from "react-toastify";

/**
 * Rating form with option for a user to explain their rating
 */
class RatingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      comment: ""
    };
    this.onRate = this.onRate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  onRate(event, { rating }) {
    this.setState({ rating: rating });
  }
  handleChange(event, { name, value }) {
    this.setState({ [name]: value });
  }
  handleSubmit() {
    if (this.state.comment === "") {
      toast.info("A comment is required to rate an appointment");
      return;
    }
    submitAppointmentRating(
      {
        appointmentId: this.props.appointmentId,
        comment: this.state.comment,
        rating: this.state.rating
      },
      (error, response) => {
        if (error) {
          toast.error("Failed to submit appointment rating");
          return;
        }
        toast.success("Successfully submitted appointment rating");
        this.setState({ rating: 0, comment: "" });
        this.props.onClose(true);
      }
    );
  }
  render() {
    let { rating, comment } = this.state;
    let { message, subMessage, open } = this.props;

    return (
      <Modal onClose={this.props.onClose} open={open}>
        <Modal.Header>
          <Header size="small">
            {message}
            <Header.Subheader>{subMessage}</Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <Segment basic>
            <Form>
              <Form.Field>
                <SemanticRating
                  icon="star"
                  rating={rating}
                  size="huge"
                  maxRating={5}
                  onRate={this.onRate}
                />
              </Form.Field>
              <Form.Field>
                <label>Comments</label>
                <TextArea
                  isEditing
                  value={comment}
                  name="comment"
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Submit Rating" onClick={this.handleSubmit} primary />
          <Button content="Cancel" onClick={this.props.onClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}

RatingModal.defaultProps = {
  message: "Rate Your Experience",
  subMessage: "How was the experience? Were there any difficulties?",
  onSave: () => {},
  onClose: () => {},
  open: false
};

export default RatingModal;
