import React, { Component } from "react";
import { List } from "semantic-ui-react";
import Image from "./Image";
import { OptionsContext } from "../OptionsContext";

import _ from "lodash";

/**
 * Takes a list of pets and renders the pet names in a comman seperated list
 * @prop {[object]} pets Pets to retrieve the names from
 * @prop {string} emptyLabel What to display when there are no pets
 * @prop {boolean} includeDetails Includes a pet's weight and breed under their name
 * @prop {boolean} includePetAvatar Displays the pets' avatars when they have one next to their name if include details is also true
 */

class PetList extends Component {
  render() {
    let { emptyLabel, pets, includeDetails, includePetAvatar } = this.props;
    if (!pets || pets.length < 1) {
      return <div>{emptyLabel}</div>;
    }
    let sortedPets = _.sortBy(pets, [
      function(p) {
        return p.petName;
      }
    ]);
    if (includeDetails === true) {
      return (
        <OptionsContext.Consumer>
          {({ breeds }) => (
            <List>
              {sortedPets.map(pet => {
                let breed = _.find(breeds, { ID: pet.breedId });
                if (breed) {
                  breed = breed.breed;
                }
                return (
                  <List.Item key={pet.ID}>
                    {includePetAvatar && (
                      <Image
                        avatar
                        blankAvatarOnError
                        src={
                          "/api/v4/pets/getPetProfilePicture?petId=" +
                          encodeURI(pet.ID)
                        }
                      />
                    )}
                    <List.Content>
                      <List.Header>{pet.petName}</List.Header>
                      <List.Description>
                        {breed}
                        {pet.weight ? `, ${pet.weight} lbs` : ""}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          )}
        </OptionsContext.Consumer>
      );
    }
    if (includeDetails === "more") {
      return (
        <List divided>
          {sortedPets.map(pet => (
            <PetListItem {...pet} />
          ))}
        </List>
      );
    } else {
      let petString = "";
      sortedPets.forEach((element, i) => {
        petString += element.petName;
        if (i < sortedPets.length) {
          petString += ", ";
        }
      });
      return <div>{petString}</div>;
    }
  }
}
PetList.defaultProps = {
  pets: [],
  emptyLabel: "",
  includePetAvatar: false,
  includeDetails: false
};
export default PetList;

function PetListItem(props) {
  // let { ID, petName, weight, breed } = props;
  let { petName } = props;
  return (
    <List.Item>
      <List.Content>
        <List.Header>{petName}</List.Header>
      </List.Content>
    </List.Item>
  );
}
