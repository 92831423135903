import React, { Component } from "react";
import { Comment, Rating } from "semantic-ui-react";

import moment from "moment";

class RatingItem extends Component {
  render() {
    let { rating, rater, comment, DateAdded } = this.props;
    return (
      <div>
        <Comment>
          <Comment.Content>
            <Comment.Author>{rater}</Comment.Author>
            <Comment.Metadata>
              <div>
                <Rating rating={rating} maxRating={5} disabled />
              </div>
            </Comment.Metadata>
            <Comment.Metadata>
              <div>{moment(DateAdded).format("LLL")}</div>
            </Comment.Metadata>
            <Comment.Text>{comment || ""}</Comment.Text>
          </Comment.Content>
        </Comment>
      </div>
    );
  }
}

export default RatingItem;
