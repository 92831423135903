import React, { Component } from "react";
import ReactTable from "react-table";
import io from "../socketConnection";
import { toast } from "react-toastify";
import Money from "../Shared/Money";
import moment from "moment";
import { filterDateAfter } from "../lib/filters";
import { DateInput } from "semantic-ui-calendar-react";
import { Segment, Header } from "semantic-ui-react";

class CustomerTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filterDate: moment().format("MM-DD-YYYY"),
      loading: false
    };
  }
  componentDidMount() {
    this.getTransactions();
  }
  getTransactions = () => {
    this.setState({ loading: true });
    io.socket.get(
      "/api/v4/transactions/findByCustomer",
      { userId: this.props.userId || undefined },
      (body, jwr) => {
        if (jwr.statusCode !== 200) {
          toast.error("Failed to retrieve transactions for customer.");
          this.setState({ loading: false });
        }
        this.setState({
          data: body.transactions,
          loading: false
        });
      }
    );
  };
  onRowClick(state, rowInfo, column) {
    if (this.state.loading === true) return;
    // Default to pushing to path
    if (rowInfo.original["Customer.UserID"]) {
      this.props.history.push(
        "/dashboard/transactions/" +
          rowInfo.original.ID +
          "/user/" +
          rowInfo.original["Customer.UserID"]
      );
    }
  }
  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };
  render() {
    let { data, pages, filterDate, loading } = this.state;
    return (
      <div>
        <Header as="h2" textAlign="center">
          Transactions
        </Header>
        <Segment>
          <ReactTable
            defaultSorted={[{ id: "transactionDate", desc: true }]}
            data={data}
            filterable
            defaultPageSize={5}
            columns={[
              {
                Header: "Transaction Date",
                accessor: "transactionDate",
                minWidth: 150,
                Filter: ({ onChange }) => (
                  <DateInput
                    animation="none"
                    dateFormat={"MM-DD-YYYY"}
                    name="filterDate"
                    placeholder="From"
                    value={filterDate}
                    closable
                    iconPosition="left"
                    onChange={(e, data) => {
                      this.handleChange(e, data);
                      onChange(data.value);
                    }}
                  />
                ),
                filterMethod: filterDateAfter,
                Cell: props => moment(props.value).format("LLL")
              },

              {
                Header: "Transaction Balance",
                accessor: "transactionBalance",
                headerClassName: "important-column",
                Cell: props => <Money value={props.value} />,
                className: "money-column important-column"
              },
              {
                Header: "Opening Balance",
                accessor: "openingBalance",
                headerClassName: "important-column",
                Cell: props => <Money value={props.value} />,
                className: "money-column important-column"
              },
              {
                Header: "Credit Given",
                accessor: "creditAmount",
                Cell: props => <Money value={props.value} />,
                className: "money-column"
              },
              {
                Header: "Total Charged",
                accessor: "charged",
                headerClassName: "important-column",
                Cell: props => <Money value={props.value} />,
                className: "money-column important-column"
              },
              {
                Header: "Service Fee",
                accessor: "serviceFee",
                Cell: props => <Money value={props.value} />,
                className: "money-column"
              },
              {
                Header: "Cancel Fee",
                accessor: "cancelFee",
                Cell: props => <Money value={props.value} />,
                className: "money-column"
              },
              {
                Header: "Returned Fee",
                accessor: "returnedFee",
                Cell: props => <Money value={props.value} />,
                className: "money-column"
              },
              {
                Header: "Reprocess Fee",
                accessor: "reprocessFee",
                Cell: props => <Money value={props.value} />,
                className: "money-column"
              },
              {
                Header: "Total Paid",
                accessor: "paid",
                headerClassName: "important-column",
                Cell: props => <Money value={props.value} />,
                className: "money-column important-column"
              },
              {
                Header: "Credit Card Amount",
                accessor: "creditCardAmount",
                Cell: props => <Money value={props.value} />,
                className: "money-column"
              },
              {
                Header: "Cash Amount",
                accessor: "cashAmount",
                Cell: props => <Money value={props.value} />,
                className: "money-column"
              },
              {
                Header: "Check Amount",
                accessor: "checkAmount",
                Cell: props => <Money value={props.value} />,
                className: "money-column"
              },
              {
                Header: "Gift Card Amount",
                accessor: "giftCardAmount",
                Cell: props => <Money value={props.value} />,
                className: "money-column"
              }
            ]}
            pages={pages}
            loading={loading}
            className="-striped -highlight"
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.original.ID != null) {
                var style = { cursor: "pointer" };
              }
              return {
                onClick: () => this.onRowClick(state, rowInfo, column),
                style: style
              };
            }}
          />
        </Segment>
      </div>
    );
  }
}

export default CustomerTransactions;
