import React, { Component } from "react";
import { Segment, Form, Button } from "semantic-ui-react";

import Input from "../Shared/Input";
import _ from "lodash";

class TodoCategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.todoCategory
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state, prevState)) {
      this.props.onUpdate(this.state);
    }
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleSave = () => {
    this.props.handleSave(this.state);
  };
  handleDelete = () => {
    this.props.handleDelete(this.state);
  };
  render() {
    let { ID, category } = this.state;
    let { hideSaveButton } = this.props;

    return (
      <Segment basic>
        <Form>
          <Input
            label="Category"
            required
            value={category}
            onChange={this.handleChange}
            name="category"
            isEditing={ID == null}
          />
          {!hideSaveButton && (
            <Button color="red" onClick={this.handleDelete}>
              Delete
            </Button>
          )}
        </Form>
      </Segment>
    );
  }
}

TodoCategoryForm.defaultProps = {
  handleSave: () => {},
  handleDelete: () => {},
  onUpdate: () => {},
  todoCategory: {},
  hideSaveButton: false
};

export default TodoCategoryForm;
