import React, { Component } from "react";

import { Header, Segment, Icon, Button, Popup } from "semantic-ui-react";
import { defaultEstimate } from "../lib/defaultObjects";

import io from "../socketConnection";
import Axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";

import AppointmentDetails from "../Forms/AppointmentDetails";
import Carousel from "./Carousel/Carousel";
import { OptionsContext } from "../OptionsContext";
import PreviewEstimate from "./PreviewEstimate";
import { resolveServiceIssue } from "../lib/apiCalls";
import Authorize from "./Authorize";
import InvoiceModal from "./InvoiceModal";

class AppointmentHistoryPreview extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      estimate: null,
      customer: null,
      images: [],
      openInvoiceModal: false
    };
    this.loadEstimate = this.loadEstimate.bind(this);
    this.getPicturePaths = this.getPicturePaths.bind(this);
    this.handleResolveServiceIssue = this.handleResolveServiceIssue.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let { estimateId, appointment } = this.props;
    if (appointment && appointment.ID) {
      this.getPicturePaths();
    }
    if (estimateId) {
      this.loadEstimate(estimateId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  closeInvoiceModal = () => {
    this.setState({ openInvoiceModal: false });
  };
  openInvoiceModal = () => {
    this.setState({ openInvoiceModal: true });
  };
  handleResolveServiceIssue(data) {
    resolveServiceIssue({ ...data }, (error, response) => {
      if (error) {
        return;
      }
      if (response) {
        this.props.onAppointmentAction();
      }
    });
  }
  async getPicturePaths() {
    let { appointment } = this.props;

    if (!appointment) return;
    try {
      let response = await Axios.get("/api/v4/appointments/getPicturePaths", {
        params: {
          appointmentId: appointment.ID,
          onlyCustomer: false
        }
      });
      if (this._isMounted) {
        if (response.status === 200) {
          // Check if files were returned in array
          if (response.data && Array.isArray(response.data)) {
            this.setState({ images: response.data });
          } else {
            this.setState({ images: [] });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  loadEstimate(id) {
    this.setState({ loading: true });
    io.socket.get(
      "/api/v4/estimate/findById",
      { estimateId: id },
      (responseData, jwres) => {
        if (jwres.error) {
          console.error(jwres.error);
          toast.error("Failed to load estimate.");
          if (this._isMounted) {
            this.setState({ estimate: null, loading: false });
          }
          return;
        }
        let estimate = responseData.estimate;
        let customer = responseData.customer;
        if (jwres.statusCode === 200 && estimate) {
          if (estimate.EstimatePets) {
            for (let estimatePet in estimate.EstimatePets) {
              let services = {};
              for (let extra of estimate.EstimatePets[estimatePet]
                .EstimatePetExtras) {
                let foundService = _.find(this.props.services, {
                  ID: extra.EstimateExtraID
                });
                if (foundService) {
                  services[foundService.item] = foundService;
                }
              }
              estimate.EstimatePets[estimatePet] = {
                ...estimate.EstimatePets[estimatePet],
                services: services
              };
            }
          }
          if (this._isMounted) {
            this.setState({
              loading: false,
              customer: customer,
              estimate: defaultEstimate({
                ...estimate,
                salesTax: this.props.salesTax,
                fuelCharge: this.props.fuelCharge,
                tripFee: this.props.tripFee,
                generatorFee: this.props.generatorFee
              })
            });
          }
        } else {
          toast.error("Failed to load estimate.");
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  render() {
    let { loading, estimate, customer, images, openInvoiceModal } = this.state;
    let { appointment, statuses } = this.props;

    if (loading) {
      return <Segment loading placeholder basic />;
    }

    let newAppointment = false;
    let appointmentStatus = appointment
      ? _.find(statuses, { ID: appointment.statusId })
      : null;

    // Set new appointment flag
    if (
      (!appointment ||
        !appointment.ID ||
        (appointmentStatus && appointmentStatus.status === "waiting")) &&
      estimate
    ) {
      newAppointment = true;
    }

    if (!appointment && !estimate) {
      return (
        <Segment basic placeholder>
          <Header icon>
            <Icon name="calendar check" />
            Select an Appointment to See Details
          </Header>
        </Segment>
      );
    }
    let paths = images.map(
      file =>
        `/api/v4/appointments/getPicture?appointmentId=${encodeURI(
          appointment.ID
        )}&fileName=${encodeURI(file.name)}`
    );
    let transactionId =
      appointment.Transactions && appointment.Transactions.length > 0
        ? _.minBy(appointment.Transactions, "DateAdded").ID
        : null;
    let fullName =
      customer && customer.User
        ? `${customer.User.firstName || ""} ${customer.User.lastName || ""}`
        : "Unknown";
    return (
      <div>
        {paths.length > 0 && <Carousel images={paths} />}
        <Header
          as="h3"
          content={
            !newAppointment ? "Selected Appointment" : "Create New Appointment"
          }
          block
        />
        <Button
          content="Transaction"
          as={Link}
          color="blue"
          disabled={!transactionId}
          to={`/dashboard/transactions/${transactionId}/user/${
            customer && customer.User ? customer.User.ID : ""
          }`}
        />
        <Popup
          inverted
          basic
          content="Create a new transaction associated to this appointment."
          trigger={
            <Button
              content="New Transaction"
              as={Link}
              color="blue"
              disabled={!appointment.ID}
              to={{
                pathname: `/dashboard/transactions/new/user/${
                  customer && customer.User ? customer.User.ID : ""
                }`,
                state: { appointmentId: appointment.ID }
              }}
            />
          }
        />
        <Authorize permission="SendInvoices">
          <Button
            content="Send Invoice"
            color="blue"
            disabled={!transactionId}
            onClick={this.openInvoiceModal}
          />
          <InvoiceModal
            key={customer ? customer.ID : 0}
            open={openInvoiceModal}
            onClose={this.closeInvoiceModal}
            customerId={customer ? customer.ID : null}
            userName={fullName}
            appointmentId={appointment.ID}
          />
        </Authorize>
        <Segment basic>
          <AppointmentDetails
            isEditing={false}
            key={
              appointment
                ? appointment.ID || appointment.appointmentDate
                : undefined
            }
            onResolveServiceIssue={this.handleResolveServiceIssue}
            columns={1}
            {...appointment}
          />
        </Segment>
        {estimate && (
          <PreviewEstimate
            estimate={estimate}
            customer={customer}
            appointmentId={appointment ? appointment.ID : null}
          />
        )}
      </div>
    );
  }
}

AppointmentHistoryPreview.defaultProps = {
  appointment: null,
  estimateId: null,
  statuses: [],
  onAppointmentAction: () => {}
};

export default props => (
  <OptionsContext.Consumer>
    {({ statuses }) => (
      <AppointmentHistoryPreview {...props} statuses={statuses} />
    )}
  </OptionsContext.Consumer>
);
