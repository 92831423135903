import React, { Component } from "react";
import ReactTable from "react-table";

import { toast } from "react-toastify";
import { Header, Divider, Menu, Button, Icon } from "semantic-ui-react";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import Money from "../Shared/Money";

import io from "../socketConnection";
import moment from "moment";
import _ from "lodash";
import ReactToPrint from "react-to-print";
import CsvDownloader from "react-csv-downloader";

class DailyTipsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      datesRange: `${moment()
        .format("MM-DD-YYYY")} - ${moment()
        .format("MM-DD-YYYY")}`,
      totals: {},
      loading: false,
      startDate: "",
      endDate: ""
    };
  }
  componentDidMount() {
    this.getTips();
  }
  componentDidUpdate(prevProps, prevState) {
    // Get the tips whenever the date range is modifed.
    if (
      !_.isEqual(
        { datesRange: prevState.datesRange },
        { datesRange: this.state.datesRange }
      )
    ) {
      this.getTips();
    }
  }
  getTips = _.debounce(() => {
    if (this.state.loading === true) return;
    this.setState({ loading: true });

    let splitDates = this.state.datesRange.split(" - ");

    let startDate = splitDates && splitDates[0];
    let endDate = splitDates && splitDates[1];

    this.setState({ startDate: startDate, endDate: endDate });

    io.socket.get(
      "/api/v4/reports/getDailyTips",
      {
        startDate: startDate || new Date(),
        endDate: endDate
      },
      (body, jwr) => {
        if (jwr.statusCode !== 200) {
          toast.error("Failed to retrieve all tips.");
          this.setState({ loading: false });
        }
        this.setState({
          data: body,
          loading: false
        });
      }
    );
  }, 300);
  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };
  render() {
    let { data, datesRange, loading } = this.state;

    return (
      <div ref={el => (this.componentRef = el)}>
        <Menu text stackable className="responsive-header">
          <Menu.Item>
            <Header as="h2">Daily Tips Report</Header>
          </Menu.Item>
          <Menu.Item>
            <DatesRangeInput
              animation="none"
              dateFormat="MM-DD-YYYY"
              name="datesRange"
              iconPosition="left"
              placeholder="From - To"
              value={datesRange}
              style={{ minWidth: "220px" }}
              onChange={this.handleChange}
              allowSameEndDate="true"
            />
          </Menu.Item>
         <Menu.Menu position="right" className="no-print">
            <Menu.Item>
              <CsvDownloader
                filename={moment(this.state.startDate).format("YYYY-MM-DD") + '-tips-export'}
                extension=".csv"
                wrapColumnChar='"'
                datas={data}
              >
                <Button icon>
                  <Icon name="table" />
                </Button>
              </CsvDownloader>
            </Menu.Item>
            <Menu.Item>
              <ReactToPrint
                pageStyle=""
                trigger={() => (
                  <Button
                    icon
                    onClick={() => {
                      window.print();
                      return false;
                    }}
                  >
                    <Icon name="print" />
                  </Button>
                )}
                fluid
                content={() => this.componentRef}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Divider hidden fitted clearing />
        <ReactTable
          data={data}
          filterable={false}
          sortable={false}
          loading={loading}
          className="-striped"
          pageSize={data.length}
          showPagination={false}
          minRows={4}
          columns={[
            {
              Header: "First Name",
              accessor: "firstName",
              maxWidth: 200
            },
            {
              Header: "Last Name",
              accessor: "lastName",
              maxWidth: 200
            },
            {
              Header: "Amount",
              accessor: "amount",
              Cell: props => (
                <Money value={parseFloat(props.value).toFixed(2)} />
              ),
              className: "money-column",
              maxWidth: 200
            }
          ]}
        />
      </div>
    );
  }
}

export default DailyTipsTable;
