import React, { Component } from "react";
import { Header, Button, Icon, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Axios from "axios";
import queryString from "query-string";

class ConfirmedAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = { confirmationStatus: null };
  }

  async componentDidMount() {
    this.setState({ confirmationStatus: "loading" });
    if (!this.props.location) {
      this.setState({ confirmationStatus: "error" });
      return;
    }
    let search = this.props.location.search;
    let token = queryString.parse(search).token;
    try {
      let response = await Axios.get("/api/v4/entrance/confirmAppointment", {
        params: { token: token }
      });
      if (response.status === 200) {
        this.setState({ confirmationStatus: "success", loading: false });
      } else {
        this.setState({ confirmationStatus: "error", loading: false });
      }
    } catch (error) {
      console.error(error);
      this.setState({ confirmationStatus: "error", loading: false });
    }
  }
  render() {
    let { confirmationStatus } = this.state;
    return (
      <Segment basic loading={confirmationStatus === "loading"}>
        {/* Loading */}
        {confirmationStatus === "loading" && (
          <Header icon as="h2" textAlign="center">
            <Header.Content>Confirming Appointment</Header.Content>
          </Header>
        )}
        {/* Successful confirmation */}
        {confirmationStatus === "success" && (
          <Header icon as="h2" textAlign="center">
            <Icon color="green" name="check" circular />
            <Header.Content>Appointment Confirmed</Header.Content>
            <Header.Subheader>
              Thank you for confirming your appointment. We look forward to
              seeing you!
            </Header.Subheader>
            <br />
            <Button as={Link} to="/login" color="purple">
              Login
            </Button>
          </Header>
        )}
        {/* Errored while confirming */}
        {confirmationStatus === "error" && (
          <Header icon as="h2" textAlign="center">
            <Header.Subheader>
              The appointment has already been confirmed or an error has
              occured. Please try again or contact Pet Love for help.
            </Header.Subheader>
          </Header>
        )}
      </Segment>
    );
  }
}

export default ConfirmedAppointment;
