import React, { Component } from "react";
import { Header, Form, Button, Label, Responsive } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import _ from "lodash";
import moment from "moment";
import { defaultRecurring } from "../lib/defaultObjects";
import { replaceAt } from "../lib/helperFunctions";
import { OptionsContext } from "../OptionsContext";

import Input from "../Shared/Input";

class Recurring extends Component {
  constructor(props) {
    super(props);

    let defaultRecurringType = _.find(props.recurringTypes, { type: "weekly" });
    let defaultRecurringObj = defaultRecurring(props.recurringPattern);

    // Find and set a default recurring type if there is none
    if (
      props.recurringPattern &&
      defaultRecurringType &&
      !props.recurringPattern.recurringTypeId
    ) {
      defaultRecurringObj.recurringTypeId = defaultRecurringType.ID;
    }

    this.state = {
      ...defaultRecurringObj
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState, this.state)) {
      this.props.onUpdate(this.state);
    }
  }
  handleToggle = (e, { name, index }) => {
    let temp = this.state[name];
    let currentValue = temp[index];
    this.setState({
      [name]: replaceAt(
        this.state[name],
        index,
        currentValue === "0" ? "1" : "0"
      )
    });
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  render() {
    let {
      recurringTypeId,
      separationCount,
      startDate,
      endDate,
      daysOfWeek,
      daysOfMonth,
      monthsOfYear
    } = this.state;

    let { recurringTypes } = this.props;

    let selectedType = _.find(recurringTypes, { ID: recurringTypeId });

    return (
      <div>
        <Header content="Recurrence Settings" as="h3" dividing />

        <Form.Group inline required>
          <label>Recurring Type</label>
          {recurringTypes.map(rtype => (
            <Form.Radio
              key={rtype.label}
              label={_.startCase(rtype.type)}
              name="recurringTypeId"
              value={rtype.ID}
              checked={recurringTypeId === rtype.ID}
              onChange={this.handleChange}
            />
          ))}
        </Form.Group>
        <Input
          label="Every"
          name="separationCount"
          attachedLabel={{
            content: selectedType ? selectedType.label : ""
          }}
          onChange={this.handleChange}
          value={separationCount}
          validation={["integer", "positive"]}
          isEditing
          labelPosition="right"
          placeholder="Enter amount..."
        />

        <Form.Group widths="2">
          <DateInput
            animation="none"
            label="Start Recurrence on"
            name="startDate"
            required
            dateFormat={"MM-DD-YYYY"}
            placeholder="Date"
            value={startDate || ""}
            iconPosition="left"
            onChange={this.handleChange}
          />
          <DateInput
            animation="none"
            label="End Recurrence on"
            name="endDate"
            dateFormat={"MM-DD-YYYY"}
            placeholder="Date"
            value={endDate || ""}
            iconPosition="left"
            onChange={this.handleChange}
          />
        </Form.Group>
        {selectedType.type === "weekly" && (
          <Form.Field>
            <label>Days</label>
            <Button.Group
              fluid
              widths="7"
              vertical={window.screen.width < Responsive.onlyTablet.minWidth}
            >
              {moment.weekdays().map((day, i) => (
                <Button
                  key={day}
                  icon
                  type="button"
                  content={day}
                  index={i}
                  toggle
                  active={daysOfWeek[i] === "1"}
                  name="daysOfWeek"
                  onClick={this.handleToggle}
                />
              ))}
            </Button.Group>
          </Form.Field>
        )}
        {selectedType.type === "monthly" && (
          <Form.Field>
            <label>Days of Month</label>
            {_.times(daysOfMonth.length, n => (
              <Label
                content={n + 1}
                index={n}
                key={n}
                as="a"
                circular
                name="daysOfMonth"
                onClick={this.handleToggle}
                color={daysOfMonth[n] === "1" ? "green" : null}
              />
            ))}
          </Form.Field>
        )}
        {selectedType.type === "yearly" && (
          <Form.Field>
            <label>Months of Year</label>
            {_.times(monthsOfYear.length, n => (
              <Label
                content={n + 1}
                index={n}
                key={n}
                as="a"
                circular
                name="monthsOfYear"
                onClick={this.handleToggle}
                color={monthsOfYear[n] === "1" ? "green" : null}
              />
            ))}
          </Form.Field>
        )}
      </div>
    );
  }
}

Recurring.defaultProps = {
  recurringPattern: {},
  recurringTypes: [],
  onUpdate: () => {}
};

export default props => (
  <OptionsContext.Consumer>
    {({ recurringTypes }) => (
      <Recurring recurringTypes={recurringTypes} {...props} />
    )}
  </OptionsContext.Consumer>
);
