import React, { Component } from "react";

import ReactTable from "react-table";
import moment from "moment";
import { toast } from "react-toastify";

import PetList from "../Shared/PetList";
import { getUpcomingAppointmentsByCustomer } from "../lib/apiCalls";
import { Icon, Message } from "semantic-ui-react";
import _ from "lodash";
import { OptionsContext } from "../OptionsContext";

class UpcomingAppointments extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { data: [], loading: false, selectedRow: null };
    this.getAppointments = this.getAppointments.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.getAppointments();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userId !== this.props.userId) {
      this.getAppointments();
    }
  }
  async getAppointments(state, instance) {
    if (!this.props.userId) return;
    this.setState({ loading: true });
    getUpcomingAppointmentsByCustomer(
      { userId: this.props.userId },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            toast.error("Failed to retrieve upcoming appointments");
            this.setState({ loading: false });
            return;
          }
          this.setState({ data: response, loading: false });
        }
      }
    );
  }
  render() {
    const { data, loading } = this.state;
    const { statuses } = this.props;

    return (
      <>
        <Message
          icon="warning circle"
          warning
          header="Reminder"
          content="Recurring appointments are subject to change until confirmed to best accommodate time preferences and routing."
        />
        <ReactTable
          getTheadThProps={(state, rowInfo, instance) => {
            return { className: "upcoming-appointments-table-" + instance.id };
          }}
          defaultSorted={[{ id: "appointmentDate" }]}
          data={data}
          defaultPageSize={5}
          columns={[
            {
              Header: "Date",
              accessor: "appointmentDate",
              minWidth: 100,
              Cell: (props) =>
                props.value
                  ? moment(props.value, "MM-DD-YYYY").format("MM-DD-YYYY")
                  : "",
            },
            {
              Header: "Time",
              minWidth: 60,
              id: "startTime",
              accessor: (data) =>
                moment(data.startTime, "h:mm A").isValid()
                  ? moment(data.startTime, "h:mm A").format("h:mm A")
                  : data.startTime,
            },
            {
              Header: "S App",
              accessor: "standingId",
              filterable: false,
              minWidth: 30,
              Cell: (props) => {
                return props.value != null ? <Icon name="check" /> : <div />;
              },
              getProps: (state, rowInfo, column) => {
                return {
                  style: {
                    textAlign: "center",
                  },
                };
              },
            },
            {
              Header: "Status",
              id: "statusId",
              accessor: (data) => {
                let appointmentStatus = _.find(statuses, {
                  ID: data.statusId,
                });
                if (data.isStanding) {
                  return "Standing";
                }
                return appointmentStatus != null
                  ? _.startCase(appointmentStatus.status)
                  : "";
              },
              minWidth: 100,
              getProps: (state, rowInfo, column) => {
                return {
                  style: {
                    textAlign: "center",
                  },
                };
              },
            },
            {
              Header: "Stylist",
              id: "employeeId",
              accessor: (data) =>
                data.Employee &&
                data.Employee.User &&
                `${data.Employee.User.firstName} ${data.Employee.User.lastName}`,
            },
            {
              Header: "Pets",
              id: "pets",
              accessor: (data) =>
                data.Estimate &&
                data.Estimate.EstimatePets &&
                data.Estimate.EstimatePets.map(
                  (petEstimate) => petEstimate.Pet
                ),
              Cell: (props) => <PetList includeDetails pets={props.value} />,
            },
            {
              Header: "Address",
              accessor: "Estimate.AddressString",
            },
            {
              Header: "Last Comment",
              id: "lastComment",
              accessor: (data) => {
                if (
                  data.CommentGroup &&
                  data.CommentGroup.Comments &&
                  data.CommentGroup.Comments.length > 0
                ) {
                  let lastComment = _.maxBy(
                    data.CommentGroup.Comments,
                    "DateAdded"
                  );
                  return lastComment.comment;
                }
                return "";
              },
              filterable: false,
              minWidth: 250,
            },
          ]}
          loading={loading}
          className="-striped"
        />
      </>
    );
  }
}

UpcomingAppointments.defaultProps = {
  onRowClick: null,
  userId: null,
  includeUserInPath: false,
  statuses: [],
};

export default (props) => (
  <OptionsContext.Consumer>
    {({ statuses }) => <UpcomingAppointments {...props} statuses={statuses} />}
  </OptionsContext.Consumer>
);
