import React, { Component } from "react";
import ReactTable from "react-table";

import { toast } from "react-toastify";
import { Header, Divider, Menu, Button, Icon } from "semantic-ui-react";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import Money from "../Shared/Money";

import io from "../socketConnection";
import moment from "moment";
import _ from "lodash";
import ReactToPrint from "react-to-print";

class PayrollTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      datesRange: `${moment()
        .startOf("month")
        .format("MM-DD-YYYY")} - ${moment()
        .endOf("month")
        .format("MM-DD-YYYY")}`,
      totals: {},
      loading: false
    };
  }
  componentDidMount() {
    this.getTransactions();
  }
  componentDidUpdate(prevProps, prevState) {
    // Get the transactions when sorting, filtering or the page was changed
    if (
      !_.isEqual(
        { datesRange: prevState.datesRange },
        { datesRange: this.state.datesRange }
      )
    ) {
      this.getTransactions();
    }
  }
  getTransactions = _.debounce(() => {
    if (this.state.loading === true) return;
    this.setState({ loading: true });

    let startDate = this.state.startDate;
    let endDate = this.state.endDate;

    let splitDates = this.state.datesRange.split(" - ");

    startDate = splitDates && splitDates[0];
    endDate = splitDates && splitDates[1];

    io.socket.get(
      "/api/v4/reports/getPayroll",
      {
        startDate: startDate || new Date(),
        endDate: endDate
      },
      (body, jwr) => {
        if (jwr.statusCode !== 200) {
          toast.error("Failed to retrieve all transactions.");
          this.setState({ loading: false });
        }
        this.setState({
          data: body,
          loading: false
        });
      }
    );
  }, 300);
  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };
  render() {
    let { data, datesRange, loading } = this.state;

    let totals = {
      totalRevenue: _.sumBy(data, "revenue"),
      totalGratuity: _.sumBy(data, "gratuity"),
      totalCommissionable: _.sumBy(data, "commissionable")
    };

    return (
      <div ref={el => (this.componentRef = el)}>
        <Menu text stackable className="responsive-header">
          <Menu.Item>
            <Header as="h2">Payroll Report</Header>
          </Menu.Item>
          <Menu.Item>
            <DatesRangeInput
              animation="none"
              dateFormat="MM-DD-YYYY"
              name="datesRange"
              iconPosition="left"
              placeholder="From - To"
              value={datesRange}
              style={{ minWidth: "220px" }}
              onChange={this.handleChange}
            />
          </Menu.Item>
          <Menu.Menu position="right" className="no-print">
            <Menu.Item>
              <ReactToPrint
                pageStyle=""
                trigger={() => (
                  <Button
                    icon
                    onClick={() => {
                      window.print();
                      return false;
                    }}
                  >
                    <Icon name="print" />
                  </Button>
                )}
                fluid
                content={() => this.componentRef}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Divider hidden fitted clearing />
        <ReactTable
          data={data}
          filterable={false}
          sortable={false}
          loading={loading}
          className="-striped"
          pageSize={data.length}
          showPagination={false}
          minRows={4}
          columns={[
            {
              Header: "Grand Totals",
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Stylist",
                  accessor: data => {
                    return `${
                      data.Appointment &&
                      data.Appointment.Employee &&
                      data.Appointment.Employee.User
                        ? `${data.Appointment.Employee.User.firstName ||
                            ""} ${data.Appointment.Employee.User.lastName ||
                            ""}`
                        : ""
                    }`;
                  },
                  id: "employee",
                  minWidth: 150
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalRevenue} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Revenue",
                  accessor: "revenue",
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            },

            {
              Header: () => {
                return "";
              },
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Commission Rate",
                  accessor: "Appointment.Employee.commissionRate",
                  Cell: props => (props.value ? props.value + "%" : ""),
                  className: "money-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalCommissionable} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Total Commissionable",
                  accessor: "commissionable",
                  Cell: props => <Money value={props.value} />,
                  className: "money-column important-column"
                }
              ]
            },
            {
              Header: () => <Money value={totals.totalGratuity} />,
              headerClassName: "important-column",
              columns: [
                {
                  Header: "Gratuity",
                  accessor: "gratuity",
                  Cell: props => <Money value={props.value} />,
                  className: "money-column"
                }
              ]
            }
          ]}
        />
      </div>
    );
  }
}

export default PayrollTable;
