import React, { Component } from "react";
import { Form, Modal, Button } from "semantic-ui-react";
import Input from "../Shared/Input";
import { OptionsContext } from "../OptionsContext";
import { toast } from "react-toastify";
import { createTodoItem } from "../lib/apiCalls";

class TodoItemModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      note: "",
      date: "",
      repeatInDays: null,
      todoCategoryId: null
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleSave = () => {
    let { note, date, repeatInDays, todoCategoryId } = this.state;
    if (!note) {
      toast.info("Todo is a required field");
      return;
    }
    createTodoItem(
      {
        todoCategoryId: parseInt(todoCategoryId),
        repeatInDays: parseInt(repeatInDays),
        date: date,
        note: note,
        customerId: this.props.customerId
      },
      (error, response) => {
        if (error) {
          toast.error("Failed to create todo item");
          return;
        }
        toast.success("Successfully created todo item");
        if (this._isMounted) {
          this.closeModal();
        }
      }
    );
  };
  openModal = () => {
    this.setState({ open: true });
  };
  closeModal = () => {
    this.setState({ open: false });
  };
  render() {
    let { todoCategories } = this.props;
    let { note, repeatInDays, date, todoCategoryId, open } = this.state;
    return (
      <Modal
        open={open}
        onClose={this.closeModal}
        closeIcon
        trigger={
          <Button
            onClick={this.openModal}
            fluid
            color="blue"
            content="New Todo Item"
          />
        }
      >
        <Modal.Header>Create Todo Item</Modal.Header>
        <Modal.Content>
          <Form>
            <Input
              isEditing
              required
              maxLength={250}
              label="Todo"
              value={note}
              placeholder="What needs to be done for this customer?"
              name="note"
              onChange={this.onChange}
            />
            <Form.Group>
              <Form.Select
                clearable
                options={todoCategories.map(category => ({
                  value: category.ID,
                  text: category.category,
                  key: category.ID
                }))}
                label="Category"
                search
                name="todoCategoryId"
                value={todoCategoryId}
                onChange={this.onChange}
                placeholder="Select a Category"
              />
              <Input
                validation="date"
                label="Date"
                value={date}
                isEditing
                name="date"
                onChange={this.onChange}
                placeholder="Select a Date"
              />{" "}
              <Input
                label="Repeat in Days"
                value={repeatInDays}
                name="repeatInDays"
                min={0}
                placeholder="Days"
                step={1}
                type="number"
                validation={["integer", "positive"]}
                onChange={this.onChange}
                isEditing
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Create" onClick={this.handleSave} color="green" />
        </Modal.Actions>
      </Modal>
    );
  }
}
TodoItemModal.defaultProps = {
  todoCategories: [],
  customerId: null
};

export default props => (
  <OptionsContext.Consumer>
    {({ todoCategories }) => {
      return <TodoItemModal {...props} todoCategories={todoCategories} />;
    }}
  </OptionsContext.Consumer>
);
