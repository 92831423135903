import React, { Component } from "react";
import PropTypes from "prop-types";

import Axios from "axios";
import _ from "lodash";
import { getToken } from "../lib/csrfToken";
import { Link } from "react-router-dom";
import Authorize from "../Shared/Authorize";

import { Header, Form, Button, Grid, Popup } from "semantic-ui-react";
import AddressForm from "./Address";
import Name from "./Name";
import Input from "../Shared/Input";
import { OptionsContext } from "../OptionsContext";
import { toast } from "react-toastify";

/**
 * A user creation form
 * @prop {boolean} hideCreateButton Hides the create button when true
 */
class UserCreate extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      notificationMessage: null,
      success: false,
      ...props
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState, this.state)) {
      let {
        firstName,
        middleName,
        lastName,
        UserTypeID,
        phoneNumber,
        emailAddress,
        Address,
        isAccount
      } = this.state;
      let user = {
        Name: {
          firstName: firstName,
          middleName: middleName,
          lastName: lastName
        },
        UserTypeID: UserTypeID,
        phoneNumber: phoneNumber,
        emailAddress: emailAddress,
        Address: Address,
        isAccount: isAccount
      };
      this.props.onChange(user);
    }
  }
  handleToggle = (e, { name }) => {
    this.setState(state => ({ [name]: !state[name] }));
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleAddressChange = (e, data, { name, value }) => {
    this.setState(state => ({ [data]: { ...state.Address, [name]: value } }));
  };

  handleSubmit = async () => {
    let { notificationMessage, success, ...rest } = this.state;
    // Return if not all required fields are filled out
    if (!this.checkRequiredFields(rest)) return;

    try {
      const response = await Axios.post(
        `/api/v4/user/create`,
        {
          firstName: rest.firstName,
          middleName: rest.middleName,
          lastName: rest.lastName,
          emailAddress: rest.emailAddress,
          UserTypeID: rest.UserTypeID,
          phoneNumber: rest.phoneNumber,
          AddressInfo: rest.Address,
          isAccount: rest.isAccount
        },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      if (response.status === 200) {
        toast.success(
          `Created new user account for ${rest.firstName} ${rest.lastName}`
        );
        if (this._isMounted) {
          if (
            this.props.location &&
            this.props.location.pathname.includes("employees")
          ) {
            this.props.history.push("/dashboard/employees/" + response.data.ID);
          } else {
            this.props.history.push("/dashboard/customers/" + response.data.ID);
          }
        }
      }
    } catch (error) {
      // Throw email duplicate error
      if (error.response && error.response.status === 409) {
        toast.error("Email already in use by another account.");
      } else {
        // Server failed to save
        toast.error("Failed to save new user.");
      }
    }
  };
  checkRequiredFields = fields => {
    // Check for required fields
    for (let data of this.props.required) {
      if (!fields[data] || fields[data] === "") {
        toast.info(
          _.startCase(data).replace("ID", "") + " is a required field."
        );
        return false;
      }
    }
    return true;
  };
  render() {
    const {
      showHeader,
      required,
      signUp,
      loading,
      hideCreateButton,
      location
    } = this.props;

    let {
      firstName,
      middleName,
      lastName,
      phoneNumber,
      emailAddress,
      UserTypeID,
      Address,
      isAccount
    } = this.state;

    let backPath =
      location && location.state
        ? location.state.backPath
        : this.props.backPath;

    let creatingEmployee = false;
    if (location.pathname.includes("employees")) {
      creatingEmployee = true;
    }
    return (
      <Form>
        {showHeader && (
          <Header as="h3">
            <Authorize permission="ViewCustomers">
              {backPath != null && (
                <Button
                  color="blue"
                  content="Back"
                  icon="left chevron"
                  as={Link}
                  to={backPath}
                />
              )}
            </Authorize>{" "}
            Create New {creatingEmployee ? "Employee" : "Customer"}
          </Header>
        )}
        <Grid celled="internally" stackable padded={false}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Group widths="equal">
                <Name
                  firstName={firstName}
                  middleName={middleName}
                  lastName={lastName}
                  handleChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Input
                  isEditing
                  validation="phone"
                  fluid
                  label="Phone"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.handleChange}
                />
                <Input
                  required={
                    required.indexOf("emailAddress") > -1 || isAccount === true
                  }
                  validation="email"
                  isEditing
                  fluid
                  label="Email"
                  name="emailAddress"
                  value={emailAddress}
                  onChange={this.handleChange}
                />
                {!signUp && (
                  <OptionsContext.Consumer>
                    {({ userTypes }) => {
                      userTypes = userTypes.filter(
                        userType =>
                          userType.UserType.toLowerCase() !== "customer"
                      );
                      return (
                        <Form.Select
                          loading={loading}
                          readOnly
                          search
                          required={required.indexOf("UserTypeID") > -1}
                          // Create the options for user types
                          options={
                            userTypes
                              ? userTypes.map(userType => {
                                  const { ID, UserType } = userType;
                                  return {
                                    key: ID,
                                    text: UserType,
                                    value: ID
                                  };
                                })
                              : []
                          }
                          fluid
                          placeholder="Select a User Type"
                          label="User Type"
                          name="UserTypeID"
                          value={UserTypeID}
                          onChange={this.handleChange}
                        />
                      );
                    }}
                  </OptionsContext.Consumer>
                )}
                {/* Is Account */}
                {!creatingEmployee && (
                  <Popup
                    inverted
                    content="By setting Yes, a user will be able to login to their customer account. Selecting No will skip the account setup process and create a record account only."
                    trigger={
                      <Form.Button
                        toggle
                        compact
                        label="Create New Account"
                        content={isAccount === true ? "Yes" : "No"}
                        icon={{
                          name: isAccount === true ? "check" : "close",
                          position: "right"
                        }}
                        name="isAccount"
                        active={creatingEmployee || isAccount}
                        onClick={this.handleToggle}
                      />
                    }
                  ></Popup>
                )}
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <AddressForm
                handleChange={(e, values) =>
                  this.handleAddressChange(e, "Address", values)
                }
                {...Address}
                hideNeedsGenerator={
                  !(
                    location &&
                    (location.pathname.includes("estimate") ||
                      location.pathname.includes("appointments"))
                  )
                }
                required={required}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        {!hideCreateButton && (
          <Button color="green" content="Create" onClick={this.handleSubmit} />
        )}
      </Form>
    );
  }
}

UserCreate.defaultProps = {
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: "",
  emailAddress: "",
  UserTypeID: undefined,
  Address: {},
  showHeader: true,
  loading: false,
  signUp: false,
  hideCreateButton: false,
  required: [],
  onChange: () => {},
  userTypes: [],
  isAccount: false
};

UserCreate.propTypes = {
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,
  UserTypeID: PropTypes.number,
  Address: PropTypes.object,
  showHeader: PropTypes.bool,
  loading: PropTypes.bool,
  signUp: PropTypes.bool,
  hideCreateButton: PropTypes.bool,
  required: PropTypes.array,
  onChange: PropTypes.func,
  userTypes: PropTypes.array,
  isAccount: PropTypes.bool
};

export default UserCreate;
