import React, { Component } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import moment from "moment";
import Axios from "axios";
import { getToken } from "../lib/csrfToken";
import { toast } from "react-toastify";
import { yearsSinceDate } from "../lib/estimateCalculations";

import {
  Header,
  Form,
  Button,
  Grid,
  Divider,
  Modal,
  Menu
} from "semantic-ui-react";

import ErrorBoundary from "../Shared/ErrorBoundary";
import EditButtonGroup from "../Shared/EditButtonGroup";
import OrButtons from "../Shared/OrButtons";
import Input from "../Shared/Input";
import TextArea from "../Shared/TextArea";
import ChangePetOwnerModal from "../Shared/ChangePetOwnerModal";
import FileUploader from "../Shared/FileUploader";
import { defaultPet } from "../lib/defaultObjects";
import { OptionsContext } from "../OptionsContext";
import Carousel from "../Shared/Carousel/Carousel";
import Authorize from "../Shared/Authorize";
import CommentGroup from "../Shared/CommentGroup";
import TinyEditButtonGroup from "../Shared/TinyEditButtonGroup";
import Money from "../Shared/Money";

/**
 * Displays details of a pet object
 */
class PetDetails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      ...defaultPet(props.pet),
      images: [],
      isCustomerEditing: false,
      isEditing: props.isEditing,
      modalOpen: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDogCat = this.handleDogCat.bind(this);
    this.handleSexToggle = this.handleSexToggle.bind(this);
    this.getPicturePaths = this.getPicturePaths.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.props.onUpdate(this.state);
    this.getPicturePaths();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.hideButtonGroup && !_.isEqual(this.state, prevState)) {
      this.props.onUpdate(this.state);
    }
    if (prevState.birthday !== this.state.birthday) {
      this.setState({
        age: yearsSinceDate(this.state.birthday)
      });
    }
    if (this.state.isEditing === false && prevState.isEditing === true) {
      this.getPicturePaths();
    }
  }
  async getPicturePaths() {
    if (this.state.ID == null) return;
    try {
      let response = await Axios.get("/api/v4/pets/getPicturePaths", {
        params: { petId: this.state.ID, userId: this.state.userId }
      });
      if (this._isMounted) {
        if (response.status === 200) {
          // Check if files were returned in array
          if (response.data && Array.isArray(response.data)) {
            this.setState({ images: response.data });
          } else {
            this.setState({ images: [] });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleDogCat = value => {
    if (this.state.animalTypeId !== value) {
      this.setState({
        animalTypeId: value,
        breedId: null
      });
    }
  };
  handleSexToggle = value => {
    this.setState({ sex: value });
  };
  handleToggle = (e, { name }) => {
    this.setState(state => {
      if (name === "isDeceased" && state.isDeceased === false) {
        return { [name]: !state[name], modalOpen: true };
      }
      return { [name]: !state[name] };
    });
  };
  sendSympathyEmail = async () => {
    let { ID } = this.state;
    if (!ID) {
      toast.error(
        "No ID for the pet was found. Please refresh your browser and try again."
      );
      return;
    }
    try {
      let response = await Axios.post(
        "/api/v4/pets/sendSympathyEmail",
        { petId: ID },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      if (this._isMounted) {
        if (response.status === 200) {
          toast.success("Successfully sent sympathy email");
        }
        this.setState({ modalOpen: false });
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to send sympathy email");
      return;
    }
  };
  /**
   * Saves pet notes only from pets. This is used when a customer is updating their pet's notes.
   * Toggles view from edit mode to view mode after successfully saving. */
  savePetNotes = async () => {
    let { ID, habits } = this.state;
    if (!ID) return;
    try {
      let response = await Axios.post(
        "/api/v4/profile/updatePetNotes",
        { petId: ID, habits: habits },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      if (this._isMounted) {
        if (response.status === 200) {
          toast.success("Successfully saved pet notes.");
          this.setState({ isCustomerEditing: false });
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to save pet notes.");
    }
  };

  handleSave = async () => {
    let { birthday, petName, breedId } = this.state;
    if (moment(birthday, "MM-DD-YYYY").year() > 9999) {
      toast.info("Year must be less than 9999");
      return;
    }
    if (this.props.required.indexOf("Breed") > -1 && breedId == null) {
      toast.info("A breed is required.");
      return;
    }
    if (petName == null || petName === "") {
      toast.info("A name is required");
      return;
    }

    try {
      let response = await Axios.post(
        `/api/v4/pets/update`,
        { ...this.state },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      if (this._isMounted) {
        if (response.status === 200) {
          this.setState({ isEditing: false });
          let updatedPet = { ...defaultPet(this.state), ...response.data };
          this.props.onUpdate(updatedPet);
          if (this.props.onSaveSuccess) {
            this.props.onSaveSuccess(updatedPet);
          }
          toast.success("Successfully saved your pet.");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to save pet.");
    }
  };

  handleEdit = () => {
    this.setState({ isEditing: true });
  };
  handleCancel = () => {
    this.setState({
      isEditing: false,
      ...defaultPet(this.props.pet)
    });
  };
  deletePet = async () => {
    try {
      let response = await Axios.delete("/api/v4/pets/delete", {
        params: { petId: this.state.ID },
        headers: { "X-CSRF-Token": getToken() }
      });
      if (this._isMounted) {
        if (response.status === 200) {
          toast.success("Successfully deleted pet.");
          window.location.reload();
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete pet.");
    }
  };

  render() {
    let {
      ID,
      petName,
      breedId,
      birthday,
      age,
      isDeceased,
      isServicePet,
      isOverridingGroomingFee,
      overrideGroomingFeePrice,
      grooming,
      habits,
      weight,
      lastFee,
      lastGroomDate,
      isEditing,
      loading,
      images,
      isCustomerEditing,
      commentGroupId,
      animalTypeId,
      modalOpen
    } = this.state;

    const {
      hideDetailsButton,
      hideButtonGroup,
      required,
      breeds,
      animalTypes,
      handlePetSelect,
      onUpdate,
      userId
    } = this.props;

    const sex = this.state.sex.toLowerCase();

    // Create route calls for each picture
    const paths = images.map(
      fileName =>
        `/api/v4/pets/getPicture?petId=${encodeURI(ID)}&fileName=${encodeURI(
          fileName
        )}`
    );

    if (ID == null) isEditing = true;
    // Create the options for breeds

    let breedOptions = [];
    if (breeds.length > 0) {
      if (animalTypeId != null) {
        let filteredBreeds = _.filter(breeds, function(breed) {
          return breed.animalTypeId === animalTypeId;
        });
        breedOptions = filteredBreeds.map(breed => {
          return {
            key: breed.ID,
            text: breed.breed,
            value: breed.ID
          };
        });
      } else {
        breedOptions = breeds.map(breed => {
          return {
            key: breed.ID,
            text: breed.breed,
            value: breed.ID
          };
        });
      }
    }

    let selectedBreed = _.find(breeds, { ID: breedId });

    if (!animalTypeId && selectedBreed && selectedBreed.AnimalType) {
      animalTypeId = selectedBreed.AnimalType.ID;
    }
    let animalTypeButtons = animalTypes.map(type => {
      return {
        active: animalTypeId === type.ID,
        disabled: !isEditing,
        onClick: () => this.handleDogCat(type.ID),
        color: animalTypeId === type.ID ? "green" : undefined,
        content: _.startCase(type.type)
      };
    });
    return (
      <ErrorBoundary>
        <Menu text stackable className="responsive-header">
          <Menu.Item>
            <Header>{`${ID ? petName : "New Pet"}'s Details`}</Header>
          </Menu.Item>
          <Menu.Menu position="right">
            {/* Edit buttons, hides the cancel when a new pet is being created */}
            {!hideButtonGroup && (
              <Authorize permission="EditPets">
                <Menu.Item>
                  <TinyEditButtonGroup
                    isEditing={isEditing}
                    onSave={this.handleSave}
                    onEdit={this.handleEdit}
                    onDelete={this.deletePet}
                    onCancel={this.handleCancel}
                    hideDelete={!ID}
                    size="medium"
                  />
                </Menu.Item>
                {ID && (
                  <Menu.Item>
                    <ChangePetOwnerModal
                      petID={ID}
                      fluid
                      isEditing={isEditing}
                      handleSuccess={customerId => {
                        handlePetSelect(null);
                        onUpdate({
                          ...this.state,
                          customerId: customerId
                        });
                      }}
                    />
                  </Menu.Item>
                )}
              </Authorize>
            )}
            {!hideDetailsButton && (
              <Menu.Item>
                <Button
                  fluid
                  type="button"
                  content="Close"
                  onClick={() => handlePetSelect(null)}
                />
              </Menu.Item>
            )}
          </Menu.Menu>
        </Menu>
        <Divider clearing fitted />
        <br />
        <Grid stackable divided="vertically">
          <Grid.Row columns="1">
            <Grid.Column>
              <Form divided="vertically">
                <Grid stackable>
                  <Grid.Row columns="2">
                    <Grid.Column>
                      <Form.Group widths={2}>
                        <Form.Field>
                          {/* Dog or cat */}
                          <OrButtons
                            fluid
                            noActiveMessage="Unknown Animal Type"
                            isEditing={isEditing}
                            buttons={animalTypeButtons}
                          />
                        </Form.Field>
                        <Form.Field>
                          {/* Male or female */}
                          <OrButtons
                            fluid
                            isEditing={isEditing}
                            noActiveMessage="Unknown Sex"
                            buttons={[
                              {
                                active: sex === "male",
                                disabled: !isEditing,
                                onClick: () => this.handleSexToggle("Male"),
                                color: sex === "male" ? "blue" : undefined,
                                content: "Male"
                              },
                              {
                                active: sex === "female",
                                disabled: !isEditing,
                                onClick: () => this.handleSexToggle("Female"),
                                color: sex === "female" ? "pink" : undefined,
                                content: "Female"
                              }
                            ]}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths={2}>
                        <Form.Field>
                          {/* Is service pet */}
                          <Button
                            fluid
                            type="button"
                            toggle
                            disabled={!isEditing}
                            content="Service Pet"
                            name="isServicePet"
                            active={isServicePet}
                            onClick={isEditing ? this.handleToggle : undefined}
                          />
                        </Form.Field>
                        {ID && (
                          <Form.Field>
                            {/* Is deceased */}
                            <Modal
                              closeIcon
                              onClose={this.closeModal}
                              header="Sympathy Email"
                              open={modalOpen}
                              content="Would you like to send a sympathy email to the customer's primary email?"
                              trigger={
                                <Button
                                  fluid
                                  type="button"
                                  toggle
                                  disabled={!isEditing}
                                  content="Deceased"
                                  name="isDeceased"
                                  active={isDeceased}
                                  onClick={
                                    isEditing ? this.handleToggle : undefined
                                  }
                                />
                              }
                              actions={[
                                {
                                  content: "Do Not Send Email",
                                  key: "noSend",
                                  onClick: this.closeModal
                                },
                                {
                                  content: "Send Email",
                                  color: "green",
                                  onClick: this.sendSympathyEmail,
                                  key: "send"
                                }
                              ]}
                            />
                          </Form.Field>
                        )}
                      </Form.Group>
                      <Divider />
                      <Form.Group widths={2}>
                        <Input
                          isEditing={isEditing}
                          label="Name"
                          required={isEditing}
                          name="petName"
                          onChange={this.handleChange}
                          value={petName}
                        />
                        {isEditing ? (
                          <Form.Select
                            search
                            fluid
                            options={breedOptions}
                            required={required.indexOf("Breed") > -1}
                            placeholder="Select Breed"
                            label="Breed"
                            name="breedId"
                            loading={loading}
                            value={breedId || ""}
                            onChange={this.handleChange}
                          />
                        ) : (
                          <Input
                            label="Breed"
                            value={
                              selectedBreed
                                ? selectedBreed.breed
                                : "None Selected"
                            }
                          />
                        )}
                      </Form.Group>
                      <Input
                        isEditing={isEditing}
                        label="Weight"
                        type="number"
                        validation={["integer", "positive"]}
                        min={0}
                        step={1}
                        value={weight}
                        name="weight"
                        // added arrow function to handle change and parseInt the value so we no longer see decimal on confirmation page
                        onChange={(e, data) => {
                          this.handleChange(e, {
                            ...data,
                            value:
                              data.value === null
                                ? data.value
                                : parseInt(data.value)
                          });
                        }}
                      />
                      <Form.Group widths={2}>
                        <Input
                          isEditing={isEditing}
                          label="Birthday"
                          validation="date"
                          placeholder="Select Date"
                          value={birthday}
                          maxDate={isEditing === true ? moment() : undefined}
                          name="birthday"
                          onChange={this.handleChange}
                        />
                        <Input
                          type="number"
                          min={0}
                          validation={["integer", "positive"]}
                          isEditing={isEditing}
                          label="Age"
                          value={age}
                          name="age"
                          // added arrow function to handle change and parseInt the value so we no longer see decimal on confirmation page
                          onChange={(e, data) => {
                            this.handleChange(e, {
                              ...data,
                              value: parseInt(data.value)
                            });
                          }}
                        />
                      </Form.Group>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Group widths={2}>
                        <Input
                          isEditing={isEditing}
                          label="Last Groomed"
                          validation="date"
                          placeholder="Select Date"
                          maxDate={isEditing === true ? moment() : undefined}
                          value={lastGroomDate}
                          name="lastGroomDate"
                          onChange={this.handleChange}
                        />
                        {ID != null && (
                          //  Not editable fields. Shows history and auto calculated fields
                          <Form.Field>
                            <label>Last Fee</label>
                            <Money value={lastFee} />
                          </Form.Field>
                        )}
                      </Form.Group>
                      <Form.Group widths={2}>
                        <Form.Field>
                          <label>Groom Fee Override</label>
                          <Form.Button
                            type="button"
                            toggle
                            fluid
                            disabled={!isEditing}
                            content="Override Grooming Fee"
                            name="isOverridingGroomingFee"
                            active={isOverridingGroomingFee}
                            onClick={isEditing ? this.handleToggle : undefined}
                          />
                        </Form.Field>
                        <Input
                          isEditing={isEditing}
                          disabled={!isOverridingGroomingFee}
                          label="Groom Fee"
                          type="number"
                          format="moneyInput"
                          placeholder={(0.0).toFixed(2)}
                          validation="money"
                          value={overrideGroomingFeePrice}
                          name="overrideGroomingFeePrice"
                          onChange={this.handleChange}
                          controlChange
                        />
                      </Form.Group>
                      <Divider />
                      <Form.Field>
                        <label>Grooming and Cut</label>
                        <TextArea
                          value={grooming === null ? "None" : grooming}
                          name="grooming"
                          onChange={this.handleChange}
                          isEditing={isEditing}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>
                          Habits
                          <Authorize userRole="Customer">
                            <EditButtonGroup
                              permission="EditProfile"
                              handleCancel={() =>
                                this.setState({
                                  isCustomerEditing: false,
                                  habits: this.props.pet
                                    ? this.props.pet.habits
                                    : ""
                                })
                              }
                              handleEdit={() =>
                                this.setState({ isCustomerEditing: true })
                              }
                              handleSubmit={this.savePetNotes}
                              hideDelete
                              isEditing={isCustomerEditing}
                              hideCancel={!ID}
                              floated="right"
                              size="mini"
                            />
                          </Authorize>
                        </label>
                        <TextArea
                          value={habits === null ? "None" : habits}
                          name="habits"
                          onChange={this.handleChange}
                          isEditing={isEditing || isCustomerEditing}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Grid.Column>
          </Grid.Row>
          {ID && (
            <Grid.Row columns={2}>
              <Grid.Column>
                {commentGroupId && (
                  <CommentGroup commentGroupId={commentGroupId} />
                )}
              </Grid.Column>
              <Grid.Column>
                {/* Handle pet images */}
                <Authorize
                  permission="EditCustomers"
                  else={paths.length > 0 ? <Carousel images={paths} /> : null}
                >
                  <>
                    {!isEditing && paths.length > 0 ? (
                      // Display carousel when there are images and not editing
                      <Carousel images={paths} />
                    ) : (
                      // Display file uploader when editing
                      <>
                        <Header
                          as="h4"
                          content="Pictures"
                          subheader="
                            Adding a picture saves it immediately."
                        />
                        <FileUploader
                          files={images.map(image => ({
                            source: image,
                            options: { type: "local" }
                          }))}
                          server={{
                            url: "/api/v4/pets",
                            load: `/getPicture?petId=${encodeURIComponent(
                              ID
                            )}&fileName=`,
                            process: {
                              url: "/uploadPicture",
                              onload: this.getPicturePaths
                            },
                            revert: null
                          }}
                          // onRemovePicture={this.getPicturePaths}
                          disabled={!isEditing || !ID}
                          data={{ petId: ID, userId: userId }}
                          handleRemoveURL="/api/v4/pets/removePicture"
                        />
                      </>
                    )}
                  </>
                </Authorize>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </ErrorBoundary>
    );
  }
}

PetDetails.defaultProps = {
  //Options
  breeds: [],
  animalTypes: [],

  handlePetSelect: () => {},
  changePetOwnerSuccess: () => {},
  handleSave: () => {},
  onUpdate: () => {},
  hideButtonGroup: false,
  hideDetailsButton: false,
  required: ["Breed"],

  pet: {}
};

PetDetails.propTypes = {
  //Options
  breeds: PropTypes.array,
  animalTypes: PropTypes.array,

  handlePetSelect: PropTypes.func,
  changePetOwnerSuccess: PropTypes.func,
  handleSave: PropTypes.func,
  onUpdate: PropTypes.func,
  hideButtonGroup: PropTypes.bool,
  hideDetailsButton: PropTypes.bool,
  required: PropTypes.array,
  pet: PropTypes.object
};

export default props => (
  <OptionsContext.Consumer>
    {({ breeds, animalTypes }) => (
      <PetDetails {...props} breeds={breeds} animalTypes={animalTypes} />
    )}
  </OptionsContext.Consumer>
);
