import React, { Component } from "react";
import { Form, Header } from "semantic-ui-react";
import { OptionsContext } from "../../OptionsContext";
import { getContactMethods, updateOtherOptions } from "../../lib/apiCalls";
import { toast } from "react-toastify";
import _ from "lodash";

class OtherManagementOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultContactMethodId: null
    };
  }

  componentDidMount() {
    getContactMethods((error, contactMethods) => {
      if (error) {
        toast.error("Failed to get default contact method");
        return;
      }
      let defaultContactMethod = _.find(contactMethods, { isDefault: true });
      if (defaultContactMethod) {
        this.setState({ defaultContactMethodId: defaultContactMethod.ID });
      }
    });
  }
  handleSave = () => {
    updateOtherOptions(this.state, (error, response) => {
      if (error) {
        toast.error("Failed to update other options");
        return;
      }
      toast.success("Successfully updated other options");
    });
  };
  onChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  render() {
    let { defaultContactMethodId } = this.state;
    return (
      <OptionsContext.Consumer>
        {({ contactMethods }) => {
          return (
            <div>
              <Header as="h2">Other Settings</Header>
              <Form>
                <Form.Select
                  onChange={this.onChange}
                  name="defaultContactMethodId"
                  value={defaultContactMethodId}
                  label="Default Method of Contact"
                  options={contactMethods.map(method => ({
                    key: method.ID,
                    text: method.method,
                    value: method.ID
                  }))}
                />
                <Form.Button
                  color="green"
                  content="Save"
                  onClick={this.handleSave}
                />
              </Form>
            </div>
          );
        }}
      </OptionsContext.Consumer>
    );
  }
}

export default OtherManagementOptions;
