import React, { Component } from "react";
import { Segment, Form, Button } from "semantic-ui-react";

import Input from "../Shared/Input";
import TextArea from "../Shared/TextArea";
import _ from "lodash";

class ProductsAndServicesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.productAndService
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state, prevState)) {
      this.props.onUpdate(this.state);
    }
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleSave = () => {
    this.props.handleSave(this.state);
  };
  handleDelete = () => {
    this.props.handleDelete(this.state);
  };
  render() {
    let {
      item,
      category,
      price,
      addonPrice,
      description,
      customerNote
    } = this.state;
    let { hideSaveButton, categories } = this.props;

    // Create category options and push the selected category as an option if it is not included
    let categoryOptions = categories.map(c => ({ value: c, text: c, key: c }));
    if (
      category &&
      !_.find(categoryOptions, c => {
        return c.text.toLowerCase() === category.toLowerCase();
      })
    ) {
      categoryOptions.push({ value: category, text: category, key: category });
    }

    return (
      <Segment basic>
        <Form>
          <Form.Group widths={2}>
            <Input
              label="Item"
              required
              value={item}
              onChange={this.handleChange}
              name="item"
              isEditing
            />
            <Form.Select
              clearable
              options={categoryOptions}
              label="Category"
              search
              allowAdditions
              name="category"
              value={category}
              onChange={this.handleChange}
              placeholder="Select a Category"
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Input
              isEditing
              required
              label="Price with Package"
              type="number"
              format="moneyInput"
              placeholder={(0.0).toFixed(2)}
              validation="money"
              value={price}
              name="price"
              onChange={this.handleChange}
            />
            <Input
              isEditing
              label="Price as Add-On"
              type="number"
              format="moneyInput"
              placeholder={(0.0).toFixed(2)}
              validation="money"
              value={addonPrice}
              name="addonPrice"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label>Description</label>
              <TextArea
                isEditing
                value={description || ""}
                onChange={this.handleChange}
                name="description"
              />
            </Form.Field>
            <Form.Field>
              <label>Customer Note</label>
              <TextArea
                isEditing
                value={customerNote || ""}
                onChange={this.handleChange}
                name="customerNote"
              />
            </Form.Field>
          </Form.Group>
          {!hideSaveButton && (
            <Button color="green" onClick={this.handleSave}>
              Save
            </Button>
          )}
          {!hideSaveButton && (
            <Button color="red" onClick={this.handleDelete}>
              Delete
            </Button>
          )}
        </Form>
      </Segment>
    );
  }
}

ProductsAndServicesForm.defaultProps = {
  handleSave: () => {},
  handleDelete: () => {},
  onUpdate: () => {},
  productAndService: {},
  hideSaveButton: false
};

export default ProductsAndServicesForm;
