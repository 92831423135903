import React, { Component } from "react";
import { Grid, Segment, Button, Divider } from "semantic-ui-react";

import _ from "lodash";
import { Link } from "react-router-dom";
import Steps from "../Shared/Steps";

/**
 * Controls a view with steps. Hides previous and next buttons based on the active view.
 * This component expects steps and views to be aligned
 * @prop {[object]} views Array of view objects to be rendered. Views are rendered in order of the array.
 * @prop {component} finishComponent
 * @prop {boolean} loading
 */
class StepManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completeIndex: 0,
      activeIndex: 0
    };
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleStepClick = this.handleStepClick.bind(this);
    this.continueToNextStep = this.continueToNextStep.bind(this);
  }

  componentDidMount() {
    let { history, location, views } = this.props;
    let firstView = views[0];
    // Redirect to first steps path if not mounting on that path
    if (
      firstView &&
      firstView.step &&
      history &&
      history.location.pathname !== firstView.step.path
    ) {
      history.replace(firstView.step.path, location.state);
    }
  }

  handleNext() {
    let { activeIndex } = this.state;
    let { views } = this.props;

    if (views[activeIndex].handleNext) {
      views[activeIndex].handleNext(this.continueToNextStep);
    } else {
      this.continueToNextStep();
    }
  }

  handlePrevious() {
    this.setState({ activeIndex: this.state.activeIndex - 1 });
  }

  continueToNextStep(doContinue = true) {
    if (doContinue === true) {
      let { completeIndex } = this.state;
      let { history, views, location, completed } = this.props;

      // Find the index of the router in case the view tried to change and the router stopped it
      let routeIndex = _.findIndex(
        views,
        view => view.step.path === location.pathname
      );
      history.replace(
        views[routeIndex + 1] && views[routeIndex + 1].step
          ? views[routeIndex + 1].step.path
          : undefined,
        location.state
      );

      this.setState({ activeIndex: routeIndex + 1 });

      if (completed === false && completeIndex === routeIndex) {
        this.setState({ completeIndex: completeIndex + 1 });
      }
    }
  }

  handleStepClick(index) {
    if (index >= 0 && index <= this.props.views.length) {
      this.setState({ activeIndex: index });
    }
  }

  render() {
    let { activeIndex, completeIndex } = this.state;
    let { views, finishComponent, loading, location, completed } = this.props;

    // Find the index of the router in case the view tried to change and the router stopped it
    let routeIndex = _.findIndex(
      views,
      view => view.step.path === location.pathname
    );

    // Overwrite the state index with the route index as the single source of truth
    activeIndex = routeIndex;
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={3} only="computer">
            <Steps
              activeIndex={activeIndex}
              completeIndex={completed === true ? views.length : completeIndex}
              handleStepClick={this.handleStepClick}
              state={location.state}
              steps={views.map(view => view.step)}
              vertical
            />
          </Grid.Column>
          <Grid.Column width={16} only="tablet mobile">
            <Steps
              activeIndex={activeIndex}
              completeIndex={completed === true ? views.length : completeIndex}
              handleStepClick={this.handleStepClick}
              state={location.state}
              steps={views.map(view => view.step)}
            />
            <Divider hidden fitted />
          </Grid.Column>
          <Grid.Column computer={13} tablet={16}>
            {/* Show loading screen */}
            {loading === true ? (
              <Segment placeholder loading />
            ) : (
              views[activeIndex] && views[activeIndex].component
            )}
            {/* Buttons to be displayed to move to previous and next step */}
            {views.length > 1 && (
              <Segment secondary clearing>
                {activeIndex > 0 && (
                  <Button
                    color="purple"
                    as={Link}
                    to={{
                      pathname:
                        views[activeIndex - 1] && views[activeIndex - 1].step
                          ? views[activeIndex - 1].step.path
                          : undefined,
                      state: location.state
                    }}
                    size="large"
                    labelPosition="left"
                    icon="left chevron"
                    content="Previous"
                    replace
                  />
                )}
                {activeIndex < views.length - 1 && (
                  <Button
                    color="purple"
                    onClick={this.handleNext}
                    size="large"
                    labelPosition="right"
                    icon="right chevron"
                    content="Next"
                    floated="right"
                    disabled={loading}
                  />
                )}

                {activeIndex >= views.length - 1 && finishComponent}
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

StepManager.defaultProps = {
  views: [],
  loading: false,
  finishComponent: null,
  completed: false
};

export default StepManager;
