import React, { Component } from "react";
import { Modal, Form, Button, Icon } from "semantic-ui-react";
import { OptionsContext } from "../OptionsContext";
import TextArea from "./TextArea";
import Authorize from "./Authorize";
import { createComplaint } from "../lib/apiCalls";
import { toast } from "react-toastify";

class ComplaintModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { employeeId: null, complaint: "", isBlocking: true };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleCreate = () => {
    let { employeeId, complaint, isBlocking } = this.state;
    if (!employeeId) {
      toast.info("An employee must be selected to create the complaint");
      return;
    }
    if (!complaint) {
      toast.info("An explanation of the complaint is required");
      return;
    }
    createComplaint(
      {
        employeeId: employeeId,
        complaint: complaint,
        isBlocking: isBlocking,
        customerId: this.props.customerId
      },
      (error, response) => {
        if (error) {
          toast.error("Failed to create complaint");
          return;
        }
        if (this._isMounted) {
          this.props.onClose();
          this.setState({ employeeId: null, complaint: null });
        }
        toast.success("Successfully created the complaint");
      }
    );
  };
  handleToggle = (e, { name }) => {
    this.setState(state => ({ [name]: !state[name] }));
  };
  render() {
    let { complaint, isBlocking, employeeId } = this.state;
    let { open } = this.props;
    return (
      <div>
        <Modal open={open} onClose={this.props.onClose}>
          <Modal.Header>New Complaint</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Icon name="warning" />A complaint can be created between a
              stylist and a customer. When a complaint is made when the blocking
              checkbox is checked, the stylist and customer can no longer be
              assigned to one another.
            </Modal.Description>
            <br />
            <Form>
              <OptionsContext.Consumer>
                {({ stylists }) => {
                  return (
                    <Form.Select
                      clearable
                      options={stylists.map(stylist => ({
                        key: stylist.ID,
                        text: `${stylist.User.firstName || ""} ${stylist.User
                          .lastName || ""}`,
                        value: stylist.ID
                      }))}
                      label="Stylist"
                      search
                      name="employeeId"
                      value={employeeId}
                      onChange={this.handleChange}
                      placeholder="Select a Stylist"
                    />
                  );
                }}
              </OptionsContext.Consumer>
              <Form.Checkbox
                label="Block scheduling employee and customer"
                name="isBlocking"
                onChange={this.handleToggle}
                checked={isBlocking}
              />
              <Form.Field>
                <label>Complaint</label>
                <TextArea
                  placeholder="Explain who made the complaint, who they made it against, and the reason behind the complaint"
                  value={complaint}
                  name="complaint"
                  isEditing
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Create"
              color="green"
              onClick={this.handleCreate}
            />
            <Button content="Cancel" onClick={this.props.onClose} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default props => (
  <Authorize permission="CreateComplaints">
    <ComplaintModal {...props} />
  </Authorize>
);
