import React, { Component } from "react";
import {
  acceptPolicies,
  getTermsOfService,
  getPrivacyPolicy
} from "../lib/apiCalls";
import {
  Modal,
  Grid,
  Segment,
  Form,
  Button,
  Divider,
  Message
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

class AcceptPoliciesModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      acceptedPolicies: false,
      done: false
    };
  }
  componentDidMount() {
    this._isMounted = true;
    getTermsOfService(null, (error, response) => {
      if (error) {
        console.error(error);
        toast.error("Failed to retrieve Terms of Service");
      } else if (response) {
        let div = document.getElementById("terms-of-service");
        if (div) {
          div.innerHTML = response.trim();
        }
      }
    });
    getPrivacyPolicy(null, (error, response) => {
      if (error) {
        console.error(error);
        toast.error("Failed to retrieve Privacy Policy");
      } else if (response) {
        let div = document.getElementById("privacy-policy");
        if (div) {
          div.innerHTML = response.trim();
        }
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleToggle = (e, { name, checked }) => this.setState({ [name]: checked });

  handleAcceptPolicies = () => {
    let { userId } = this.props;
    if (!userId) {
      return;
    }
    if (this.state.acceptedPolicies !== true) {
      toast.info("You must accept the policies before continuing.");
      return;
    }
    acceptPolicies(
      {
        userId: userId,
        acceptedPolicies: this.state.acceptedPolicies
      },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            toast.error("Failed to save acknowledgement of policies");
            return;
          }
          this.setState({ done: true });
        }
      }
    );
  };
  render() {
    let { acceptedPolicies, done } = this.state;
    return (
      <Modal
        size="large"
        closeIcon={done === true}
        onClose={this.props.onAcceptedPolicies}
        closeOnDimmerClick={false}
        open={this.props.open}
      >
        <Modal.Header>Policy Acknowledgement</Modal.Header>
        <Modal.Content>
          <Grid columns={2} stackable>
            <Grid.Column>
              <Segment
                style={{ height: "400px", overflowY: "scroll" }}
                id="privacy-policy"
              ></Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment
                style={{ height: "400px", overflowY: "scroll" }}
                id="terms-of-service"
              ></Segment>
            </Grid.Column>
          </Grid>
          <Divider hidden />
          <Form>
            <Form.Checkbox
              label={
                <label>
                  I acknowledge that I agree to the{" "}
                  <Link target="_blank" to="/termsOfService">
                    Terms of Use
                  </Link>{" "}
                  and have read the{" "}
                  <Link target="_blank" to="/privacyPolicy">
                    Privacy Policy
                  </Link>{" "}
                  <span className="required">*</span>
                </label>
              }
              checked={acceptedPolicies}
              onChange={this.handleToggle}
              name="acceptedPolicies"
              id="login-accepted-policies"
            />
          </Form>
          {done === true && (
            <Message
              header="Thank you!"
              content="You may hand the device back to the employee now"
              success
            />
          )}
        </Modal.Content>
        {done !== true && (
          <Modal.Actions>
            <Button
              disabled={acceptedPolicies !== true}
              type="button"
              content="Submit"
              color="green"
              onClick={this.handleAcceptPolicies}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}
AcceptPoliciesModal.defaultProps = {
  onAcceptedPolicies: () => {}
};
export default AcceptPoliciesModal;
