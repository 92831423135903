import React, { Component } from "react";
import "./App.css";
import "react-table/react-table.css";
import Layout from "./Layout";
import Entrance from "./Pages/Entrance";
import FourOFour from "./Pages/FourOFour";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import { UserContext } from "./UserContext";
import { ThemeContext } from "./ThemeContext";
import ErrorBoundary from "./Shared/ErrorBoundary";
import { setToken } from "./lib/csrfToken";

import io from "./socketConnection";

import UnsavedChangesConfirmationModal from "./Shared/UnsavedChangesConfirmationModal";
import { ToastContainer, toast } from "react-toastify";

import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";

class App extends Component {
  constructor(props) {
    super(props);

    // Initialize the set user function to update user throughout application
    this.setUser = user => {
      this.setState(state => {
        return {
          user: { ...user }
        };
      });
    };

    // Initialize the set theme function to update theme throughout application
    this.setTheme = theme => {
      this.setState(state => {
        return {
          theme: { ...theme }
        };
      });
    };
    // Initialize context
    this.state = {
      user: {},
      theme: { color: "purple" },
      setUser: this.setUser,
      setTheme: this.setTheme,
      loading: true,

      confirmationModal: { open: false, callback: () => {} }
    };
    this.confirmationNavigation = this.confirmationNavigation.bind(this);
  }
  async UNSAFE_componentWillMount() {
    await setToken();
  }
  async componentDidMount() {
    if (this.state.userId) {
      this.setState({ loading: false });
      return;
    }
    io.socket.get("/api/v4/entrance/check-session-user", data => {
      this.setState({ user: data, loading: false });
    });
  }
  componentWillUnmount() {
    // Disconnect the socket when the page is done
    io.socket.disconnect();
  }
  /**
   * Function called when requesting user confirmation to continue navigating
   * @param {string} message Message to display in modal
   * @param {function} callback Callback called to give router a flag to redirect or not
   */
  confirmationNavigation(message, callback) {
    this.setState({
      confirmationModal: {
        open: true,
        message: message,
        onConfirm: navigate => {
          callback(navigate);
          this.setState({
            confirmationModal: { open: false, onConfirm: () => {} }
          });
        }
      }
    });
  }
  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <UserContext.Provider
        value={{ user: this.state.user, setUser: this.state.setUser }}
      >
        <ThemeContext.Provider
          value={{ theme: this.state.theme, setTheme: this.state.setTheme }}
        >
          <ErrorBoundary>
            <div className="background login" />
            <Router getUserConfirmation={this.confirmationNavigation}>
              <>
                <Switch>
                  <Route path={`/privacyPolicy`} component={PrivacyPolicy} />
                  <Route path={`/termsOfService`} component={TermsOfService} />
                  <Route
                    path="/login"
                    render={props => (
                      <Entrance {...props} user={this.state.user} />
                    )}
                  />
                  <Route
                    path="/password"
                    render={props => (
                      <Entrance {...props} user={this.state.user} />
                    )}
                  />
                  <Route
                    path="/sign-up"
                    render={props => (
                      <Entrance {...props} user={this.state.user} />
                    )}
                  />
                  <Route
                    path="/appointmentConfirmation"
                    render={props => (
                      <Entrance {...props} user={this.state.user} />
                    )}
                  />
                  <Route
                    path="/emailConfirmation"
                    render={props => (
                      <Entrance {...props} user={this.state.user} />
                    )}
                  />
                  <Route
                    path="/appointmentFeedback"
                    render={props => (
                      <Entrance {...props} user={this.state.user} />
                    )}
                  />
                  <Route
                    path="/appointmentCompletion"
                    render={props => (
                      <Layout
                        {...props}
                        confirmationModal={this.state.confirmationModal}
                      />
                    )}
                  />
                  <Route
                    path="/appointmentCompletionWithoutCustomer"
                    render={props => (
                      <Layout
                        {...props}
                        confirmationModal={this.state.confirmationModal}
                      />
                    )}
                  />
                  <Route
                    path="/dashboard"
                    render={props => (
                      <Layout
                        {...props}
                        confirmationModal={this.state.confirmationModal}
                      />
                    )}
                  />
                  <Route path="/" exact component={Default} />
                  <Route path="/*" component={FourOFour} />
                  <Route component={Default} />
                </Switch>
                <UnsavedChangesConfirmationModal
                  {...this.state.confirmationModal}
                />
                <ToastContainer
                  autoClose={5000}
                  position={toast.POSITION.BOTTOM_RIGHT}
                />
              </>
            </Router>
          </ErrorBoundary>
        </ThemeContext.Provider>
      </UserContext.Provider>
    );
  }
}
const Default = () => {
  return <Redirect to={`/login`} />;
};

export default App;
