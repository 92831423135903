import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Segment,
  Statistic,
  Header,
  Item,
  Grid,
  Label,
  Responsive
} from "semantic-ui-react";
import Money from "./Money";
import { getUserBalance } from "../lib/apiCalls";
import { toast } from "react-toastify";
import moment from "moment";

class Balance extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      balance: 0,
      recentPayments: [],
      loading: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.userId) {
      this.setState({ loading: false });
      return;
    }
    getUserBalance({ userId: this.props.userId }, (error, response) => {
      if (error) {
        toast.error("Failed to retrieve balance");
        if (this._isMounted) {
          this.setState({ loading: false });
        }
        return;
      }
      if (this._isMounted) {
        this.setState({ ...response, loading: false });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let { balance, recentPayments, loading } = this.state;
    return (
      <Segment.Group
        horizontal={window.screen.width >= Responsive.onlyTablet.minWidth}
        className="horizontal-grid-segment"
      >
        <Segment loading={loading} textAlign="center" vertical>
          <Statistic color={balance < 0 ? "red" : "green"}>
            <Statistic.Label>Balance</Statistic.Label>
            <Statistic.Value>
              <Money value={balance} />
            </Statistic.Value>
          </Statistic>
        </Segment>
        <Segment
          loading={loading}
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          <Header dividing>Recent Payments</Header>
          <Item.Group divided>
            {recentPayments.map(transaction => {
              let totalPaid =
                parseFloat(transaction.creditCardAmount) +
                parseFloat(transaction.cashAmount) +
                parseFloat(transaction.checkAmount) +
                parseFloat(transaction.giftCardAmount);
              if (
                parseFloat(totalPaid) > 0 ||
                parseFloat(transaction.creditAmount) > 0
              ) {
                return (
                  <Item key={transaction.ID}>
                    <Item.Content>
                      <Grid columns="equal">
                        <Grid.Column>
                          <Header
                            size="small"
                            content={moment(transaction.transactionDate).format(
                              "LL"
                            )}
                            subheader={
                              transaction.TransactionsReason
                                ? transaction.TransactionsReason.reason
                                : null
                            }
                          />
                          <div>
                            {transaction.creditCardAmount > 0 && (
                              <Label size="small">Credit Card</Label>
                            )}
                            {transaction.cashAmount > 0 && (
                              <Label size="small">Cash</Label>
                            )}
                            {transaction.checkAmount > 0 && (
                              <Label size="small">Check</Label>
                            )}
                            {transaction.giftCardAmount > 0 && (
                              <Label size="small">Gift Card</Label>
                            )}
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <Header>
                            <Header.Content>
                              <Money value={totalPaid} />
                              <Header.Subheader>Total Paid</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Header>
                            <Header.Content>
                              <Money value={transaction.creditAmount} />
                              <Header.Subheader>Credited</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Grid.Column>
                      </Grid>
                    </Item.Content>
                  </Item>
                );
              } else {
                return null;
              }
            })}
          </Item.Group>
        </Segment>
      </Segment.Group>
    );
  }
}

Balance.defaultProps = {
  userId: null
};

Balance.propTypes = {
  userId: PropTypes.number
};

export default Balance;
