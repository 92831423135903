import React, { Component } from "react";

import ReactTable from "react-table";
import moment from "moment";

import { Link } from "react-router-dom";
import _ from "lodash";
import {
  Header,
  Icon,
  Button,
  Divider,
  Menu,
  Form,
  Popup,
} from "semantic-ui-react";
import PetList from "../Shared/PetList";

import customFilter, {
  filterIncludesPet,
  sortTime,
  sortByPetName,
} from "../lib/filters";
import Input from "../Shared/Input";
import AppointmentPreview from "../Shared/AppointmentPreview";
import SidebarLayout from "../Shared/SidebarLayout";
import { AppointmentsContext } from "../AppointmentsContext";
import {
  removeMapsco,
  getTrueIndexes,
  convertMomentDateToUTC,
} from "../lib/helperFunctions";
import DatePagination from "../Shared/DatePagination";
import { OptionsContext } from "../OptionsContext";
import { RRule, RRuleSet } from "rrule";
import { toast } from "react-toastify";

class AllAppointments extends Component {
  constructor(props) {
    super(props);
    this.appointmentTable = null;

    this.state = {
      selectedRow: null,
      activeDay: 0,
      appointmentDateFilter: moment().format("MM-DD-YYYY"),
      filtered: [],
      rowCopy: null,
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.createHoles = this.createHoles.bind(this);
    this.createStandings = this.createStandings.bind(this);
    this.getRRuleSet = this.getRRuleSet.bind(this);
    this.generateHolesBetweenTimes = this.generateHolesBetweenTimes.bind(this);
  }
  onRowClick(state, rowInfo, column) {
    if (rowInfo.original.ID || rowInfo.original.standingId) {
      this.setState({ selectedRow: rowInfo.original });
    }
    let clickedStylist = _.find(this.props.stylists, {
      ID: rowInfo.original.employeeId,
    });

    let { location } = this.props;
    if (!location.state || !location.state.estimate) {
      return;
    }

    if (
      _.find(this.props.blockingComplaints, {
        employeeId: clickedStylist.ID,
        customerId: location.state.estimate.customerId,
      })
    ) {
      toast.error(
        "Cannot auto select stylist due to blocking complaint. Block must be removed to assign stylist."
      );
      let clickedRowCopy = {
        startTime: rowInfo.original.startTime,
        endTime: rowInfo.original.endTime,
        appointmentDate: rowInfo.original.appointmentDate,
      };
      this.setState({ rowCopy: clickedRowCopy });
      return;
    }
    let clickedRowCopy = {
      startTime: rowInfo.original.startTime,
      endTime: rowInfo.original.endTime,
      employeeId: rowInfo.original.employeeId,
      appointmentDate: rowInfo.original.appointmentDate,
    };
    this.setState({ rowCopy: clickedRowCopy });
  }
  handleAppointmentAction = (newAppointmentId) => {
    if (newAppointmentId) {
      // Find the appointment in the table to select
      this.findAppointmentInTable(newAppointmentId);
    }
  };
  findAppointmentInTable = (newAppointmentId) => {
    if (this.appointmentTable != null) {
      let foundAppointment = _.find(
        this.appointmentTable.getResolvedState().sortedData,
        (data) => data._original.ID === newAppointmentId
      );
      if (foundAppointment != null) {
        this.setState({ selectedRow: foundAppointment._original });
      }
    }
  };
  getTrProps = (state, rowInfo, column, instance) => {
    let { selectedRow } = this.state;
    if (rowInfo != null) {
      var style = {};
      var classNames = " ";
      // Add stylist divider if sorted by appointment date then stylist or stylist first
      if (state && state.sorted && rowInfo.row) {
        // Sorted first is appointment date
        if (state.sorted[0] && state.sorted[0].id === "appointmentDate") {
          // sorted second is employeId
          if (state.sorted[1] && state.sorted[1].id === "employeeId") {
            // Check if next row has different employee
            let nextRow = state.pageRows[rowInfo.viewIndex + 1];
            if (nextRow && nextRow.employeeId !== rowInfo.row.employeeId) {
              classNames = classNames + "dividing-row ";
            }
          }
        } else if (
          // sorted first is employeeId
          state.sorted[0] &&
          state.sorted[0].id === "employeeId"
        ) {
          // Check if next row has different employee
          let nextRow = state.pageRows[rowInfo.viewIndex + 1];
          if (nextRow && nextRow.employeeId !== rowInfo.row.employeeId) {
            classNames = classNames + "dividing-row ";
          }
        }
      }
      // If the row has an ID, set the row to have a pointer to show it can be selected
      if (rowInfo.original.ID != null || rowInfo.original.standingId != null) {
        style.cursor = "pointer";
      }
      // Determine if row is selected from the ID of the row or the standing ID of the row
      if (selectedRow) {
        if (selectedRow.ID != null && rowInfo.original.ID === selectedRow.ID) {
          classNames = classNames + " selected-row";
        }
        if (
          selectedRow.standingId != null &&
          rowInfo.original.standingId === selectedRow.standingId
        ) {
          classNames = classNames + " selected-row";
        }
      }
      // Set a classname based on status
      if (rowInfo.original.statusId) {
        let appointmentStatus = _.find(this.props.statuses, {
          ID: rowInfo.original.statusId,
        });
        classNames =
          classNames + " appointment-status-" + appointmentStatus.status;
      }
    }
    return {
      onClick: () => this.onRowClick(state, rowInfo, column),
      style: style,
      className: classNames,
    };
  };
  createHoles(date, appointments) {
    let dayOfWeek = date.format("dddd");
    // Get all stylist's to create holes for
    let stylists = this.props.stylists.filter((stylist) => {
      return stylist.dateTerminated === null;
    });

    let holes = [];
    stylists.forEach((stylist) => {
      // count the amount of pets for the day, appointments pets, and any holes created
      // get the appointments associated to the stylist for this day
      let stylistAppointments = appointments.filter(
        (appointment) => appointment.employeeId === stylist.ID
      );

      // Get the end time
      let endTime = stylist.workDayEndTime
        ? moment(
            `${moment(date).format("MM-DD-YYYY")} ${stylist.workDayEndTime}`,
            "MM-DD-YYYY h:mm A"
          )
        : null;

      let workDayStartTime = moment(
        `${moment(date).format("MM-DD-YYYY")} ${stylist.workDayStartTime}`,
        "MM-DD-YYYY h:mm A"
      );

      if (
        !endTime &&
        stylist.petsPerDay &&
        stylist.minutesPerPet &&
        workDayStartTime
      ) {
        endTime = moment(workDayStartTime).add(
          (stylist.petsPerDay || 5) * (stylist.minutesPerPet || 90),
          "minutes"
        );
      }
      // BASE SCHEDULE
      if (stylist["works" + dayOfWeek] === true) {
        holes = holes.concat(
          this.generateHolesBetweenTimes(
            workDayStartTime,
            endTime,
            stylist,
            stylistAppointments,
            date
          )
        );
      }

      // WORKING SCHEDULE EXCEPTIONS
      for (let each of stylist.ScheduleExceptions) {
        if (each.isWorking !== true) continue;
        let event = each.Event;

        let eventStart = moment(
          `${event.startDate} ${event.startTime}`,
          "MM-DD-YYYY h:mm A"
        );
        let eventEnd = moment(
          `${event.endDate} ${event.endTime}`,
          "MM-DD-YYYY h:mm A"
        );

        // Check if a recurring schedule exception occurs
        if (event.isRecurring === true) {
          let duration = Math.abs(
            moment.duration(moment(eventEnd).diff(eventStart)).asMinutes()
          );
          // Check if the hole is within a recurring rule
          let rruleSet = this.getRRuleSet(event);
          // Get all instances of the event for that day
          let instances = rruleSet.between(
            convertMomentDateToUTC(
              moment(date).utc().subtract(duration).startOf("day")
            ),
            convertMomentDateToUTC(
              moment(date).utc().add(duration).endOf("day")
            ),
            true
          );
          // Check if the appointment is in event time period when an instance is found
          if (instances.length < 1) {
            continue;
          }
        } else {
          if (eventStart.isBefore(moment(date).startOf("day"))) {
            eventStart = moment(date).startOf("day");
          }
          if (eventEnd.isAfter(moment(date).endOf("day"))) {
            eventEnd = moment(date).endOf("day");
          }
          // Do not generate holes if the exception is not for the viewing date
          if (
            !date.isBetween(
              moment(eventStart).startOf("day"),
              moment(eventEnd).endOf("day"),
              null,
              "[)"
            )
          ) {
            continue;
          }
        }

        // Generate holes until end of schedule exception or start time
        if (stylist["works" + dayOfWeek] === true) {
          // Stylist works this day so generate the exceptions around the base schedule
          if (eventStart.isBefore(workDayStartTime)) {
            holes = holes.concat(
              this.generateHolesBetweenTimes(
                eventStart,
                eventEnd.isBefore(workDayStartTime)
                  ? eventEnd
                  : workDayStartTime,
                stylist,
                stylistAppointments,
                date
              )
            );
            if (eventEnd.isAfter(endTime)) {
              holes = holes.concat(
                this.generateHolesBetweenTimes(
                  endTime,
                  eventEnd,
                  stylist,
                  stylistAppointments,
                  date
                )
              );
            }
          } else {
            holes = holes.concat(
              this.generateHolesBetweenTimes(
                eventStart.isSameOrAfter(endTime) ? eventStart : endTime,
                eventEnd,
                stylist,
                stylistAppointments,
                date
              )
            );
          }
        } else {
          // Stylist does not work this day so generate the holes regardless of base schedule
          holes = holes.concat(
            this.generateHolesBetweenTimes(
              eventStart,
              eventEnd,
              stylist,
              stylistAppointments,
              date
            )
          );
        }
      }
    });

    //TODO: Figure out how to get this working appropriately for filtering extra holes added during work exception hole additions
    // Remove any duplicate holes within the array
    // holes = holes.filter((hole, index, holes) => {
    //   // Check the index of each hole in the list
    //   // If that index is equal to the index of a hole that has a duplicate start date, remove it from the list
    //   return index !== holes.findIndex((h) => hole.startDate === h.startDate);
    // });

    return holes;
  }
  generateHolesBetweenTimes(
    startTime,
    endTime,
    stylist,
    stylistAppointments,
    date
  ) {
    let time = moment(
      `${moment(date).format("MM-DD-YYYY")} ${moment(startTime).format(
        "h:mm A"
      )}`,
      "MM-DD-YYYY h:mm A"
    );
    let timeEnd = moment(
      `${moment(date).format("MM-DD-YYYY")} ${moment(endTime).format(
        "h:mm A"
      )}`,
      "MM-DD-YYYY h:mm A"
    );

    const formattedDate = date.format("MM-DD-YYYY");
    // Update the time to start at the current time if it is the current day
    let isCurrentDay = moment().isSame(date, "day");
    let stylistHoles = [];
    // Generate holes until the end time is reached
    while (time.isBefore(timeEnd)) {
      let nextTime = moment(time);
      if (stylist.minutesPerPet && parseInt(stylist.minutesPerPet) > 0) {
        nextTime.add(parseInt(stylist.minutesPerPet), "m");
      } else {
        nextTime.add(90, "m");
      }
      // Find an appointment that starts in this hole time
      let foundAppointmentWithinHole = _.find(
        stylistAppointments,
        (appointment) => {
          if (!appointment.startTime) return;
          return moment(
            `${formattedDate} ${appointment.startTime}`,
            "MM-DD-YYYY h:mm A"
          ).isBetween(moment(time), moment(nextTime), null, "[)");
        }
      );

      if (foundAppointmentWithinHole != null) {
        // Get appointment end time and set that as the time instead of increment the time by the appointment hole time
        let appointmentEndTime = null;
        if (foundAppointmentWithinHole.endTime != null) {
          appointmentEndTime = moment(
            foundAppointmentWithinHole.endTime,
            "h:mm A"
          );
        } else {
          appointmentEndTime = this.getAppointmentEndTime(
            foundAppointmentWithinHole,
            stylist.minutesPerPet
          );
        }
        let duration = Math.abs(
          moment.duration(
            moment(
              `${formattedDate} ${moment(appointmentEndTime).format("h:mm A")}`,
              "MM-DD-YYYY h:mm A"
            ).diff(moment(time))
          )
        );
        // Set the next hole time to look at based on appointment endtime or defaulting to the minutes per pet time
        if (duration !== 0) {
          time.add(duration);
        } else {
          time.add(stylist.minutesPerPet || 90, "m");
        }
      } else {
        // Add the new hole if it is after the current time
        if (!isCurrentDay || moment().isSameOrBefore(time)) {
          if (this.notInStylistExceptions(date, time, stylist)) {
            stylistHoles.push({
              appointmentDate: formattedDate,
              startTime: moment(time).format("h:mm A"),
              Employee: stylist,
              employeeId: stylist.ID,
            });
          }
        }
        time.add(stylist.minutesPerPet || 90, "m");
      }
    }
    return stylistHoles;
  }
  notInStylistExceptions = (date, time, stylist) => {
    let holeTime = moment(
      date.format("MM-DD-YYYY") + " " + time.format("h:mm A"),
      "MM-DD-YYYY h:mm A"
    );
    for (let i = 0; i < stylist.ScheduleExceptions.length; i++) {
      let event = stylist.ScheduleExceptions[i].Event;
      if (stylist.ScheduleExceptions[i].isWorking === true || !event) continue;
      if (event.isRecurring === true) {
        // Check if the hole is within a recurring rule
        let rruleSet = this.getRRuleSet(event);

        // Get duration of exception
        // Find dates of exception that have the possibility of happening at the same time as the appointment
        let eventStart = moment(
          `${event.startDate} ${event.startTime}`,
          "MM-DD-YYYY h:mm A"
        );
        let eventEnd = moment(
          `${event.endDate} ${event.endTime}`,
          "MM-DD-YYYY h:mm A"
        );
        let eventDuration = Math.abs(eventStart.diff(eventEnd));
        let instances =
          rruleSet.between(
            convertMomentDateToUTC(
              moment(holeTime)
                .utc()
                .startOf("day")
                .subtract(eventDuration)
                .toDate()
            ),
            convertMomentDateToUTC(
              moment(holeTime).utc().endOf("day").add(eventDuration).toDate()
            ),
            true
          ) || [];
        // Check if the appointment is in event time period when an instance is found
        if (
          instances.length > 0 &&
          _.find(instances, (date) => {
            let actualStart = moment(
              `${moment(date).utc().format("MM-DD-YYYY")} ${event.startTime}`,
              "MM-DD-YYYY h:mm A"
            );
            let actualEnd = moment(actualStart).add(eventDuration);
            return moment(holeTime).isBetween(
              actualStart,
              actualEnd,
              null,
              "[)"
            );
          }) != null
        ) {
          return false;
        }
      } else {
        // Check the one time event
        if (
          holeTime.isBetween(
            moment(
              moment(event.startDate, "MM-DD-YYYY").format("MM-DD-YYYY") +
                " " +
                event.startTime,
              "MM-DD-YYYY h:mm A"
            ),
            moment(
              moment(event.endDate, "MM-DD-YYYY").format("MM-DD-YYYY") +
                " " +
                event.endTime,
              "MM-DD-YYYY h:mm A"
            ),
            null,
            "[)"
          )
        ) {
          return false;
        }
      }
    }

    // Passed all exceptions
    return true;
  };
  getRRuleSet(event) {
    const rruleSet = new RRuleSet();
    if (!event || !event.RecurringPattern) return;
    let recurrence = event.RecurringPattern;
    // Find the recurring type from options
    let recurringType = _.find(this.props.recurringTypes, {
      ID: recurrence.recurringTypeId,
    });
    if (!recurringType) return;
    // Generate the rule for the recurrence
    rruleSet.rrule(
      new RRule({
        freq: RRule[recurringType.type.toUpperCase()],
        interval: recurrence.separationCount || 1,
        byweekday: recurrence.daysOfWeek
          ? getTrueIndexes(recurrence.daysOfWeek, -1)
          : null,
        bymonth: recurrence.monthsOfYear
          ? getTrueIndexes(recurrence.monthsOfYear)
          : null,
        bymonthday: recurrence.daysOfMonth
          ? getTrueIndexes(recurrence.daysOfMonth)
          : null,
        dtstart: convertMomentDateToUTC(
          moment(recurrence.startDate, "MM-DD-YYYY").utc().startOf("day")
        ),
        until: recurrence.endDate
          ? convertMomentDateToUTC(
              moment(recurrence.endDate, "MM-DD-YYYY").utc().endOf("day")
            )
          : null,
      })
    );
    // Add all the excluded dates to the rrule
    if (event.EventExceptions && event.EventExceptions.length > 0) {
      for (let each of event.EventExceptions) {
        rruleSet.exdate(
          convertMomentDateToUTC(moment(each.startDate, "MM-DD-YYYY"))
        );
      }
    }

    return rruleSet;
  }
  updateDate = (e, { value }) => {
    let date = moment(value, "MM-DD-YYYY");
    let days = date.diff(moment().startOf("day"), "days");
    this.setState({
      activeDay: days,
      appointmentDateFilter: value,
    });
  };
  createStandings(standingAppointments, date) {
    let standingInstances = [];

    for (let i = 0; i < standingAppointments.length; i++) {
      let appointment = standingAppointments[i];
      let rruleSet = this.getRRuleSet(appointment.Standing);
      if (!rruleSet) {
        continue;
      }
      let instances = rruleSet.between(
        convertMomentDateToUTC(moment(date).utc().startOf("day")),
        convertMomentDateToUTC(moment(date).utc().endOf("day")),
        true
      );
      standingInstances = standingInstances.concat(
        instances.map((instance) => ({
          ...appointment,
          ID: undefined,
          standingId: appointment.ID,
          appointmentDate: moment(instance).utc().format("MM-DD-YYYY"),
        }))
      );
    }

    return standingInstances;
  }
  getAppointmentEndTime = (appointment, minutesPerPet = 90) => {
    // If the appointment end time is set, use that time
    if (appointment.endTime) {
      return moment(
        `${appointment.appointmentDate} ${appointment.endTime}`,
        "MM-DD-YYYY h:mm A"
      );
    }
    // Calculate the ending time from the pets if no set end time
    let time = moment(
      `${appointment.appointmentDate} ${appointment.startTime}`,
      "MM-DD-YYYY h:mm A"
    );
    if (
      appointment &&
      appointment.Estimate &&
      appointment.Estimate.EstimatePets
    ) {
      appointment.Estimate.EstimatePets.forEach((estimatePet) => {
        if (estimatePet.Pet) {
          time.add(
            estimatePet.Pet.minutesToGroom && estimatePet.Pet.minutesToGroom > 0
              ? estimatePet.Pet.minutesToGroom
              : minutesPerPet,
            "m"
          );
        }
      });
    }
    return time;
  };

  render() {
    const { selectedRow, rowCopy, activeDay, filtered, appointmentDateFilter } =
      this.state;
    const { header, location, statuses } = this.props;
    return (
      <SidebarLayout>
        <div>
          {header && (
            <Menu text stackable className="responsive-header">
              <Menu.Item>
                <Header as="h2">{header}</Header>
              </Menu.Item>
              <Menu.Item>
                <Input
                  validation="date"
                  icon={false}
                  placeholder="Date"
                  isEditing
                  value={appointmentDateFilter}
                  name="appointmentDateFilter"
                  onChange={this.updateDate}
                />
              </Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Button
                    as={Link}
                    fluid
                    content="New Estimate"
                    color="blue"
                    to="/dashboard/estimate"
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          )}
          <Divider hidden fitted clearing />
          <AppointmentsContext.Consumer>
            {({ appointments, standings }) => {
              let date = moment().add(activeDay, "days");

              // Filter the appointments by the date
              let filteredAppointments = _.filter(appointments, {
                appointmentDate: date.format("MM-DD-YYYY"),
              });

              // Find all the standing appointments
              let appointmentStandings = this.createStandings(standings, date);

              // Merge the filtered appointments and the standing appointments
              let allRows = filteredAppointments.concat(appointmentStandings);
              // Merge appointment holes
              let holes = this.createHoles(date, allRows);
              allRows = allRows.concat(holes);

              return (
                <ReactTable
                  ref={(ref) => (this.appointmentTable = ref)}
                  getTheadThProps={(state, rowInfo, instance) => {
                    return { className: "appointments-table-" + instance.id };
                  }}
                  showPaginationTop={true}
                  showPageSizeOptions={false}
                  data={allRows}
                  activeDay={activeDay}
                  page={0}
                  minRows={15}
                  pageSize={allRows.length || 20}
                  style={{
                    height: window.innerHeight - 150, // This will force the table body to overflow and scroll, since there is not enough room
                  }}
                  defaultSorted={[
                    { id: "appointmentDate" },
                    { id: "employeeId" },
                    { id: "startTime" },
                  ]}
                  onPageChange={(activeDay) => {
                    let date = moment().add(activeDay, "days");

                    this.setState({
                      activeDay: activeDay,
                      appointmentDateFilter: date.format("MM-DD-YYYY"),
                    });
                  }}
                  PaginationComponent={DatePagination}
                  className="-striped -highlight appointment-table"
                  // Add styling and other props to each row
                  getTrProps={this.getTrProps}
                  // Control filtering to set appointment date when page changes
                  filterable
                  filtered={filtered}
                  defaultFilterMethod={customFilter}
                  onFilteredChange={(filters, column) => {
                    this.setState({
                      filtered: filters,
                    });
                  }}
                  columns={[
                    {
                      Header: "Time",
                      accessor: "startTime",
                      Cell: (props) =>
                        props.value
                          ? moment(props.value, "h:mm A").format("h:mm A")
                          : null,
                      minWidth: 70,
                      sortMethod: sortTime,
                      filterable: false,
                    },
                    {
                      Header: "Stylist",
                      headerClassName: "dropdown-filter",
                      id: "employeeId",
                      accessor: (data) => {
                        let fullName =
                          data && data.Employee && data.Employee.User
                            ? `${data.Employee.User.firstName || ""} ${
                                data.Employee.User.lastName || ""
                              }`
                            : `${data.EmployeeFirstName || ""} ${
                                data.EmployeeLastName || ""
                              }`;
                        return fullName;
                      },
                      Filter: ({ filter, onChange }) => (
                        <Form size="small">
                          <Form.Select
                            className="filter-select"
                            fluid
                            selectOnBlur={false}
                            clearable
                            search
                            name="employeeId"
                            placeholder="Select Stylist"
                            options={this.props.stylists.map((stylist) => {
                              let fullName =
                                stylist && stylist.User
                                  ? `${stylist.User.firstName || ""} ${
                                      stylist.User.lastName || ""
                                    }`
                                  : `${stylist.EmployeeFirstName || ""} ${
                                      stylist.EmployeeLastName || ""
                                    }`;
                              return {
                                key: stylist.ID,
                                value: fullName,
                                text: fullName,
                              };
                            })}
                            value={filter ? filter.value : null}
                            onChange={(event, { name, value }) => {
                              onChange(value);
                            }}
                          />
                        </Form>
                      ),
                    },
                    {
                      Header: "Customer",
                      id: "customerId",
                      accessor: (data) =>
                        data.Customer &&
                        data.Customer.User &&
                        `${data.Customer.User.firstName} ${data.Customer.User.lastName}`,
                    },
                    {
                      Header: "Status",
                      id: "statusId",
                      accessor: (data) => {
                        let appointmentStatus = _.find(statuses, {
                          ID: data.statusId,
                        });
                        if (data.isStanding) {
                          return "Standing";
                        }
                        return appointmentStatus != null
                          ? _.startCase(appointmentStatus.status)
                          : "";
                      },
                      minWidth: 50,
                      getProps: (state, rowInfo, column) => {
                        return {
                          style: {
                            textAlign: "center",
                          },
                        };
                      },
                    },
                    {
                      Header: () => (
                        <Popup
                          inverted
                          content="Using Preferred Stylist"
                          trigger={<i className="fas fa-user-check"></i>}
                        />
                      ),
                      id: "preferredStylistId",
                      accessor: (data) =>
                        data.Customer &&
                        data.Customer.preferredStylistId &&
                        data.Customer.preferredStylistId === data.employeeId,
                      filterable: false,
                      width: 40,
                      Cell: (props) =>
                        props.value === true ? <Icon name="check" /> : "",
                      getProps: (state, rowInfo, column) => {
                        return {
                          style: {
                            textAlign: "center",
                          },
                        };
                      },
                    },
                    {
                      Header: () => (
                        <Popup
                          inverted
                          content="Standing Appointment"
                          trigger={<Icon name="redo" />}
                        />
                      ),
                      accessor: "Standing.ID",
                      filterable: false,
                      width: 40,
                      Cell: (props) => {
                        return props.value != null ? (
                          <Icon name="check" />
                        ) : (
                          <div />
                        );
                      },
                      getProps: (state, rowInfo, column) => {
                        return { style: { textAlign: "center" } };
                      },
                    },
                    {
                      Header: "Pets",
                      id: "pets",
                      accessor: (data) =>
                        data.Estimate && data.Estimate.EstimatePets
                          ? data.Estimate.EstimatePets.map(
                              (petEstimate) => petEstimate.Pet
                            )
                          : null,
                      Cell: (props) => (
                        <PetList includeDetails pets={props.value} />
                      ),
                      filterMethod: filterIncludesPet,
                      sortMethod: sortByPetName,
                    },
                    {
                      Header: "Mapsco",
                      id: "estimateAddressMapsco",
                      accessor: (data) =>
                        data.Estimate &&
                        data.Estimate.AddressString &&
                        removeMapsco(data.Estimate.AddressString).mapsco,
                      minWidth: 60,
                    },
                    {
                      Header: "Address",
                      id: "estimateAddress",
                      accessor: (data) =>
                        data.Estimate &&
                        data.Estimate.AddressString &&
                        removeMapsco(data.Estimate.AddressString).address,
                    },
                  ]}
                />
              );
            }}
          </AppointmentsContext.Consumer>
        </div>
        <AppointmentPreview
          appointment={selectedRow}
          requiredAppointmentFields={[
            "employeeId",
            "startTime",
            "appointmentDate",
          ]}
          onAppointmentAction={this.handleAppointmentAction}
          estimateId={
            selectedRow && selectedRow.Estimate ? selectedRow.Estimate.ID : null
          }
        />
        {/* Appointment displayed on left side of table as stated by sidebar layout.  */}
        {location.state && location.state.estimate ? (
          <AppointmentPreview
            requiredAppointmentFields={[
              "employeeId",
              "startTime",
              "appointmentDate",
            ]}
            onAppointmentAction={(newAppointmentId) => {
              this.props.history.replace("/dashboard/appointments", {});
              this.handleAppointmentAction(newAppointmentId);
            }}
            estimateId={location.state.estimate.ID}
            appointment={
              location.state && location.state.appointment
                ? { ...rowCopy, ...location.state.appointment }
                : rowCopy
            }
          />
        ) : null}
      </SidebarLayout>
    );
  }
}

AllAppointments.defaultProps = {
  onRowClick: null,
  stylists: [],
  statuses: [],
};

export default (props) => (
  <OptionsContext.Consumer>
    {({ stylists, blockingComplaints, recurringTypes, statuses }) => (
      <AllAppointments
        {...props}
        stylists={stylists}
        blockingComplaints={blockingComplaints}
        recurringTypes={recurringTypes}
        statuses={statuses}
      />
    )}
  </OptionsContext.Consumer>
);
