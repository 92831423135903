import React, { Component } from "react";
import {
  Header,
  Grid,
  Segment,
  Button,
  Form,
  Divider,
  Container,
  Accordion,
  Label
} from "semantic-ui-react";
import { toast } from "react-toastify";
import {
  findTransactionById,
  saveNewTransaction,
  getUserBalance,
  getUserDetails
} from "../lib/apiCalls";
import { Link } from "react-router-dom";
import Input from "../Shared/Input";
import TextArea from "../Shared/TextArea";
import { moneyFormat } from "../lib/format";
import { OptionsContext } from "../OptionsContext";
import TransactionPayment from "../Shared/Transactions/PaymentTabs";
import { defaultTransaction } from "../lib/defaultObjects";
import Authorize from "../Shared/Authorize";
import _ from "lodash";
import RefundModal from "../Shared/Transactions/RefundModal";
import { validateCreditCard } from "../lib/validation";
import VoidModal from "../Shared/Transactions/VoidModal";

class Transaction extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      transaction: defaultTransaction(props.transaction),
      loading: false,
      isEditing: false
    };
    this.calculateTransactionData = this.calculateTransactionData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let { match } = this.props;
    if (match && match.params) {
      this.getUserDetails();
      if (match.params.transactionId != null) {
        this.getTransaction(match.params.transactionId);
      } else if (match.params.userId || this.props.userId) {
        this.getUserBalance();
      }
    } else {
      this.setState({ isEditing: true });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let { prevMatch } = prevProps;
    let { match } = this.props;

    if (match && match.params && prevMatch) {
      if (prevMatch.params.transactionId !== match.params.transactionId) {
        this.getTransaction(match.params.transactionId);
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onChange = (e, { name, value }) => {
    if (this.state.transaction && this.state.transaction.ID != null) return;
    this.props.onChange(e, { name, value });
    this.setState(state => ({
      transaction: { ...state.transaction, [name]: value }
    }));
  };
  onMoneyChange = (e, { name, value }) => {
    this.setState(state => {
      let moneyValue =
        countDecimals(value) > 2
          ? Math.floor(parseFloat(value) * 100) / 100
          : value;
      let transaction = { ...state.transaction, [name]: moneyValue };

      if (transaction && transaction.ID != null) return;
      // Update the service fee if the breakdown gave an updated number

      if (name === "serviceFee") {
        transaction.serviceFee = moneyValue;
        transaction.serviceFeeOther = parseFloat(
          (
            parseFloat(transaction.serviceFee || 0) -
            parseFloat(transaction.serviceFeeGroom || 0) -
            parseFloat(transaction.serviceFeeTrip || 0) -
            parseFloat(transaction.serviceFeeGenerator || 0) -
            parseFloat(transaction.serviceFeeFuel || 0) +
            parseFloat(transaction.serviceDiscountTotal || 0) -
            parseFloat(transaction.serviceFeeSalesTax || 0)
          ).toFixed(2)
        );
      } else if (
        !this.serviceEqualsBreakdown(transaction) &&
        name.includes("service")
      ) {
        transaction.serviceFee = parseFloat(
          (
            parseFloat(transaction.serviceFeeGroom || 0) +
            parseFloat(transaction.serviceFeeTrip || 0) +
            parseFloat(transaction.serviceFeeGenerator || 0) +
            parseFloat(transaction.serviceFeeFuel || 0) -
            parseFloat(transaction.serviceDiscountTotal || 0) +
            parseFloat(transaction.serviceFeeSalesTax || 0) +
            parseFloat(transaction.serviceFeeOther || 0)
          ).toFixed(2)
        );
      }

      this.props.onChange(e, { name, moneyValue });
      return {
        transaction: transaction
      };
    });
  };
  onAutoFill = (
    methodOfPayment,
    amount = 0,
    totalCharges = 0,
    totalPaid = 0
  ) => {
    // Don't change values if no transaction or the paid amount is greater than the charges
    if (this.state.transaction && this.state.transaction.ID != null) return;
    if (totalPaid >= totalCharges) return;
    let amountLeft = parseFloat(totalCharges) - parseFloat(totalPaid);

    let value = parseFloat(amount) + parseFloat(amountLeft);
    value = value.toFixed(2);
    this.setState(state => ({
      transaction: { ...state.transaction, [methodOfPayment]: value + "" }
    }));
    this.props.onChange(null, { name: [methodOfPayment], value: value + "" });
  };
  getUserDetails = () => {
    let { match } = this.props;
    let userId =
      match && match.params.userId ? match.params.userId : this.props.userId;
    if (!userId) return;
    this.setState({ loading: true });
    getUserDetails({ userId: userId }, (error, data) => {
      if (error) {
        toast.error("Failed to retrieve user details");
      }

      if (this._isMounted) {
        this.setState(state => ({
          user: data,
          loading: false
        }));
      }
    });
  };
  getUserBalance = () => {
    let { match } = this.props;
    let userId =
      match && match.params.userId ? match.params.userId : this.props.userId;
    if (!userId) return;
    this.setState({ loading: true });
    getUserBalance({ userId: userId }, (error, data) => {
      if (error) {
        toast.error("Failed to retrieve balance");
      }
      let defaultTransactionReason = _.find(
        this.props.transactionsReasons,
        ({ reason }) => reason.includes("Service")
      );

      if (this._isMounted) {
        this.setState(state => ({
          transaction: {
            ...state.transaction,
            reasonId: defaultTransactionReason && defaultTransactionReason.ID,
            openingBalance: data ? data.balance : undefined
          },
          loading: false,
          isEditing: true
        }));
      }
    });
  };
  getTransaction = transactionId => {
    this.setState({ loading: true });

    findTransactionById({ transactionId: transactionId }, (error, data) => {
      if (error) {
        toast.error("Failed to retrieve transaction");
        return;
      }
      if (this._isMounted) {
        this.setState({
          transaction: data,
          loading: false,
          isEditing: false
        });
      }
    });
  };
  handleSave = () => {
    let { match, history, location } = this.props;
    let { transaction } = this.state;
    if (!match || !match.params.userId) return;

    if (!transaction.reasonId) {
      toast.info("A reason for the transaction is required");
      return;
    }

    if (
      transaction.checkAmount != null &&
      parseFloat(transaction.checkAmount) !== 0 &&
      !transaction.checkDate &&
      !transaction.checkNumber
    ) {
      toast.info(
        "A check date and number are required when inputting a check amount."
      );
      return;
    }
    if (transaction.giftCardAmount && !transaction.giftCardNumber) {
      toast.info(
        "A gift card number is required when inputting a gift card amount."
      );
      return;
    }
    if (
      transaction.creditCardAmount != null &&
      parseFloat(transaction.creditCardAmount) !== 0
    ) {
      if (transaction.isOneTimePayment) {
        if (
          !transaction.cardNumber ||
          !transaction.nameOnCard ||
          !transaction.securityCode ||
          !transaction.expiration ||
          !transaction.billingZipcode
        ) {
          toast.info(
            "A name on card, card number, expiration date, security code, and billing zipcode are required for one time payment methods."
          );
          return;
        }

        if (!validateCreditCard(transaction.cardNumber)) {
          toast.error("Credit card number is invalid");
          return;
        }
      } else {
        if (!transaction.creditCardId) {
          toast.info(
            "A credit card must be selected when not making a one time payment."
          );
          return;
        }
      }
    }

    saveNewTransaction(
      {
        ...transaction,
        appointmentId:
          location && location.state && location.state.appointmentId,
        userId: match.params.userId
      },
      (error, response) => {
        if (error) {
          this.setState({
            completingAppointment: false
          });
          return;
        }
        if (this._isMounted) {
          this.setState({
            isEditing: false,
            completingAppointment: false
          });
          history.push(
            `/dashboard/transactions/${response.ID}/user/${match.params.userId}`
          );
          if (response.ID) {
            this.getTransaction(response.ID);
          }
        }
      }
    );
  };
  serviceEqualsBreakdown = transaction => {
    let {
      serviceFee,
      serviceFeeGroom,
      serviceFeeTrip,
      serviceFeeGenerator,
      serviceFeeFuel,
      serviceDiscountTotal,
      serviceFeeSalesTax,
      serviceFeeOther
    } = transaction;

    let calculatedServiceFee = parseFloat(
      _.sum([
        parseFloat(serviceFeeGroom),
        parseFloat(serviceFeeTrip),
        parseFloat(serviceFeeGenerator),
        parseFloat(serviceFeeFuel),
        -parseFloat(serviceDiscountTotal),
        parseFloat(serviceFeeSalesTax),
        parseFloat(serviceFeeOther)
      ]).toFixed(2)
    );
    if (parseFloat(serviceFee) === parseFloat(calculatedServiceFee)) {
      return true;
    }
    return false;
  };
  handleNew = () => {
    this.getUserBalance();
    this.setState({ transaction: defaultTransaction() });
  };
  calculateTransactionData() {
    let {
      checkAmount,
      creditCardAmount,
      cashAmount,
      giftCardAmount,
      creditAmount,
      serviceFee,
      cancelFee,
      returnedFee,
      reprocessFee,
      gratuity,
      openingBalance
    } = this.state.transaction;

    let totalCharges =
      parseFloat(serviceFee || 0) +
      parseFloat(cancelFee || 0) +
      parseFloat(returnedFee || 0) +
      parseFloat(reprocessFee || 0) +
      parseFloat(gratuity || 0);

    let totalPaid =
      parseFloat(cashAmount || 0) +
      parseFloat(creditCardAmount || 0) +
      parseFloat(checkAmount || 0) +
      parseFloat(giftCardAmount || 0);

    let transactionBalance =
      parseFloat(totalCharges || 0) - parseFloat(totalPaid || 0);

    let closingBalance =
      parseFloat(openingBalance || 0) +
      parseFloat(creditAmount || 0) -
      parseFloat(transactionBalance || 0);

    // Used in UI to allow customer to see the credit used from their account
    let totalCredit =
      parseFloat(openingBalance || 0) + parseFloat(creditAmount || 0);

    let creditUsed = 0;
    if (totalCharges > 0 && totalCredit > 0) {
      if (totalCredit >= totalCharges) {
        creditUsed = totalCharges;
      } else {
        creditUsed = totalCredit;
      }
    }

    return {
      totalPaid: totalPaid,
      totalCharges: totalCharges,
      totalCredit: totalCredit,
      creditUsed: creditUsed,
      transactionBalance: transactionBalance,
      closingBalance: closingBalance
    };
  }
  render() {
    let { match, location, freezeCharges, transactionsReasons } = this.props;
    let { isEditing, transaction, user } = this.state;
    if (!transaction) {
      transaction = {};
    }
    let userId = match.params.userId || this.props.userId;

    let {
      ID,
      refTransactionId,
      isRefunded,
      isVoided,
      // isReceiptSent,
      status,

      checkDate,
      checkNumber,
      giftCardNumber,
      checkAmount,
      creditCardAmount,
      cashAmount,
      giftCardAmount,
      creditAmount,
      serviceFee,
      cancelFee,
      returnedFee,
      reprocessFee,
      gratuity,

      // Breakdown of services
      serviceFeeGroom,
      serviceFeeTrip,
      serviceFeeGenerator,
      serviceFeeFuel,
      serviceDiscountTotal,
      serviceFeeSalesTax,
      serviceFeeOther,

      openingBalance,
      reasonId,
      notes,
      CreditCard,

      // Credit Card Info
      isOneTimePayment,
      creditCardId,
      nameOnCard,
      cardNumber,
      expiration,
      securityCode,
      billingZipcode
    } = transaction;

    let calculatedTransactionData = this.calculateTransactionData();

    let newTransactionPath =
      "/dashboard/transactions/new/user/" + match.params.userId;

    let selectedReason = _.find(transactionsReasons, {
      ID: reasonId
    });
    let username = `${user ? user.firstName || "" : ""} ${
      user ? user.lastName || "" : ""
    }`;
    let enableGratuity =
      !transaction.ID &&
      location &&
      location.state &&
      location.state.appointmentId;

    return (
      <Container>
        <Form>
          <Grid stackable doubling columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Header as="h2">
                  {`${ID == null ? "New " : ""}Transaction`}
                  {isRefunded === true && (
                    <Label color="purple">Refunded</Label>
                  )}
                  {isVoided === true && <Label color="grey">Voided</Label>}
                  {match.params.userId && (
                    <Header.Subheader>
                      <Link to={"/dashboard/customers/" + match.params.userId}>
                        {match.params.userId}
                        {user ? ": " + username : ""}
                      </Link>
                    </Header.Subheader>
                  )}
                </Header>
              </Grid.Column>
              {this.props.hideCreateButton !== true && (
                <Grid.Column>
                  <Authorize permission="CreateTransactions">
                    {ID != null && (
                      <Button
                        content="Add New Transaction"
                        color="blue"
                        as={Link}
                        to={newTransactionPath}
                        onClick={this.handleNew}
                        floated="right"
                      />
                    )}
                  </Authorize>
                  {/* Show voiding button if captured or authorized and waiting for settlement */}
                  {ID &&
                    (status === "capturedPendingSettlement" ||
                      status === "authorizedPendingCapture") && (
                      <Authorize permission="VoidTransactions">
                        {ID != null && refTransactionId && (
                          <VoidModal
                            refTransactionId={refTransactionId}
                            amount={parseFloat(creditCardAmount).toFixed(2)}
                            onVoided={() => {
                              this.getTransaction(ID);
                            }}
                          />
                        )}
                      </Authorize>
                    )}
                  {/* Show refund button if settled successfully */}
                  {ID && status === "settledSuccessfully" && (
                    <Authorize permission="RefundTransactions">
                      {ID != null && refTransactionId && (
                        <RefundModal
                          refTransactionId={refTransactionId}
                          settledSuccessfully
                          defaultAmount={parseFloat(creditCardAmount).toFixed(
                            2
                          )}
                          onRefunded={() => {
                            this.getTransaction(ID);
                          }}
                        />
                      )}
                    </Authorize>
                  )}
                </Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Header as="h3" content="Credit" floated="left" />
                  <Header
                    as="h3"
                    floated="right"
                    color={
                      calculatedTransactionData.totalCredit >= 0
                        ? "green"
                        : "red"
                    }
                  >
                    $
                    {moneyFormat(
                      Math.abs(calculatedTransactionData.totalCredit)
                    )}
                  </Header>
                  <Divider clearing />
                  <Grid columns="equal">
                    <Grid.Column textAlign="center">
                      <Header as="h3">Opening Balance</Header>
                      <Header
                        size="large"
                        color={openingBalance >= 0 ? "green" : "red"}
                      >
                        ${moneyFormat(Math.abs(openingBalance))}
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Input
                        label="Add Credit to Account"
                        name="creditAmount"
                        min={0}
                        value={creditAmount}
                        isEditing={isEditing}
                        type="number"
                        format="moneyInput"
                        validation={["money"]}
                        onChange={this.onMoneyChange}
                        placeholder="0.00"
                      />
                    </Grid.Column>
                  </Grid>
                </Segment>

                {/* Charges */}
                <Segment clearing>
                  <Header as="h3" content="Charges" floated="left" />
                  <Header as="h3" floated="right" color="red">
                    ${moneyFormat(calculatedTransactionData.totalCharges)}
                  </Header>
                  <Divider clearing />
                  <Input
                    type="number"
                    format="moneyInput"
                    placeholder="0.00"
                    isEditing={
                      isEditing && freezeCharges !== true && enableGratuity
                    }
                    label="Gratuity"
                    name="gratuity"
                    value={gratuity}
                    onChange={this.onMoneyChange}
                  />
                  <Input
                    type="number"
                    format="moneyInput"
                    onChange={this.onMoneyChange}
                    placeholder="0.00"
                    isEditing={isEditing && freezeCharges !== true}
                    label="Services"
                    name="serviceFee"
                    value={serviceFee}
                  />{" "}
                  {/* Breakdown */}
                  <Accordion
                    fluid
                    styled
                    panels={[
                      {
                        key: "pane-0",
                        title: {
                          content: "Breakdown of Services"
                        },
                        content: {
                          content: (
                            <div>
                              <Input
                                label="Groom Fee"
                                name="serviceFeeGroom"
                                type="number"
                                format="moneyInput"
                                step="0.01"
                                placeholder="0.00"
                                isEditing={isEditing && freezeCharges !== true}
                                value={serviceFeeGroom}
                                onChange={this.onMoneyChange}
                              />
                              <Input
                                label="Trip Fee"
                                name="serviceFeeTrip"
                                type="number"
                                format="moneyInput"
                                placeholder="0.00"
                                isEditing={isEditing && freezeCharges !== true}
                                value={serviceFeeTrip}
                                onChange={this.onMoneyChange}
                              />
                              <Input
                                label="Generator Fee"
                                name="serviceFeeGenerator"
                                type="number"
                                format="moneyInput"
                                placeholder="0.00"
                                isEditing={isEditing && freezeCharges !== true}
                                value={serviceFeeGenerator}
                                onChange={this.onMoneyChange}
                              />
                              <Input
                                label="Fuel Fee"
                                name="serviceFeeFuel"
                                type="number"
                                format="moneyInput"
                                placeholder="0.00"
                                isEditing={isEditing && freezeCharges !== true}
                                value={serviceFeeFuel}
                                onChange={this.onMoneyChange}
                              />
                              <Input
                                label="Discounts"
                                name="serviceDiscountTotal"
                                type="number"
                                format="moneyInput"
                                placeholder="0.00"
                                isEditing={isEditing && freezeCharges !== true}
                                value={serviceDiscountTotal}
                                onChange={this.onMoneyChange}
                              />
                              <Input
                                label="Sales Tax"
                                name="serviceFeeSalesTax"
                                type="number"
                                format="moneyInput"
                                placeholder="0.00"
                                isEditing={isEditing && freezeCharges !== true}
                                value={serviceFeeSalesTax}
                                onChange={this.onMoneyChange}
                              />
                              <Input
                                label="Other"
                                name="serviceFeeOther"
                                type="number"
                                format="moneyInput"
                                placeholder="0.00"
                                isEditing={isEditing && freezeCharges !== true}
                                value={serviceFeeOther}
                                onChange={this.onMoneyChange}
                              />
                            </div>
                          )
                        }
                      }
                    ]}
                  />
                  <Divider hidden />
                  <Input
                    label="Cancellation Fee"
                    name="cancelFee"
                    value={cancelFee}
                    isEditing={isEditing && freezeCharges !== true}
                    type="number"
                    format="moneyInput"
                    onChange={this.onMoneyChange}
                    placeholder="0.00"
                  />
                  <Input
                    label="Reprocess Fee"
                    name="reprocessFee"
                    value={reprocessFee}
                    isEditing={isEditing && freezeCharges !== true}
                    type="number"
                    format="moneyInput"
                    onChange={this.onMoneyChange}
                    placeholder="0.00"
                  />
                  <Input
                    label="Returned Fee"
                    name="returnedFee"
                    value={returnedFee}
                    isEditing={isEditing && freezeCharges !== true}
                    type="number"
                    format="moneyInput"
                    onChange={this.onMoneyChange}
                    placeholder="0.00"
                  />
                </Segment>

                {/* Payment */}
                <Segment clearing>
                  <Header
                    as="h3"
                    content="Payment"
                    floated="left"
                    subheader="Credit is used for payment before all other payment methods"
                  />
                  <Header as="h3" floated="right" color="green">
                    ${moneyFormat(calculatedTransactionData.totalPaid)}
                    <Header.Subheader>Payment</Header.Subheader>
                  </Header>
                  <Header as="h3" floated="right" color="green">
                    ${moneyFormat(calculatedTransactionData.creditUsed)}
                    <Header.Subheader>Credit Used</Header.Subheader>
                  </Header>
                  <Divider clearing />
                  <TransactionPayment
                    userId={userId}
                    onChange={this.onChange}
                    onAutoFill={(methodOfPayment, amount) =>
                      this.onAutoFill(
                        methodOfPayment,
                        amount,
                        calculatedTransactionData.totalCharges -
                          calculatedTransactionData.creditUsed,
                        calculatedTransactionData.totalPaid
                      )
                    }
                    CreditCard={CreditCard}
                    creditCardId={creditCardId}
                    nameOnCard={nameOnCard}
                    cardNumber={cardNumber}
                    expiration={expiration}
                    securityCode={securityCode}
                    billingZipcode={billingZipcode}
                    creditCardAmount={creditCardAmount}
                    cashAmount={cashAmount}
                    checkAmount={checkAmount}
                    checkDate={checkDate}
                    checkNumber={checkNumber}
                    giftCardAmount={giftCardAmount}
                    giftCardNumber={giftCardNumber}
                    refTransactionId={refTransactionId}
                    status={status}
                    isOneTimePayment={isOneTimePayment}
                    isEditing={isEditing}
                  />
                </Segment>
                <Segment>
                  {isEditing ? (
                    <Form.Select
                      search
                      required
                      options={
                        transactionsReasons
                          ? transactionsReasons.map(reason => ({
                              key: reason.ID,
                              value: reason.ID,
                              text: reason.reason
                            }))
                          : []
                      }
                      onChange={this.onChange}
                      value={reasonId || null}
                      name="reasonId"
                      placeholder="Select a reason for this transaction"
                      label="Reason of Transaction"
                    />
                  ) : (
                    <Form.Field>
                      <label>Reason of Transaction</label>
                      <div>{selectedReason ? selectedReason.reason : ""}</div>
                    </Form.Field>
                  )}

                  <Form.Field>
                    <label>Notes</label>
                    <TextArea
                      value={notes || ""}
                      name="notes"
                      placeholder="Additional Notes"
                      isEditing={isEditing}
                      onChange={this.onChange}
                    />
                  </Form.Field>
                </Segment>

                <Segment.Group horizontal>
                  <Segment textAlign="center">
                    <Header as="h3">Transaction Total</Header>
                    <Header
                      size="large"
                      color={
                        calculatedTransactionData.transactionBalance <= 0
                          ? "green"
                          : "red"
                      }
                    >
                      $
                      {moneyFormat(
                        Math.abs(calculatedTransactionData.transactionBalance)
                      )}
                    </Header>
                  </Segment>
                  <Segment textAlign="center">
                    <Header as="h3">Closing Balance</Header>
                    <Header
                      size="large"
                      color={
                        calculatedTransactionData.closingBalance >= 0
                          ? "green"
                          : "red"
                      }
                    >
                      $
                      {moneyFormat(
                        Math.abs(calculatedTransactionData.closingBalance)
                      )}
                    </Header>
                  </Segment>
                </Segment.Group>
                {this.props.hideCreateButton !== true && (
                  <Authorize permission="CreateTransactions">
                    {ID == null && (
                      <Button
                        content="Create Transaction"
                        color="green"
                        onClick={this.handleSave}
                      />
                    )}
                  </Authorize>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }
}

Transaction.defaultProps = {
  onChange: () => {},
  transactionsReasons: [],
  transaction: {},
  freezeCharges: false,
  salesTax: 0
};
export default props => (
  <OptionsContext.Consumer>
    {({ transactionsReasons, salesTax }) => (
      <Transaction
        {...props}
        salesTax={salesTax}
        transactionsReasons={transactionsReasons}
      />
    )}
  </OptionsContext.Consumer>
);
function countDecimals(value) {
  if (Math.floor(value) === value) return 0;
  if (
    value &&
    parseFloat(value)
      .toString()
      .split(".")[1]
  ) {
    return parseFloat(value)
      .toString()
      .split(".")[1].length;
  }
  return 0;
}
