import React, { Component } from "react";
import { Card, List, Image } from "semantic-ui-react";
import moment from "moment";
import { OptionsContext } from "../OptionsContext";
import _ from "lodash";

class PetCard extends Component {
  render() {
    const {
      petName,
      breed,
      birthday,
      lastGroomDate,
      weight,
      handleClick,
      sex
    } = this.props;
    const image = null;
    let color;
    if (sex === "Male") color = "blue";
    if (sex === "Female") color = "pink";
    let birthdayFormatted = birthday
      ? moment(birthday).format("dddd, MMMM Do YYYY")
      : "N/A";
    if (birthdayFormatted === "Invalid Data") birthdayFormatted = "N/A";

    let lastGroomed = lastGroomDate
      ? moment(lastGroomDate).format("dddd, MMMM Do YYYY")
      : "N/A";
    if (lastGroomed === "Invalid Data") lastGroomed = "N/A";

    return (
      <Card color={color} link onClick={() => handleClick(this.props)}>
        {image && <Image />}

        <Card.Content>
          <Card.Header>
            <OptionsContext.Consumer>
              {({ animalTypes }) => {
                let foundAnimalType = _.find(animalTypes, {
                  ID: breed.AnimalType.ID
                });
                if (foundAnimalType) {
                  return (
                    <i
                      className={"fas fa-" + foundAnimalType.type.toLowerCase()}
                    />
                  );
                } else {
                  return null;
                }
              }}
            </OptionsContext.Consumer>{" "}
            {petName}
          </Card.Header>
          <List divided relaxed>
            <List.Item>
              <List.Content>
                <List.Header>Breed</List.Header>
                <List.Description>
                  {breed ? breed.breed : "Unknown"}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Birthday</List.Header>
                <List.Description>{birthdayFormatted}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Weight</List.Header>
                <List.Description>{weight || "N/A"}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Last Groomed</List.Header>
                <List.Description>{lastGroomed}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Card.Content>
      </Card>
    );
  }
}

PetCard.defaultProps = {
  handleClick: () => {}
};
export default PetCard;
