import React, { Component } from "react";
import { Form, Modal, Button } from "semantic-ui-react";
import Input from "../Shared/Input";
import { toast } from "react-toastify";
import { createLoginAccount } from "../lib/apiCalls";

class CreateAccountModal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loginEmail: "",
      open: false
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleSave = () => {
    let { loginEmail } = this.state;
    if (!loginEmail) {
      toast.info("Login Email is a required field");
      return;
    }
    createLoginAccount(
      {
        loginEmail: loginEmail,
        userId: this.props.userId
      },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            toast.error("Failed to create account");
            return;
          }
          toast.success("Successfully created login account");
          this.closeModal();
        }
      }
    );
  };
  openModal = () => {
    this.setState({ open: true });
  };
  closeModal = () => {
    this.setState({ open: false });
  };
  render() {
    let { loginEmail, open } = this.state;
    return (
      <Modal
        open={open}
        onClose={this.closeModal}
        closeIcon
        trigger={
          <Button
            onClick={this.openModal}
            fluid
            color="blue"
            content="Create Account"
          />
        }
      >
        <Modal.Header>Create Account</Modal.Header>
        <Modal.Content>
          <Form>
            <Input
              isEditing
              required
              label="Login Email"
              value={loginEmail}
              placeholder="Enter Email"
              name="loginEmail"
              onChange={this.onChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Create" onClick={this.handleSave} color="green" />
        </Modal.Actions>
      </Modal>
    );
  }
}
CreateAccountModal.defaultProps = {
  userId: null
};

export default CreateAccountModal;
