import React, { Component } from "react";
import { Image as SemanticImage } from "semantic-ui-react";

/**
 * Renders Semantic UI React Image with a wrapper for catching and displaying children when no image was loaded.
 */
class Image extends Component {
  constructor(props) {
    super(props);
    this.state = { didImageError: false };
  }
  render() {
    let { children, blankAvatarOnError, ...props } = this.props;
    let { didImageError } = this.state;
    if (didImageError === true && blankAvatarOnError === false) {
      return children;
    } else {
      return (
        <SemanticImage
          key={didImageError}
          onError={() => this.setState({ didImageError: true })}
          {...props}
          // Corrects src formatting and adds src to window location
          src={
            props.src && didImageError === false
              ? window.location.origin + props.src
              : undefined
          }
        />
      );
    }
  }
}

Image.defaultProps = {
  children: null,
  blankAvatarOnError: false
};

export default Image;
