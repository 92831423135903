import React from "react";

import { List, Segment } from "semantic-ui-react";
import Input from "../Shared/Input";
import Money from "./Money";

/**
 * Display the totals for estimate confirmation in a card like format
 */
function EstimateTotalsList(props) {
  const {
    service,
    trip,
    fuel,
    generator,
    discounts,
    tax,
    total,
    needsGenerator,
    onChange,
    isEditing
  } = props;
  return (
    <Segment>
      <List divided size="large">
        <List.Item>
          <List.Content floated="right">
            <Money value={service} />
          </List.Content>
          <List.Header>Service</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            <Input
              type="number"
              isEditing={isEditing}
              size="mini"
              validation="money"
              placeholder={(0.0).toFixed(2)}
              value={trip}
              name="tripFee"
              onChange={onChange}
              format="moneyInput"
              controlChange
            />
          </List.Content>
          <List.Header>Trip Fee</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {/* Display not applicable if estimate does not need generator */}
            {needsGenerator === true ? (
              <Input
                size="mini"
                type="number"
                isEditing={needsGenerator && isEditing}
                validation="money"
                placeholder={(0.0).toFixed(2)}
                value={generator}
                name="generatorFee"
                onChange={onChange}
                format="moneyInput"
                controlChange
              />
            ) : (
              "N/A"
            )}
          </List.Content>
          <List.Header>Generator Fee</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            <Money value={fuel} />
          </List.Content>
          <List.Header>Fuel Fee</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            <Money value={discounts} />
          </List.Content>
          <List.Header>Discounts</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            <Money value={tax} />
          </List.Content>
          <List.Header>Tax</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            <Money value={total} />
          </List.Content>
          <List.Header>Total</List.Header>
        </List.Item>
      </List>
    </Segment>
  );
}

EstimateTotalsList.defaultProps = {
  service: 0,
  trip: 0,
  fuel: 0,
  generator: 0,
  other: 0,
  discounts: 0,
  tax: 0,
  total: 0,
  needsGenerator: false,
  isEditing: true,
  onChange: () => {}
};

export default EstimateTotalsList;
