/**
 * Attemps to parse a float and returns the value rounded to two decimal places.
 * @param {any} value
 */
export function moneyFormat(value) {
  let money = roundToMoney(parseFloat(value));
  if (!isNaN(money)) {
    return money.toFixed(2);
  } else {
    return (0.0).toFixed(2);
  }
}

function roundToMoney(num) {
  return Math.round(num * 100) / 100;
}

/**
 * Removes extra decimals in an money input
 * @param {number} value Value to check for a money format in input
 */
export function moneyInputFormat(value) {
  let money = parseFloat(value);
  if (!isNaN(money)) {
    if (decimalPlaces(money) > 2) {
      return Math.floor(money * 100) / 100;
    }
    return value;
  }
  return value;
}

/**
 * Counts the decimal places in a decimal number
 * @param {number} num
 */
function decimalPlaces(num) {
  var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

export function format(value, format) {
  switch (format) {
    case "money":
      return moneyFormat(value);
    case "moneyInput":
      return moneyInputFormat(value);
    default:
      return value;
  }
}
