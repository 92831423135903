import React, { Component } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";

import { deleteUser } from "../lib/apiCalls";
import Authorize from "./Authorize";

class DeleteUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, submitting: false };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleDelete = () => {
    if (!this.props.userId) {
      toast.error(
        "Cannot delete user without userID. Please try refreshing the page and trying again."
      );
      return;
    }
    this.setState({ submitting: true });
    deleteUser(
      {
        userId: this.props.userId
      },
      (error, response) => {
        if (error) {
          toast.error("Failed to delete user");
          this.setState({ submitting: false });
          return;
        }
        if (this.props.history) {
          if (this.props.location.pathname.indexOf("employees") > -1) {
            this.props.history.push("/dashboard/employees");
          } else {
            this.props.history.push("/dashboard/customers");
          }
        } else {
          this.props.onClose();
        }

        toast.success("Successfully deleted user");
      }
    );
  };
  render() {
    let { submitting } = this.state;
    let { open } = this.props;
    return (
      <div>
        <Modal open={open} onClose={this.props.onClose}>
          <Modal.Header>
            Are you sure you want to delete this user?
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Icon name="warning" />
              Deletion cannot be undone from the application. Are you sure you
              want to continue and delete the user?
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Delete"
              disabled={submitting}
              color="red"
              onClick={this.handleDelete}
            />
            <Button
              content="Cancel"
              disabled={submitting}
              onClick={this.props.onClose}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

DeleteUserModal.defaultProps = {
  onClose: () => {},
  userId: null,
  open: false
};

export default props => (
  <Authorize permission="DangerousEditing">
    <DeleteUserModal {...props} />
  </Authorize>
);
