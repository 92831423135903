import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Comment as SemanticComment,
  Header,
  Form,
  Popup,
  Segment
} from "semantic-ui-react";

import ErrorBoundary from "./ErrorBoundary";
import Comment from "./Comment";

import Axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import { getToken } from "../lib/csrfToken";
import Authorize from "./Authorize";

/**
 * Displays a group of comments sorted by time where newer comments are on top.
 * @prop {string} header String displayed at the top of the group
 * @prop {integer} commentGroupId ID of the comment group to retrieve comments for
 *
 */
class CommentGroup extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      newComment: "",
      loading: true
    };

    this.handleAddComment = this.handleAddComment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCommentAdded = this.onCommentAdded.bind(this);
  }
  async componentDidMount() {
    this._isMounted = true;
    let { commentGroupId } = this.props;
    if (!commentGroupId) {
      this.setState({ loading: false });
      return;
    }
    try {
      let response = await Axios.get("/api/v4/comments/findByGroup", {
        params: { commentGroupId: commentGroupId }
      });

      if (this._isMounted) {
        if (response.status === 200) {
          let sortedComments = _.sortBy(
            response.data.comments,
            comment => comment.DateAdded
          );
          this.setState({ comments: sortedComments, loading: false });
        } else {
          this.setState({ loading: false });
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to get group of comments.");
      this.setState({ loading: false });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }
  onCommentAdded(newComment) {
    this.setState({
      comments: [...this.state.comments, newComment],
      newComment: ""
    });
  }
  async handleAddComment() {
    if (this.props.showNewComment !== true || this.state.newComment === "")
      return;
    try {
      let response = await Axios.post(
        "/api/v4/comments/create",
        {
          comment: this.state.newComment,
          commentGroupId: this.props.commentGroupId
        },
        { headers: { "X-CSRF-Token": getToken() } }
      );

      if (response.status === 200) {
        if (this._isMounted) {
          this.onCommentAdded(response.data);
        }
        toast.success("Successfully added comment");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to add comment.");
    }
  }
  render() {
    let { newComment, comments, loading } = this.state;
    let { header, showNewComment, maxHeight, commentGroupId } = this.props;

    /* Make all comments */
    let allComments = comments.map(comment => (
      <Comment comment={comment} key={comment.ID} />
    ));
    allComments.reverse();

    return (
      <ErrorBoundary>
        <Segment loading={loading}>
          {/* Show header if passed in */}
          {header && (
            <Header as="h3" dividing>
              {header}
            </Header>
          )}

          {/* Show new comment text area. */}
          <Authorize permission="CreateComments">
            {showNewComment && (
              <Form reply>
                <Form.TextArea
                  disabled={commentGroupId == null}
                  rows={1}
                  placeholder="Add a comment"
                  value={newComment}
                  name="newComment"
                  onChange={this.handleChange}
                />
                <Popup
                  inverted
                  basic
                  trigger={
                    <Form.Button
                      fluid
                      content="Add Comment"
                      disabled={commentGroupId == null}
                      icon="pencil"
                      color="green"
                      onClick={this.handleAddComment}
                    />
                  }
                  content="Add a new comment"
                ></Popup>
              </Form>
            )}
          </Authorize>
          <div
            style={{
              marginTop: "10px",
              maxHeight: maxHeight,
              overflowY: "auto"
            }}
          >
            <SemanticComment.Group style={{ maxWidth: "none" }}>
              {allComments.length > 0
                ? allComments
                : "Be the first to add a comment"}
            </SemanticComment.Group>
          </div>
        </Segment>
      </ErrorBoundary>
    );
  }
}

CommentGroup.defaultProps = {
  header: "Comments",
  maxHeight: "200px",
  showNewComment: true,
  commentGroupId: null
};

CommentGroup.propTypes = {
  header: PropTypes.string,
  maxHeight: PropTypes.string,
  showNewComment: PropTypes.bool,
  commentGroupId: PropTypes.number
};

export default props => (
  <Authorize permission="ViewComments">
    <CommentGroup {...props} />
  </Authorize>
);
