import React from "react";
import { TextArea as SemanticTextArea, Form } from "semantic-ui-react";

function TextArea(props) {
  let { isEditing, inForm, ...rest } = props;
  if (isEditing === false) {
    return <div>{rest.value}</div>;
  }
  if (inForm === true) {
    return (
      <Form.TextArea readOnly={!isEditing} disabled={!isEditing} {...rest} />
    );
  }
  return (
    <SemanticTextArea readOnly={!isEditing} disabled={!isEditing} {...rest} />
  );
}

TextArea.defaultProps = {
  maxLength: 250,
  isEditing: false,
  inForm: false
};

export default TextArea;
