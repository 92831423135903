import React, { Component } from "react";
import { Modal, Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { voidTransaction } from "../../lib/apiCalls";
import Money from "../../Shared/Money";

class VoidModal extends Component {
  constructor(props) {
    super(props);

    this.state = { modalOpen: false, amount: props.defaultAmount };
  }

  handleOpen = () =>
    this.setState({ modalOpen: true, amount: this.props.defaultAmount });

  handleClose = () => this.setState({ modalOpen: false });

  onChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleVoid = () => {
    if (!this.props.refTransactionId) {
      toast.error("No Transaction ID available for voiding");
      return;
    }
    voidTransaction(
      {
        refTransactionId: this.props.refTransactionId
      },
      (error, response) => {
        if (error) {
          console.error(error);
        } else {
          this.handleClose();
          this.props.onVoided();
        }
      }
    );
  };
  render() {
    return (
      <div>
        <Modal
          closeIcon
          size="small"
          trigger={
            <Button
              content="Void Transaction"
              color="blue"
              onClick={this.handleOpen}
              floated="right"
            />
          }
          open={this.state.modalOpen}
          onClose={this.handleClose}
        >
          <Modal.Header>Void Transaction</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              This will cancel the payment and charge the customer's CRMS
              account back for <Money value={this.props.amount} />.
            </Modal.Description>
            <br />
            Are you sure you want to void this transaction? This cannot be
            undone!
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleVoid} color="green">
              Void
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

VoidModal.defaultProps = {
  refTransactionId: null,
  amount: 0,
  onVoided: () => {}
};

export default VoidModal;
