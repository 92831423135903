import React, { Component } from "react";
import { Modal, Icon, Button } from "semantic-ui-react";

class ConfirmTransactionBalanceModal extends Component {
  render() {
    return (
      <Modal
        closeIcon
        size="small"
        trigger={this.props.trigger}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>
          <Icon name="warning" />
          Warning
        </Modal.Header>
        <Modal.Content>
          The charges and payment amounts do not match up! If this is correct,
          click Continue. Otherwise, click Cancel to return and adjust the
          payment.
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button onClick={this.props.onContinue} color="green">
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ConfirmTransactionBalanceModal.defaultProps = {
  onClose: () => {},
  onContinue: () => {},
  open: false,
  trigger: null
};

export default ConfirmTransactionBalanceModal;
