import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Comment as SemanticComment } from "semantic-ui-react";

class Comment extends Component {
  render() {
    let { comment } = this.props;
    return (
      <SemanticComment>
        <SemanticComment.Content>
          <SemanticComment.Author>
            {comment.author}
            <SemanticComment.Metadata>
              <div>{moment(comment.DateAdded).format("LLL")}</div>
            </SemanticComment.Metadata>
          </SemanticComment.Author>
          <SemanticComment.Text>{comment.comment}</SemanticComment.Text>
        </SemanticComment.Content>
      </SemanticComment>
    );
  }
}

Comment.defaultProps = {
  comment: {}
};

Comment.propTypes = {
  user: PropTypes.object
};

export default Comment;
