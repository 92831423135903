import React, { Component } from "react";

import Authorize from "./Authorize";
import { toast } from "react-toastify";
import { getUserDetails } from "../lib/apiCalls";

import EmployeeDetails from "../Forms/EmployeeDetails";
import CommentGroup from "./CommentGroup";
import RatingArea from "./RatingArea";

class EmployeeOverview extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { match } = this.props;
    if (match && match.params.userId) {
      getUserDetails({ userId: match.params.userId }, (error, response) => {
        if (error) {
          toast.error("Failed to retrieve user");
          return;
        }
        if (this._isMounted) {
          this.setState({ user: response });
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    let { user } = this.state;
    let employee = user.Employee ? user.Employee : {};
    return (
      <div>
        <EmployeeDetails
          key={"employeeDetails_" + employee.ID}
          employeeId={employee.ID}
        />

        <Authorize permission="EditEmployees">
          {/* Show Employee rating and comments */}
          <CommentGroup
            key={"comments_" + user.ID}
            commentGroupId={user.commentGroupId}
          />
          <RatingArea key={"ratings_" + user.ID} userId={user.ID} />
        </Authorize>
      </div>
    );
  }
}

export default EmployeeOverview;
