import React, { Component } from "react";
import {
  Segment,
  Header,
  Grid,
  Icon,
  Button,
  Responsive,
  Divider,
  Modal
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import _ from "lodash";
import PetList from "./PetList";
import { moneyFormat } from "../lib/format";
import { removeMapsco } from "../lib/helperFunctions";
import { OptionsContext } from "../OptionsContext";
import { notifyStylistEnRoute } from "../lib/apiCalls";
import { toast } from "react-toastify";
import CommentGroup from "./CommentGroup";

/**
 * Displays the information for a next/in progress appointment for a stylist
 */
class InProgressAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.sendNotification = this.sendNotification.bind(this);
  }
  open = () => {
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false });
  };
  sendNotification() {
    if (!this.props.appointment || !this.props.appointment.ID) return;
    notifyStylistEnRoute(
      { appointmentId: this.props.appointment.ID },
      (error, response) => {
        if (error) {
          toast.error("Failed to notify customer that you are on the way");
        }
        this.close();
      }
    );
  }
  render() {
    let { open } = this.state;
    let { appointment } = this.props;
    let user =
      appointment && appointment.Customer ? appointment.Customer.User : null;
    let estimate = appointment.Estimate || {};
    let time = appointment.startTime;
    let address = removeMapsco(estimate.AddressString);
    return (
      <OptionsContext.Consumer>
        {({ statuses }) => {
          let appointmentStatus = _.find(statuses, {
            ID: appointment.statusId
          });
          return (
            <div>
              <Modal
                onClose={this.close}
                header="Are you en route?"
                content='If you are en route, a notification will inform the customer that you are on your way. If you selected "Directions Only", no notification will be sent.'
                open={open}
                actions={[
                  {
                    content: "Directions Only",
                    as: "a",
                    target: "_blank",
                    href:
                      "https://www.google.com/maps/dir/?api=1&destination=" +
                      encodeURIComponent(address ? address.address : ""),
                    onClick: this.close
                  },
                  {
                    content: "En Route",
                    color: "green",
                    onClick: this.sendNotification,
                    as: "a",
                    target: "_blank",
                    href:
                      "https://www.google.com/maps/dir/?api=1&destination=" +
                      encodeURIComponent(address ? address.address : "")
                  }
                ]}
              />
              <Header as="h2" block attached="top">
                {user ? `${user.firstName} ${user.lastName}` : "No User"}

                {/* Display the estimate on the right when on desktop */}
                <Responsive
                  as={Header}
                  size="tiny"
                  color="blue"
                  floated="right"
                  minWidth={Responsive.onlyTablet.minWidth}
                >
                  <Icon fitted name="dollar" />
                  <Header.Content>
                    {moneyFormat(!estimate ? "0.00" : estimate.TotalEstimate)}
                    <Header.Subheader>Estimate</Header.Subheader>
                  </Header.Content>
                </Responsive>

                {/* Display the estimate and start time on the right when on desktop */}
                <Responsive
                  as={Header}
                  size="tiny"
                  color="blue"
                  floated="left"
                  minWidth={Responsive.onlyTablet.minWidth}
                >
                  <Icon fitted name="clock" />
                  <Header.Content>
                    {time || "No Time Saved"}
                    <Header.Subheader>Start Time</Header.Subheader>
                  </Header.Content>
                </Responsive>

                <Header.Subheader>
                  {appointmentStatus.status === "inProgress"
                    ? "In Progress"
                    : "Next"}{" "}
                  Appointment
                </Header.Subheader>

                {/* Display the estimate and time under next appointment when on mobile */}
                <Responsive
                  as={Header}
                  size="tiny"
                  color="blue"
                  {...Responsive.onlyMobile}
                >
                  <Icon fitted name="clock" />
                  <Header.Content>
                    {time || "No Time Saved"}
                    <Header.Subheader>Start Time</Header.Subheader>
                  </Header.Content>
                </Responsive>
                <Responsive
                  as={Header}
                  icon="dollar"
                  size="tiny"
                  color="blue"
                  {...Responsive.onlyMobile}
                >
                  <Icon fitted name="dollar" />
                  <Header.Content>
                    {moneyFormat(!estimate ? "0.00" : estimate.TotalEstimate)}
                    <Header.Subheader>Estimate</Header.Subheader>
                  </Header.Content>
                </Responsive>
              </Header>
              <Segment attached>
                <Grid columns="equal" stackable>
                  <Grid.Column>
                    <Segment attached="top">
                      <Header as="h4" dividing content="Address" />
                      {estimate.AddressString}
                      <Divider />
                      {estimate.AddressNotes || "No Instructions Added"}
                    </Segment>
                    <Button
                      attached="bottom"
                      fluid
                      onClick={this.open}
                      content="Directions"
                      color="blue"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CommentGroup commentGroupId={appointment.commentGroupId} />
                  </Grid.Column>

                  <Grid.Column>
                    <Segment>
                      <Header dividing as="h4" content="Pets" />
                      <PetList
                        emptyLabel="No Pets"
                        includeDetails
                        includePetAvatar
                        pets={
                          estimate && estimate.EstimatePets
                            ? estimate.EstimatePets.map(
                                petEstimate => petEstimate.Pet
                              )
                            : []
                        }
                      />
                    </Segment>
                  </Grid.Column>
                </Grid>
              </Segment>
              <Segment attached="bottom">
                <Button
                  content="Estimate Details"
                  as={Link}
                  color="blue"
                  to={{
                    pathname: `/dashboard/schedule/currentAppointment/${
                      appointment.ID
                    }/estimate/${estimate.ID}/user/${user ? user.ID : ""}`
                  }}
                />
              </Segment>
            </div>
          );
        }}
      </OptionsContext.Consumer>
    );
  }
}

InProgressAppointment.defaultProps = {
  appointment: {}
};

export default InProgressAppointment;
