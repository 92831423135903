import React, { Component } from "react";
import { Button, Form, Header, Input } from "semantic-ui-react";

import Axios from "axios";
import queryString from "query-string";
import Notification from "../Shared/Notification";
import { getToken } from "../lib/csrfToken";
import { Redirect } from "react-router-dom";
import { checkIfValid } from "../lib/validation";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      emailAddress: "",
      isPasswordVisible: false
    };
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, messageContent: null });

  togglePassword = () =>
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });

  handleSubmit = async () => {
    const { newPassword, emailAddress } = this.state;
    // use checkIfValid function to see if the new password meets password requirements and if it doesn't stop submission and display error message
    if (!checkIfValid("password", newPassword)) {
      this.setState({
        messageContent: "Password must be at least 10 characters long."
      });
      return;
    }
    if (!emailAddress) {
      this.setState({ messageContent: "Username is required" });
      return;
    }
    this.setState({ loading: true });

    let search = this.props.location.search;
    let token = queryString.parse(search).token;
    if (token) {
      try {
        const response = await Axios.post(
          "/api/v4/entrance/update-password-and-login",
          {
            token: token,
            emailAddress: emailAddress,
            password: newPassword
          },
          {
            headers: { "X-CSRF-Token": getToken() }
          }
        );
        if (response.status === 200) {
          this.props.setUser({ ...response.data });
          this.setState({ redirectToDash: true });
        }
      } catch (error) {
        this.setState({
          loading: false,
          messageContent: "Failed to reset password."
        });
        return;
      }
    } else {
      this.setState({
        loading: false,
        messageContent: "Failed to reset password."
      });
      return;
    }
  };
  render() {
    const {
      isPasswordVisible,
      messageContent,
      redirectToDash,
      loading,
      newPassword,
      emailAddress
    } = this.state;
    if (redirectToDash) {
      return <Redirect to="/dashboard" />;
    }
    return (
      // per Mozilla dev docs adding the autoComplete="new-password" in the form will disable browser storage of password. Setting it to autoComplete="off" the browser will still offer to remember the password for their internal password storage
      // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
      <Form autoComplete="new-password">
        <Header as="h2" color="purple" textAlign="center">
          Reset Password
        </Header>
        <br />
        <Form.Field>
          <label>Email Address</label>
          <Input
            placeholder="Enter email address"
            onChange={this.handleChange}
            value={emailAddress}
            name="emailAddress"
          />
        </Form.Field>
        <Form.Field name="confirmPassword">
          <label>New Password</label>
          <Input
            placeholder="Enter a new password"
            onChange={this.handleChange}
            icon={{
              circular: true,
              link: true,
              name: isPasswordVisible ? "eye slash" : "eye",
              onClick: this.togglePassword
            }}
            value={newPassword}
            name="newPassword"
            autoComplete="new-password"
            type={isPasswordVisible ? "test" : "password"}
          />
          <Notification error content={messageContent} />
        </Form.Field>

        <Button
          loading={loading}
          disabled={loading}
          fluid
          size="large"
          onClick={this.handleSubmit}
          color="purple"
          content="Save"
        />
      </Form>
    );
  }
}

export default ResetPassword;
