import React, { Component } from "react";

import { CarouselProvider, Slider, Slide, Image } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import DotGroup from "./DotGroup";
import { Image as SemanticImage, Segment } from "semantic-ui-react";

class Carousel extends Component {
  render() {
    let { images } = this.props;

    let slides = images.map((image, i) => (
      <Slide key={image} tag="a" index={i}>
        <SemanticImage
          as={Image}
          fluid
          centered
          src={image}
          index={i}
          rounded
        />
      </Slide>
    ));

    return (
      <Segment>
        <CarouselProvider
          naturalSlideWidth={1}
          naturalSlideHeight={1}
          totalSlides={slides.length}
          hasMasterSpinner
        >
          <Slider>{slides}</Slider>
          <br />
          <DotGroup slides={slides.length} />
        </CarouselProvider>
      </Segment>
    );
  }
}

Carousel.defaultProps = {
  images: []
};

export default Carousel;
