import React, { Component } from "react";
import { Form, Grid, Popup, Button } from "semantic-ui-react";

import Image from "../Shared/Image";
import Name from "../Forms/Name";
import Input from "./Input";
import Authorize from "./Authorize";
import FileUploader from "./FileUploader";

import moment from "moment";

import _ from "lodash";
import { OptionsContext } from "../OptionsContext";

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingPicture: false
    };
  }
  finishedUpload = err => {
    if (err) {
      console.error(err);
    } else {
      this.setState({ isEditingPicture: false });
    }
  };
  render() {
    let { isEditingPicture } = this.state;

    let {
      ID,
      firstName,
      middleName,
      lastName,
      birthday,
      UserTypeId,
      isEditing,
      showUserType
    } = this.props;

    let profilePicturePath = ID
      ? "/api/v4/user/getProfilePicture?userId=" + encodeURI(ID)
      : null;

    let uploader = (
      <FileUploader
        imageCropAspectRatio={"1:1"}
        imageResizeTargetWidth={200}
        imageResizeTargetHeight={200}
        onprocessfile={this.finishedUpload}
        allowMultiple={false}
        allowRevert={false}
        server={{ url: "/api/v4/user/uploadPicture" }}
        data={{ userId: ID }}
        imageTransformOutputMimeType="image/jpeg"
        labelIdle={`Upload a Profile Picture Here! <span class="filepond--label-action">Browse</span>`}
      />
    );
    return (
      <Form>
        <Grid stackable columns={1}>
          <Grid.Row textAlign="center">
            <Grid.Column>
              {isEditingPicture === false ? (
                <>
                  <Grid.Column />
                  <Popup
                    trigger={
                      <Image
                        bordered
                        as="a"
                        style={{ cursor: "pointer" }}
                        circular
                        onClick={() =>
                          this.setState({ isEditingPicture: true })
                        }
                        size="small"
                        src={profilePicturePath}
                      >
                        {uploader}
                      </Image>
                    }
                    inverted
                    position="top center"
                  >
                    Click to Change Profile Picture
                  </Popup>
                  <Grid.Column />
                </>
              ) : (
                <>
                  {uploader}
                  <Button
                    fluid
                    content="Cancel"
                    onClick={() => {
                      this.setState({ isEditingPicture: false });
                    }}
                  />
                </>
              )}
            </Grid.Column>
            <Grid.Column textAlign="left">
              {/* Name for the user */}
              <Form.Group widths="3">
                <Name
                  isEditing={isEditing}
                  firstName={firstName}
                  middleName={middleName}
                  lastName={lastName}
                  handleChange={this.props.handleChange}
                />
              </Form.Group>
              <Form.Group widths={3}>
                <Input
                  label="Birthday"
                  validation="date"
                  name="birthday"
                  isEditing={isEditing}
                  dateFormat="LL"
                  value={birthday}
                  onChange={this.props.handleChange}
                />
                <Input
                  label="Age"
                  isEditing={false}
                  value={birthday ? moment().diff(birthday, "years") : ""}
                />
                {/* Show the permissions of the user if able to see them */}
                {showUserType !== false && (
                  <Authorize permission="ViewPermissions">
                    <OptionsContext.Consumer>
                      {({ userTypes }) => {
                        return isEditing ? (
                          <Form.Select
                            readOnly={!isEditing}
                            search
                            placeholder="Select a User Type"
                            options={
                              userTypes
                                ? userTypes
                                    .filter(
                                      userType =>
                                        userType.UserType !== "Customer"
                                    )
                                    .map(userType => {
                                      const { ID, UserType } = userType;
                                      return {
                                        key: ID,
                                        text: UserType,
                                        value: ID
                                      };
                                    })
                                : []
                            }
                            fluid
                            label="User Type"
                            name="UserTypeId"
                            value={UserTypeId}
                            onChange={this.props.handleChange}
                          />
                        ) : (
                          <Input label="User Type">
                            {_.find(userTypes, { ID: UserTypeId })
                              ? _.find(userTypes, { ID: UserTypeId }).UserType
                              : "Role Not Found"}
                          </Input>
                        );
                      }}
                    </OptionsContext.Consumer>
                  </Authorize>
                )}
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

UserDetails.defaultProps = {
  ID: null,
  firstName: "",
  middleName: "",
  lastName: "",
  birthday: "",
  UserTypeId: null,
  handleChange: () => {},
  showUserType: false,
  isEditing: true
};

export default UserDetails;
