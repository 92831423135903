import React, { Component } from "react";
import { toast } from "react-toastify";
import CustomerOverview from "./CustomerOverview";
import { getUserDetails } from "../lib/apiCalls";
import { Segment, Header, Message } from "semantic-ui-react";
import PetsView from "./PetsView";

import { Route } from "react-router-dom";

class CustomerProfile extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      dismissMessage: false
    };
  }
  componentDidMount() {
    this._isMounted = true;
    let { match } = this.props;
    if (!match || !match.params.userId) return;

    // Get the user's info for their name
    getUserDetails({ userId: match.params.userId }, (error, response) => {
      if (error) {
        toast.error("Failed to retrieve user information");
      }
      if (this._isMounted) {
        this.setState({ user: response || {} });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleDismiss = () => {
    this.setState({ dismissMessage: true });
  };
  render() {
    let { loading, user, dismissMessage } = this.state;
    let { location } = this.props;
    if (loading) {
      return <Segment loading placeholder />;
    }
    let isNewCustomer = false;
    if (location && location.state && location.state.isNewCustomer) {
      isNewCustomer = true;
    }
    return (
      <div>
        {isNewCustomer && !dismissMessage && (
          <Message
            icon="info circle"
            warning
            onDismiss={this.handleDismiss}
            header="Thank you for setting up a profile! Someone from our office will contact you shortly."
          />
        )}
        <Header textAlign="center" as="h1">
          {!loading && `Welcome ${user.firstName || ""} ${user.lastName || ""}`}
        </Header>

        <Route component={CustomerOverview} />
        <PetsView
          key={user.Customer ? user.Customer.ID : null}
          userId={user.ID}
          customerId={user.Customer ? user.Customer.ID : null}
        />
      </div>
    );
  }
}

export default CustomerProfile;
