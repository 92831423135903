import React, { Component } from "react";
import { Form, Grid, Segment, Button } from "semantic-ui-react";
import CommentGroup from "../Shared/CommentGroup";
import Authorize from "../Shared/Authorize";
import { defaultEvent } from "../lib/defaultObjects";
import { toast } from "react-toastify";
import Input from "../Shared/Input";
import Recurring from "./Recurring";
import {
  updateStandingAppointmentEvent,
  createStandingAppointment
} from "../lib/apiCalls";
import { OptionsContext } from "../OptionsContext";

class StandingAppointment extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    let { appointment } = props;
    this.state = {
      isEditing: true,
      event: appointment.Standing
        ? defaultEvent(appointment.Standing)
        : defaultEvent({
            title: "Standing Appointment",
            startTime: appointment ? appointment.startTime : null,
            endTime: appointment ? appointment.endTime : null,
            RecurringPattern: {
              startDate: appointment ? appointment.appointmentDate : null
            },
            isRecurring: true,
            isAllDay: false
          })
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  updateRecurring = recurringPattern => {
    let event = { ...this.state.event };
    event.RecurringPattern = recurringPattern;
    this.setState({ event: event });
  };
  handleEventChange = (e, { name, value }) => {
    this.setState(state => ({ event: { ...state.event, [name]: value } }));
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleSave = () => {
    let { event } = this.state;
    let failMessage = null;
    if (event.title == null || event.title === "") {
      failMessage = "Title is required";
    }

    if (
      event.RecurringPattern.startDate == null ||
      event.RecurringPattern.startDate === ""
    ) {
      failMessage = "A start date is required for the recurrence";
    }

    if (failMessage != null) {
      toast.info(failMessage);
      return;
    }

    let { appointment } = this.props;
    if (appointment.isStanding === true) {
      updateStandingAppointmentEvent(
        {
          ...event,
          appointmentId: appointment.ID
        },
        (error, response) => {
          if (this._isMounted) {
            if (!error) {
              this.props.onSave(response);
              toast.success(
                "Successfully saved standing appointment recurrence details"
              );
            } else {
              toast.error("Failed to save standing appointment");
            }
          }
        }
      );
    } else {
      // When the data is not a standing, it is a request and needs to create the standing
      createStandingAppointment(
        {
          ...this.props.appointment,
          appointmentId: this.props.appointment.ID,
          event: event
        },
        (error, response) => {
          if (this._isMounted) {
            if (!error) {
              this.props.onSave(response);
              toast.success("Successfully created standing appointment");
            } else {
              toast.error("Failed to create standing appointment");
            }
          }
        }
      );
    }
  };
  render() {
    let { isEditing, event } = this.state;
    let { handleSave, appointment } = this.props;
    let isNew = !appointment.Standing || !appointment.Standing.ID;
    return (
      <Grid stackable>
        <Grid.Column computer="10" tablet="16">
          <Form onSubmit={handleSave} autoComplete="off">
            <Input
              required
              isEditing={isEditing}
              label="Title"
              value={event.title}
              name="title"
              onChange={this.handleEventChange}
            />

            <Segment>
              <Recurring
                onUpdate={this.updateRecurring}
                recurringPattern={event.RecurringPattern}
              />
            </Segment>
            <Button
              content={!isNew ? "Update" : "Create"}
              onClick={this.handleSave}
              color="green"
            />
          </Form>
        </Grid.Column>
        <Grid.Column computer="6" tablet="16">
          <CommentGroup
            header="Comments"
            commentGroupId={appointment.commentGroupId}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

StandingAppointment.defaultProps = {
  onSave: () => {},
  appointment: {},
  stylists: []
};

export default props => (
  <OptionsContext.Consumer>
    {({ stylists }) => (
      <Authorize permission="EditStandingAppointments">
        <StandingAppointment
          {...props}
          stylists={stylists}
          key={props.appointment ? props.appointment.ID : props.key}
        />
      </Authorize>
    )}
  </OptionsContext.Consumer>
);
