import React, { Component } from "react";
import FileUploader from "./FileUploader";
import ErrorBoundary from "./ErrorBoundary";
import { Header, Modal, Button, Radio } from "semantic-ui-react";
import { HoverButtonContext } from "../HoverButtonContext";
import Axios from "axios";
import _ from "lodash";
import { toast } from "react-toastify";
import { getToken } from "../lib/csrfToken";
/**
 * Displays a modal that has a file uploader to specifically save images to the appointment.
 * The modal expects a hover button to be found to hand the open modal function to.
 */
class AppointmentPictures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      visibilityOpen: false,
      selectedFile: null,
      images: []
    };
    this.getPicturePaths = this.getPicturePaths.bind(this);
    this.handleSaveVisibility = this.handleSaveVisibility.bind(this);
  }

  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({ open: false });
  };
  setSelectedFile = file => {
    let { images } = this.state;
    this.setState({ selectedFile: _.find(images, { name: file }) });
  };
  handleVisibilityChange = (e, { checked }) => {
    this.setState(state => ({
      selectedFile: { ...state.selectedFile, visibleToCustomer: checked }
    }));
  };
  componentDidMount() {
    this.getPicturePaths();
    this.props.setHoverButton({
      visible: true,
      icon: "picture",
      onClick: this.openModal,
      circular: true,
      color: "blue",
      size: "large"
    });
  }
  componentWillUnmount() {
    this.props.setHoverButton({
      visible: false
    });
  }

  async handleSaveVisibility() {
    let { ID } = this.props;
    let { selectedFile } = this.state;
    if (ID == null || selectedFile == null) return;
    try {
      let response = await Axios.post(
        "/api/v4/appointments/setPictureVisibility",
        {
          appointmentId: ID,
          fileName: selectedFile.name,
          visibleToCustomer: selectedFile.visibleToCustomer
        },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      if (response.status === 200) {
        // Updated the list of images in state
        let images = [...this.state.images];
        let index = _.findIndex(images, { name: selectedFile.name });
        if (index != null) {
          images[index] = selectedFile;
          this.setState({
            images: images,
            selectedFile: null
          });
        }
        toast.success("Successfully updated image visibility.");
      } else {
        toast.error("Failed to update the image visibility.");
      }
    } catch (error) {
      toast.error("Failed to update the image visibility.");
      console.error(error);
    }
  }

  /**
   * Retrieve all of the pictures for this appointment
   */
  async getPicturePaths() {
    let { ID } = this.props;
    if (ID == null) return;
    try {
      let response = await Axios.get("/api/v4/appointments/getPicturePaths", {
        params: { appointmentId: ID }
      });
      if (response.status === 200) {
        // Check if files were returned in array
        if (response.data && Array.isArray(response.data)) {
          this.setState({
            images: response.data
          });
        } else {
          this.setState({ images: [] });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    let { ID } = this.props;
    let { images, open, selectedFile } = this.state;
    return (
      <ErrorBoundary>
        <Modal onClose={this.closeModal} closeIcon open={open} centered={false}>
          <Modal.Content>
            {/* Display secondary modal to control the visibility of the selected image */}
            <Modal open={selectedFile != null} centered={false} size="mini">
              <Modal.Header>Select Visibility</Modal.Header>
              <Modal.Content>
                <Radio
                  label="Visible to Customer"
                  toggle
                  checked={selectedFile && selectedFile.visibleToCustomer}
                  onChange={this.handleVisibilityChange}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Cancel"
                  onClick={() => this.setSelectedFile(null)}
                />
                <Button
                  content="Save"
                  color="green"
                  onClick={this.handleSaveVisibility}
                />
              </Modal.Actions>
            </Modal>
            <Header
              content="Appointment Uploads"
              as="h3"
              subheader="Tap an image to change its visibility for the customer. Images are hidden by default."
            />
            <FileUploader
              files={images.map(image => ({
                source: image.name,
                options: { type: "local" }
              }))}
              server={{
                url: "/api/v4/appointments",
                load: `/getPicture?appointmentId=${encodeURIComponent(
                  ID
                )}&fileName=`,
                process: {
                  url: "/uploadPicture",
                  onload: this.getPicturePaths
                },
                revert: null
              }}
              data={{ appointmentId: ID }}
              maxFiles={20}
              className="appointment-uploader"
              imagePreviewMaxHeight={128}
              handleRemoveURL="/api/v4/appointments/removePicture"
              onactivatefile={file => this.setSelectedFile(file.filename)}
            />
          </Modal.Content>
        </Modal>
      </ErrorBoundary>
    );
  }
}

AppointmentPictures.defaultProps = {
  setHoverButton: () => {},
  ID: null
};

export default props => (
  <HoverButtonContext.Consumer>
    {({ setHoverButton }) => {
      return <AppointmentPictures {...props} setHoverButton={setHoverButton} />;
    }}
  </HoverButtonContext.Consumer>
);
