import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Grid,
  Header,
  Segment,
  Message,
  Icon,
  Responsive
} from "semantic-ui-react";

import Money from "./Money";
import EstimateConfirmationItem from "./EstimateConfirmationItem";
import EstimateConfirmationCustomer from "./EstimateConfirmationCustomer";
import EstimateConfirmationTotals from "./EstimateConfirmationTotals";

class EstimateConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePetDetails: true
    };
    this.togglePetDetails = this.togglePetDetails.bind(this);
  }
  togglePetDetails() {
    this.setState(state => ({ hidePetDetails: !state.hidePetDetails }));
  }
  render() {
    let {
      estimate,
      isNewUser,
      totals,
      user,
      newUser,
      hideHeader,
      allowHidingPetDetails
    } = this.props;

    let { hidePetDetails } = this.state;

    let petEstimates = estimate.PetEstimates;

    let rows = [];
    let columns = [];

    let maxColumns = window.innerWidth < Responsive.onlyMobile.maxWidth ? 1 : 3;
    let visibleCount = 0;

    // Make confirmation items for each pet estimate
    for (let i in petEstimates) {
      const data = petEstimates[i];
      // Do not include deceased pets or pets specified not to be included on the estimate
      if (data.Pet.isDeceased === false && data.includeInEstimate === true) {
        visibleCount += 1;
        columns.push(
          <Grid.Column key={i}>
            <EstimateConfirmationItem {...data} />
          </Grid.Column>
        );
      }
      // Determine when to add a new row
      if (
        visibleCount % maxColumns === 0 ||
        parseInt(i) === petEstimates.length - 1
      ) {
        rows.push(
          <Grid.Row columns={maxColumns} key={i}>
            {columns}
          </Grid.Row>
        );
        columns = [];
      }
    }

    let petComponent = (
      <>
        {visibleCount > 0 ? (
          rows
        ) : (
          // Show a warning for no pets being added to the estimate
          <Grid.Row>
            <Grid.Column>
              <Message
                warning
                icon="warning"
                header="No pets were added to the estimate."
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </>
    );
    return (
      <div id="estimate-confirmation">
        {!hideHeader && (
          <Segment clearing basic>
            <Header as="h2" floated="left">
              Overview
            </Header>
            <Header as="h2" floated="right">
              Service Total: <Money value={totals.total} />
            </Header>
          </Segment>
        )}
        <Grid stackable>
          <Grid.Row columns="equal">
            <Grid.Column>
              <EstimateConfirmationCustomer
                estimate={estimate}
                user={user}
                newUser={newUser}
                address={estimate.Address}
                isNewUser={isNewUser}
              />
            </Grid.Column>
            <Grid.Column>
              <EstimateConfirmationTotals
                totals={totals}
                needsGenerator={estimate.needsGenerator === true}
              />
            </Grid.Column>
          </Grid.Row>
          {allowHidingPetDetails === true ? (
            <Grid.Row>
              <Grid.Column>
                <Segment
                  attached={!hidePetDetails ? "top" : undefined}
                  onClick={this.togglePetDetails}
                  style={{ cursor: "pointer" }}
                >
                  <Header>
                    {hidePetDetails !== true ? (
                      <Icon name="angle up" />
                    ) : (
                      <Icon name="angle down" />
                    )}
                    Pet Details
                  </Header>
                </Segment>
                {!hidePetDetails && (
                  <Segment attached="bottom">
                    <Grid columns="equal" stackable>
                      {petComponent}
                    </Grid>
                  </Segment>
                )}
              </Grid.Column>
            </Grid.Row>
          ) : (
            petComponent
          )}
        </Grid>
      </div>
    );
  }
}

EstimateConfirmation.defaultProps = {
  estimate: {},
  totals: {},
  user: {},
  hideHeader: false,
  newUser: {},
  isNewUser: false,
  allowHidingPetDetails: false
};

EstimateConfirmation.propTypes = {
  estimate: PropTypes.object,
  totals: PropTypes.object,
  hideHeader: PropTypes.bool,
  user: PropTypes.object,
  newUser: PropTypes.object,
  isNewUser: PropTypes.bool,
  allowHidingPetDetails: PropTypes.bool
};

export default EstimateConfirmation;
