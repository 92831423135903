import React from "react";
import PropTypes from "prop-types";

import { Header, List, Label } from "semantic-ui-react";
import moment from "moment";
import _ from "lodash";

import {
  calculatePetEstimate,
  getPackagePrices
} from "../lib/estimateCalculations";
import Money from "./Money";
import { OptionsContext } from "../OptionsContext";

function EstimateConfirmationItem(props) {
  let {
    otherFee,
    otherDescription,
    estimatePackage,
    services,
    Pet,
    breeds
  } = props;
  let birthday = Pet.birthday
    ? moment(Pet.birthday).format("dddd, MMMM Do YYYY")
    : "Unknown";
  if (birthday === "Invalid Data") birthday = "Unknown";

  let breedDetails =
    breeds.length > 0 ? _.find(breeds, { ID: Pet.breedId }) : null;

  let packagePrices = getPackagePrices(breedDetails, Pet.lastGroomDate);
  let subtotal = calculatePetEstimate({ ...props }, packagePrices);

  let packageCost = "Unknown";
  if (breedDetails) {
    if (estimatePackage.toLowerCase() === "deluxe") {
      packageCost = packagePrices.deluxePrice;
    } else if (estimatePackage.toLowerCase() === "b&b") {
      packageCost = packagePrices.bathBrushPrice;
    }
  }
  let petIcon = undefined;
  if (Pet.animalType != null) {
    if (Pet.animalType.type === "Cat") petIcon = "fas fa-cat";

    if (Pet.animalType.type === "Dog") petIcon = "fas fa-dog";
  }
  let useAddonPrices = estimatePackage.toLowerCase() === "add-on special";

  return (
    <>
      <Header block>
        <i className={petIcon} /> {(Pet.petName || "Pet") + "'s Estimate  "}
        <Header size="small" floated="right">
          <Money value={subtotal} />
        </Header>
      </Header>
      <List celled>
        <List.Item>
          {" "}
          <List.Content floated="right">
            {/* Display the overridden grooming fee or the package price */}
            {Pet.isOverridingGroomingFee ? (
              <>
                <Label size="mini" color="orange">
                  Overridden
                </Label>
                <Money value={Pet.overrideGroomingFeePrice} />
              </>
            ) : (
              <Money value={packageCost} />
            )}
          </List.Content>
          <List.Header>Package</List.Header>
          <List.Description>{estimatePackage}</List.Description>
        </List.Item>
        <List.Item>
          <List.Header>Extra Services</List.Header>
          <List.List>
            {/* Make list items for all services */}
            {Object.keys(services).length > 0
              ? _.map(services, (service, i) => {
                  return (
                    <List.Item key={i}>
                      <List.Content floated="right">
                        <Money
                          value={
                            useAddonPrices && service.addonPrice
                              ? service.addonPrice
                              : service.price
                          }
                        />
                      </List.Content>
                      {service.item}
                    </List.Item>
                  );
                })
              : "None"}
          </List.List>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            <Money value={otherFee} />
          </List.Content>
          <List.Header>Other Fees</List.Header>
        </List.Item>
        <List.Item>
          <List.Header>Other Description</List.Header>
          <List.Description>{otherDescription || "N/A"}</List.Description>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {Pet.isDeaceased && (
              <Label size="mini" inverted>
                Deceased
              </Label>
            )}
            {Pet.isServicePet && (
              <Label size="mini" color="purple">
                Service Pet
              </Label>
            )}
            {Pet.sex === "Male" ? (
              <Label size="mini" color="blue">
                Male
              </Label>
            ) : Pet.sex === "Female" ? (
              <Label size="mini" color="pink">
                Female
              </Label>
            ) : null}
          </List.Content>
          <List.Header>Pet Details</List.Header>
          <List.List>
            <List.Item>
              <List.Content floated="right">
                {breedDetails ? breedDetails.breed : "Unknown"}
              </List.Content>
              <List.Header>Breed</List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {Pet.weight || "Unknown"}
              </List.Content>
              <List.Header>Weight</List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">{birthday}</List.Content>
              <List.Header>Birthday</List.Header>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                {Pet.age ? Pet.age + " years old" : "N/A"}
              </List.Content>
              <List.Header>Age</List.Header>
            </List.Item>
            <List.Item>
              <List.Header>Grooming</List.Header>
              <List.Description>{Pet.grooming || "N/A"}</List.Description>
            </List.Item>
            {Pet.isOverridingGroomingFee === true && (
              <List.Item>
                <List.Content floated="right">
                  {Pet.isOverridingGroomingFee
                    ? Pet.overrideGroomingFeePrice
                    : "N/A"}
                </List.Content>
                <List.Header>Custom Groom Fee Price</List.Header>
              </List.Item>
            )}
          </List.List>
        </List.Item>
      </List>
    </>
  );
}

EstimateConfirmationItem.defaultProps = {
  otherFee: 0,
  otherDescription: "",
  estimatePackage: null,
  services: {},
  Pet: {},
  breeds: []
};

EstimateConfirmationItem.propTypes = {
  otherFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  otherDescription: PropTypes.string,
  estimatePackage: PropTypes.string,
  services: PropTypes.object,
  Pet: PropTypes.object,
  breeds: PropTypes.array
};

export default props => (
  <OptionsContext.Consumer>
    {({ breeds }) => <EstimateConfirmationItem {...props} breeds={breeds} />}
  </OptionsContext.Consumer>
);
