import React, { Component } from "react";
import { Container, Button } from "semantic-ui-react";
import { Dot, ButtonBack, ButtonNext } from "pure-react-carousel";

class DotGroup extends Component {
  render() {
    let { slides, size } = this.props;
    return (
      <Container textAlign="center">
        <Button icon="chevron left" as={ButtonBack} size={size} />
        <Button.Group size={size}>
          {[...Array(slides).keys()].map(slide => (
            <Button as={Dot} key={slide} icon="circle" slide={slide} />
          ))}
        </Button.Group>{" "}
        <Button icon="chevron right" as={ButtonNext} size={size} />
      </Container>
    );
  }
}

DotGroup.defaultProps = {
  size: "mini"
};

export default DotGroup;
