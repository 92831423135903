import React, { Component } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import ErrorBoundary from "../Shared/ErrorBoundary";
import Authorize from "../Shared/Authorize";
import { UserContext } from "../UserContext";

import AllAppointments from "../Tables/AllAppointments";
import WaitingList from "../Tables/WaitingList";
import ServiceIssuesList from "../Tables/ServiceIssuesList";
import AllAppointmentsHistory from "../Tables/AllAppointmentsHistory";
import PreviousAppointments from "../Tables/PreviousAppointments";
import StandingAppointments from "../Tables/StandingAppointments";
import TodoList from "../Tables/TodoList";

class Appoinments extends Component {
  render() {
    const { match } = this.props;
    return (
      <Authorize
        userRole="Customer"
        else={
          <ErrorBoundary>
            <Switch>
              {/* Render table of appointments */}
              <Route
                exact
                path={`${match.path}/`}
                render={(props) => (
                  <AllAppointments {...props} header="Schedule" />
                )}
              />
              {/* Render table of appointments with a selected customer*/}
              <Route
                path={`${match.path}/user/:userId(\\d+)`}
                render={(props) => (
                  <AllAppointments
                    {...props}
                    header="Schedule"
                    selectedUserID={match.params.userId}
                  />
                )}
              />
              {/* Render table for customers on waiting list */}
              <Route
                path={`${match.path}/waitingList`}
                component={WaitingList}
              />
              {/* Render table standing appointments for managers */}
              <Route
                path={`${match.path}/standings`}
                component={StandingAppointments}
              />

              <Route path={`${match.path}/todoList`} component={TodoList} />
              {/* Render table for managers of service issue appointments*/}
              <Authorize permission="ViewAppointmentHistory">
                <Route
                  path={`${match.path}/serviceIssuesList`}
                  component={ServiceIssuesList}
                />
                <Route
                  path={`${match.path}/history`}
                  component={AllAppointmentsHistory}
                />
              </Authorize>
              <Redirect to={`${match.path}/`} />
            </Switch>
          </ErrorBoundary>
        }
      >
        <ErrorBoundary>
          <Switch>
            <Route
              path={`${match.path}/history`}
              render={(props) => (
                <UserContext.Consumer>
                  {({ user }) => (
                    <PreviousAppointments
                      {...props}
                      userId={user.userId}
                      header="Previous Appointments"
                    />
                  )}
                </UserContext.Consumer>
              )}
            />
            <Redirect to={`${match.path}/history`} />
          </Switch>
        </ErrorBoundary>
      </Authorize>
    );
  }
}

export default Appoinments;
