import React from "react";
import PropTypes from "prop-types";

import { Header, List } from "semantic-ui-react";
import Money from "./Money";

/**
 * Display the totals for estimate confirmation in a card like format
 */
function EstimateConfirmationTotals(props) {
  let { totals, needsGenerator } = props;

  return (
    <div>
      <Header block>Totals</Header>
      <List celled size="large">
        <List.Item>
          <List.Content floated="right">
            +<Money value={totals.service} />
          </List.Content>
          <List.Header>Pet Services</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            +<Money value={totals.trip} />
          </List.Content>
          <List.Header>Trip Fee</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            {needsGenerator ? (
              <>
                +<Money value={totals.generator} />
              </>
            ) : (
              "N/A"
            )}
          </List.Content>
          <List.Header>Generator Fee</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            +<Money value={totals.fuel} />
          </List.Content>
          <List.Header>Fuel Fee</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            -<Money value={totals.discounts} />
          </List.Content>
          <List.Header>Discounts</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            +<Money value={totals.tax} />
          </List.Content>
          <List.Header>Tax</List.Header>
        </List.Item>
        <List.Item>
          <List.Content floated="right">
            =<Money value={totals.total} />
          </List.Content>
          <List.Header>Total</List.Header>
        </List.Item>
      </List>
    </div>
  );
}

EstimateConfirmationTotals.defaultProps = {
  totals: {},
  needsGenerator: false
};

EstimateConfirmationTotals.propTypes = {
  totals: PropTypes.object,
  needsGenerator: PropTypes.bool
};
export default EstimateConfirmationTotals;
