import React, { Component } from "react";
import PropTypes from "prop-types";

import { Form, Input as SemanticInput } from "semantic-ui-react";
import Validation from "../Shared/Validation";
import ErrorBoundary from "./ErrorBoundary";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
import { format as formatAs } from "../lib/format";

/**
 * Semantic Form.Input wrapped with validation support. Can choose to pass validation and check validation when not focused on component.
 * @prop {boolean} checkOnFocus When true, checks the validation when not focused on the input
 * @prop {string|regex} validation When passed value to validation component
 */
class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkValidation: !props.checkOnFocus
    };
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onFocus() {
    this.setState({ checkValidation: false });
  }
  onBlur() {
    this.setState({ checkValidation: true });
  }
  onChange(e, data) {
    let { controlChange, format, onChange, type } = this.props;
    if (controlChange === true) {
      if (format != null) {
        onChange(e, {
          ...data,
          value: formatAs(data.value, format)
        });
        return;
      }
      onChange(e, data);
    } else {
      let updatedData = { ...data };
      if (type === "number" && data.value === "") {
        updatedData.value = null;
      }
      onChange(e, updatedData);
    }
  }
  render() {
    let {
      validation,
      checkOnFocus,
      isEditing,
      maxDate,
      format,
      controlChange,
      dateFormat,
      label,
      attachedLabel,

      // Field props
      required,
      width,
      fieldProps,

      ...rest
    } = this.props;

    let passedFieldProps = {
      required: required,
      width: width,
      ...fieldProps
    };

    let passedProps = {
      required: required,
      transparent: !isEditing,
      placeholder: label,
      label: attachedLabel,
      ...rest,
      // This fixes...
      type: rest.type === "number" ? "text" : rest.type,
      readOnly: !isEditing,
      value:
        rest.value === null || rest.value === undefined ? "" : rest.value + "",
      onChange: this.onChange
    };

    // Add props for a money input field
    if (format === "moneyInput") {
      passedProps.iconPosition = "left";
      passedProps.icon = "dollar";
      passedProps.className = "money";
      passedProps.type = "number";
      passedProps.step = 0.01;
    }

    if (validation) {
      if (checkOnFocus) {
        // Stop checking validation when focused to not show errors while typing
        passedProps.onFocus = this.onFocus;
        // Check validation when not focused to show errors
        passedProps.onBlur = this.onBlur;
        // Check the validation when mouse leaves box to show errors
        passedProps.onMouseOut = this.onBlur;
      }
      let { checkValidation } = this.state;

      // Handle a date input
      if (validation === "date") {
        if (isEditing) {
          return (
            <DateInput
              animation="none"
              startMode="month"
              closable
              maxDate={maxDate}
              error={
                typeof passedProps.value === "string"
                  ? passedProps.value.toLowerCase() === "invalid date"
                  : undefined
              }
              dateFormat={dateFormat || "MM-DD-YYYY"}
              preserveViewMode={false}
              {...passedProps}
              label={label}
            />
          );
        } else {
          return (
            <Form.Field {...passedFieldProps}>
              <label>{label}</label>
              <SemanticInput {...passedProps} />
            </Form.Field>
          );
        }
      }

      // Handle a date input
      if (validation === "time") {
        if (isEditing === true) {
          return (
            <TimeInput
              animation="none"
              closable
              timeFormat="AMPM"
              {...passedProps}
              label={label}
            />
          );
        } else {
          return (
            <Form.Field {...passedFieldProps}>
              <label>{label}</label>
              <SemanticInput {...passedProps} />
            </Form.Field>
          );
        }
      }
      return (
        <ErrorBoundary>
          <Validation
            validation={checkValidation ? validation : null}
            value={passedProps.value}
            required={passedProps.required}
          >
            {error => {
              return (
                <Form.Field
                  {...passedFieldProps}
                  error={passedProps.error || error}
                >
                  <label>{label}</label>
                  <SemanticInput {...passedProps} />
                </Form.Field>
              );
            }}
          </Validation>
        </ErrorBoundary>
      );
    }
    return (
      <Form.Field {...passedFieldProps}>
        <label>{label}</label>
        <SemanticInput {...passedProps} />
      </Form.Field>
    );
  }
}

Input.defaultProps = {
  checkOnFocus: true,
  controlChange: false,
  isEditing: false,
  validation: undefined,
  format: undefined,
  maxLength: 50,
  onChange: () => {},
  fieldProps: {}
};

Input.propTypes = {
  checkOnFocus: PropTypes.bool,
  controlChange: PropTypes.bool,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func,
  validation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.instanceOf(RegExp)
  ]),
  format: PropTypes.string,
  maxLength: PropTypes.number,

  fieldProps: PropTypes.object
};
export default Input;
