import React, { Component } from "react";
import Authorize from "../Shared/Authorize";
import ErrorBoundary from "../Shared/ErrorBoundary";

import StylistRoute from "../Shared/StylistRoute";
import Estimate from "../Shared/Estimate";

import { Switch, Route, Redirect } from "react-router-dom";
import CustomerSignOff from "../Forms/CustomerSignOff";

class Schedule extends Component {
  render() {
    let { match } = this.props;
    return (
      <ErrorBoundary>
        <Authorize permission="ViewDailyRoute">
          <Switch>
            <Route path={match.path + "/upcoming"} component={StylistRoute} />
            <Route
              path={
                match.path +
                "/currentAppointment/:appointmentId/estimate/:estimateId(\\d+)/user/:userId(\\d+)"
              }
              component={Estimate}
            />
            <Route
              path={
                match.path +
                "/currentAppointment/:appointmentId(\\d+)/completing"
              }
              component={CustomerSignOff}
            />
            <Redirect to={match.path + "/upcoming"} />
          </Switch>
        </Authorize>
      </ErrorBoundary>
    );
  }
}

export default Schedule;
