import React, { Component } from "react";

import { Menu, Dropdown, Responsive } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import Authorize from "../Shared/Authorize";
import { UserContext } from "../UserContext";

const BASE_URL = "/dashboard";

/**
 * Navbar items for desktop and mobile.
 * Do not forget to add the page to the layout for routing!
 */
class MenuItems extends Component {
  handleItemClick = (e, data) => {
    if (data.to) {
      this.props.history.push(data.to);
    }
    this.props.handleItemClick(e, data);
  };
  render() {
    // Get the currently active menu item based on window location
    let pathName = window.location.pathname;
    let activeItem = pathName.substring(
      pathName.indexOf(BASE_URL) + BASE_URL.length
    );
    let userDropdown = (
      <>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Menu.Item
            // Setting this style of this menu item fixes issues from the item being wrapped in a response element
            style={{ height: "100%" }}
            as={Dropdown}
            active={
              activeItem.indexOf("/employees") >= 0 ||
              activeItem.indexOf("/customers") >= 0
            }
            text="Users"
          >
            <Dropdown.Menu>
              <Authorize permission="ViewAllCustomers">
                <Dropdown.Item
                  to="/dashboard/customers"
                  content="Customers"
                  onClick={this.handleItemClick}
                  active={activeItem.indexOf("/customers") >= 0}
                />
              </Authorize>
              <Authorize permission="ViewAllEmployees">
                <Dropdown.Item
                  to="/dashboard/employees"
                  content="Employees"
                  onClick={this.handleItemClick}
                  active={activeItem.indexOf("/employees") >= 0}
                />
              </Authorize>
            </Dropdown.Menu>
          </Menu.Item>
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <Menu.Item
            // Setting this style of this menu item fixes issues from the item being wrapped in a response element
            style={{ height: "100%" }}
          >
            <Menu.Header>Users</Menu.Header>
            <Menu.Menu>
              <Menu.Item
                name="customers"
                as={Link}
                to="/dashboard/customers"
                onClick={this.props.handleItemClick}
                active={activeItem.indexOf("/customers") >= 0}
              />
              <Menu.Item
                name="employees"
                as={Link}
                onClick={this.props.handleItemClick}
                to="/dashboard/employees"
                active={activeItem.indexOf("/employees") >= 0}
              />
            </Menu.Menu>
          </Menu.Item>
        </Responsive>
      </>
    );

    let appointmentDropdown = (
      <>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Menu.Item
            // Setting this style of this menu item fixes issues from the item being wrapped in a response element
            style={{ height: "100%" }}
            as={Dropdown}
            active={[
              "/appointments",
              "/appointments/waitingList",
              "/appointments/serviceIssuesList",
              "/appointments/standings",
              "/appointments/history",
              "/appointments/todoList"
            ].includes(activeItem)}
            text="Appointments"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                to="/dashboard/appointments"
                content="Schedule"
                onClick={this.handleItemClick}
                active={
                  activeItem.indexOf("/appointments") >= 0 &&
                  activeItem.length === "/appointments".length
                }
              />
              <Dropdown.Item
                to="/dashboard/appointments/waitingList"
                content="Waiting List"
                onClick={this.handleItemClick}
                active={activeItem.indexOf("/appointments/waitingList") >= 0}
              />
              <Authorize permission="EditStandingAppointments">
                <Dropdown.Item
                  to="/dashboard/appointments/standings"
                  content="Standings"
                  onClick={this.handleItemClick}
                  active={activeItem.indexOf("/appointments/standings") >= 0}
                />
              </Authorize>
              <Authorize permission="ViewServiceIssues">
                <Dropdown.Item
                  to="/dashboard/appointments/serviceIssuesList"
                  content="Service Issues"
                  onClick={this.handleItemClick}
                  active={
                    activeItem.indexOf("/appointments/serviceIssuesList") >= 0
                  }
                />
              </Authorize>
              <Authorize permission="ViewAppointmentHistory">
                <Dropdown.Item
                  to="/dashboard/appointments/history"
                  content="History"
                  onClick={this.handleItemClick}
                  active={activeItem.indexOf("/appointments/history") >= 0}
                />
              </Authorize>
            </Dropdown.Menu>
          </Menu.Item>
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <Menu.Item
            // Setting this style of this menu item fixes issues from the item being wrapped in a response element
            style={{ height: "100%" }}
          >
            <Menu.Header>Appointments</Menu.Header>
            <Menu.Menu>
              <Menu.Item
                name="schedule"
                as={Link}
                to="/dashboard/appointments"
                onClick={this.props.handleItemClick}
                active={
                  activeItem.indexOf("/appointments") >= 0 &&
                  activeItem.length === "/appointments".length
                }
              />
              <Menu.Item
                name="waitingList"
                as={Link}
                onClick={this.props.handleItemClick}
                to="/dashboard/appointments/waitingList"
                active={activeItem.indexOf("/appointments/waitingList") >= 0}
              />
              <Authorize permission="EditStandingAppointments">
                <Menu.Item
                  name="standings"
                  as={Link}
                  onClick={this.props.handleItemClick}
                  to="/dashboard/appointments/standings"
                  active={activeItem.indexOf("/appointments/standings") >= 0}
                />
              </Authorize>
              <Authorize permission="ViewServiceIssues">
                <Menu.Item
                  name="serviceIssuesList"
                  as={Link}
                  onClick={this.props.handleItemClick}
                  to="/dashboard/appointments/serviceIssuesList"
                  active={
                    activeItem.indexOf("/appointments/serviceIssuesList") >= 0
                  }
                />
              </Authorize>
              <Authorize permission="ViewAppointmentHistory">
                <Menu.Item
                  name="history"
                  as={Link}
                  onClick={this.props.handleItemClick}
                  to="/dashboard/appointments/history"
                  active={activeItem.indexOf("/appointments/history") >= 0}
                />
              </Authorize>
            </Menu.Menu>
          </Menu.Item>
        </Responsive>
      </>
    );

    let managementDropdown = (
      <>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Menu.Item
            // Setting this style of this menu item fixes issues from the item being wrapped in a response element
            style={{ height: "100%" }}
            as={Dropdown}
            active={[
              "/management",
              "/outstandingBalances",
              "/reports/payroll",
              "/reprots/salesTax",
              "/reprots/dailyTips",
              "/complaints"
            ].includes(activeItem)}
            text="Management"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                content="Application Settings"
                to="/dashboard/management"
                onClick={this.handleItemClick}
                active={
                  activeItem.indexOf("/management") >= 0 &&
                  activeItem.length === "/management".length
                }
              />
              <Authorize permission="ViewComplaints">
                <Dropdown.Item
                  content="Complaints"
                  to="/dashboard/complaints"
                  onClick={this.handleItemClick}
                  active={activeItem.indexOf("/dashboard/complaints") >= 0}
                />
              </Authorize>
              <Authorize permission="ViewOutstandingBalances">
                <Dropdown.Item
                  content="Outstanding Balances"
                  to="/dashboard/outstandingBalances"
                  onClick={this.handleItemClick}
                  active={
                    activeItem.indexOf("/dashboard/outstandingBalances") >= 0
                  }
                />
              </Authorize>
              <Authorize permission="ViewAdminReports">
                <Dropdown.Item
                  content="Payroll Report"
                  to="/dashboard/reports/payroll"
                  onClick={this.handleItemClick}
                  active={activeItem.indexOf("/dashboard/reports/payroll") >= 0}
                />
              </Authorize>
              <Authorize permission="ViewAdminReports">
                <Dropdown.Item
                  content="Sales Tax Report"
                  to="/dashboard/reports/salesTax"
                  onClick={this.handleItemClick}
                  active={
                    activeItem.indexOf("/dashboard/reports/salesTax") >= 0
                  }
                />
              </Authorize>
              <Authorize permission="ViewManagerReports">
                <Dropdown.Item
                  content="Daily Tips Report"
                  to="/dashboard/reports/dailyTips"
                  onClick={this.handleItemClick}
                  active={
                    activeItem.indexOf("/dashboard/reports/dailyTips") >= 0
                  }
                />
              </Authorize>
            </Dropdown.Menu>
          </Menu.Item>
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <Menu.Item
            // Setting this style of this menu item fixes issues from the item being wrapped in a response element
            style={{ height: "100%" }}
          >
            <Menu.Header>Management</Menu.Header>
            <Menu.Menu>
              <Menu.Item
                name="applicationSettings"
                as={Link}
                to="/dashboard/management"
                onClick={this.handleItemClick}
                active={
                  activeItem.indexOf("/management") >= 0 &&
                  activeItem.length === "/management".length
                }
              />
              <Authorize permission="ViewAdminReports">
                <Menu.Item
                  name="salesTax"
                  as={Link}
                  to="/dashboard/reports/salesTax"
                  onClick={this.handleItemClick}
                  active={
                    activeItem.indexOf("/dashboard/reports/salesTax") >= 0
                  }
                />
              </Authorize>
              <Authorize permission="ViewAdminReports">
                <Menu.Item
                  name="payroll"
                  as={Link}
                  to="/dashboard/reports/payroll"
                  onClick={this.handleItemClick}
                  active={activeItem.indexOf("/dashboard/reports/payroll") >= 0}
                />
              </Authorize>
              <Authorize permission="ViewManagerReports">
                <Menu.Item
                  name="dailyTips"
                  as={Link}
                  to="/dashboard/reports/dailyTips"
                  onClick={this.handleItemClick}
                  active={activeItem.indexOf("/dashboard/reports/dailyTips") >= 0}
                />
              </Authorize>
            </Menu.Menu>
          </Menu.Item>
        </Responsive>
      </>
    );

    return (
      <>
        <Authorize permission="ViewDailyRoute">
          <Menu.Item
            as={Link}
            to="/dashboard/schedule"
            name="Schedule"
            onClick={this.props.handleItemClick}
            active={activeItem.indexOf("/schedule") >= 0}
          />
        </Authorize>
        <Authorize permission="ViewAllAppointments">
          {appointmentDropdown}
        </Authorize>

        <Authorize permission="ViewTodoItems">
          <Menu.Item
            as={Link}
            to="/dashboard/todoList"
            content="Todo Items"
            name="todoList"
            onClick={this.props.handleItemClick}
            active={activeItem.indexOf("/todoList") >= 0}
          />
        </Authorize>

        {/* Display just the customer tab if not authorized to see the employees as well */}
        <Authorize
          permission="ViewManagement"
          else={
            <Authorize permission="ViewAllCustomers">
              <Menu.Item
                as={Link}
                to="/dashboard/customers"
                name="customers"
                onClick={this.props.handleItemClick}
                active={activeItem.indexOf("/customers") >= 0}
              />
            </Authorize>
          }
        >
          {userDropdown}
        </Authorize>
        <Authorize permission="ViewAllTransactions">
          <Menu.Item
            as={Link}
            to="/dashboard/transactions"
            name="transactions"
            onClick={this.props.handleItemClick}
            active={activeItem.indexOf("/transactions") >= 0}
          />
        </Authorize>

        <Authorize permissions={["EditSettings", "ViewAdminReports", "ViewManagerReports"]}>
          {managementDropdown}
        </Authorize>

        <UserContext.Consumer>
          {({ user }) => {
            return (
              <Authorize permission="ViewCustomerDashboard">
                <Menu.Item
                  as={Link}
                  to={"/dashboard/profile/" + user.userId}
                  name="profile"
                  onClick={this.props.handleItemClick}
                  active={activeItem.indexOf("/profile") >= 0}
                />
                <Menu.Item
                  as={Link}
                  to={"/dashboard/information/" + user.userId}
                  name="information"
                  onClick={this.props.handleItemClick}
                  active={activeItem.indexOf("/information") >= 0}
                />
                <Menu.Item
                  as={Link}
                  name="estimates"
                  to="/dashboard/estimates"
                  onClick={this.props.handleItemClick}
                  active={activeItem.indexOf("/estimates") >= 0}
                />
                <Menu.Item
                  as={Link}
                  name="appointments"
                  to="/dashboard/appointments"
                  onClick={this.props.handleItemClick}
                  active={activeItem.indexOf("/appointments") >= 0}
                />
                <Menu.Item
                  as={Link}
                  name="transactions"
                  to="/dashboard/transactions"
                  onClick={this.props.handleItemClick}
                  active={activeItem.indexOf("/transactions") >= 0}
                />
                {/* <Menu.Item
                  name="documents"
                  as={Link}
                  to="/dashboard/documents"
                  onClick={this.props.handleItemClick}
                  active={activeItem.indexOf("/documents") >= 0}
                /> */}
              </Authorize>
            );
          }}
        </UserContext.Consumer>
      </>
    );
  }
}

export default withRouter(MenuItems);
