import React, { Component } from "react";

import Axios from "axios";
import ReactTable from "react-table";
import moment from "moment";
import { toast } from "react-toastify";

import { Header, Segment } from "semantic-ui-react";
import Money from "../Shared/Money";

class Estimates extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { data: [], loading: false, selectedRow: null };
    this.getEstimates = this.getEstimates.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.getEstimates();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  async getEstimates() {
    if (!this.props.userId) return;
    this.setState({ loading: true });
    try {
      let response = await Axios.get("/api/v4/estimate/findByCustomer", {
        params: { userId: this.props.userId }
      });
      if (this._isMounted) {
        if (response.status === 200) {
          this.setState({
            data: response.data
          });
        }
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to retrieve previous estimates.");
      this.setState({ loading: false });
    }
  }
  onRowClick(state, rowInfo, column) {
    if (this.state.loading === true) return;
    // Default to pushing to path
    let path = "";
    if (this.props.includeUserInPath && this.props.userId) {
      path =
        "/dashboard/estimate/" +
        rowInfo.original.ID +
        "/user/" +
        this.props.userId;
    } else {
      path =
        "/dashboard/estimates/" +
        rowInfo.original.ID +
        "/user/" +
        this.props.userId;
    }
    this.props.history.push(path + "/info");
  }
  render() {
    const { selectedRow, data, loading } = this.state;
    const { header } = this.props;

    return (
      <div>
        {header && (
          <Header as="h2" textAlign="center">
            {header}
          </Header>
        )}
        <Segment>
          <ReactTable
            getTheadThProps={(state, rowInfo, instance) => {
              return { className: "customer-table-" + instance.id };
            }}
            data={data}
            columns={columns}
            loading={loading}
            defaultPageSize={5}
            className="-striped -highlight"
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.original.ID != null) {
                var style = { cursor: "pointer" };
              }
              return {
                onClick: () => this.onRowClick(state, rowInfo, column),
                style: style,
                className:
                  rowInfo &&
                  selectedRow &&
                  rowInfo.original.ID === selectedRow.ID
                    ? "selected-row"
                    : undefined
              };
            }}
          />
        </Segment>
      </div>
    );
  }
}

Estimates.defaultProps = {
  userId: null,
  header: null,
  includeUserInPath: false
};

export default Estimates;

const columns = [
  {
    Header: "Date Saved",
    accessor: "LastEdited",
    Cell: props => <div>{moment(props.value).format("LLL")}</div>
  },
  {
    Header: "Total Estimate",
    accessor: "TotalEstimate",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Services",
    accessor: "FeeGroom",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Trip Fee",
    accessor: "FeeTrip",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Generator Fee",
    accessor: "FeeGenerator",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Fuel Fee",
    accessor: "FeeFuel",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Sales Tax",
    accessor: "FeeSalesTax",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Discounts",
    accessor: "DiscountTotal",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Pets",
    id: "pets",
    accessor: data => {
      let petNames = "";
      if (data.EstimatePets) {
        for (let i = 0; i < data.EstimatePets.length; i++) {
          petNames +=
            data.EstimatePets[i].Pet.PetName +
            (i < data.EstimatePets.length - 1 ? ", " : "");
        }
      }
      return petNames;
    }
  }
];
