import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import Money from "../Shared/Money";
import Input from "../Shared/Input";

class EstimateTotals extends Component {
  render() {
    const {
      service,
      trip,
      fuel,
      generator,
      discounts,
      tax,
      total,
      needsGenerator,
      onChange,
      isEditing
    } = this.props;
    return (
      <Table celled columns={7}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Service</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Trip</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Fuel</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Generator</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Discounts</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="right">
              <Money value={service} />
            </Table.Cell>
            <Table.Cell>
              <Input
                fluid
                type="number"
                isEditing={isEditing}
                validation="money"
                placeholder={(0.0).toFixed(2)}
                value={trip}
                format="moneyInput"
                controlChange
                name="tripFee"
                onChange={onChange}
              />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Money value={fuel} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              {/* Display not applicable if estimate does not need generator */}
              {needsGenerator === true ? (
                <Input
                  fluid
                  type="number"
                  isEditing={needsGenerator && isEditing}
                  validation="money"
                  placeholder={(0.0).toFixed(2)}
                  value={generator}
                  name="generatorFee"
                  onChange={onChange}
                  format="moneyInput"
                  controlChange
                />
              ) : (
                "N/A"
              )}
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Money value={discounts} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Money value={tax} />
            </Table.Cell>
            <Table.Cell textAlign="right" positive>
              <Money value={total} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

EstimateTotals.defaultProps = {
  service: 0,
  trip: 0,
  fuel: 0,
  generator: 0,
  other: 0,
  discounts: 0,
  tax: 0,
  total: 0,
  needsGenerator: false,
  isEditing: true,
  onChange: () => {}
};

export default EstimateTotals;
