import React, { Component } from "react";

import Axios from "axios";

import ReactTable from "react-table";
import moment from "moment";
import { toast } from "react-toastify";

import PetList from "../Shared/PetList";
import { Button, Popup, Segment, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import StandingAppointmentRequestModal from "../Forms/StandingAppointmentRequestModal";
import CreateServiceIssueModal from "../Forms/CreateServiceIssueModal";
import Authorize from "../Shared/Authorize";
import _ from "lodash";
import { OptionsContext } from "../OptionsContext";
import { removeMapsco } from "../lib/helperFunctions";

class PreviousAppointments extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: -1,
      loading: false,
      selectedRow: null,
      openCreateServiceIssueModal: false,
      openStandingAppointmentRequestModal: false,
    };
    this.getAppointments = this.getAppointments.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  async getAppointments(state, instance) {
    if (!this.props.userId) return;
    this.setState({ loading: true });
    try {
      let response = await Axios.get("/api/v4/appointments/findByCustomer", {
        params: {
          userId: this.props.userId,
          page: state.page,
          pageSize: state.pageSize,
        },
      });

      if (this._isMounted) {
        if (response.status === 200) {
          this.setState({
            data: response.data.rows,
            pages: response.data.pages || 0,
          });
        }
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to retrieve appointments.");
      this.setState({ loading: false });
    }
  }
  onRowClick(state, rowInfo, column) {
    if (this.state.loading === true) return;
    // Add user to path if employee user
    if (this.props.includeUserInPath && rowInfo && rowInfo.original.ID) {
      this.props.history.push("/dashboard/appointments/history", {
        defaultAppointmentId: rowInfo.original.ID,
        defaultAppointmentDate: rowInfo.original.appointmentDate,
      });
    } else {
      if (
        rowInfo &&
        rowInfo.original.Estimate &&
        rowInfo.original.Customer &&
        rowInfo.original.Customer.User
      ) {
        let path = `/dashboard/estimates/${rowInfo.original.Estimate.ID}/user/${rowInfo.original.Customer.User.ID}/`;
        this.props.history.push(path);
      }
    }
  }
  getProps = (state, rowInfo, column) => {
    if (column.Header !== "") {
      const { selectedRow } = this.state;
      if (rowInfo && rowInfo.original.ID != null) {
        var style = { cursor: "pointer" };
      }
      let classNames =
        rowInfo && selectedRow && rowInfo.original.ID === selectedRow.ID
          ? "selected-row"
          : "";
      if (rowInfo && rowInfo.original.Status) {
        classNames =
          classNames + " appointment-status-" + rowInfo.original.Status.status;
      }
      return {
        onClick: () => this.onRowClick(state, rowInfo, column),
        style: style,
        className: classNames,
      };
    }
    return {};
  };
  closeCreateServiceIssueModal = () => {
    this.setState({ openCreateServiceIssueModal: false });
  };
  openCreateServiceIssueModal = (value) => {
    this.setState({ openCreateServiceIssueModal: value });
  };
  closeStandingAppointmentRequestModal = () => {
    this.setState({ openStandingAppointmentRequestModal: false });
  };
  openStandingAppointmentRequestModal = (value) => {
    this.setState({ openStandingAppointmentRequestModal: value });
  };
  render() {
    const {
      data,
      loading,
      openCreateServiceIssueModal,
      openStandingAppointmentRequestModal,
      pages,
    } = this.state;
    let { includeUserInPath, header } = this.props;

    let extraColumns = [];
    if (includeUserInPath !== true) {
      extraColumns.push({
        Header: "",
        id: "transactionId",
        getProps: () => {
          return {};
        },
        accessor: (data) => {
          return data.Transactions && data.Transactions.length > 0
            ? _.minBy(data.Transactions, "DateAdded").ID
            : null;
        },
        width: 100,
        Cell: (props) => (
          <Popup
            inverted
            basic
            content="Click to view transaction for appointment"
            trigger={
              <Button
                fluid
                icon="tag"
                color="blue"
                as={Link}
                disabled={!props.value}
                to={`/dashboard/transactions/${props.value}`}
              />
            }
          />
        ),
      });
    } else {
      extraColumns.push({
        Header: "",
        accessor: "ID",
        width: 45,
        sortable: false,
        filterable: false,
        getProps: () => {
          return {};
        },
        Cell: (props) => (
          <Popup
            inverted
            basic
            content="Click to create a service issue for this appointment."
            trigger={
              <Button
                fluid
                icon="list"
                size="small"
                color="blue"
                onClick={() => this.openCreateServiceIssueModal(props.value)}
              />
            }
          />
        ),
      });
      extraColumns.push({
        Header: "",
        accessor: "ID",
        width: 45,
        sortable: false,
        filterable: false,
        getProps: () => {
          return {};
        },
        Cell: (props) => (
          <Popup
            basic
            inverted
            content="Click to request a standing appointment for the customer using this appointments details."
            trigger={
              <Button
                fluid
                size="small"
                icon="redo"
                color="blue"
                onClick={() =>
                  this.openStandingAppointmentRequestModal(props.value)
                }
              />
            }
          />
        ),
      });
    }

    let allColumns = [...extraColumns, ...columns];
    if (includeUserInPath !== false) {
      allColumns.push({
        Header: "Last Comment",
        id: "lastComment",
        accessor: (data) => {
          if (data.lastComment && data.lastComment.comment) {
            return data.lastComment.comment;
          }
          return "";
        },
        minWidth: 250,
      });
    }

    return (
      <div>
        {header && (
          <Header as="h2" textAlign="center">
            {header}
          </Header>
        )}
        <Segment>
          <ReactTable
            manual
            getTheadThProps={(state, rowInfo, instance) => {
              return {
                className: "previous-appointments-table-" + instance.id,
              };
            }}
            pages={pages}
            sortable={false}
            data={data}
            onFetchData={this.getAppointments}
            defaultPageSize={5}
            columns={allColumns}
            loading={loading}
            className="-striped -highlight"
            getTdProps={this.getProps}
          />
        </Segment>
        <Authorize permission="EditAppointments">
          <CreateServiceIssueModal
            appointmentId={openCreateServiceIssueModal}
            open={openCreateServiceIssueModal !== false}
            onOpen={this.openCreateServiceIssueModal}
            onClose={this.closeCreateServiceIssueModal}
          />
          <StandingAppointmentRequestModal
            appointmentId={openStandingAppointmentRequestModal}
            open={openStandingAppointmentRequestModal !== false}
            onOpen={this.openStandingAppointmentRequestModal}
            onClose={this.closeStandingAppointmentRequestModal}
          />
        </Authorize>
      </div>
    );
  }
}

PreviousAppointments.defaultProps = {
  onRowClick: null,
  header: null,
  userId: null,
  includeUserInPath: false,
  statuses: [],
};

export default (props) => (
  <OptionsContext.Consumer>
    {({ statuses }) => <PreviousAppointments {...props} statuses={statuses} />}
  </OptionsContext.Consumer>
);

const columns = [
  {
    Header: "Date",
    accessor: "appointmentDate",
    minWidth: 80,
    Cell: (props) => moment(props.value, "MM-DD-YYYY").format("LL"),
  },
  {
    Header: "Time",
    minWidth: 50,
    id: "startTime",
    accessor: (data) =>
      moment(data.startTime, "h:mm A").isValid()
        ? moment(data.startTime, "h:mm A").format("h:mm A")
        : data.startTime,
  },
  {
    Header: "Stylist",
    id: "employeeId",
    accessor: (data) =>
      data.Employee &&
      data.Employee.User &&
      `${data.Employee.User.firstName} ${data.Employee.User.lastName}`,
  },
  {
    Header: "Pets",
    id: "pets",
    accessor: (data) =>
      data.Estimate &&
      data.Estimate.EstimatePets &&
      data.Estimate.EstimatePets.map((petEstimate) => petEstimate.Pet),
    Cell: (props) => <PetList includeDetails pets={props.value} />,
  },
  {
    Header: "Address",
    id: "estimateAddress",
    accessor: (data) => {
      return data.Estimate && data.Estimate.AddressString
        ? removeMapsco(data.Estimate.AddressString).address
        : null;
    },
  },
];
