import React, { Component } from "react";

import { Modal, Card, List, Icon } from "semantic-ui-react";

class PackageInformation extends Component {
  render() {
    return (
      <Modal closeIcon size="large" trigger={<Icon name="info circle" />}>
        <Modal.Header content="Packages and Services" />
        <Modal.Content image>
          <Modal.Description>
            <Card.Group>
              <Card>
                <Card.Content>
                  <Card.Header>Bath and Brush</Card.Header>
                  <List bulleted>
                    <List.Item>Bath</List.Item>
                    <List.Item>Brush </List.Item>
                    <List.Item>Trim Nails </List.Item>
                    <List.Item>
                      <strong>Optional</strong> Clean Ears *Remove Ear Hair
                    </List.Item>
                    <List.Item>Spray Cologne</List.Item>
                    <List.Item>
                      <strong>Optional</strong> Anal Gland Expression (Topical;
                      if needed.)
                    </List.Item>
                  </List>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Deluxe Style</Card.Header>
                  Includes everything in Bath and Brush package PLUS a haircut:
                  <List bulleted>
                    <List.Item>Any style haircut</List.Item>
                    <List.Item>Hand Scissoring</List.Item>
                    <List.Item>Shave down</List.Item>
                    <List.Item>Ribbon or Bandana</List.Item>
                  </List>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header>Add-on Special</Card.Header>
                  Buy two get one free (of equal or lesser value) of any of the
                  following add-ons:
                  <List bulleted>
                    <List.Item>
                      Shed-less treatment, $5-20 (based on size and coat type)
                    </List.Item>
                    <List.Item>Teeth Brushing, $10</List.Item>
                    <List.Item>
                      Specialty Shampoo, $5
                      <List.List bulleted>
                        <List.Item>Oatmeal</List.Item>
                        <List.Item>Whitening</List.Item>
                        <List.Item>Conditioner</List.Item>
                        <List.Item>Medicated</List.Item>
                      </List.List>
                    </List.Item>
                    <List.Item>Nail Grinding (dremmel)</List.Item>
                  </List>
                </Card.Content>
              </Card>
            </Card.Group>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default PackageInformation;
