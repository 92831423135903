import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "../Shared/Input";

class Name extends Component {
  render() {
    let {
      firstName,
      middleName,
      lastName,
      showMiddle,
      handleChange,
      isEditing,
      required
    } = this.props;
    return (
      <>
        <Input
          isEditing={isEditing}
          label="First Name"
          name="firstName"
          value={firstName}
          onChange={handleChange}
          required={required.includes("firstName")}
        />
        {showMiddle && (
          <Input
            isEditing={isEditing}
            label="Middle Name"
            name="middleName"
            value={middleName}
            onChange={handleChange}
          />
        )}
        <Input
          isEditing={isEditing}
          label="Last Name"
          name="lastName"
          value={lastName}
          onChange={handleChange}
          required={required.includes("lastName")}
        />
      </>
    );
  }
}

Name.defaultProps = {
  firstName: "",
  middlename: "",
  lastName: "",
  showMiddle: true,
  isEditing: true,
  required: [],
  handleChange: () => {}
};

Name.propTypes = {
  firstName: PropTypes.string,
  middlename: PropTypes.string,
  lastName: PropTypes.string,
  showMiddle: PropTypes.bool,
  isEditing: PropTypes.bool,
  required: PropTypes.array,
  handleChange: PropTypes.func
};

export default Name;
