import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Form } from "semantic-ui-react";
import { makeAddressString } from "../lib/helperFunctions";
import { OptionsContext } from "../OptionsContext";

/**
 * Displays selections that can be picked for an estimate such as physical address, email, phone number, and credit card to be used.
 *  @prop {[object]} addresses
 *  @prop {[object]} phoneNumbers
 *  @prop {[object]} emails
 *  @prop {boolean} needsGenerator
 *  @prop {string} addressString
 *  @prop {string} emailString
 *  @prop {string} phoneNumberString
 *  @prop {function} onChange
 */
class EstimateDetails extends Component {
  onCheckboxChange = (e, data) => {
    this.props.onChange(e, { ...data, value: data.checked });
  };
  render() {
    let {
      needsGenerator,
      addresses,
      emails,
      phoneNumbers,
      addressString,
      emailString,
      phoneNumberString,
      onChange,
      isEditing,
    } = this.props;

    // Make address options and add selected address if not in the options
    let addressOptions = makeAddressOptions(addresses, this.props.mapscoCities);
    if (addressString && !_.find(addressOptions, { text: addressString })) {
      addressOptions.push(
        makeOneOption(addressString, this.props.mapscoCities)
      );
    }

    // Make email options and add selected email if not in the options
    let emailOptions = makeEmailOptions(emails);
    if (emailString && !_.find(emailOptions, { text: emailString })) {
      emailOptions.push(makeOneOption(emailString));
    }

    // Make phone number options and add selected phone number if not in the options
    let phoneNumberOptions = makePhoneNumberOptions(phoneNumbers);
    if (
      phoneNumberString &&
      !_.find(phoneNumberOptions, { text: phoneNumberString })
    ) {
      phoneNumberOptions.push(makeOneOption(phoneNumberString));
    }

    return (
      <>
        <Form>
          {isEditing === true ? (
            <Form.Dropdown
              label="Address"
              placeholder="Select Address"
              name="addressString"
              selection
              required
              options={addressOptions}
              value={addressString}
              onChange={onChange}
              selectOnBlur={false}
              noResultsMessage="No Addresses"
            />
          ) : (
            <Form.Field>
              <label>Address</label>
              <div>{addressString}</div>
            </Form.Field>
          )}

          <Form.Checkbox
            label="Needs Generator"
            inline
            disabled={!isEditing}
            readOnly={!isEditing}
            name="needsGenerator"
            checked={needsGenerator}
            onChange={this.onCheckboxChange}
          />
          {isEditing === true ? (
            <Form.Dropdown
              label="Email"
              placeholder="Select Email"
              name="emailString"
              selection
              selectOnBlur={false}
              options={emailOptions}
              value={emailString}
              onChange={onChange}
              noResultsMessage="No Emails"
            />
          ) : (
            <Form.Field>
              <label>Email</label>
              <div>{emailString || "None"}</div>
            </Form.Field>
          )}
          {isEditing === true ? (
            <Form.Dropdown
              label="Phone Number"
              placeholder="Select Phone Number"
              name="phoneNumberString"
              selection
              selectOnBlur={false}
              options={phoneNumberOptions}
              value={phoneNumberString}
              onChange={onChange}
              noResultsMessage="No Phone Numbers"
            />
          ) : (
            <Form.Field>
              <label>Phone Number</label>
              <div> {phoneNumberString || "None"} </div>
            </Form.Field>
          )}
        </Form>
      </>
    );
  }
}

EstimateDetails.defaultProps = {
  addresses: [],
  phoneNumbers: [],
  emails: [],
  needsGenerator: false,
  addressString: "",
  emailString: "",
  phoneNumberString: "",
  mapscoCities: [],
  isEditing: true,
  onChange: () => {},
};

EstimateDetails.propTypes = {
  addresses: PropTypes.array,
  phoneNumbers: PropTypes.array,
  emails: PropTypes.array,
  needsGenerator: PropTypes.bool,
  addressString: PropTypes.string,
  emailString: PropTypes.string,
  phoneNumberString: PropTypes.string,
  mapscoCities: PropTypes.array,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func,
};

export default (props) => (
  <OptionsContext.Consumer>
    {({ mapscoCities }) => (
      <EstimateDetails {...props} mapscoCities={mapscoCities} />
    )}
  </OptionsContext.Consumer>
);

/**
 * Make array of options for the address dropdown
 * Formats the address as a stringified value
 * @param {array} addresses
 */
function makeAddressOptions(addresses, mapscoCities) {
  return addresses.map((address) => {
    let mapscoCity = _.find(mapscoCities, { ID: address.mapscoCityId });
    let value = makeAddressString(address, mapscoCity);
    return {
      key: address.ID,
      value: value,
      text: value,
      needsgenerator: address.needsGenerator ? "true" : undefined,
      addressnotes: address.notes,
    };
  });
}

/**
 * Make array of options for the email address dropdown
 * @param {array} emails
 */
function makeEmailOptions(emails) {
  return emails.map((email) => {
    return {
      key: email.ID,
      value: email.emailAddress,
      text: email.emailAddress,
    };
  });
}

/**
 * Make array of options for the phone number dropdown
 * @param {array} phoneNumbers
 */
function makePhoneNumberOptions(phoneNumbers) {
  return phoneNumbers.map((phoneNumber) => {
    return {
      key: phoneNumber.ID,
      value: phoneNumber.phoneNumber,
      text: phoneNumber.phoneNumber,
      label: {
        content: phoneNumber.label,
        size: "small",
        color: phoneNumber.isPrimary === true ? "green" : null,
      },
    };
  });
}

function makeOneOption(string) {
  return {
    key: string,
    value: string,
    text: string,
  };
}
