import React, { Component } from "react";
import { Header, Button, Segment } from "semantic-ui-react";
import SignatureCanvas from "react-signature-canvas";

/**
 * Displays a canvas to be used to create a signature for the customer with accept and clear buttons
 */
class Signature extends Component {
  sigCanvas = {};
  clear = () => {
    this.sigCanvas.clear();
  };
  accept = () => {
    this.sigCanvas.getCanvas().toBlob(blob => {
      this.props.onAccept(blob);
    });
    this.sigCanvas.off();
  };
  render() {
    return (
      <Segment>
        <Header>Sign Here</Header>
        <SignatureCanvas
          canvasProps={{
            className: "signature-canvas"
          }}
          ref={ref => {
            this.sigCanvas = ref;
          }}
        />
        <Button
          content="Clear"
          onClick={this.clear}
          disabled={this.props.accepted}
        />
        <Button
          content="Accept"
          color="blue"
          disabled={this.props.accepted}
          onClick={this.accept}
        />
      </Segment>
    );
  }
}

Signature.defaultProps = {
  onAccept: () => {},
  accepted: false
};

export default Signature;
