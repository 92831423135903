import React, { Component } from "react";
import Authorize from "../Shared/Authorize";
import ErrorBoundary from "../Shared/ErrorBoundary";

import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Header, Segment, Button } from "semantic-ui-react";

import EmployeeTable from "../Tables/EmployeeTable";
import UserCreate from "../Forms/UserCreate";
import EmployeePage from "../Shared/EmployeePage";

/**
 * Views table with all users and a user form that displays data for a selected user
 */
class Employees extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          path={`${match.path}/`}
          exact
          render={props => (
            <Header as="h2">
              Employees
              <Button
                floated="right"
                content="Create New Employee"
                color="blue"
                as={Link}
                to={`${match.path}/new`}
              />
            </Header>
          )}
        />
        <Switch>
          <Route path={`${match.path}/`} exact component={EmployeeTable} />
          <Route
            path={`${match.path}/new`}
            render={props => (
              <Segment>
                <UserCreate
                  {...props}
                  required={["emailAddress", "UserTypeID"]}
                  backPath={`${match.path}/`}
                />
              </Segment>
            )}
          />
          <Route
            path={`${match.path}/:userId(\\d+)`}
            component={EmployeePage}
          />
          <Redirect to={`${match.path}/`} />
        </Switch>
      </div>
    );
  }
}
export default props => (
  <Authorize permission="ViewEmployees">
    <ErrorBoundary>
      <Employees {...props} />
    </ErrorBoundary>
  </Authorize>
);
