import PropTypes from "prop-types";
import React, { Component } from "react";

import { Icon, Menu, Responsive, Sidebar, Button } from "semantic-ui-react";
import MenuItems from "./MenuItems";
import Axios from "axios";

class MobileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { activeItem: "customer", sidebarOpened: false };
  }

  handleClose = () => this.setState({ sidebarOpened: false });
  handleOpen = () => this.setState({ sidebarOpened: true });
  handleItemClick = (e, { name }) =>
    this.setState({ activeItem: name, sidebarOpened: false });

  handleClick = async () => {
    try {
      await Axios.get("/api/v4/account/logout");
      this.props.setUser({ userId: null });
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;
    const { activeItem } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          color="purple"
          as={Menu}
          direction="top"
          animation="overlay"
          inverted
          vertical
          visible={sidebarOpened}
          onHide={this.handleClose}
        >
          <MenuItems
            activeItem={activeItem}
            handleItemClick={this.handleItemClick}
          />
          <Menu.Item
            onClick={this.handleClick}
            name="logout"
            active={activeItem.indexOf("/logout") >= 0}
          />
          <Button inverted fluid icon="close" onClick={this.handleClose} />
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Menu inverted color="purple" secondary>
            <Menu.Item onClick={this.handleOpen}>
              <Icon name="sidebar" />
            </Menu.Item>
            <Menu.Item position="right">CRMS</Menu.Item>
          </Menu>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.element.isRequired
};

export default MobileContainer;
