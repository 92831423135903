import React from "react";

export const OptionsContext = React.createContext({
  breeds: [],
  animalTypes: [],
  userTypes: [],
  stylists: [],
  blockingComplaints: [],
  services: [],
  packages: [],
  mapscoCities: [],
  todoCategories: [],
  transactionsReasons: [],
  recurringTypes: [],
  statuses: [],
  contactMethods: [],

  fuelCharge: 0.0375,
  salesTax: 0.0825,
  generatorFee: 10
});
