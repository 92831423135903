import React, { Component } from "react";
import ReactTable from "react-table";
import { Header, Button, Modal, Divider, Menu } from "semantic-ui-react";
import {
  getProductsAndServices,
  updateProductAndService,
  createProductAndService,
  deleteProductAndService
} from "../../lib/apiCalls";
import { toast } from "react-toastify";
import Money from "../Money";
import _ from "lodash";
import ProductsAndServicesForm from "../../Forms/ProductsAndServicesForm";

class ProductsAndServicesSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsAndServices: [],
      loading: true,
      openAddModal: false,
      newProductAndService: {}
    };
    this.getProductsAndServices = this.getProductsAndServices.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  componentDidMount() {
    this.getProductsAndServices();
  }
  handleDelete(data) {
    deleteProductAndService(data, (error, response) => {
      if (error != null) {
        toast.error("Failed to delete product and service");
        return;
      }
      let updatedProductAndServiceIndex = _.findIndex(
        this.state.productsAndServices,
        { ID: data.ID }
      );
      let temp = [...this.state.productsAndServices];
      temp.splice(updatedProductAndServiceIndex, 1);
      this.setState({ productsAndServices: temp });

      toast.success("Successfully deleted product and service");
    });
  }
  handleSave(data) {
    if (!data.item || !data.price) {
      toast.info("Please fill in the required fields");
      return;
    }
    updateProductAndService(data, (error, response) => {
      if (error) {
        toast.error("Failed to update product and service");
        return;
      }
      let updatedProductAndServiceIndex = _.findIndex(
        this.state.productsAndServices,
        { ID: data.ID }
      );
      let temp = [...this.state.productsAndServices];
      temp.splice(updatedProductAndServiceIndex, 1, data);
      this.setState({ productsAndServices: temp });

      toast.success("Successfully updated product and service");
    });
  }
  handleCreate() {
    let { newProductAndService } = this.state;
    if (!newProductAndService.item || !newProductAndService.price) {
      toast.info("Please fill in the required fields");
      return;
    }
    createProductAndService(newProductAndService, (error, response) => {
      if (error) {
        toast.error("Failed to create product and service");
        return;
      }
      toast.success("Successfully create product and service");
      this.setState({
        productsAndServices: [
          ...this.state.productsAndServices,
          { ...newProductAndService, ID: response.ID }
        ]
      });
      this.closeAddModal();
    });
  }
  getProductsAndServices() {
    getProductsAndServices(null, (error, response) => {
      if (error) {
        toast.error("Failed to retrieve products and services");
        this.setState({ loading: false });
      } else {
        this.setState({ productsAndServices: response, loading: false });
      }
    });
  }
  openAddModal = () => {
    this.setState({ openAddModal: true });
  };
  closeAddModal = () => {
    this.setState({ openAddModal: false, newProductAndService: {} });
  };
  updateNewProductsAndServices = data => {
    this.setState({ newProductAndService: data });
  };
  render() {
    let {
      loading,
      productsAndServices,
      productAndService,
      openAddModal
    } = this.state;

    let categories = _.uniqBy(productsAndServices, "category");
    categories = _.map(categories, "category");

    return (
      <div>
        <Menu text stackable className="responsive-header">
          <Menu.Item>
            <Header as="h2">Products and Services</Header>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Modal
                open={openAddModal}
                trigger={
                  <Button
                    fluid
                    color="blue"
                    content="Add Product or Service"
                    onClick={this.openAddModal}
                  />
                }
              >
                <Modal.Header>New Product or Service</Modal.Header>
                <Modal.Content>
                  <ProductsAndServicesForm
                    categories={categories}
                    productAndService={productAndService}
                    onUpdate={this.updateNewProductsAndServices}
                    hideSaveButton
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content="Create"
                    color="green"
                    onClick={this.handleCreate}
                  />
                  <Button content="Cancel" onClick={this.closeAddModal} />
                </Modal.Actions>
              </Modal>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Divider hidden fitted clearing />
        <ReactTable
          loading={loading}
          data={productsAndServices}
          minRows={10}
          pageSize={productsAndServices.length}
          columns={columns}
          showPagination={false}
          className="-striped -highlight"
          style={{
            height: window.innerHeight - 175 // This will force the table body to overflow and scroll, since there is not enough room
          }}
          SubComponent={row => {
            return (
              <div>
                <ProductsAndServicesForm
                  categories={categories}
                  productAndService={row.original}
                  handleSave={this.handleSave}
                  handleDelete={this.handleDelete}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default ProductsAndServicesSettings;

const columns = [
  {
    Header: "Item",
    accessor: "item"
  },
  {
    Header: "Category",
    accessor: "category"
  },
  {
    Header: "Price",
    accessor: "price",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "AddOn Price",
    accessor: "addonPrice",
    className: "money-column",
    Cell: props => (props.value ? <Money value={props.value} /> : "")
  },
  {
    Header: "Description",
    accessor: "description"
  },
  {
    Header: "Customer Note",
    accessor: "customerNote"
  }
];
