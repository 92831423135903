import React, { Component } from "react";
import {
  Header,
  Icon,
  Form,
  Segment,
  Message,
  Button,
  Grid,
  Divider,
  Statistic,
  List,
  Container,
  Modal
} from "semantic-ui-react";
import EstimateConfirmation from "../Shared/EstimateConfirmation";
import Carousel from "../Shared/Carousel/Carousel";
import GratuitySlider from "../Shared/GratuitySlider";
import Image from "../Shared/Image";
import Signature from "../Shared/Signature";
import CustomerThankYou from "../Shared/CustomerThankYou";
import Axios from "axios";
import ReceiptOptions from "../Shared/ReceiptOptions";
import Money from "../Shared/Money";
import { Route } from "react-router-dom";
import {
  completeAppointment,
  getCustomerDefaultGratuity,
  createTransactionForAppointment,
  sendReceipt,
  completeAppointmentWithoutCustomer,
  getUserBalance
} from "../lib/apiCalls";
import Transaction from "./Transaction";
import { defaultTransaction } from "../lib/defaultObjects";
import { toast } from "react-toastify";
import { OptionsContext } from "../OptionsContext";
import _ from "lodash";
import { HoverButtonContext } from "../HoverButtonContext";
import { getToken } from "../lib/csrfToken";
import ConfirmTransactionBalanceModal from "../Shared/ConfirmTransactionBalanceModal";

class CustomerSignOff extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    let defaultTransactionReason = _.find(
      props.transactionsReasons,
      ({ reason }) => reason.includes("Service")
    );

    this.state = {
      receiptInformation: null,
      signatureAccepted: false,
      gratuity: 0,
      gratuityMethod: "",
      defaultGratuityPercent: 0,
      defaultGratuityDollar: null,
      transaction: defaultTransaction({
        reasonId: defaultTransactionReason && defaultTransactionReason.ID
      }),
      images: [],
      loading: true,
      view: "payment",
      receipt: null,
      completingAppointment: false,
      transactionId: null,
      modalOpen: false
    };
    this.handleCompleteAppointment = this.handleCompleteAppointment.bind(this);
    this.handleReceipt = this.handleReceipt.bind(this);
    this.getPicturePaths = this.getPicturePaths.bind(this);
    this.acceptSignature = this.acceptSignature.bind(this);
    this.getDefaultPercent = this.getDefaultPercent.bind(this);
    this.timer = 0
  }

  async componentDidMount() {
    this._isMounted = true;

    let { match } = this.props;
    if (!match || !match.params || !match.params.appointmentId) {
      this.setState({ loading: false });
      return;
    }
    if (!(await this.needsSignoff())) {
      this.setState({ loading: false });
      return;
    }

    this.getPicturePaths();
    this.getDefaultPercent();
    this.getUserBalance();

    this.setState({ loading: false });
    window.addEventListener("popstate", this.handleBack, false);
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("popstate", this.handleBack, false);
    // Display the hover button to allow the employee to continue
    this.props.setHoverButton({
      visible: false,
      icon: null,
      labelPosition: undefined,
      content: "Empty",
      onClick: () => {},
      circular: true,
      color: "grey"
    });
  }
  handleBack = event => {
    if (event.state) {
      if (this.state.view === "customerSigning") {
        this.setState({ view: "payment" });
      } else if (this.state.view === "thank") {
        this.setState({ view: "payment" });
      }
    }
  };
  async acceptSignature(file) {
    let { match, location } = this.props;
    if (!match || !match.params || !match.params.appointmentId || !location) {
      toast.error(
        "Appointment information missing. Please try refreshing your page or saving the estimate and try again."
      );
      return;
    }
    if (!(await this.needsSignoff())) {
      return;
    }

    let data = new FormData();
    data.append("appointmentId", match.params.appointmentId);
    data.append("file", file, "signature");

    try {
      // Send signature to server with the appointment id
      await Axios.post("/api/v4/appointments/uploadSignature", data, {
        headers: {
          "X-CSRF-Token": getToken(),
          "Content-Type": "multipart/form-data"
        }
      });
      if (this._isMounted) {
        this.setState({ signatureAccepted: true });
      }
    } catch (error) {
      if (this._isMounted) {
        console.error(error);
        toast.error("Failed to save signature");
      }
    }
  }
  getDefaultPercent() {
    let { location } = this.props;
    if (!location || !location.state) return;
    getCustomerDefaultGratuity(
      { userId: location.state.user.ID },
      (error, response) => {
        if (this._isMounted) {
          if (response) {
            let serviceTotal = location.state.estimate
              ? location.state.estimate.totalFee
              : 0;
            // let gratuity =
            //   parseFloat(response.defaultGratuityPercent || 0) *
            //   0.01 *
            //   parseFloat(serviceTotal);
            // if (response.defaultGratuityDollar != null) {
            //   gratuity = parseFloat(response.defaultGratuityDollar).toFixed(2);
            // }
            let gratuity = parseFloat(response.defaultGratuityDollar || 0).toFixed(2);
            if ( gratuity < 0.01 && response.defaultGratuityPercent != null) {
              gratuity = parseFloat(response.defaultGratuityPercent || 0) *
              0.01 *
              parseFloat(serviceTotal);
            }
            this.setState(state => ({
              defaultGratuityPercent: response.defaultGratuityPercent || 0,
              gratuity: parseFloat(gratuity || 0).toFixed(2)
            }));
          }
        }
      }
    );
  }
  needsSignoff = async () => {
    this.setState({ loading: true });
    let { match } = this.props;
    if (!match || !match.params || !match.params.appointmentId) {
      return true;
    }

    // Perform check on appointment
    // If the appointment is not in progress or
    // There is a transaction associated to the appointment
    // The user should not stay on this screen
    try {
      // Send signature to server with the appointment id
      let response = await Axios.get("/api/v4/appointments/signoffCheck", {
        params: {
          appointmentId: match.params.appointmentId
        },
        headers: {
          "X-CSRF-Token": getToken()
        }
      });
      if (response.data && response.data.error === true) {
        this.setState({
          redirectModalMessage: response.data.message
        });
        this.setState({ loading: false });
        return false;
      } else {
        this.setState({ loading: false });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  };
  /**
   * Calls the server to handle the receipt delivery method based on customer selections
   */
  handleReceipt(method, value) {
    // Clear previous timer if user changes receipt
    clearInterval(this.timer)
    // Send the receipt method to the server to allow the receipt for the transaction to be sent
    this.setState({
      receiptInformation: { method: method, value: value }
    });

    // Call api function to set appointment status to completed
    sendReceipt(
      { transactionId: this.state.transactionId, method: method, value: value },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            console.error(error);
            return;
          }
          this.setState({
            completeAppointment: true,
            completingAppointment: false,
            view: "thankYou"
          });
        }
      }
    );

    
    let count = 4
    const buttonProps = {
      visible: true,
      icon: "right arrow",
      labelPosition: "right",
      content: <>Continue as Employee ({count + 1})</>,
      onClick: () => null,
      circular: true,
      color: "grey"
    }
    const onClick = () => {
      clearInterval(this.timer)
      this.props.setHoverButton({
        ...buttonProps,
        loading: true,
        disabled: true
      });
      this.handleCompleteAppointment()
    }
    this.props.setHoverButton(buttonProps);
    this.timer = setInterval(() => {
      // Display the hover button to allow the employee to continue
      if (count) {
        this.props.setHoverButton({
          ...buttonProps,
          content: <>Continue as Employee ({count})</>,
          onClick,
        });
        count--;
      } else {
        onClick()
      }
    }, 1000)
  }
  /**
   * Submit the customer signing and transaction to the server.
   */
  handleCustomerSubmission = async () => {
    let { match, location } = this.props;
    if (!match || !match.params || !match.params.appointmentId || !location) {
      toast.error(
        "Appointment information missing. Please try refreshing your page or saving the estimate and try again."
      );
      return;
    }

    let { gratuity, gratuityMethod } = this.state;
    // Return when there is no gratuity method specified for the gratuity
    if (!gratuityMethod && gratuity > 0) {
      toast.info("A gratuity method is required when a gratuity is specified");
      return;
    }

    // Call the submit appointment transaction
    if (this.state.completingAppointment === true) {
      console.warn("Trying to complete an appointment already in progress");
      return;
    }

    if (!(await this.needsSignoff())) {
      return;
    }

    let { estimate } = location.state;
    let transactionData = { ...this.state.transaction };

    if (estimate) {
      // Add services and breakdown to transaction
      transactionData.serviceFee = estimate.totalFee;
      transactionData.serviceFeeFuel = estimate.fuelFee;
      transactionData.serviceFeeGroom = estimate.serviceFee;
      transactionData.serviceFeeGenerator =
        estimate.needsGenerator === true ? estimate.generatorFee : 0;
      transactionData.serviceFeeSalesTax = estimate.salesTaxFee;
      transactionData.serviceFeeTrip = estimate.tripFee;
      transactionData.serviceDiscountTotal = estimate.discounts;
    }

    switch (gratuityMethod) {
      case "cash":
        transactionData.cashAmount =
          parseFloat(transactionData.cashAmount) + parseFloat(gratuity);
        break;
      case "creditCard":
        transactionData.creditCardAmount = parseFloat(
          (
            parseFloat(transactionData.creditCardAmount) + parseFloat(gratuity)
          ).toFixed(2)
        );
        break;
      case "check":
        transactionData.checkAmount =
          parseFloat(transactionData.checkAmount) + parseFloat(gratuity);
        break;
      case "giftCard":
        transactionData.giftCardAmount =
          parseFloat(transactionData.giftCardAmount) + parseFloat(gratuity);
        break;
      case "credit":
        break;
      default:
        break;
    }
    this.setState({ completingAppointment: true });

    // Call api function to set appointment status to completed
    createTransactionForAppointment(
      {
        appointmentId: match.params.appointmentId,
        transaction: {
          ...this.state.receiptInformation,
          ...transactionData,
          gratuity: gratuity
        }
      },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            console.error(error);
            toast.error(
              "An error occured while processing your payment. Please hand the device back to the employee...\n" +
                (typeof error === "string" ? error : ""),
              { autoClose: 10000 }
            );
            this.setState({
              completingAppointment: false
            });
            return;
          }
          this.setState({
            completeAppointment: true,
            completingAppointment: false,
            view: "thankYou",
            transactionId: response.transactionId
          });
        }
      }
    );
  };

  /**
   * Set the appointment to a completed status and redirect to the stylist route
   */
  handleCompleteAppointment() {
    // Skip the function if it is still trying to complete it
    if (this.state.completingAppointment) return;
    let { match, location } = this.props;
    if (!match || !match.params || !match.params.appointmentId || !location) {
      toast.error(
        "Appointment information missing. Please try refreshing your page or saving the estimate and try again."
      );
      return;
    }

    this.setState({ completingAppointment: true });

    // Call api function to set appointment status to completed
    completeAppointment(
      {
        appointmentId: match.params.appointmentId
      },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            toast.error(error);
            this.setState({
              completingAppointment: false
            });
            return;
          }
          this.setState({
            completeAppointment: true,
            completingAppointment: false,
            view: "thankYou"
          });
          toast.success("Successfully completed appointment");
          this.handleClose();
        }
      }
    );
  }

  // Check if the transaction charges and payment are equal so the user can be prompted if necessary
  handleTransactionBalance = () => {
    let estimate = this.props.location
      ? this.props.location.state.estimate
      : {};
    let { transaction } = this.state;

    // Include gratuity if the customer is absent, otherwise it is hidden from employee
    let gratuity = 0;
    if (this.isCustomerAbsent()) {
      gratuity = parseFloat(this.state.gratuity || 0);
    }

    let totalCharges = parseFloat(estimate.totalFee || 0) + gratuity;
    let totalPaid =
      parseFloat(transaction.cashAmount || 0) +
      parseFloat(transaction.creditCardAmount || 0) +
      parseFloat(transaction.checkAmount || 0) +
      parseFloat(transaction.giftCardAmount || 0);
    let transactionBalance =
      parseFloat(totalCharges || 0) - parseFloat(totalPaid || 0);

    if (parseFloat(transactionBalance) !== 0) {
      this.setState({ modalOpen: true });
    } else if (this.isCustomerAbsent()) {
      this.handleCompleteAppointmentWithoutCustomer();
    } else {
      this.handlePaymentContinue();
    }
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };
  isCustomerAbsent = () => {
    let customerAbsentSignoff = false;
    if (
      this.props.location &&
      this.props.location.pathname.includes(
        "appointmentCompletionWithoutCustomer"
      )
    ) {
      customerAbsentSignoff = true;
    }
    return customerAbsentSignoff;
  };
  handleContinue = () => {
    if (this.isCustomerAbsent()) {
      this.handleCompleteAppointmentWithoutCustomer();
    } else {
      this.handlePaymentContinue();
    }
    this.setState({ modalOpen: false });
  };
  handleCompleteAppointmentWithoutCustomer = async () => {
    let { match, location } = this.props;
    if (!match || !match.params || !match.params.appointmentId || !location) {
      toast.error(
        "Appointment information missing. Please try refreshing your page or saving the estimate and try again."
      );
      return;
    }

    if (!(await this.needsSignoff())) {
      return;
    }

    this.setState({ completingAppointment: true });

    let { estimate } = location.state;
    let { gratuity } = this.state;
    let transactionData = { ...this.state.transaction };

    if (estimate) {
      // Add services and breakdown to transaction
      transactionData.serviceFee = estimate.totalFee;
      transactionData.serviceFeeFuel = estimate.fuelFee;
      transactionData.serviceFeeGroom = estimate.serviceFee;
      transactionData.serviceFeeGenerator =
        estimate.needsGenerator === true ? estimate.generatorFee : 0;
      transactionData.serviceFeeSalesTax = estimate.salesTaxFee;
      transactionData.serviceFeeTrip = estimate.tripFee;
      transactionData.serviceDiscountTotal = estimate.discounts;
    }

    // Call api to save the transaction for the appointment before completing the appointment
    createTransactionForAppointment(
      {
        appointmentId: match.params.appointmentId,
        transaction: {
          ...this.state.receiptInformation,
          ...transactionData,
          gratuity: gratuity
        }
      },
      (error, response) => {
        if (this._isMounted) {
          if (error != null) {
            toast.error(
              "An error occured while processing payment.\n" +
                (typeof error === "string" ? error : ""),
              {
                autoClose: 10000
              }
            );
            this.setState({
              completingAppointment: false
            });
            return;
          }

          // Call api function to set appointment status to completed
          completeAppointmentWithoutCustomer(
            {
              appointmentId: match.params.appointmentId
            },
            error => {
              if (error) {
                if (typeof error === "string") {
                  toast.error(error);
                }
                this.setState({
                  completingAppointment: false
                });
                return;
              }
              this.props.history.push("/dashboard");
              this.setState({
                completeAppointment: true,
                completingAppointment: false
              });
              toast.success("Successfully completed appointment");
            }
          );
          return;
        }
      }
    );
  };
  async getPicturePaths() {
    let { match } = this.props;
    if (!match || !match.params || !match.params.appointmentId) return;
    try {
      let response = await Axios.get("/api/v4/appointments/getPicturePaths", {
        params: {
          appointmentId: match.params.appointmentId,
          onlyCustomer: true
        }
      });
      if (this._isMounted) {
        if (response.status === 200) {
          // Check if files were returned in array
          if (response.data && Array.isArray(response.data)) {
            this.setState({ images: response.data });
          } else {
            this.setState({ images: [] });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  handleClose = () => {
    this.props.history.push("/dashboard");
    this.props.setHoverButton({ visible: false, onClick: () => {} });
  };
  onTransactionChange = (e, data) => {
    this.setState(state => ({
      transaction: { ...state.transaction, [data.name]: data.value }
    }));
  };
  handlePaymentContinue = () => {
    // Check that the transaction has valid payment methods
    let { transaction } = this.state;
    if (
      transaction.creditCardAmount > 0 &&
      !transaction.creditCardId &&
      !(
        transaction.nameOnCard &&
        transaction.cardNumber &&
        transaction.expiration &&
        transaction.securityCode &&
        transaction.billingZipcode
      )
    ) {
      toast.info(
        "A credit card must be entered when a credit amount is added."
      );
      return;
    }
    if (
      transaction.checkAmount > 0 &&
      !transaction.checkDate &&
      !transaction.checkNumber
    ) {
      toast.info(
        "A check number and date is required when a check amount is added."
      );
      return;
    }
    if (transaction.giftCardAmount > 0 && !transaction.giftCardNumber) {
      toast.info(
        "A gift card number is required when a gift card amount is added."
      );
      return;
    }
    this.setState({ view: "customerSigning" });
    this.props.history.push(
      this.props.location.pathname,
      this.props.location.state
    );
  };
  handleGratuity = (value, method) => {
    this.setState({
      gratuity: parseFloat(value).toFixed(2),
      gratuityMethod: method
    });
  };
  getUserBalance = () => {
    let { location } = this.props;
    let userId =
      location && location.state && location.state.user
        ? location.state.user.ID
        : null;
    if (!userId) return;
    this.setState({ loading: true });
    getUserBalance({ userId: userId }, (error, data) => {
      if (error) {
        toast.error("Failed to retrieve balance");
      }

      if (this._isMounted) {
        this.setState(state => ({
          openingBalance: data ? data.balance : undefined
        }));
      }
    });
  };
  redirect = () => {
    this.props.history.push("/dashboard");
  };
  render() {
    let { location } = this.props;
    let {
      receiptInformation,
      gratuity,
      gratuityMethod,
      defaultGratuityPercent,
      images,
      signatureAccepted,
      view,
      completingAppointment
    } = this.state;
    let transaction = { ...this.state.transaction };

    let customerAbsentSignoff = this.isCustomerAbsent();
    // If a user was passed in add the user details
    if (location.state) {
      let { user, appointment, estimate } = location.state;
      if (user) {
        var customerName = user.Name ? user.Name.firstName : "";
        var customerUserId = user.ID;
      }
      if (appointment) {
        // Create route calls for each picture
        var paths = images.map(
          fileName =>
            `/api/v4/appointments/getPicture?appointmentId=${encodeURI(
              appointment.ID
            )}&fileName=${encodeURI(fileName)}`
        );
      }
      if (estimate) {
        // Add services and breakdown to transaction
        transaction.serviceFee = estimate.totalFee;
        transaction.serviceFeeFuel = estimate.fuelFee;
        transaction.serviceFeeGroom = estimate.serviceFee;
        transaction.serviceFeeGenerator =
          estimate.needsGenerator === true ? estimate.generatorFee : 0;
        transaction.serviceFeeSalesTax = estimate.salesTaxFee;
        transaction.serviceFeeTrip = estimate.tripFee;
        transaction.serviceDiscountTotal = estimate.discounts;
      }
    }
    let acceptedGratuityMethods = [];
    if (parseFloat(transaction.creditCardAmount) > 0) {
      acceptedGratuityMethods.push("creditCard");
    }
    // Commenting out since no longer used but could be used in the future
    // if (transaction.cashAmount > 0) {
    //   acceptedGratuityMethods.push("cash");
    // }
    if (parseFloat(transaction.checkAmount) > 0) {
      acceptedGratuityMethods.push("check");
    }
    // Commenting out since no longer used but could be used in the future
    // if (openingBalance > 0 || transaction.creditAmount > 0) {
    //   acceptedGratuityMethods.push("credit");
    // }
    return (
      <Container>
        <Modal
          header="Sign-Off No Longer Needed"
          content={
            <Modal.Content>
              <strong>{this.state.redirectModalMessage}</strong>
              <br />
              <br />
              Because of this, signing is no longer required for this
              appointment.
            </Modal.Content>
          }
          open={this.state.redirectModalMessage != null}
          actions={[
            {
              key: "done",
              content: "Continue as Employee",
              positive: true,
              onClick: this.redirect
            }
          ]}
        ></Modal>
        <Segment loading={this.state.loading}>
          {customerAbsentSignoff && (
            <Header as="h2" textAlign="center">
              Complete Appointment - Absent Customer
            </Header>
          )}

          {/* Show payment options for the customer signoff */}
          {view === "payment" && (
            <>
              <Route
                render={props => (
                  <Transaction
                    key={gratuity}
                    {...props}
                    userId={customerUserId}
                    transaction={{
                      ...transaction,
                      gratuity: customerAbsentSignoff
                        ? gratuity
                        : transaction
                        ? transaction.gratuity
                        : 0
                    }}
                    freezeCharges
                    hideCreateButton
                    onChange={this.onTransactionChange}
                  />
                )}
              />
              <ConfirmTransactionBalanceModal
                open={this.state.modalOpen}
                onClose={this.closeModal}
                onContinue={this.handleContinue}
              />
              <Button
                type="button"
                content={
                  customerAbsentSignoff
                    ? "Complete Appointment"
                    : "Continue to Customer Signing"
                }
                color={completingAppointment === true ? "grey" : "green"}
                disabled={
                  transaction.reasonId === null &&
                  completingAppointment === true
                }
                onClick={this.handleTransactionBalance}
                loading={completingAppointment}
              />
            </>
          )}
          {/* Show sign-off page before the thank you page */}
          {view === "customerSigning" && (
            <>
              {/* Header with customer's name and avatar */}
              <Header as="h2" textAlign="center">
                <Image
                  bordered
                  circular
                  src={
                    "/api/v4/user/getProfilePicture?userId=" +
                    encodeURI(customerUserId)
                  }
                >
                  <Icon name="user" circular />
                </Image>
                Hello {customerName}!
              </Header>

              {/* Carousel of pictures from the appointment */}
              {paths && paths.length > 0 && (
                <Grid textAlign="center">
                  <Grid.Column width={6}>
                    <Carousel images={paths} />
                  </Grid.Column>
                </Grid>
              )}

              <Form>
                {/* Pet Detail cards from the estimate */}
                <EstimateConfirmation {...location.state} hideHeader />
                {/* Gratuity Slider */}
                <Segment>
                  <GratuitySlider
                    price={transaction.serviceFee}
                    defaultPercent={defaultGratuityPercent}
                    value={gratuity}
                    method={gratuityMethod}
                    acceptedGratuityMethods={acceptedGratuityMethods}
                    onChange={this.handleGratuity}
                  />
                </Segment>

                {/* Breakdown of the costs */}
                <Segment color="purple">
                  <Header content="Payment" dividing />
                  <List size="large" divided>
                    <List.Item>
                      Services
                      <List.Content floated="right">
                        <Money value={transaction.serviceFee} />
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      Gratuity
                      <List.Content floated="right">
                        <Money value={gratuity} />
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <strong>Total</strong>
                      <List.Content floated="right">
                        <Money
                          value={
                            parseFloat(transaction.serviceFee) +
                            parseFloat(gratuity)
                          }
                        />
                      </List.Content>
                    </List.Item>
                  </List>
                </Segment>

                {/* Signature */}
                <Signature
                  accepted={signatureAccepted}
                  onAccept={this.acceptSignature}
                />

                {/* Submit Button with redirect to thank you page at the end  */}
                <Button
                  type="button"
                  content="Submit"
                  onClick={this.handleCustomerSubmission}
                  loading={completingAppointment}
                  color={completingAppointment === true ? "grey" : "green"}
                  disabled={
                    completingAppointment === true || !signatureAccepted
                  }
                />
              </Form>
            </>
          )}
          {/* Show thank you page if finished submitting the sign off */}
          {view === "thankYou" && (
            <Grid textAlign="center" columns="1">
              <Grid.Column>
                {/* Thank you page with picture, total due, and receipt delivery preference */}
                <CustomerThankYou customerUserId={customerUserId} />
                <Divider />
                <Statistic size="small">
                  <Statistic.Value>
                    <Money
                      value={(
                        parseFloat(transaction.serviceFee) +
                        parseFloat(gratuity)
                      ).toFixed(2)}
                    />
                  </Statistic.Value>
                  <Statistic.Label>Total</Statistic.Label>
                </Statistic>
                <Divider />
                <ReceiptOptions
                  emailAddresses={
                    location.state && location.state.user
                      ? location.state.user.Emails
                      : null
                  }
                  phoneNumbers={
                    location.state && location.state.user
                      ? location.state.user.PhoneNumbers
                      : null
                  }
                  onSelect={this.handleReceipt}
                  disabled={receiptInformation != null}
                />
                <Message
                  hidden={receiptInformation == null}
                  floating
                  success
                  header="Appointment Signed For!"
                  list={[
                    "Your receipt will be delivered by the selected method after the transaction",
                    "Please hand the device back to your stylist"
                  ]}
                />
              </Grid.Column>
            </Grid>
          )}
        </Segment>
        <Divider section hidden />
      </Container>
    );
  }
}

CustomerSignOff.defaultProps = {
  setHoverButton: () => {},
  transactionsReasons: []
};

export default props => (
  <HoverButtonContext.Consumer>
    {({ setHoverButton }) => {
      return (
        <OptionsContext.Consumer>
          {({ transactionsReasons }) => (
            <CustomerSignOff
              {...props}
              setHoverButton={setHoverButton}
              transactionsReasons={transactionsReasons}
            />
          )}
        </OptionsContext.Consumer>
      );
    }}
  </HoverButtonContext.Consumer>
);
