import React, { Component } from "react";

import { Segment, Divider, Header, Button, Modal } from "semantic-ui-react";
import ReactTable from "react-table";

import ErrorBoundary from "../Shared/ErrorBoundary";
import CreditCardDetails from "../Forms/CreditCardDetails";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  deleteCreditCard,
  getCreditCards,
  addNewCard,
  setPrimaryCreditCard
} from "../lib/apiCalls";
import moment from "moment";
import { validateZipCode, validateCreditCard } from "../lib/validation";

/**
 * Displays a table of credit cards. If authorized for edit user, shows buttons and fields
 * for adding, deleting and editing credit cards in the table.
 * @prop {boolean} isEditing Displays editing features
 * @prop {function} handleEdit Function called when a user edits existing the credit cards in the table
 */
class CreditCards extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      creditCards: [],
      newCreditCard: {}
    };
    this.handleCreate = this.handleCreate.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    let { match } = this.props;
    let userId = match ? match.params.userId : null;
    if (!userId) return;
    this.getCreditCards(userId);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  shouldComponentUpdate(prevProps, prevState) {
    return (
      !_.isEqual(prevProps, this.props) || !_.isEqual(prevState, this.state)
    );
  }
  getCreditCards(userId) {
    getCreditCards({ userId: userId }, (error, response) => {
      if (error) {
        toast.error("Failed to retrieve credit cards");
        return;
      }
      if (this._isMounted) {
        this.setState({
          creditCards: response,
          newCreditCard: {
            isPrimary: response.filter(card => card.isPrimary).length < 1
          }
        });
      }
    });
  }
  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  updateNewCreditCard = data => {
    this.setState({ newCreditCard: data });
  };
  handleDelete = creditCard => {
    deleteCreditCard({ ID: creditCard.ID }, (error, response) => {
      if (error) {
        toast.error("Failed to remove credit card");
        return;
      }
      let temp = [...this.state.creditCards];
      let index = _.findIndex(temp, { ID: creditCard.ID });
      if (index > -1) {
        temp.splice(index, 1);
      }
      this.setState({
        creditCards: temp,
        newCreditCard: {
          isPrimary: temp.filter(card => card.isPrimary).length < 1
        }
      });
      toast.success("Successfully removed credit card");
    });
  };
  handlePrimary = data => {
    setPrimaryCreditCard(data, (error, response) => {
      if (error) {
        console.error(error);
        toast.error("Failed to set primary card");
        return;
      }
      let updateCardIndex = _.findIndex(this.state.creditCards, {
        ID: data.ID
      });
      let temp = [...this.state.creditCards];
      temp.splice(updateCardIndex, 1, { ...data, isPrimary: true });

      this.setState({ addresses: temp });
      this.updatePrimary(data);

      toast.success("Successfully updated primary card");
    });
  };
  updatePrimary = card => {
    let temp = this.state.creditCards.map(stateCard => ({
      ...stateCard,
      isPrimary: stateCard.ID === card.ID
    }));
    this.setState({ creditCards: temp });
  };

  addCreditCard = card => {
    this.setState({ creditCards: [...this.state.creditCards, card] });
  };
  handleCreate() {
    let { match } = this.props;
    let {
      nameOnCard,
      cardNumber,
      expiration,
      securityCode,
      billingZipcode,
      isPrimary
    } = this.state.newCreditCard;

    if (!match || !match.params.userId) {
      toast.info("A user is required to create a credit card");
      return;
    }
    if (!nameOnCard) {
      toast.info("A name for the credit card is required.");
      return;
    }
    if (!cardNumber) {
      toast.info("A card number for the credit card is required.");
      return;
    }

    if (!validateCreditCard(cardNumber)) {
      toast.error("Credit card number is invalid");
      return;
    }
    if (!expiration || expiration === "") {
      toast.info("A expiration for the credit card is required.");
      return;
    }
    if (!securityCode || securityCode === "") {
      toast.info("A security code for the credit card is required.");
      return;
    }
    if (
      !billingZipcode ||
      billingZipcode === "" ||
      !validateZipCode(billingZipcode)
    ) {
      toast.info("A valid billing zipcode for the credit card is required.");
      return;
    }

    addNewCard(
      {
        userId: match.params.userId,
        isPrimary: isPrimary,
        nameOnCard: nameOnCard,
        cardNumber: cardNumber,
        expiration: expiration,
        securityCode: securityCode,
        billingZipcode: billingZipcode
      },
      (error, response) => {
        if (error) {
          toast.error("Failed to create new credit card");
          return;
        }
        this.addCreditCard({
          userId: match.params.userId,
          isPrimary: isPrimary,
          nameOnCard: nameOnCard,
          cardNumber: "******" + cardNumber.replace(/\D/g, "").substr(-4),
          expiration: expiration,
          ID: response.newCardId
        });
        this.setState({
          newCreditCard: {
            isPrimary:
              this.state.creditCards.filter(card => card.isPrimary).length < 1
          }
        });
        this.getCreditCards(match.params.userId);
        this.closeModal();
      }
    );
  }

  setPrimary = card => {
    let temp = this.state.creditCards.map(stateCard => ({
      ...stateCard,
      isPrimary: stateCard.ID === card.ID
    }));
    this.setState({ creditCards: temp });
  };
  openModal = () => {
    this.setState({ openNewCreditCardModal: true });
  };
  closeModal = () => {
    this.setState({ openNewCreditCardModal: false });
  };
  render() {
    let {
      creditCards,
      openNewCreditCardModal,
      loading,
      newCreditCard
    } = this.state;
    let { allowEditing, fullName } = this.props;

    return (
      <ErrorBoundary>
        <Segment>
          <Header as="h2">
            Credit Cards
            {allowEditing && (
              <Modal
                open={openNewCreditCardModal}
                onClose={this.closeModal}
                trigger={
                  <Button
                    color="blue"
                    floated="right"
                    content="Add Credit Card"
                    onClick={this.openModal}
                  />
                }
              >
                <Modal.Header>
                  New Credit Card {fullName ? " for " + fullName : ""}
                </Modal.Header>
                <Modal.Content>
                  <CreditCardDetails
                    creditCard={newCreditCard}
                    hideActionButtons
                    onUpdate={this.updateNewCreditCard}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content="Create"
                    color="green"
                    onClick={this.handleCreate}
                  />
                  <Button content="Cancel" onClick={this.closeModal} />
                </Modal.Actions>
              </Modal>
            )}
          </Header>
          <Divider hidden />
          <ReactTable
            loading={loading}
            data={creditCards}
            minRows={4}
            collapseOnDataChange={false}
            pageSize={creditCards.length}
            columns={columns}
            showPagination={false}
            getTrProps={(state, rowInfo, column, instance) => {
              if (!rowInfo) {
                return {};
              }
              let cardExpired = false;
              let today = moment();
              let cardExpiration = moment(
                rowInfo.original.expiration,
                "MM/YY"
              ).endOf("month");
              if (cardExpiration.year() < today) {
                cardExpiration.add(100, "year");
              }
              cardExpired = today.isAfter(cardExpiration);
              return {
                className: cardExpired
                  ? "negative-row"
                  : rowInfo.original.isPrimary === true
                  ? "primary-row"
                  : ""
              };
            }}
            SubComponent={
              allowEditing
                ? row => {
                    return (
                      <Segment basic>
                        <CreditCardDetails
                          creditCard={row.original}
                          handleDelete={this.handleDelete}
                          handlePrimary={this.handlePrimary}
                        />
                      </Segment>
                    );
                  }
                : undefined
            }
          />
        </Segment>
      </ErrorBoundary>
    );
  }
}

CreditCards.defaultProps = {
  allowEditing: true
};

export default CreditCards;

const columns = [
  {
    Header: "Name on Card",
    accessor: "nameOnCard"
  },
  {
    Header: "Last Four Digits",
    accessor: "cardNumber"
  },
  {
    Header: "Expiration",
    accessor: "expiration"
  },
  {
    Header: "Billing Zipcode",
    accessor: "billingZipcode"
  }
];
