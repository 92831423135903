import React, { Component } from "react";

import { Header, Segment, Icon, Divider, Message } from "semantic-ui-react";
import { defaultEstimate } from "../lib/defaultObjects";

import io from "../socketConnection";
import { toast } from "react-toastify";
import _ from "lodash";

import AppointmentDetails from "../Forms/AppointmentDetails";
import {
  createAppointment,
  updateAppointment,
  onAddToWaitList,
  deleteAppointment,
  rescheduleAppointment,
  createAppointmentInstance,
  cancelAppointment,
  confirmAppointment
} from "../lib/apiCalls";
import { OptionsContext } from "../OptionsContext";
import PreviewEstimate from "./PreviewEstimate";

class AppointmentPreview extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      estimate: null,
      customer: null,
      disabledInstanceButton: false
    };
    this.loadEstimate = this.loadEstimate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCreateAppointment = this.handleCreateAppointment.bind(this);
    this.handleUpdateAppointment = this.handleUpdateAppointment.bind(this);
    this.handleRescheduleAppointment = this.handleRescheduleAppointment.bind(
      this
    );
    this.handleConfirmAppointment = this.handleConfirmAppointment.bind(this);
    this.handleAddToWaitList = this.handleAddToWaitList.bind(this);
    this.handleCreateInstance = this.handleCreateInstance.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let { estimateId } = this.props;
    if (estimateId) {
      this.loadEstimate(estimateId);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    // Load an estimate for an existing appointment if it wasn't loaded before
    if (prevProps.estimateId !== this.props.estimateId) {
      if (this.props.appointment && this.props.appointment.ID) {
        this.loadEstimate(this.props.estimateId);
      } else {
        this.setState({ estimate: null });
      }
    }
    if (prevProps && prevProps.appointment) {
      if (prevProps.appointment.ID !== this.props.appointment.ID) {
        this.setState({ disabledInstanceButton: false });
      } else if (
        prevProps.appointment.standingId !== this.props.appointment.standingId
      ) {
        this.setState({ disabledInstanceButton: false });
      }
    }
  }

  handleDelete(data) {
    deleteAppointment(
      { ...data, estimateId: this.props.estimateId },
      (error, response) => {
        if (!error) {
          this.props.onAppointmentAction();
        }
      }
    );
  }
  handleCancel(data) {
    cancelAppointment(data, (error, response) => {
      if (!error) {
        this.props.onAppointmentAction();
      }
    });
  }
  handleAddToWaitList(data) {
    onAddToWaitList(
      { ...data, estimateId: this.props.estimateId },
      (error, response) => {
        if (!error) {
          this.props.onAppointmentAction();
        }
      }
    );
  }
  handleUpdateAppointment(data) {
    updateAppointment(data, (error, response) => {
      if (error) {
        return;
      }
      if (response) {
        this.props.onAppointmentAction();
      }
    });
  }
  handleCreateAppointment(data) {
    createAppointment(
      { ...data, estimateId: this.props.estimateId },
      (error, response) => {
        if (error) {
          return;
        }
        if (response && response.newAppointmentId) {
          this.props.onAppointmentAction(response.newAppointmentId);
        }
      }
    );
  }
  handleRescheduleAppointment(data) {
    rescheduleAppointment(
      { ...data, estimateId: this.props.estimateId },
      (error, response) => {
        if (error) {
          return;
        }
        if (response) {
          this.props.onAppointmentAction();
        }
      }
    );
  }
  handleConfirmAppointment(data) {
    confirmAppointment({ ...data }, (error, response) => {
      if (error) {
        return;
      }
      if (response) {
        this.props.onAppointmentAction();
      }
    });
  }
  handleCreateInstance(data) {
    createAppointmentInstance(data, (error, response) => {
      if (error) {
        toast.error("Failed to save appointment");
        return;
      }
      this.setState({ disabledInstanceButton: true });
      toast.success("Successfully created instance");
      if (response) {
        this.props.onAppointmentAction(response.newAppointmentId);
      }
    });
  }

  loadEstimate(id) {
    this.setState({ loading: true });
    io.socket.get(
      "/api/v4/estimate/findById",
      { estimateId: id },
      (responseData, jwres) => {
        if (jwres.error) {
          console.error(jwres.error);
          toast.error("Failed to load estimate.");
          if (this._isMounted) {
            this.setState({ estimate: null, loading: false });
          }
          return;
        }
        if (this._isMounted) {
          let estimate = responseData.estimate;
          let customer = responseData.customer;
          if (jwres.statusCode === 200 && estimate) {
            if (estimate.EstimatePets) {
              for (let estimatePet in estimate.EstimatePets) {
                let services = {};
                for (let extra of estimate.EstimatePets[estimatePet]
                  .EstimatePetExtras) {
                  let foundService = _.find(this.props.services, {
                    ID: extra.EstimateExtraID
                  });
                  if (foundService) {
                    services[foundService.item] = foundService;
                  }
                }
                estimate.EstimatePets[estimatePet] = {
                  ...estimate.EstimatePets[estimatePet],
                  services: services
                };
              }
            }
            this.setState({
              loading: false,
              customer: customer,
              estimate: defaultEstimate({
                ...estimate,
                salesTax: this.props.salesTax,
                fuelCharge: this.props.fuelCharge,
                tripFee: this.props.tripFee,
                generatorFee: this.props.generatorFee
              })
            });
          }
        } else {
          toast.error("Failed to load estimate.");
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  render() {
    let { loading, estimate, customer } = this.state;
    let { appointment, requiredAppointmentFields, statuses } = this.props;
    if (loading) {
      return <Segment loading placeholder basic />;
    }

    let newAppointment = false;
    let appointmentStatus = appointment
      ? _.find(statuses, { ID: appointment.statusId })
      : null;
    let standingAppointment = appointment && appointment.isStanding === true;
    // Set new appointment flag
    if (
      (!appointment ||
        !appointment.ID ||
        (appointmentStatus && appointmentStatus.status === "waiting")) &&
      estimate
    ) {
      newAppointment = true;
    }

    if (!appointment && !estimate) {
      return (
        <Segment basic placeholder>
          <Header icon>
            <Icon name="calendar check" />
            Select an Appointment to See Details
          </Header>
        </Segment>
      );
    }

    let pets =
      estimate && estimate.PetEstimates
        ? estimate.PetEstimates.map(petEstimate => petEstimate.Pet)
        : [];
    return (
      <div>
        <Header
          as="h3"
          content={
            !newAppointment ? "Selected Appointment" : "Create New Appointment"
          }
          block
        />
        <AppointmentDetails
          required={requiredAppointmentFields}
          key={
            appointment
              ? appointment.ID || appointment.appointmentDate
              : undefined
          }
          pets={pets}
          customerId={customer ? customer.ID : null}
          columns={1}
          onDelete={this.handleDelete}
          onCancel={this.handleCancel}
          onReschedule={this.handleRescheduleAppointment}
          onConfirm={this.handleConfirmAppointment}
          onSave={
            appointment && appointment.ID != null
              ? this.handleUpdateAppointment
              : this.handleCreateAppointment
          }
          onAddToWaitList={this.handleAddToWaitList}
          onCreateInstance={this.handleCreateInstance}
          employeeId={customer ? customer.preferredStylistId : null}
          {...appointment}
          isEditing={!standingAppointment}
          disabledInstanceButton={this.state.disabledInstanceButton}
        />
        {standingAppointment && (
          <Message
            icon="info circle"
            header="Standing Appointment Placeholder"
            content="This appointment must be turned into an instance before editing information."
          ></Message>
        )}
        <Divider hidden />
        {estimate && (
          <PreviewEstimate
            estimate={estimate}
            customer={customer}
            appointmentId={appointment ? appointment.ID : null}
          />
        )}
      </div>
    );
  }
}

AppointmentPreview.defaultProps = {
  appointment: null,
  estimateId: null,
  onAppointmentAction: () => {},
  requiredAppointmentFields: undefined,
  statuses: []
};

export default props => (
  <OptionsContext.Consumer>
    {({ statuses }) => <AppointmentPreview {...props} statuses={statuses} />}
  </OptionsContext.Consumer>
);
