import React, { Component } from "react";
import { Form, Label, Divider, Button } from "semantic-ui-react";
import Input from "../Shared/Input";
import { getCreditCardType } from "../lib/helperFunctions";
import _ from "lodash";
import { defaultCreditCard } from "../lib/defaultObjects";

class CreditCardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultCreditCard(props.creditCard)
    };
    this.onExpirationChange = this.onExpirationChange.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state, prevState)) {
      this.props.onUpdate(this.state);
    }
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  onExpirationChange(e, data) {
    let value = data.value;
    if (data.value && data.value.length > 2) {
      value = value.replace(/\D+/g, "");
      value = `${value.substring(0, 2)}/${value.substring(2, 4)}`;
    }
    this.handleChange(e, { ...data, value: value });
  }
  handleDelete = () => {
    this.props.handleDelete(this.state);
  };
  handlePrimary = () => {
    this.props.handlePrimary(this.state);
  };
  handleToggle = (e, { name }) => {
    this.setState(state => ({
      [name]: !state[name]
    }));
  };
  render() {
    let {
      nameOnCard,
      cardNumber,
      expiration,
      securityCode,
      billingZipcode,
      isPrimary,
      ID
    } = this.state;
    let type = getCreditCardType(cardNumber);
    let { hideActionButtons } = this.props;

    let isEditing = ID != null ? false : true;

    return (
      <Form>
        <Button
          type="button"
          toggle
          disabled={!isEditing}
          content="Primary Card"
          name="isPrimary"
          active={isPrimary}
          onClick={this.handleToggle}
        />
        <Divider hidden />
        <Form.Group widths={4}>
          <Input
            label="Name on Card"
            name="nameOnCard"
            isEditing={isEditing}
            required={isEditing}
            value={nameOnCard}
            onChange={this.handleChange}
          />
          <Input
            label={
              <>
                Card Number{" "}
                {type && <Label color="blue" size="small" content={type} />}
              </>
            }
            name="cardNumber"
            format="creditCard"
            placeholder="0000 0000 0000 0000"
            validation="creditCard"
            maxLength={16}
            isEditing={isEditing}
            required={isEditing}
            value={cardNumber}
            onChange={this.handleChange}
          />
          <Input
            label="Expiration Date"
            name="expiration"
            isEditing={isEditing}
            required={isEditing}
            placeholder="MM/YY"
            value={expiration}
            onChange={this.onExpirationChange}
            maxLength={5}
          />
          {isEditing && (
            <Input
              label="Security Code"
              name="securityCode"
              isEditing
              required
              value={securityCode}
              onChange={this.handleChange}
              maxLength={4}
            />
          )}
          <Input
            label="Billing Zipcode"
            name="billingZipcode"
            isEditing={isEditing}
            validation="zipcode"
            required={isEditing}
            value={billingZipcode}
            onChange={this.handleChange}
            maxLength={10}
          />
        </Form.Group>
        {!hideActionButtons && (
          <>
            <Button
              color="green"
              content="Set as Primary"
              onClick={this.handlePrimary}
            />
            <Button color="red" content="Delete" onClick={this.handleDelete} />
          </>
        )}
      </Form>
    );
  }
}
CreditCardDetails.defaultProps = {
  onAddNewCard: () => {},
  handleDelete: () => {},
  handlePrimary: () => {},
  onUpdate: () => {}
};

export default CreditCardDetails;
