import React, { Component } from "react";
import ReactTable from "react-table";
import { Header, Button, Modal, Divider, Menu } from "semantic-ui-react";
import {
  getTodoCategories,
  createTodoCategory,
  deleteTodoCategory
} from "../../lib/apiCalls";
import { toast } from "react-toastify";
import _ from "lodash";
import TodoCategoryForm from "../../Forms/TodoCategoryForm";

class TodoCategorySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todoCategories: [],
      loading: true,
      openAddModal: false,
      newTodoCategory: {}
    };
    this.getTodoCategories = this.getTodoCategories.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  componentDidMount() {
    this.getTodoCategories();
  }
  handleDelete(data) {
    deleteTodoCategory(data, (error, response) => {
      if (error != null) {
        toast.error("Failed to delete todo category");
        return;
      }
      let updatedTodoCategoryIndex = _.findIndex(this.state.todoCategories, {
        ID: data.ID
      });
      let temp = [...this.state.todoCategories];
      temp.splice(updatedTodoCategoryIndex, 1);
      this.setState({ todoCategories: temp });

      toast.success("Successfully deleted todo category");
    });
  }
  handleCreate() {
    let { newTodoCategory } = this.state;
    if (!newTodoCategory.category) {
      toast.info("Please fill in the required fields");
      return;
    }
    createTodoCategory(newTodoCategory, (error, response) => {
      if (error) {
        toast.error("Failed to create todo category");
        return;
      }
      toast.success("Successfully create todo category");
      this.setState({
        todoCategories: [
          ...this.state.todoCategories,
          { ...newTodoCategory, ID: response.ID }
        ]
      });
      this.closeAddModal();
    });
  }
  getTodoCategories() {
    getTodoCategories(null, (error, response) => {
      if (error) {
        toast.error("Failed to retrieve todo categories");
        this.setState({ loading: false });
      } else {
        this.setState({ todoCategories: response, loading: false });
      }
    });
  }
  openAddModal = () => {
    this.setState({ openAddModal: true });
  };
  closeAddModal = () => {
    this.setState({ openAddModal: false, newTodoCategory: {} });
  };
  updateNewTodoCategory = data => {
    this.setState({ newTodoCategory: data });
  };
  render() {
    let { loading, todoCategories, newTodoCategory, openAddModal } = this.state;
    return (
      <div>
        <Menu text stackable className="responsive-header">
          <Menu.Item>
            <Header as="h2">Todo Categories</Header>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Modal
                open={openAddModal}
                trigger={
                  <Button
                    fluid
                    color="blue"
                    content="Add Todo Category"
                    onClick={this.openAddModal}
                  />
                }
              >
                <Modal.Header>New Todo Category</Modal.Header>
                <Modal.Content>
                  <TodoCategoryForm
                    todoCategory={newTodoCategory}
                    onUpdate={this.updateNewTodoCategory}
                    hideSaveButton
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content="Create"
                    color="green"
                    onClick={this.handleCreate}
                  />
                  <Button content="Cancel" onClick={this.closeAddModal} />
                </Modal.Actions>
              </Modal>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Divider hidden fitted clearing />
        <ReactTable
          loading={loading}
          data={todoCategories}
          minRows={todoCategories.length > 0 ? todoCategories.length : 4}
          pageSize={todoCategories.length}
          columns={columns}
          showPagination={false}
          className="-striped -highlight"
          style={{
            maxHeight: window.innerHeight - 175 // This will force the table body to overflow and scroll, since there is not enough room
          }}
          SubComponent={row => {
            return (
              <div>
                <TodoCategoryForm
                  todoCategory={row.original}
                  handleSave={this.handleSave}
                  handleDelete={this.handleDelete}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default TodoCategorySettings;

const columns = [
  {
    Header: "Category",
    accessor: "category"
  }
];
