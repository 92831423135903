import React, { Component } from "react";
import InProgressAppointment from "./InProgressAppointment";
import {
  Container,
  Tab,
  Divider,
  Menu,
  Label,
  Header,
  Icon,
  Segment,
  Message,
  Statistic,
} from "semantic-ui-react";

import io from "../socketConnection";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";

import { moneyFormat } from "../lib/format";

import { UserContext } from "../UserContext";
import ScheduleItem from "./ScheduleItem";
import { OptionsContext } from "../OptionsContext";

class StylistRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      loading: false,
    };
    this.getAppointments = this.getAppointments.bind(this);
    this.updateAppointment = this.updateAppointment.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getAppointments();
    this.subscribeToAppointmentUpdates();
  }

  componentWillUnmount() {
    // Stop watching for appointments
    io.socket.off(this.props.userId + "", this.updateAppointment);
  }
  async getAppointments() {
    io.socket.get(
      "/api/v4/profile/getAppointmentsByEmployee",
      null,
      (responseData, jwres) => {
        if (jwres.error) {
          console.error(jwres.error);
          toast.error("Failed to retrieve appointments.");
          this.setState({ loading: false });
          return;
        }
        if (jwres.statusCode === 200) {
          this.setState({
            appointments: _.sortBy(responseData, [
              function (o) {
                return o.startTime;
              },
            ]),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      }
    );
  }
  subscribeToAppointmentUpdates = () => {
    if (this.props.userId) {
      // Update the appointment in the list of appointments when one is updated
      io.socket.on(this.props.userId + "", this.updateAppointment);
    }
  };

  updateAppointment(data) {
    // Return if no appointment given
    if (!data.appointment) return;
    this.getAppointments();
  }
  compareStatuses = (statusId, checkStatuses) => {
    let { statuses } = this.props;
    let appointmentStatus = _.find(statuses, { ID: statusId });
    return checkStatuses.includes(appointmentStatus.status);
  };

  render() {
    let { loading, appointments } = this.state;
    let upcomingAppointments = [];
    let completedAppointments = [];
    let currentAppointment = {};

    // Separate the upcoming appointments and the completed appointments
    if (appointments && appointments.length > 0) {
      // Get upcoming appointments
      upcomingAppointments = appointments.filter((appointment) => {
        return this.compareStatuses(appointment.statusId, [
          "scheduled",
          "confirmed",
        ]);
      });

      upcomingAppointments = _.sortBy(upcomingAppointments, [
        function (o) {
          return moment(o.startTime, "h:mm A").toDate();
        },
      ]);

      // Get the current or next appointment
      currentAppointment = appointments.filter((appointment) => {
        return this.compareStatuses(appointment.statusId, ["inProgress"]);
      });
      if (!currentAppointment || currentAppointment.length < 1) {
        currentAppointment = upcomingAppointments.shift();
      } else {
        currentAppointment = currentAppointment[0];
      }

      // Get a list of completed appointments
      completedAppointments = appointments.filter((appointment) => {
        return this.compareStatuses(appointment.statusId, ["completed"]);
      });
      completedAppointments = _.sortBy(completedAppointments, [
        function (o) {
          return moment(o.startTime, "h:mm A").toDate();
        },
      ]);
      completedAppointments = _.reverse(completedAppointments);
    } else {
      // Just show a placeholder if no appointments are show for the day
      return (
        <Container>
          <Segment placeholder loading={loading}>
            {loading === true ? (
              <Header icon>Loading Appointments</Header>
            ) : (
              <Header icon>
                <Icon name="calendar times outline" />
                You are not scheduled for another appointment.
              </Header>
            )}
          </Segment>
        </Container>
      );
    }
    let commissionTotalCost = _.sumBy(completedAppointments, (a) => {
      if (a.Transactions && a.Transactions.length > 0) {
        return _.sumBy(a.Transactions, (t) => {
          return parseFloat(
            (
              parseFloat(
                t && t.serviceFeeGroom != null ? t.serviceFeeGroom : 0
              ) +
              parseFloat(
                t && t.serviceFeeGenerator != null ? t.serviceFeeGenerator : 0
              ) +
              parseFloat(t && t.cancelFee != null ? t.cancelFee : 0)
            ).toFixed(2)
          );
        });
      } else {
        return 0;
      }
    });
    let gratuityTotalCost = _.sumBy(completedAppointments, (a) => {
      if (a.Transactions && a.Transactions.length > 0) {
        return _.sumBy(a.Transactions, (t) => {
          return parseFloat(
            parseFloat(t && t.gratuity != null ? t.gratuity : 0).toFixed(2)
          );
        });
      } else {
        return 0;
      }
    });
    const panes = [
      {
        menuItem: (
          <Menu.Item key="Upcoming">
            Upcoming<Label>{upcomingAppointments.length}</Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane loading={loading}>
            {upcomingAppointments.length > 0 ? (
              upcomingAppointments.map((appointment) => {
                return (
                  <ScheduleItem
                    key={appointment.ID}
                    appointment={appointment}
                  />
                );
              })
            ) : (
              <Message
                icon="info circle"
                header="No Upcoming Appointments"
                content="You have completed or are on the last appointment scheduled for you today."
                info
              />
            )}
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="Completed">
            Completed<Label>{completedAppointments.length}</Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane loading={loading}>
            {completedAppointments.length > 0 ? (
              completedAppointments.map((appointment) => {
                return (
                  <ScheduleItem
                    key={appointment.ID}
                    appointment={appointment}
                    complete
                  />
                );
              })
            ) : (
              <Message
                icon="info circle"
                header="No Completed Appointments"
                content="You have not yet completed any appointments scheduled for you today."
                info
              />
            )}
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Container>
        {currentAppointment && currentAppointment.ID ? (
          <InProgressAppointment appointment={currentAppointment} />
        ) : (
          <Segment placeholder>
            <Header icon>
              <Icon name="calendar times outline" />
              No upcoming appointments available.
            </Header>
          </Segment>
        )}
        <Divider />
        <Statistic.Group widths="2" size="tiny">
          <Statistic>
            <Statistic.Value>
              <Icon fitted name="dollar" />
              {moneyFormat(commissionTotalCost)}
            </Statistic.Value>
            <Statistic.Label>Today's Commissionable</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>
              <Icon fitted name="dollar" />
              {moneyFormat(gratuityTotalCost)}
            </Statistic.Value>
            <Statistic.Label>Today's Gratuity</Statistic.Label>
          </Statistic>
        </Statistic.Group>
        <Divider />
        <Tab menu={{ widths: 2, attached: "top" }} panes={panes} />
      </Container>
    );
  }
}

StylistRoute.defaultProps = {
  appointments: [],
  statuses: [],
};

export default (props) => (
  <OptionsContext.Consumer>
    {({ statuses }) => {
      return (
        <UserContext.Consumer>
          {({ user }) => {
            return (
              <StylistRoute
                {...props}
                userId={user.userId}
                statuses={statuses}
              />
            );
          }}
        </UserContext.Consumer>
      );
    }}
  </OptionsContext.Consumer>
);
