import React, { Component } from "react";
import {
  Grid,
  Menu,
  Container,
  Header,
  Divider,
  Dropdown,
  Button,
  Icon,
  Segment
} from "semantic-ui-react";
import { Switch, Route, Link, Redirect } from "react-router-dom";

import EmployeeOverview from "./EmployeeOverview";

import AddressesTable from "../Tables/Addresses";
import PhoneNumbersTable from "../Tables/PhoneNumbers";
import EmailsTable from "../Tables/Emails";

import StylistDetails from "../Forms/StylistDetails";

import { getUserDetails } from "../lib/apiCalls";
import Authorize from "./Authorize";
import { toast } from "react-toastify";
import CreateAccountModal from "../Forms/CreateAccountModal";
import DeleteUserModal from "./DeleteUserModal";

class EmployeePage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      openDeleteUserModal: false,
      loading: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { match } = this.props;
    if (match && match.params.userId) {
      getUserDetails({ userId: match.params.userId }, (error, response) => {
        if (error) {
          toast.error("Failed to retrieve user");
          this.setState({ loading: false });
          return;
        }
        if (this._isMounted) {
          this.setState({ user: response, loading: false });
        }
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  closeDeleteUserModal = () => {
    this.setState({ openDeleteUserModal: false });
  };
  openDeleteUserModal = () => {
    this.setState({ openDeleteUserModal: true });
  };
  render() {
    let { user, openDeleteUserModal, loading } = this.state;
    let fullName = `${user.firstName || ""} ${user.lastName || ""}`;

    if (loading) {
      return (
        <Container>
          <Header as="h2">Loading Profile...</Header>
          <Segment placeholder loading padded="very"></Segment>
        </Container>
      );
    }
    if (user.IsDeleted) {
      return (
        <Container>
          <Segment placeholder>
            <Header icon>
              <Icon name="user delete" />
              {user ? fullName + "'s" : "This user's"} profile has been marked
              as deleted. Please contact an administrator if there are any
              issues.
            </Header>
          </Segment>
        </Container>
      );
    }
    let { location, match } = this.props;
    let pathname = location.pathname || "";

    let menuItems = [
      <Menu.Item
        header
        key="overview"
        name="overview"
        active={pathname.indexOf("overview") > 0}
        as={Link}
        to={`${match.url}/overview`}
      />,
      <Menu.Item key="information">
        <Menu.Header>Information</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            key="stylistInformation"
            name="stylistInformation"
            active={pathname.indexOf("stylistInformation") > 0}
            as={Link}
            to={`${match.url}/stylistInformation`}
          />
          <Menu.Item
            key="addresses"
            name="addresses"
            active={pathname.indexOf("addresses") > 0}
            as={Link}
            to={`${match.url}/addresses`}
          />
          <Menu.Item
            key="phoneNumbers"
            name="phoneNumbers"
            active={pathname.indexOf("phoneNumbers") > 0}
            as={Link}
            to={`${match.url}/phoneNumbers`}
          />
          <Menu.Item
            key="emailAddresses"
            name="emailAddresses"
            active={pathname.indexOf("emailAddresses") > 0}
            as={Link}
            to={`${match.url}/emailAddresses`}
          />
        </Menu.Menu>
      </Menu.Item>
    ];
    return (
      <Container>
        <Route
          render={props => (
            <DeleteUserModal
              {...props}
              open={openDeleteUserModal}
              onClose={this.closeDeleteUserModal}
              userId={user.ID}
            />
          )}
        />
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Authorize permission="ViewEmployees">
                <Menu text stackable className="responsive-header">
                  <Menu.Item>
                    <Header as="h2">{fullName}'s Profile</Header>
                  </Menu.Item>
                  <Authorize permission="EditUsers">
                    <Menu.Menu position="right">
                      {user.isAccount !== true && (
                        <Menu.Item>
                          <CreateAccountModal
                            userId={user.ID ? user.ID : null}
                          />
                        </Menu.Item>
                      )}
                    </Menu.Menu>
                    <Authorize permissions={["DangerousEditing"]}>
                      <Dropdown
                        trigger={
                          <Menu.Item>
                            <Button icon="ellipsis horizontal" />
                          </Menu.Item>
                        }
                        icon={null}
                        options={[
                          <Authorize
                            permission="DangerousEditing"
                            key="deleteUser"
                          >
                            <Dropdown.Item
                              onClick={this.openDeleteUserModal}
                              content="Delete User"
                            />
                          </Authorize>
                        ]}
                      />
                    </Authorize>
                  </Authorize>
                </Menu>
              </Authorize>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Menu fluid size="large" color="purple" pointing vertical>
                {menuItems}
              </Menu>
              <Divider hidden />
            </Grid.Column>
            <Grid.Column mobile={16} computer={13}>
              <Switch>
                <Route
                  path={`${match.path}/overview`}
                  component={EmployeeOverview}
                />
                <Route
                  path={`${match.path}/addresses`}
                  component={AddressesTable}
                />
                <Route
                  path={`${match.path}/phoneNumbers`}
                  component={PhoneNumbersTable}
                />
                <Route
                  path={`${match.path}/emailAddresses`}
                  component={EmailsTable}
                />
                <Route
                  path={`${match.url}/stylistInformation`}
                  render={props => (
                    <StylistDetails
                      {...props}
                      employeeId={user.Employee ? user.Employee.ID : null}
                      key={user.Employee ? user.Employee.ID : null}
                    />
                  )}
                />
                <Redirect to={`${match.path}/overview`} />
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default EmployeePage;
