import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Form,
  Button,
  Label,
  Segment,
  Header,
  Divider,
  Message,
  Icon
} from "semantic-ui-react";

import { OptionsContext } from "../OptionsContext";
import _ from "lodash";

import Authorize from "../Shared/Authorize";
import TinyEditButtonGroup from "../Shared/TinyEditButtonGroup";
import { updateCustomerDetails, getCustomerDetails } from "../lib/apiCalls";
import { toast } from "react-toastify";
import UserDetails from "../Shared/UserDetails";
import Input from "../Shared/Input";
import { checkIfValid } from "../lib/validation";

/**
 * Displays customer details such as senior citizen and military
 */
class CustomerDetails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isEditing: false,
      isSeniorCitizen: false,
      isMilitary: false,
      isTaxExempt: false,
      tripCharge: 0,
      defaultGratuityPercent: null,
      defaultGratuityDollar: null,
      contactMethodId: null,
      preferredStylistId: null,
      onWaitingList: false,
      hasRescheduled: false,
      User: {}
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // Get customer details
    this.getCustomerDetails();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  handleToggle = (e, { name }) => {
    this.setState(state => ({ [name]: !state[name] }));
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleUserChange = (e, { name, value }) => {
    this.setState(state => ({ User: { ...state.User, [name]: value } }));
  };
  handlePreferred = (e, data) => {
    this.handleChange(e, { ...data, value: data.name, name: "contactMethod" });
  };
  handleEdit = () => {
    this.setState({ isEditing: true });
  };
  handleCancel = () => {
    this.getCustomerDetails();
  };
  handleSave = () => {
    if (
      this.state.defaultGratuityDollar &&
      !checkIfValid("money", this.state.defaultGratuityDollar)
    ) {
      toast.info("Invalid dollar amount for default gratuity dollar");
      return;
    }
    if (
      this.state.tripCharge &&
      !checkIfValid("money", this.state.tripCharge)
    ) {
      toast.info("Invalid dollar amount for default trip charge");
      return;
    }

    updateCustomerDetails(
      {
        customerId: this.props.customerId,
        isSeniorCitizen: this.state.isSeniorCitizen,
        isMilitary: this.state.isMilitary,
        isTaxExempt: this.state.isTaxExempt,
        tripCharge: this.state.tripCharge,
        defaultGratuityPercent: this.state.defaultGratuityPercent,
        defaultGratuityDollar: this.state.defaultGratuityDollar,
        contactMethodId: this.state.contactMethodId,
        preferredStylistId: this.state.preferredStylistId,
        User: this.state.User
      },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            toast.error("Failed to save customer details");
            return;
          }
          toast.success("Updated customer details");
          this.setState({ isEditing: false });
        }
      }
    );
  };
  getCustomerDetails() {
    if (!this.props.customerId) {
      this.setState({ loading: false });
      return;
    }
    getCustomerDetails(
      { customerId: this.props.customerId },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            toast.error("Failed to retrieve customer details");
            this.setState({ loading: false });
            return;
          }
          this.setState({ ...response, isEditing: false, loading: false });
        }
      }
    );
  }
  render() {
    let {
      isSeniorCitizen,
      isMilitary,
      isTaxExempt,
      tripCharge,
      defaultGratuityPercent,
      defaultGratuityDollar,
      contactMethodId,
      preferredStylistId,
      User,
      isEditing,
      loading,
      onWaitingList,
      hasRescheduled
    } = this.state;
    return (
      <Segment loading={loading}>
        <Header floated="left">
          Customer Details{" "}
          {onWaitingList && <Label content="On Waiting List" color="yellow" />}
          {hasRescheduled && <Label content="Has Rescheduled" color="purple" />}
        </Header>
        <Authorize permission="EditCustomers">
          <TinyEditButtonGroup
            isEditing={isEditing}
            onSave={this.handleSave}
            onEdit={this.handleEdit}
            onCancel={this.handleCancel}
          />
        </Authorize>
        <Divider clearing />
        <UserDetails
          isEditing={isEditing}
          {...User}
          showUserType={false}
          handleChange={this.handleUserChange}
        />
        <Divider clearing />
        <OptionsContext.Consumer>
          {({ contactMethods, stylists }) => {
            let selectedStylist = _.find(stylists, { ID: preferredStylistId });
            return (
              <Form>
                <Form.Group widths={2}>
                  <Form.Field>
                    <label>Preferred Stylist</label>
                    {isEditing ? (
                      <Form.Dropdown
                        compact
                        search
                        selection
                        name="preferredStylistId"
                        value={preferredStylistId}
                        options={stylists.map(stylist => ({
                          key: stylist.ID,
                          text: `${stylist.User.firstName} ${stylist.User.lastName}`,
                          value: stylist.ID
                        }))}
                        onChange={this.handleChange}
                        placeholder="Select a Stylist"
                      />
                    ) : (
                      <div>
                        {selectedStylist
                          ? `${selectedStylist.User.firstName ||
                              ""} ${selectedStylist.User.lastName || ""}`
                          : "No preferred stylist"}
                      </div>
                    )}
                  </Form.Field>
                  <Input
                    label="Default Trip Charge"
                    name="tripCharge"
                    value={tripCharge}
                    onChange={this.handleChange}
                    attachedLabel="$"
                    validation={["positive", "money"]}
                    isEditing={isEditing}
                    labelPosition="left"
                    placeholder="Enter amount..."
                  />
                </Form.Group>
                <Authorize
                  permission="EditAppointments"
                  else={
                    <Form.Group widths={2}>
                      <Input
                        label="Default Gratuity Dollar"
                        name="defaultGratuityDollar"
                        attachedLabel="$"
                        value={defaultGratuityDollar}
                        isEditing={false}
                        labelPosition="left"
                        placeholder="Enter amount..."
                      />
                      <Input
                        label="Default Gratuity Percent"
                        name="defaultGratuityPercent"
                        attachedLabel="%"
                        value={defaultGratuityPercent}
                        isEditing={false}
                        labelPosition="right"
                        placeholder="Enter percent..."
                      />
                    </Form.Group>
                  }
                >
                  <Form.Group widths={2}>
                    <Input
                      label="Default Gratuity Dollar"
                      name="defaultGratuityDollar"
                      attachedLabel="$"
                      onChange={this.handleChange}
                      value={defaultGratuityDollar}
                      validation={["positive", "money"]}
                      isEditing={isEditing}
                      labelPosition="left"
                      placeholder="Enter amount..."
                    />
                    <Input
                      label="Default Gratuity Percent"
                      name="defaultGratuityPercent"
                      attachedLabel="%"
                      onChange={this.handleChange}
                      value={defaultGratuityPercent}
                      validation={["positive"]}
                      isEditing={isEditing}
                      labelPosition="right"
                      placeholder="Enter percent..."
                    />
                  </Form.Group>
                </Authorize>
                <Message size="tiny">
                  <Icon name="info" />
                  Default Gratuity Dollar is used when both the dollar and
                  percent are set and dollar is greater than $0.
                </Message>

                <Form.Group inline>
                  <label>Preferred Method of Contact</label>
                  {contactMethods != null
                    ? contactMethods.map(contactMethod => {
                        let name = contactMethod.method.toLowerCase();
                        return (
                          <Form.Radio
                            id={"contact-method-" + name}
                            key={contactMethod.ID}
                            label={contactMethod.method}
                            value={contactMethod.ID}
                            name="contactMethodId"
                            readOnly={contactMethod.ID === name}
                            disabled={
                              !isEditing && contactMethod.ID !== contactMethodId
                            }
                            checked={contactMethod.ID === contactMethodId}
                            onChange={this.handleChange}
                          />
                        );
                      })
                    : null}
                </Form.Group>

                {/* Show update button for customer */}
                {/* Show editable details for employee */}
                <Authorize
                  userRole="Customer"
                  else={
                    <>
                      <Button
                        disabled={!isEditing}
                        toggle
                        content="Senior Citizen"
                        name="isSeniorCitizen"
                        active={isSeniorCitizen}
                        onClick={this.handleToggle}
                      />
                      <Button
                        disabled={!isEditing}
                        toggle
                        content="Military"
                        name="isMilitary"
                        active={isMilitary}
                        onClick={this.handleToggle}
                      />
                      <Button
                        disabled={!isEditing}
                        toggle
                        content="Tax Exempt"
                        name="isTaxExempt"
                        active={isTaxExempt}
                        onClick={this.handleToggle}
                      />
                    </>
                  }
                >
                  {/* Military and Senior citizen */}
                  {isSeniorCitizen === true && (
                    <Label color="blue" content="Senior Citizen" />
                  )}
                  {isMilitary === true && (
                    <Label color="blue" content="Military" />
                  )}
                  {isTaxExempt === true && (
                    <Label color="blue" content="Tax Exempt" />
                  )}
                </Authorize>
              </Form>
            );
          }}
        </OptionsContext.Consumer>
      </Segment>
    );
  }
}

CustomerDetails.defaultProps = {
  onUpdate: () => {},
  customer: {},
  isEditing: false
};
CustomerDetails.propTypes = {
  onUpdate: PropTypes.func,
  customer: PropTypes.object,
  isEditing: PropTypes.bool
};
export default CustomerDetails;
