import React, { Component } from "react";
import ReactTable from "react-table";
import io from "../socketConnection";
import { toast } from "react-toastify";
import moment from "moment";
import { filterDateAfter } from "../lib/filters";
import {
  Icon,
  Popup,
  Header,
  Checkbox,
  Grid,
  Modal,
  List,
  Segment
} from "semantic-ui-react";
import _ from "lodash";
import { getToken } from "../lib/csrfToken";
import Authorize from "../Shared/Authorize";
import Axios from "axios";
import customFilter from "../lib/filters";

class AllComplaints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      showTerminated: false,
      selectedComplaint: null
    };
  }
  componentDidMount() {
    this.getComplaints();
  }
  getComplaints = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true });
    io.socket.get("/api/v4/complaints/findAll", {}, (body, jwr) => {
      if (jwr.statusCode !== 200) {
        toast.error("Failed to retrieve complaints");
        this.setState({ loading: false });
      } else {
        this.setState({
          data: body || [],
          loading: false
        });
      }
    });
  };

  handleToggleBlocking = async rowProps => {
    if (!rowProps.original) {
      toast.error(
        "Complaint not found. Please try refreshing your page and trying again"
      );
      return;
    }
    try {
      await Axios.post(
        "/api/v4/complaints/setBlocking",
        {
          complaintId: rowProps.original.ID,
          isBlocking: !rowProps.original.isBlocking
        },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );

      // Update the table's data to correctly show the value
      let temp = [...this.state.data];
      let index = _.findIndex(temp, { ID: rowProps.original.ID });
      if (index < 0) return;

      temp.splice(index, 1, {
        ...temp[index],
        isBlocking: !temp[index].isBlocking
      });
      this.setState({ data: temp });
    } catch (error) {
      console.error(error);
      toast.error("Failed to change complaint's blocking status");
    }
  };
  onToggle = (e, { name }) => {
    this.setState(state => ({ [name]: !state[name] }));
  };
  viewComplaint(data) {
    this.setState({ selectedComplaint: data });
  }
  getProps = (state, rowInfo, column) => {
    if (!rowInfo || typeof column.Header !== "string") {
      return {};
    }
    return {
      onClick: () => this.viewComplaint(rowInfo.original),
      style: { cursor: "pointer" }
    };
  };
  render() {
    let { data, loading, showTerminated, selectedComplaint } = this.state;
    return (
      <div>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">Complaints</Header>{" "}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Checkbox
                toggle
                label="Show Terminated"
                name="showTerminated"
                onChange={this.onToggle}
                checked={showTerminated}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={selectedComplaint !== null}
          closeIcon
          size="small"
          onClose={() => this.setState({ selectedComplaint: null })}
        >
          <Modal.Header>Complaint</Modal.Header>
          <Modal.Content>
            <Segment basic>
              <List divided relaxed>
                <List.Item>
                  <List.Content>
                    <List.Header>Customer</List.Header>
                    {selectedComplaint &&
                    selectedComplaint.Customer &&
                    selectedComplaint.Customer.User
                      ? `${selectedComplaint.Customer.User.firstName ||
                          ""} ${selectedComplaint.Customer.User.lastName || ""}`
                      : ""}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Employee</List.Header>
                    {selectedComplaint &&
                    selectedComplaint.Employee &&
                    selectedComplaint.Employee.User
                      ? `${selectedComplaint.Employee.User.firstName ||
                          ""} ${selectedComplaint.Employee.User.lastName || ""}`
                      : ""}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Complaint</List.Header>
                    {selectedComplaint && selectedComplaint.complaint}
                  </List.Content>
                </List.Item>
              </List>
            </Segment>
          </Modal.Content>
        </Modal>

        <ReactTable
          defaultSorted={[
            { id: "DateAdded", desc: true },
            { id: "employeeName" },
            { id: "customerName" }
          ]}
          data={
            showTerminated
              ? data
              : data.filter(complaint =>
                  complaint &&
                  complaint.Employee &&
                  complaint.Employee.dateTerminated
                    ? moment(
                        complaint.Employee.dateTerminated,
                        "MM-DD-YYYY"
                      ).isAfter(moment())
                    : true
                )
          }
          filterable
          defaultPageSize={20}
          defaultFilterMethod={customFilter}
          className="-striped -highlight"
          loading={loading}
          columns={[
            {
              Header: () => (
                <Popup
                  inverted
                  basic
                  position="top center"
                  content="Blocks assigning users to each other"
                  trigger={<Icon name="ban" />}
                />
              ),
              getProps: (state, rowInfo, column) => {
                return {
                  style: { textAlign: "center" }
                };
              },
              accessor: "isBlocking",
              filterable: false,
              width: 75,
              Cell: props => {
                return (
                  <Authorize
                    permission="EditComplaints"
                    else={props.value === true ? <Icon name="check" /> : null}
                  >
                    <Checkbox
                      toggle
                      checked={props.value === true}
                      onClick={() => this.handleToggleBlocking(props)}
                    />
                  </Authorize>
                );
              }
            },
            {
              Header: "Date Made",
              accessor: "DateAdded",
              filterMethod: filterDateAfter,
              getProps: this.getProps,
              Cell: props => moment(props.value).format("LLL")
            },
            {
              Header: "Employee",
              id: "employeeName",
              getProps: this.getProps,
              accessor: data => {
                let fullName =
                  data && data.Employee && data.Employee.User
                    ? `${data.Employee.User.firstName || ""} ${data.Employee
                        .User.lastName || ""}`
                    : "";
                return fullName;
              }
            },
            {
              Header: "Customer",
              id: "customerName",
              getProps: this.getProps,
              accessor: data => {
                let fullName =
                  data && data.Customer && data.Customer.User
                    ? `${data.Customer.User.firstName || ""} ${data.Customer
                        .User.lastName || ""}`
                    : "";
                return fullName;
              }
            },
            {
              Header: "Complaint",
              accessor: "complaint",
              minWidth: 250,
              getProps: this.getProps
            }
          ]}
        />
      </div>
    );
  }
}

export default AllComplaints;
