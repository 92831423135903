import React, { Component } from "react";

import ReactTable from "react-table";
import moment from "moment";

import _ from "lodash";
import { Header, Icon, Divider, Form } from "semantic-ui-react";
import PetList from "../Shared/PetList";

import customFilter, { filterIncludesPet, sortByPetName } from "../lib/filters";
import Input from "../Shared/Input";
import AppointmentHistoryPreview from "../Shared/AppointmentHistoryPreview";
import SidebarLayout from "../Shared/SidebarLayout";
import { removeMapsco } from "../lib/helperFunctions";
import DatePagination from "../Shared/DatePagination";
import { OptionsContext } from "../OptionsContext";
import io from "../socketConnection";
import { toast } from "react-toastify";

class AllAppointmentsHistory extends Component {
  constructor(props) {
    super(props);
    this.appointmentHistoryTable = null;
    this.state = {
      selectedRow: null,
      loading: false,
      data: [],
      activeDay: 0,
      appointmentDateFilter: moment().format("MM-DD-YYYY"),
      filtered: [],
    };
    this.onRowClick = this.onRowClick.bind(this);
  }
  componentDidMount() {
    // Check for a default selected appointment
    // Set the default filtered to that date and then find the appointment in the table to select that row
    let { location } = this.props;
    if (
      this.state.selectedRow === null &&
      location &&
      location.state &&
      location.state.defaultAppointmentDate
    ) {
      let date = moment(location.state.defaultAppointmentDate, "MM-DD-YYYY");
      let days = date.diff(moment().startOf("day"), "days");
      this.getAppointmentHistory(days);
      this.setState({
        activeDay: days,
        appointmentDateFilter: location.state.defaultAppointmentDate,
      });
    } else {
      this.getAppointmentHistory(0);
    }
  }
  // Check for a defualt appointment in case none was selected on mounting
  componentDidUpdate() {
    let { location } = this.props;
    if (
      this.state.selectedRow === null &&
      location &&
      location.state &&
      location.state.defaultAppointmentId
    ) {
      this.checkForDefaultAppointment(location.state.defaultAppointmentId);
    }
  }
  getAppointmentHistory = (activeDay) => {
    this.setState({ loading: true });
    io.socket.get(
      "/api/v4/appointments/getAllHistory",
      { date: moment().add(activeDay, "days") },
      (body, jwr) => {
        if (jwr.statusCode !== 200) {
          toast.error("Failed to retrieve appointment history.");
          this.setState({ loading: false });
        }
        this.setState({ data: body.rows, loading: false });
      }
    );
  };
  onRowClick(state, rowInfo, column) {
    if (rowInfo.original.ID) {
      this.setState({ selectedRow: rowInfo.original });
    }
  }
  getTrProps = (state, rowInfo, column, instance) => {
    let { selectedRow } = this.state;
    if (rowInfo != null) {
      var style = {};
      var classNames = " ";

      // Add stylist divider if sorted by appointment date then stylist or stylist first
      if (state && state.sorted && rowInfo.row) {
        // Sorted first is appointment date
        if (state.sorted[0] && state.sorted[0].id === "appointmentDate") {
          // sorted second is employeId
          if (state.sorted[1] && state.sorted[1].id === "employeeId") {
            // Check if next row has different employee
            let nextRow = state.pageRows[rowInfo.viewIndex + 1];
            if (nextRow && nextRow.employeeId !== rowInfo.row.employeeId) {
              classNames = classNames + "dividing-row ";
            }
          }
        } else if (
          // sorted first is employeeId
          state.sorted[0] &&
          state.sorted[0].id === "employeeId"
        ) {
          // Check if next row has different employee
          let nextRow = state.pageRows[rowInfo.viewIndex + 1];
          if (nextRow && nextRow.employeeId !== rowInfo.row.employeeId) {
            classNames = classNames + "dividing-row ";
          }
        }
      }
      // If the row has an ID, set the row to have a pointer to show it can be selected
      if (rowInfo.original.ID != null) {
        style.cursor = "pointer";
      }
      if (selectedRow && rowInfo.original.ID === selectedRow.ID) {
        classNames = classNames + "selected-row";
      }
      if (rowInfo.original.statusId) {
        let appointmentStatus = _.find(this.props.statuses, {
          ID: rowInfo.original.statusId,
        });
        classNames =
          classNames + " appointment-status-" + appointmentStatus.status;
      }
    }
    return {
      onClick: () => this.onRowClick(state, rowInfo, column),
      style: style,
      className: classNames,
    };
  };
  // Checks the currently visible data on the appointment table for the default appointment
  checkForDefaultAppointment = (defaultId) => {
    if (this.appointmentHistoryTable != null) {
      let foundDefaultAppointment = _.find(
        this.appointmentHistoryTable.getResolvedState().sortedData,
        (data) => data._original.ID === defaultId
      );
      if (foundDefaultAppointment != null) {
        this.setState({ selectedRow: foundDefaultAppointment._original });
      }
    }
  };
  updateDate = (e, { value }) => {
    let date = moment(value, "MM-DD-YYYY");
    let days = date.diff(moment().startOf("day"), "days");
    this.getAppointmentHistory(days);
    this.setState({
      activeDay: days,
      appointmentDateFilter: value,
    });
  };
  render() {
    const {
      selectedRow,
      activeDay,
      filtered,
      data,
      loading,
      appointmentDateFilter,
    } = this.state;
    let date = moment().add(activeDay, "days");
    let filteredAppointments = _.filter(data, {
      appointmentDate: date.format("MM-DD-YYYY"),
    });
    return (
      <SidebarLayout>
        <div>
          <Header as="h2" floated="left">
            Appointment History
          </Header>
          <Input
            validation="date"
            icon={false}
            placeholder="Date"
            isEditing
            dateFormat="MM-DD-YYYY"
            maxDate={moment()}
            value={appointmentDateFilter}
            name="appointmentDateFilter"
            onChange={this.updateDate}
          />
          <Header as="h2"></Header>
          <Divider hidden fitted clearing />
          <ReactTable
            ref={(ref) => (this.appointmentHistoryTable = ref)}
            loading={loading}
            getTheadThProps={(state, rowInfo, instance) => {
              return {
                className: "appointments-history-table-" + instance.id,
              };
            }}
            showPaginationTop={true}
            showPageSizeOptions={false}
            data={filteredAppointments}
            activeDay={activeDay}
            paginationProps={{ maxActiveDay: 0 }}
            page={0}
            minRows={15}
            pageSize={filteredAppointments.length}
            style={{
              height: window.innerHeight - 150, // This will force the table body to overflow and scroll, since there is not enough room
            }}
            defaultSorted={[
              { id: "appointmentDate" },
              { id: "employeeId" },
              { id: "startTime" },
            ]}
            onPageChange={(activeDay) => {
              let date = moment().add(activeDay, "days");

              this.setState({
                activeDay: activeDay,
                appointmentDateFilter: date.format("MM-DD-YYYY"),
              });
              this.getAppointmentHistory(activeDay);
            }}
            PaginationComponent={DatePagination}
            className="-striped -highlight appointment-table"
            // Add styling and other props to each row
            getTrProps={this.getTrProps}
            // Control filtering to set appointment date when page changes
            filterable
            filtered={filtered}
            defaultFilterMethod={customFilter}
            onFilteredChange={(filters, column) => {
              let currentFilter = _.find(filtered, {
                id: "appointmentDate",
              });
              let filter = _.find(filters, { id: "appointmentDate" });
              // Check if the appointment date was filtered to update active day
              if (filter && filter.value !== currentFilter.value) {
                let date = moment(filter.value, "MM-DD-YYYY");
                let days = date.diff(moment(), "days");

                this.setState({
                  activeDay: days + 1,
                });
              }
              this.setState({
                filtered: filters,
              });
            }}
            columns={[
              {
                Header: "Started",
                accessor: "timeStarted",
                Cell: (props) =>
                  props.value
                    ? moment(props.value, "h:mm A").format("h:mm A")
                    : null,
                minWidth: 70,
                filterable: false,
              },
              {
                Header: "Ended",
                accessor: "timeEnded",
                Cell: (props) =>
                  props.value
                    ? moment(props.value, "h:mm A").format("h:mm A")
                    : null,
                minWidth: 70,
                filterable: false,
              },
              {
                Header: "Stylist",
                id: "employeeId",
                headerClassName: "dropdown-filter",
                accessor: (data) => {
                  let fullName =
                    data && data.Employee && data.Employee.User
                      ? `${data.Employee.User.firstName || ""} ${
                          data.Employee.User.lastName || ""
                        }`
                      : `${data.EmployeeFirstName || ""} ${
                          data.EmployeeLastName || ""
                        }`;
                  return fullName;
                },
                Filter: ({ filter, onChange }) => (
                  <Form size="small">
                    <Form.Select
                      className="filter-select"
                      fluid
                      clearable
                      search
                      name="employeeId"
                      placeholder="Select Stylist"
                      options={this.props.stylists.map((stylist) => {
                        let fullName =
                          stylist && stylist.User
                            ? `${stylist.User.firstName || ""} ${
                                stylist.User.lastName || ""
                              }`
                            : `${stylist.EmployeeFirstName || ""} ${
                                stylist.EmployeeLastName || ""
                              }`;
                        return {
                          key: stylist.ID,
                          value: fullName,
                          text: fullName,
                        };
                      })}
                      value={filter ? filter.value : null}
                      onChange={(event, { name, value }) => onChange(value)}
                    />
                  </Form>
                ),
              },
              {
                Header: "Customer",
                id: "customerId",
                accessor: (data) =>
                  data.Customer &&
                  data.Customer.User &&
                  `${data.Customer.User.firstName} ${data.Customer.User.lastName}`,
              },
              {
                Header: "Status",
                accessor: "statusId",
                Cell: (props) => {
                  let appointmentStatus = _.find(this.props.statuses, {
                    ID: props.value,
                  });
                  return appointmentStatus
                    ? _.startCase(appointmentStatus.status)
                    : "";
                },
                minWidth: 50,
                getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      textAlign: "center",
                    },
                  };
                },
              },
              {
                Header: "S App",
                id: "standingId",
                accessor: (data) => data.standingId != null,
                filterable: false,
                minWidth: 30,
                Cell: (props) =>
                  props.value === true ? <Icon name="check" /> : <div />,
                getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      textAlign: "center",
                    },
                  };
                },
              },
              {
                Header: "Pets",
                id: "pets",
                accessor: (data) =>
                  data.Estimate && data.Estimate.EstimatePets
                    ? data.Estimate.EstimatePets.map(
                        (petEstimate) => petEstimate.Pet
                      )
                    : null,
                Cell: (props) => <PetList includeDetails pets={props.value} />,
                filterMethod: filterIncludesPet,
                sortMethod: sortByPetName,
              },
              {
                Header: "Mapsco",
                id: "estimateAddressMapsco",
                accessor: (data) =>
                  data.Estimate &&
                  data.Estimate.AddressString &&
                  removeMapsco(data.Estimate.AddressString).mapsco,
                minWidth: 60,
              },
              {
                Header: "Address",
                id: "estimateAddress",
                accessor: (data) =>
                  data.Estimate &&
                  data.Estimate.AddressString &&
                  removeMapsco(data.Estimate.AddressString).address,
              },
            ]}
          />
        </div>
        <AppointmentHistoryPreview
          appointment={selectedRow}
          key={selectedRow ? selectedRow.ID : undefined}
          requiredAppointmentFields={[
            "employeeId",
            "startTime",
            "appointmentDate",
          ]}
          estimateId={selectedRow ? selectedRow.Estimate.ID : null}
        />
      </SidebarLayout>
    );
  }
}

AllAppointmentsHistory.defaultProps = {
  onRowClick: null,
  stylists: [],
  statuses: [],
};

export default (props) => (
  <OptionsContext.Consumer>
    {({ stylists, statuses }) => (
      <AllAppointmentsHistory
        stylists={stylists}
        {...props}
        statuses={statuses}
      />
    )}
  </OptionsContext.Consumer>
);
