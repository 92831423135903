import React, { Component } from "react";
import { Grid, Segment } from "semantic-ui-react";

/** Renders three children in a grid format. The first child is the main view. The second child is a sidebar view on the right side. The third child is optional and is a sidebar view on the left side. */
class SidebarLayout extends Component {
  render() {
    let { children } = this.props;

    if (!children) {
      return null;
    }
    return (
      <Grid doubling stackable columns="equal">
        {children[2] && (
          <>
            {/* Display screen on computer */}
            <Grid.Column width={4} only="computer">
              <Segment>{children[2]}</Segment>
            </Grid.Column>
            {/* Remove sticky when grid is stacked */}
            <Grid.Column width={16} only="mobile tablet">
              <Segment>{children[2]}</Segment>
            </Grid.Column>
          </>
        )}
        <Grid.Column computer={children[2] ? 8 : 12} tablet={16}>
          {children[0] ? children[0] : null}
        </Grid.Column>
        <Grid.Column computer={4} tablet={16}>
          <Segment>{children[1] ? children[1] : null}</Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default SidebarLayout;
