import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "../Shared/Input";
import { Form, Dropdown } from "semantic-ui-react";
import { OptionsContext } from "../OptionsContext";

/**
 * Displays fields in an address format
 */
class Address extends Component {
  render() {
    const {
      readOnly,
      required,
      hideNeedsGenerator,
      handleChange,
      address,
      city,
      state,
      zipcode,
      needsGenerator,
      mapsco,
      mapscoCityId
    } = this.props;

    return (
      <OptionsContext.Consumer>
        {({ mapscoCities }) => {
          return (
            <>
              <Input
                required={required.indexOf("Address") > -1}
                isEditing={!readOnly}
                fluid
                label="Address"
                name="address"
                value={address}
                onChange={handleChange}
              />

              <Form.Group widths="equal">
                <Input
                  required={required.indexOf("City") > -1}
                  isEditing={!readOnly}
                  fluid
                  label="City"
                  name="city"
                  value={city}
                  onChange={handleChange}
                />

                <Input
                  required={required.indexOf("State") > -1}
                  isEditing={!readOnly}
                  transparent={readOnly}
                  fluid
                  label="State"
                  name="state"
                  value={state}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Input
                  required={required.indexOf("Zipcode") > -1}
                  isEditing={!readOnly}
                  validation="zipcode"
                  fluid
                  label="ZIP Code"
                  name="zipcode"
                  value={zipcode}
                  onChange={handleChange}
                />
                <Input
                  required={required.indexOf("Mapsco") > -1}
                  isEditing={!readOnly}
                  validation="mapsco"
                  fluid
                  label="Mapsco"
                  name="mapsco"
                  value={mapsco}
                  onChange={handleChange}
                  attachedLabel={
                    <Dropdown
                      placeholder="City"
                      selection
                      compact
                      disabled={readOnly}
                      name="mapscoCityId"
                      onChange={handleChange}
                      value={mapscoCityId}
                      options={mapscoCities.map(city => ({
                        text: city.city,
                        key: city.ID,
                        value: city.ID
                      }))}
                    />
                  }
                />
              </Form.Group>

              {!hideNeedsGenerator && (
                <Form.Checkbox
                  label="Needs Generator"
                  disabled={readOnly}
                  checked={needsGenerator}
                  onChange={(e, data) =>
                    handleChange(e, { ...data, value: data.checked })
                  }
                  name="needsGenerator"
                />
              )}
            </>
          );
        }}
      </OptionsContext.Consumer>
    );
  }
}

Address.defaultProps = {
  handleChange: () => {},
  required: [],
  readOnly: false,
  hideNeedsGenerator: false,
  address: "",
  city: "",
  state: "",
  zipcode: "",
  mapsco: ""
};

Address.propTypes = {
  handleChange: PropTypes.func,
  required: PropTypes.array,
  readOnly: PropTypes.bool,
  hideNeedsGenerator: PropTypes.bool,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipcode: PropTypes.string,
  mapsco: PropTypes.string
};

export default Address;
