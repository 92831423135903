import React, { Component } from "react";
import Authorize from "../Shared/Authorize";
import ErrorBoundary from "../Shared/ErrorBoundary";
import { Switch, Route, Redirect } from "react-router-dom";
import TransactionsTable from "../Tables/TransactionsTable";
import Transaction from "../Forms/Transaction";

class AllTransactions extends Component {
  render() {
    const { match } = this.props;
    return (
      <Authorize permission="ViewAllTransactions">
        <ErrorBoundary>
          <Switch>
            <Route
              exact
              path={`${match.path}/`}
              component={TransactionsTable}
            />
            <Route
              exact
              path={`${match.path}/new/user/:userId(\\d+)`}
              component={Transaction}
            />
            <Route
              exact
              path={`${match.path}/:transactionId(\\d+)/user/:userId(\\d+)`}
              component={Transaction}
            />
            <Route
              exact
              path={`${match.path}/:transactionId(\\d+)`}
              component={Transaction}
            />
            <Route exact path={`${match.path}/new`} component={Transaction} />
            <Redirect to={`${match.path}/`} />
          </Switch>
        </ErrorBoundary>
      </Authorize>
    );
  }
}

export default AllTransactions;
