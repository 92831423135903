import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Icon } from "semantic-ui-react";

/**
 * Modal that confirms canceling without changing
 * @param {object} props
 */
function ConfirmCancelModal(props) {
  return (
    <Modal open={props.open} onClose={props.handleCancel} size="small">
      <Modal.Header
        content={
          <>
            <Icon name="warning circle" />
            Warning: Unsaved Changes
          </>
        }
      />
      <Modal.Content content={props.content} />
      <Modal.Actions>
        <Button
          icon="chevron left"
          content="Cancel"
          onClick={props.handleCancel}
        />
        <Button
          icon="cancel"
          content="Continue without Saving"
          color="red"
          onClick={props.handleNo}
        />
        <Button
          icon="checkmark"
          content="Save and Continue"
          color="purple"
          onClick={props.handleSave}
        />
      </Modal.Actions>
    </Modal>
  );
}

ConfirmCancelModal.defaultProps = {
  open: false,
  content: null,
  handleCancel: () => {},
  handleNo: () => {},
  handleSave: () => {}
};

ConfirmCancelModal.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.any,
  handleCancel: PropTypes.func,
  handleNo: PropTypes.func,
  handleSave: PropTypes.func
};

export default ConfirmCancelModal;
