import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import Authorize from "./Authorize";

class TinyEditButtonGroup extends Component {
  render() {
    let {
      isEditing,
      onEdit,
      onSave,
      onCancel,
      onDelete,
      hideDelete,
      size
    } = this.props;
    return (
      <div>
        {isEditing !== true && (
          <Button
            floated="right"
            icon="pencil"
            basic
            toggle
            active={isEditing}
            onClick={onEdit}
            color="grey"
            size={size}
          />
        )}
        {isEditing === true && (
          <Button
            floated="right"
            icon="cancel"
            toggle
            basic
            active={isEditing}
            onClick={onCancel}
            color="grey"
            size={size}
          />
        )}
        {isEditing === true && (
          <Button
            floated="right"
            icon="save"
            toggle
            active
            onClick={onSave}
            color="green"
            size={size}
          />
        )}

        <Authorize permission="DangerousEditing">
          {isEditing === true && hideDelete !== true && onDelete && (
            <Button
              className="tiny-delete"
              floated="right"
              icon="trash"
              onClick={onDelete}
              color="red"
              size={size}
            />
          )}
        </Authorize>
      </div>
    );
  }
}

TinyEditButtonGroup.defaultProps = {
  size: "tiny"
};

export default TinyEditButtonGroup;
