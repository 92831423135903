import React from "react";
import PropTypes from "prop-types";

import Authorize from "./Authorize";
import { Button } from "semantic-ui-react";

/**
 * Button group that displays proper buttons when editing or not editing
 *
 * @prop {string} floated Allows the group to be floated
 * @prop {function} handleCancel Function called when clicking the cancel button
 * @prop {function} handleDelete Function called when clicking the delete button
 * @prop {function} handleSubmit Function called when clicking the submit button
 * @prop {function} handleEdit Function called when clicking the edit button
 * @prop {boolean} isEditing Determines which buttons to show
 * @prop {boolean} hideCancel Hides the cancel button
 * @prop {boolean} hideDelete Hides the delete button
 * @prop {string} permission Displays the group if given this permission
 */
function EditButtonGroup(props) {
  return (
    <Authorize permission={props.permission}>
      {props.isEditing ? (
        <>
          {!props.hideCancel && (
            <Button
              content="Cancel"
              // icon="cancel"
              fluid={props.fluid}
              onClick={props.handleCancel}
              floated={props.floated}
              size={props.size}
            />
          )}

          <Button
            floated={props.floated}
            color="green"
            icon="save"
            content="Save"
            fluid={props.fluid}
            onClick={props.handleSubmit}
            size={props.size}
          />
          {!props.hideDelete && (
            <Button
              floated={props.floated}
              fluid={props.fluid}
              color="red"
              icon="trash alternate"
              content="Delete"
              onClick={props.handleDelete}
              size={props.size}
            />
          )}
        </>
      ) : (
        <Button
          floated={props.floated}
          fluid={props.fluid}
          content="Edit"
          icon="edit"
          color="purple"
          onClick={props.handleEdit}
          size={props.size}
        />
      )}
    </Authorize>
  );
}
EditButtonGroup.defaultProps = {
  floated: undefined,
  fluid: false,
  handleCancel: () => {},
  handleDelete: () => {},
  handleSubmit: () => {},
  handleEdit: () => {},
  isEditing: false,
  hideCancel: false,
  hideDelete: false,
  permission: null
};

EditButtonGroup.propTypes = {
  floated: PropTypes.string,
  fluid: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  isEditing: PropTypes.bool,
  hideCancel: PropTypes.bool,
  hideDelete: PropTypes.bool,
  permission: PropTypes.string.isRequired
};

export default EditButtonGroup;
