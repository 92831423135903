import React, { Component } from "react";

import {
  Header,
  Segment,
  Button,
  Grid,
  Icon,
  Popup,
  Responsive,
  Divider,
  Menu,
  Modal
} from "semantic-ui-react";
import _ from "lodash";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import {
  calculateTotals,
  getPackagePrices,
  calculatePetEstimate,
} from "../lib/estimateCalculations";
import {
  defaultUser,
  defaultEstimate,
  defaultPetEstimate,
} from "../lib/defaultObjects";
import {
  isEmpty,
  checkIfValid,
  validateZipCode,
  validateMapsco,
} from "../lib/validation";

import Authorize from "./Authorize";
import ErrorBoundary from "./ErrorBoundary";
import CardLayout from "./CardLayout";
import EstimateCard from "./EstimateCard";

import EstimateDetails from "../Forms/EstimateDetails";
import PetEstimateDetails from "../Forms/PetEstimateDetails";
import EstimateConfirmation from "./EstimateConfirmation";
import EstimateTotals from "../Tables/EstimateTotals";
import UserCreate from "../Forms/UserCreate";
import { OptionsContext } from "../OptionsContext";

import Axios from "axios";
import { getToken } from "../lib/csrfToken";
import io from "../socketConnection";
import StepManager from "./StepManager";
import CustomerModal from "./CustomerModal";

import { findUserById, startAppointment } from "../lib/apiCalls";
import {
  removeMapsco,
  getZipCode,
  makeAddressString,
} from "../lib/helperFunctions";
import { UserContext } from "../UserContext";
import EstimateCustomerConfirmationModal from "./EstimateCustomerConfirmationModal";
import EstimateTotalsList from "./EstimateTotalsList";
import AppointmentPictures from "./AppointmentPictures";
import AppointmentCompletionModal from "./AppointmentCompletionModal";

/**
 * Page to create a new estimate. Holds all pet estimates, estimate details, and user details. Can add a new user.
 * Controls the active index of the steps.
 * Keeps track of which steps are completed.
 * @prop {object} Appointment
 */
class Estimate extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      estimate: defaultEstimate({
        salesTax: props.salesTax,
        fuelCharge: props.fuelCharge,
        tripFee: props.tripFee,
        generatorFee: props.generatorFee,
      }),
      appointment: null,
      user: {},
      newUser: defaultUser(),
      tripFeeConsentModal: {
        prevTripFee: props.tripFee,
        needConsent: false,
        onYes: () => null,
        onNo: () => {}
      }
    };

    this.updatePetEstimate = this.updatePetEstimate.bind(this);
    this.getEstimate = this.getEstimate.bind(this);
    this.takeConsentToIncreaseTripCharge = this.takeConsentToIncreaseTripCharge.bind(this);
    this.getCustomerPrimaries = this.getCustomerPrimaries.bind(this);
    this.getPreviousEstimate = this.getPreviousEstimate.bind(this);
    this.saveEstimate = this.saveEstimate.bind(this);
    this.handleScheduleAppointment = this.handleScheduleAppointment.bind(this);
    this.handleCompleteAppointment = this.handleCompleteAppointment.bind(this);
    this.handleStartAppointment = this.handleStartAppointment.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.fetchData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match &&
      prevProps.match &&
      this.props.match.params.estimateId !== prevProps.match.params.estimateId
    ) {
      this.fetchData();
    }
  }

  fetchData = () => {
    let { location, history, match } = this.props;
    let startingStep = "";

    if (!location.pathname.includes(startingStep)) {
      history.replace(`${match.url}/${startingStep}`, location.state);
    }

    // Get user if on estimate screen for an existing user
    if (match.params.userId) {
      this.setState({ loading: true });
      // User helper function to get the customer and convert the data into proper format
      findUserById({ userId: match.params.userId }, (error, response) => {
        if (error) {
          this.setState({ loading: false, isEditing: false });
          return;
        }
        if (this._isMounted) {
          // When looking at an existing estimate, load the estimate
          if (match.params.estimateId) {
            // Give the user's values to the estimate if it needs to make default values
            this.getEstimate(match.params.estimateId, response);
          } else {
            // For new estimate
            // Get previously complete appointment estimate ID to set default values
            this.getPreviousEstimate(
              match.params.userId,
              (previousEstimate) => {
                if (previousEstimate && previousEstimate.ID) {
                  // There was a previous estimate so set default data to that
                  this.getEstimate(previousEstimate.ID, response, true);
                } else {
                  let customerPets = response.Customer
                    ? response.Customer.Pets
                    : [];
                  // If no previous appointment with an estimate was found
                  // try to load the customer's primary values
                  // and create the default pet estimates for all their pets
                  let PetEstimates = customerPets.map((pet) => {
                    return defaultPetEstimate({ Pet: pet });
                  });
                  PetEstimates = PetEstimates.map((petEstimate) => {
                    let breedDetails = _.find(this.props.breeds, {
                      ID: petEstimate.Pet.breedId,
                    });
                    let packagePrices = {};
                    if (!breedDetails) {
                      breedDetails = null;
                    } else {
                      packagePrices = getPackagePrices(
                        breedDetails,
                        petEstimate.Pet.lastGroomDate
                      );
                    }
                    let subtotal = calculatePetEstimate(
                      petEstimate,
                      packagePrices
                    );
                    return { ...petEstimate, subtotal: subtotal };
                  });

                  this.setState((state) => ({
                    loading: false,
                    estimate: {
                      ...state.estimate,
                      PetEstimates: PetEstimates,
                      tripFee: response.Customer.tripCharge != null ? response.Customer.tripCharge : state.estimate.tripFee,
                      ...this.getCustomerPrimaries(response),
                    },
                    tripFeeConsentModal: {
                      ...state.tripFeeConsentModal,
                      prevTripFee: response.Customer.tripCharge != null ? response.Customer.tripCharge : state.tripFeeConsentModal.prevTripFee
                    },
                  }));
                }
              }
            );
          }
          // Set the user to state
          this.setState({ user: response });
        }
      });
    } else if (match.params.estimateId) {
      // Should never occur. Cannot save an estimate without a customer
      this.setState({ loading: true });
      this.getEstimate(match.params.estimateId);
    }
  };
  /**
   * Get's an object with a user's primary values if an estimate does not already have default values.
   * Values included in the check are phone numbers, emails, and addresses.
   * @param {object} user
   * @param {object} estimate
   */
  getCustomerPrimaries(user = {}, estimate = {}) {
    let { PhoneNumbers, Addresses, Emails } = user;
    let { phoneNumberString, addressString, emailString } = estimate;

    let primaries = {};
    // When given and copying the records, check if they exist or load the customer's primary data
    if (!phoneNumberString && PhoneNumbers && PhoneNumbers.length > 0) {
      let primaryPhoneNumber = _.find(PhoneNumbers, {
        isPrimary: true,
      });
      if (primaryPhoneNumber) {
        primaries.phoneNumberString = primaryPhoneNumber.phoneNumber;
      }
    }

    if (!emailString && Emails && Emails.length > 0) {
      let primaryEmail = _.find(Emails, { isPrimary: true });
      if (primaryEmail) {
        primaries.emailString = primaryEmail.emailAddress;
      }
    }

    if (!addressString && Addresses && Addresses.length > 0) {
      let primaryAddress = _.find(Addresses, { isPrimary: true });
      if (primaryAddress) {
        let mapscoCity = _.find(this.props.mapscoCities, {
          ID: primaryAddress.mapscoCityId,
        });
        primaries.addressString = makeAddressString(primaryAddress, mapscoCity);
        primaries.needsGenerator = primaryAddress.needsGenerator === true;
        primaries.addressNotes = primaryAddress.notes;
      }
    }

    return primaries;
  }

  /**
   * Calls API to get the last saved estimate of a customer
   * @param {integer} userId
   * @param {function} callback Returns the estimate if an estimate was found or null if not
   */
  getPreviousEstimate(userId, callback = () => {}) {
    if (!userId) return;
    io.socket.get(
      "/api/v4/estimate/findLastByCustomer",
      { userId: userId },
      (responseData, jwres) => {
        if (jwres.error) {
          console.error(jwres.error);
          toast.error("Failed to load estimate.");
          return;
        }
        if (jwres.statusCode === 200 && responseData !== "OK") {
          callback(responseData);
        } else {
          callback(null);
        }
      }
    );
  }

  /**
   * Retrieves an object from the API of an estimate by ID. Can be returned as a copy of the found estimate by specifying isCopy to true
   * @param {*} id Estimate's ID
   * @param {*} user Allows the estimate to be given default selections from the user's data
   * @param {*} isCopy Formats the returned estimate as a copy of the existing estimate
   */
  async getEstimate(id, user, isCopy = false) {
    io.socket.get(
      "/api/v4/estimate/findById",
      { estimateId: id, isCopy: isCopy },
      (responseData, jwres) => {
        if (jwres.error) {
          console.error(jwres.error);
          toast.error("Failed to load estimate.");
          this.setState({ loading: false });
          return;
        }
        let estimate = responseData.estimate;
        let primaries = this.getCustomerPrimaries(user, estimate);
        if (jwres.statusCode === 200 && estimate) {
          let appointmentStatus = responseData.appointment
            ? _.find(this.props.statuses, {
                ID: responseData.appointment.statusId,
              })
            : null;
          let isCompleted = false;

          if (appointmentStatus) {
            isCompleted = ["completed", "cancelled", "serviceIssue"].includes(
              appointmentStatus.status
            );
          }

          // Convert the estimate pet extra to services
          if (estimate.EstimatePets) {
            for (let estimatePet in estimate.EstimatePets) {
              let services = {};
              for (let extra of estimate.EstimatePets[estimatePet]
                .EstimatePetExtras) {
                let foundService = _.find(this.props.services, {
                  ID: extra.EstimateExtraID,
                });
                if (foundService) {
                  services[foundService.item] = foundService;
                }
              }
              if (isCopy === true) {
                let breedDetails = _.find(this.props.breeds, {
                  ID: estimate.EstimatePets[estimatePet].Pet.breedId,
                });
                let packagePrices = {};
                if (!breedDetails) {
                  breedDetails = null;
                } else {
                  packagePrices = getPackagePrices(
                    breedDetails,
                    estimate.EstimatePets[estimatePet].Pet.lastGroomDate
                  );
                }
                var subtotal = calculatePetEstimate(
                  estimate.EstimatePets[estimatePet],
                  packagePrices
                );
              }
              estimate.EstimatePets[estimatePet] = {
                ...estimate.EstimatePets[estimatePet],
                services: services,
                subtotal:
                  subtotal || estimate.EstimatePets[estimatePet].subtotal,
              };
            }

            if (isCompleted !== true) {
              // Check if looking at an existing estimate with pet estimates
              // Only create default pet estimates for pets that were not on the estimate already
              let customerPets = user.Customer.Pets;
              let petEstimates = [...estimate.EstimatePets];

              // Remove IDs of all pet estimates if making a copy
              if (isCopy === true) {
                petEstimates.forEach((x) => delete x.ID);
              }

              for (let each of customerPets) {
                if (_.findIndex(petEstimates, { Pet: { ID: each.ID } }) < 0) {
                  // Specifically do not include these pet estimates since they were not included in the estimate before loading
                  let newPetEstimate = defaultPetEstimate({
                    Pet: each,
                    includeInEstimate: false,
                  });
                  if (isCopy === true) {
                    let breedDetails = _.find(this.props.breeds, {
                      ID: newPetEstimate.Pet.breedId,
                    });
                    let packagePrices = {};
                    if (!breedDetails) {
                      breedDetails = null;
                    } else {
                      packagePrices = getPackagePrices(
                        breedDetails,
                        newPetEstimate.Pet.lastGroomDate
                      );
                    }
                    newPetEstimate.subtotal = calculatePetEstimate(
                      newPetEstimate,
                      packagePrices
                    );
                  }
                  petEstimates.push(newPetEstimate);
                }
              }
              estimate.EstimatePets = petEstimates;
            }
          }
          if (!isCompleted || isCopy === true) {
            // Update the address notes in the background
            let foundEstimateAddress = _.find(user.Addresses, (address) => {
              let mapscoCity = _.find(this.props.mapscoCities, {
                ID: address.mapscoCityId,
              });
              let addressString = makeAddressString(address, mapscoCity);
              return estimate.AddressString === addressString;
            });
            if (foundEstimateAddress) {
              estimate.AddressNotes = foundEstimateAddress.notes;
            }

            if (isCopy) {
              // Only do it if you're copying from previous estimate
              // 20220618 SSTONE: Pull in TripCharge from Customer Profile as default for new/incomplete estimates
              estimate.FeeTrip = user.Customer.tripCharge;
            }
          }

          const defaultEstimateData = defaultEstimate({
            AddressString: primaries.addressString,
            EmailString: primaries.EmailString,
            ...estimate,
            PhoneNumberString: primaries.phoneNumberString,
            ID: isCopy === true ? undefined : estimate.ID,
            salesTax: this.props.salesTax,
            fuelCharge: this.props.fuelCharge,
            tripFee: this.props.tripFee,
            generatorFee: this.props.generatorFee,
          })

          this.setState({
            loading: false,
            appointment: responseData.appointment,
            estimate: defaultEstimate({
              AddressString: primaries.addressString,
              EmailString: primaries.EmailString,
              AddressNotes: primaries.addressNotes,
              ...estimate,
              PhoneNumberString: primaries.phoneNumberString,
              ID: isCopy === true ? undefined : estimate.ID,
              salesTax: this.props.salesTax,
              fuelCharge: this.props.fuelCharge,
              tripFee: this.props.tripFee,
              generatorFee: this.props.generatorFee,
            }),
            tripFeeConsentModal: {
              ...this.state.tripFeeConsentModal,
              prevTripFee: defaultEstimateData.tripFee,
            }
          });
        } else {
          toast.error("Failed to load estimate.");
        }
      }
    );
  }

  /**
   * Updates the user and merges keys together
   * @param {object} user these are the user details
   */
  handleNewUserChange = (user) => {
    this.setState((state) => ({
      newUser: _.merge(state.newUser, user),
    }));
  };

  /**
   * Toggle including the pet estimate in the total estimate by an index
   * @param {integer} index Index of the pet estimate
   */
  toggleIncludeInEstimate = (index) => {
    let tempPetEstimates = [...this.state.estimate.PetEstimates];
    const currentPetEstimate = tempPetEstimates[index];
    tempPetEstimates[index] = {
      ...currentPetEstimate,
      includeInEstimate: !currentPetEstimate.includeInEstimate,
    };
    this.setState((state) => ({
      estimate: { ...state.estimate, PetEstimates: tempPetEstimates },
    }));
  };

  checkCustomerInfo = (next) => {
    const { newUser, user, estimate } = this.state;
    let failedRequirements = null;

    if (user && user.ID) {
      // Check the selected address saved to the estimate
      let mapsco = removeMapsco(estimate.addressString).mapsco;
      if (mapsco && mapsco.length > 6) {
        mapsco = mapsco.substring(2, mapsco.length);
      }
      let zipcode = getZipCode(estimate.addressString);

      if (!estimate.addressString || !validateZipCode(zipcode)) {
        failedRequirements =
          "An estimate requires a valid ZIP Code for the selected address";
      }
      if (
        !estimate.addressString ||
        mapsco == null ||
        mapsco === "No Mapsco" ||
        !validateMapsco(mapsco)
      ) {
        failedRequirements =
          "An estimate requires a valid Mapsco for the selected address";
      }
    } else {
      if (
        !newUser ||
        isEmpty(newUser.Address.zipcode) ||
        isEmpty(newUser.Address.mapsco) ||
        isEmpty(newUser.Address.mapscoCityId) ||
        !checkIfValid("zipcode", newUser.Address.zipcode) ||
        !validateMapsco(newUser.Address.mapsco)
      ) {
        failedRequirements =
          "An estimate requires a valid ZIP Code and Mapsco for the selected address.";
      }
      if (newUser.isAccount === true && isEmpty(newUser.emailAddress)) {
        failedRequirements =
          "An email address is required when creating a new account.";
      }
    }

    // Change active index and update completed
    if (!failedRequirements) {
      next(true);
    } else {
      next(false);
      toast.info(failedRequirements);
    }
  };
  checkPetInformation = (next) => {
    const { estimate } = this.state;
    let petEstimates = estimate.PetEstimates;
    let failedRequirements = null;

    const failedPetEstimates = _.filter(petEstimates, (petEstimate) => {
      return petEstimate.includeInEstimate
        ? !petEstimate.Pet.breedId || !petEstimate.estimatePackage
        : false;
    });
    if (failedPetEstimates.length > 0) {
      failedRequirements =
        "A pet estimate requires a breed and a package for all pets.";
    }

    // Change active index and update completed
    if (!failedRequirements) {
      next(true);
    } else {
      next(false);
      toast.info(failedRequirements);
    }
  };

  /** Update a pet estimate with given pet details and estimate details */
  updatePetEstimate(pet, estimate, index) {
    if (index == null) {
      return;
    }
    let newPetEstimates = [...this.state.estimate.PetEstimates];
    let currentEstimate = { ...newPetEstimates[index] };
    if (currentEstimate.includeInEstimate == null) {
      currentEstimate.includeInEstimate = true;
    }

    let breedDetails = _.find(this.props.breeds, { ID: pet.breedId });
    let packagePrices = {};
    if (!breedDetails) {
      breedDetails = null;
    } else {
      packagePrices = getPackagePrices(breedDetails, pet.lastGroomDate);
    }
    let subtotal = calculatePetEstimate(
      { ...newPetEstimates[index], Pet: pet },
      packagePrices
    );

    newPetEstimates[index] = {
      ...newPetEstimates[index],
      subtotal: subtotal,
      pet: _.merge(currentEstimate.Pet, pet),
      estimate: _.merge(currentEstimate.estimate, estimate),
    };
    this.setState((state) => ({
      estimate: { ...state.estimate, PetEstimates: newPetEstimates },
    }));
  }

  onEstimateChange = (e, { name, value, options }) => {
    if (name === "addressString") {
      let foundAddress = _.find(options, { value: value });
      this.setState((state) => ({
        estimate: {
          ...state.estimate,
          [name]: value,
          needsGenerator: foundAddress.needsgenerator === "true",
          addressNotes: foundAddress.addressnotes || null,
        },
      }));
    } else {
      this.setState((state) => ({
        estimate: { ...state.estimate, [name]: value },
      }));
    }
  };
  saveToEstimate = (petEstimate, index) => {
    let tempPetEstimates = [...this.state.estimate.PetEstimates];
    if (index != null) {
      let currentEstimate = { ...tempPetEstimates[index] };
      if (currentEstimate.includeInEstimate == null) {
        currentEstimate.includeInEstimate = true;
      }
      tempPetEstimates[index] = { ...currentEstimate, ...petEstimate };
    } else {
      tempPetEstimates.push(defaultPetEstimate(petEstimate));
    }
    this.setState((state) => ({
      estimate: { ...state.estimate, PetEstimates: tempPetEstimates },
    }));
  };

  createEstimateObject = (tripChargeIncrementConsent = false) => {
    let { user, newUser, estimate } = this.state;
    let { breeds } = this.props;

    // Get all the totals
    let totals = {
      ...calculateTotals(
        estimate,
        user.Customer,
        estimate.PetEstimates,
        breeds
      ),
    };

    // Merge user and new user and put in proper format for api
    let tempUser = { ...user };
    let tempNewUser = { ...newUser };
    _.merge(tempNewUser, tempUser);

    let { Name, ...restOfUser } = tempNewUser;
    let newUserData = {};
    if (!user.ID) {
      let mapscoCity = _.find(this.props.mapscoCities, {
        ID: restOfUser.Address.mapscoCityId,
      });
      newUserData = {
        addressString: makeAddressString(restOfUser.Address, mapscoCity),
        phoneNumberString: restOfUser.phoneNumber,
        emailString: restOfUser.emailAddress,
      };
    }

    // Format data for estimate on api
    let estimateData = {
      estimate: {
        ...estimate,
        isComplete: false,
        feeServices: totals.service,
        feeTrip: totals.trip,
        feeFuel: totals.fuel,
        feeGenerator: totals.generator,
        feeSalesTax: totals.tax,
        feeTotal: totals.total,
        discount: totals.discounts,
        ...newUserData,
      },
      user: { ...Name, ...restOfUser },
      tripChargeIncrementConsent,
    };

    return estimateData;
  };

  takeConsentToIncreaseTripCharge(consentVerdict) {
    this.setState({ tripFeeConsentModal: { ...this.state.tripFeeConsentModal, needConsent: false } })
    if (consentVerdict) {
      this.state.tripFeeConsentModal.onYes()
    } else {
      this.state.tripFeeConsentModal.onNo()
    }
  }

  /**
   * Calls the API with the estimate data saved in state
   * @param {function} callback Returns error, response to the callback function
   */
  async saveEstimate(callback = () => {}) {
    const _save = async (tripChargeIncrementConsent = false) => {
      try {
        let estimateData = this.createEstimateObject(tripChargeIncrementConsent);
        let response = await Axios.post("/api/v4/estimate/save", estimateData, {
          headers: { "X-CSRF-Token": getToken() },
        });
        if (response.status === 200) {
          // Return the response to the callback and give a success message
          callback(null, response);
          toast.success("Successfully saved the estimate.");
        }
      } catch (error) {
        toast.error("Failed to save estimate.");
        console.error(error);
        callback(error);
      }
    }
    if (
      this.state.tripFeeConsentModal.prevTripFee < this.state.estimate.tripFee &&
      this.state.user.Customer.tripCharge <this.state.estimate.tripFee
    ) {
      this.setState({
        tripFeeConsentModal: {
          ...this.state.tripFeeConsentModal,
          needConsent: true,
          onYes: () => _save(true),
          onNo: () => _save(false)
        } })
    } else {
      await _save(false)
    }
  }

  handleSave = async () => {
    let { onSave, Appointment } = this.props;

    // Call the save from props instead of here if appointment
    if (Appointment && Appointment.ID) {
      onSave({
        estimateInfo: this.createEstimateObject(),
        appointmentId: Appointment.ID,
        ...Appointment,
      });
      return;
    }

    // Redirect to the current user's profile
    this.saveEstimate((error, response) => {
      if (error) return;
      if (response.data.userID) {
        this.setState({
          shouldRedirect: true,
          currentUserId: response.data.userID,
        });
      } else {
        this.setState({ shouldRedirect: true });
      }
    });
  };

  handleScheduleAppointment() {
    this.saveEstimate((error, response) => {
      if (error) return;
      this.props.history.push("/dashboard/appointments", {
        estimate: response.data,
        appointment: this.state.appointment,
      });
    });
  }

  handleStartAppointment() {
    // Save the estimate
    this.saveEstimate((error) => {
      if (error) return;
      // Set the appointment status to in progress
      startAppointment(
        {
          ID: this.state.appointment ? this.state.appointment.ID : null,
        },
        (error) => {
          if (error) return;
          
          // Push to the current location with new location state
          // window.location.reload();

          // 20220619 SSTONE: Updated logic to NOT do a full window reload and instead re-get the current estimate
          // to pick up the inProgress status
          this.getEstimate(this.state.estimate.ID, this.state.user);

          // Reset view to 1st page to match previous ("reload()") experience.
          this.props.history.push(this.props.location.pathname.replace('confirmation','info'));
        }
      );
    });
  }

  handleCompleteAppointment(estimate, user, totals, appointment) {
    // Save the estimate
    this.saveEstimate((error) => {
      if (error) return;
      let path = `/appointmentCompletion/${this.state.appointment.ID}/customerSigning`;

      this.props.history.push(path, {
        estimate: estimate,
        user: user,
        totals: totals,
        appointment: appointment,
      });
    });
  }

  handleCompleteAppointmentWithoutCustomer = (
    estimate,
    user,
    totals,
    appointment
  ) => {
    // Save the estimate
    this.saveEstimate((error) => {
      if (error) return;
      let path = `/appointmentCompletionWithoutCustomer/${this.state.appointment.ID}/noCustomerConfirmation`;

      this.props.history.push(path, {
        estimate: estimate,
        user: user,
        totals: totals,
        appointment: appointment,
      });
    });
  };

  render() {
    let {
      estimate,
      appointment,
      loading,
      shouldRedirect,

      user,
      newUser,
    } = this.state;

    /* Redirects an operator to the users view or back to the profile page otherwise */
    if (shouldRedirect === true) {
      return (
        <Authorize
          debug
          permission="ViewAllCustomers"
          else={<Redirect to="/dashboard" />}
        >
          <Redirect
            to={
              "/dashboard/customers" +
              (this.state.currentUserId ? "/" + this.state.currentUserId : "")
            }
          />
        </Authorize>
      );
    }

    let { match, breeds, statuses } = this.props;

    let appointmentStatus = appointment
      ? _.find(statuses, { ID: appointment.statusId })
      : null;
    if (!appointmentStatus) {
      appointmentStatus = {};
    }
    let isEditing = true;

    if (appointment && appointmentStatus) {
      isEditing = !["completed", "cancelled", "serviceIssue"].includes(
        appointmentStatus.status
      );
    }

    let petEstimates = estimate.PetEstimates;
    let totals = {
      ...calculateTotals(estimate, user.Customer, petEstimates, breeds),
    };
    // Update the total fee if the total ever changes
    estimate.discounts = totals.discounts;
    estimate.serviceFee = totals.service;
    estimate.fuelFee = totals.fuel;
    estimate.salesTaxFee = totals.tax;
    estimate.generatorFee = totals.generator;
    estimate.tripFee = totals.trip;
    estimate.totalFee = totals.total;

    let customerComponent = null;

    let totalsComponent = (
      <>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <EstimateTotals
            {...totals}
            isEditing={isEditing}
            onChange={this.onEstimateChange}
            needsGenerator={estimate.needsGenerator}
          />
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <EstimateTotalsList
            {...totals}
            isEditing={isEditing}
            onChange={this.onEstimateChange}
            needsGenerator={estimate.needsGenerator}
          />
        </Responsive>
      </>
    );

    // Display new customer or existing customer's profile
    if (match && match.params.userId != null) {
      customerComponent = (
        <Route
          render={(props) => (
            <>
              <Segment>
                <EstimateDetails
                  isEditing={isEditing}
                  needsGenerator={estimate.needsGenerator}
                  addresses={user.Addresses}
                  emails={user.Emails}
                  phoneNumbers={user.PhoneNumbers}
                  addressString={estimate.addressString}
                  phoneNumberString={estimate.phoneNumberString}
                  emailString={estimate.emailString}
                  onChange={this.onEstimateChange}
                />
              </Segment>
              <CustomerModal
                onClose={this.fetchData}
                {...props}
                userId={match.params.userId}
              />
            </>
          )}
        />
      );
    } else {
      customerComponent = (
        <Route
          render={(props) => (
            <Authorize
              permission="EditCustomers"
              else={
                <Segment>
                  <Header icon="info" content="No customer was found." />
                </Segment>
              }
            >
              <Segment>
                <Header content="New User" as="h2" dividing />
                <UserCreate
                  {...props}
                  Address={newUser.Address}
                  {...newUser.Name}
                  emailAddress={newUser.emailAddress}
                  phoneNumber={newUser.phoneNumber}
                  required={["Zipcode", "Mapsco"]}
                  isAccount={newUser.isAccount}
                  showHeader={false}
                  signUp={true}
                  onChange={this.handleNewUserChange}
                  hideCreateButton={true}
                />
              </Segment>
            </Authorize>
          )}
        />
      );
    }
    let views = [
      {
        step: {
          icon: "user",
          path: match.url + "/info",
          title: "Details",
        },
        handleNext: this.checkCustomerInfo,
        component: (
          <>
            {totalsComponent}
            <Divider hidden />
            {/* Display the user information for either creating a new user or the current user's information */}
            {customerComponent}
          </>
        ),
      },
      {
        step: {
          icon: "paw",
          path: match.url + "/pets",
          title: "Pet Services",
        },
        handleNext: this.checkPetInformation,
        // Display the pet details and pet estimate details
        component: (
          <>
            {totalsComponent}
            <Divider hidden />
            <Route
              render={(props) => (
                <CardLayout
                  {...props}
                  showAddCard={isEditing}
                  itemsPerRow={
                    window.innerWidth < Responsive.onlyMobile.maxWidth ? 1 : 3
                  }
                  allCards={petEstimates}
                  display={(card, index, handleSelect) => (
                    <EstimateCard
                      key={index}
                      {...card}
                      toggleIncludeInEstimate={this.toggleIncludeInEstimate.bind(
                        this,
                        index
                      )}
                      handleSelect={handleSelect}
                      index={index}
                      breeds={breeds}
                    />
                  )}
                  details={(selectedCard, clearSelected) => (
                    <PetEstimateDetails
                      key={selectedCard.Pet ? selectedCard.Pet.ID : undefined}
                      {...selectedCard}
                      isEditing={isEditing}
                      customerId={user.Customer ? user.Customer.ID : null}
                      userId={user.ID}
                      cancel={clearSelected}
                      updatePetEstimate={this.updatePetEstimate}
                      saveToEstimate={this.saveToEstimate}
                      handleRemoveFromEstimate={() =>
                        this.handleRemoveFromEstimate(selectedCard.index)
                      }
                    />
                  )}
                />
              )}
            />
          </>
        ),
      },
      {
        step: {
          icon: "check",
          path: match.url + "/confirmation",
          title: "Confirmation",
        },
        //  Details need to include preferred stylist, senior citizen
        component: (
          <Route
            render={(props) => (
              <Segment>
                <EstimateConfirmation
                  estimate={estimate}
                  newUser={newUser}
                  user={user}
                  totals={totals}
                  isNewUser={!user.ID}
                />
              </Segment>
            )}
          />
        ),
      },
    ];

    let customerName = `${
      user.Name && user.Name.firstName ? user.Name.firstName + " " : ""
    }${(user.Name && user.Name.lastName) || ""}`;
    return (
      <ErrorBoundary>
        {/* <Prompt
          when={!estimate && !shouldRedirect}
          message="You will lose all details for this estimate. Are you sure you want to continue?"
        /> */}
        {appointmentStatus && appointmentStatus.status === "inProgress" && (
          <AppointmentPictures ID={appointment.ID} />
        )}
        <Grid stackable container>
          <Grid.Row>
            <Grid.Column width="16">
              <Header as="h2">
                Estimate Information{customerName !== "" && ": " + customerName}
                <Header.Subheader>
                  <Icon name="warning circle" fitted /> Remember that all
                  estimates are approximate and prices are subject to change.
                </Header.Subheader>
              </Header>
              <Route
                render={(props) => (
                  <StepManager
                    loading={loading}
                    {...props}
                    views={views}
                    completed={!!estimate.ID}
                    finishComponent={
                      isEditing === true ? (
                        <Menu
                          text
                          floated="right"
                          stackable
                          className="responsive-header"
                          size="large"
                        >
                          <Menu.Menu position="right">
                            {/* Check if the user should see the start appointment and complete appointment buttons based on permissions first and the the assigned employee */}
                            {appointment && appointment.ID && (
                              <Authorize
                                permission="StartAppointments"
                                else={
                                  <UserContext.Consumer>
                                    {({ user: appUser }) => {
                                      if (
                                        appointment &&
                                        appointment.employee &&
                                        appUser.userId ===
                                          appointment.employee.userId
                                      ) {
                                        // Estimate Stylist Actions
                                        return (
                                          <>
                                            {/* A modal with a confirmation for stylists when starting an appointment */}
                                            {appointment &&
                                              appointment.ID &&
                                              appointment.isStanding ===
                                                false &&
                                              (appointmentStatus.status ===
                                                "scheduled" ||
                                                appointmentStatus.status ===
                                                  "confirmed") && (
                                                <Menu.Item>
                                                  <EstimateCustomerConfirmationModal
                                                    key={user.ID}
                                                    userId={user.ID}
                                                    loading={loading}
                                                    handleStartAppointment={
                                                      this
                                                        .handleStartAppointment
                                                    }
                                                  />
                                                </Menu.Item>
                                              )}
                                            {/* Complete Appointment button */}
                                            {(!appointment ||
                                              !appointment.ID ||
                                              appointmentStatus.status ===
                                                "inProgress") && (
                                              <Menu.Item>
                                                <AppointmentCompletionModal
                                                  handleCustomerOnSite={() =>
                                                    this.handleCompleteAppointment(
                                                      estimate,
                                                      user,
                                                      totals,
                                                      appointment
                                                    )
                                                  }
                                                  handleCustomerNotHome={() =>
                                                    this.handleCompleteAppointmentWithoutCustomer(
                                                      estimate,
                                                      user,
                                                      totals,
                                                      appointment
                                                    )
                                                  }
                                                />
                                              </Menu.Item>
                                            )}
                                          </>
                                        );
                                      }
                                    }}
                                  </UserContext.Consumer>
                                }
                              >
                                <>
                                  {/* A modal with a confirmation for stylists when starting an appointment */}
                                  {appointment &&
                                    appointment.ID &&
                                    appointment.isStanding === false &&
                                    (appointmentStatus.status === "scheduled" ||
                                      appointmentStatus.status ===
                                        "confirmed") && (
                                      <Menu.Item>
                                        <EstimateCustomerConfirmationModal
                                          key={user.ID}
                                          userId={user.ID}
                                          loading={loading}
                                          handleStartAppointment={
                                            this.handleStartAppointment
                                          }
                                        />
                                      </Menu.Item>
                                    )}
                                  {(!appointment ||
                                    !appointment.ID ||
                                    appointmentStatus.status ===
                                      "inProgress") && (
                                    <Menu.Item>
                                      <AppointmentCompletionModal
                                        handleCustomerOnSite={() =>
                                          this.handleCompleteAppointment(
                                            estimate,
                                            user,
                                            totals,
                                            appointment
                                          )
                                        }
                                        handleCustomerNotHome={() =>
                                          this.handleCompleteAppointmentWithoutCustomer(
                                            estimate,
                                            user,
                                            totals,
                                            appointment
                                          )
                                        }
                                      />
                                    </Menu.Item>
                                  )}
                                </>
                              </Authorize>
                            )}
                            {(!appointment ||
                              !appointment.ID ||
                              appointmentStatus.status === "waiting" ||
                              appointmentStatus.status === "rescheduled") && (
                              <Authorize permission="EditAppointments">
                                <Menu.Item>
                                  <Popup
                                    trigger={
                                      <Button
                                        content={`${
                                          estimate.ID ? "Save" : "Create"
                                        } and Schedule Appointment`}
                                        color="blue"
                                        fluid
                                        onClick={this.handleScheduleAppointment}
                                      />
                                    }
                                    content="Save the estimate and continue to the appointment page to schedule a new appointment with this estimate."
                                    inverted
                                  />
                                </Menu.Item>
                              </Authorize>
                            )}
                            <Authorize permission="EditEstimates">
                              <Menu.Item>
                                <Button
                                  content={`${
                                    estimate.ID ? "Save" : "Create"
                                  } Estimate${
                                    appointment && appointment.ID ? " Only" : ""
                                  }`}
                                  fluid
                                  color="green"
                                  onClick={this.handleSave}
                                  disabled={loading}
                                />
                              </Menu.Item>
                            </Authorize>
                          </Menu.Menu>
                        </Menu>
                      ) : null
                    }
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal open={this.state.tripFeeConsentModal.needConsent} onClose={null}>
          <Modal.Header>Please select an option</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              You have increased the trip charge on this estimate from ${this.state.tripFeeConsentModal.prevTripFee} to ${this.state.estimate.tripFee}, do you want to save this change to the customer's profile?
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color='black'
              onClick={() => this.takeConsentToIncreaseTripCharge(false)}
            >
              No
            </Button>
            <Button
              content="Yes"
              labelPosition='right'
              icon='checkmark'
              positive
              onClick={() => this.takeConsentToIncreaseTripCharge(true)}
            />
          </Modal.Actions>
        </Modal>
      </ErrorBoundary>
    );
  }
}

Estimate.defaultProps = {
  Appointment: {},
  onSaveEstimate: () => {},
};

export default (props) => (
  <Authorize permission="ViewEstimates">
    <OptionsContext.Consumer>
      {({
        breeds,
        services,
        fuelCharge,
        salesTax,
        tripFee,
        generatorFee,
        statuses,
        mapscoCities,
      }) => (
        <Estimate
          {...props}
          breeds={breeds}
          services={services}
          fuelCharge={fuelCharge}
          salesTax={salesTax}
          tripFee={tripFee}
          generatorFee={generatorFee}
          statuses={statuses}
          mapscoCities={mapscoCities}
        />
      )}
    </OptionsContext.Consumer>
  </Authorize>
);
