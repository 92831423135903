import React, { Component } from "react";

import Axios from "axios";

import ReactTable from "react-table";
import moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";

import { Header, Form } from "semantic-ui-react";
import PetList from "../Shared/PetList";
import { OptionsContext } from "../OptionsContext";
import customFilter from "../lib/filters";
import SidebarLayout from "../Shared/SidebarLayout";
import AppointmentHistoryPreview from "../Shared/AppointmentHistoryPreview";
import AppointmentStandingPreview from "../Shared/AppointmentStandingPreview";

class ServiceIssuesList extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: -1,
      loading: false,
      selectedRow: null
    };
    this.getAppointments = this.getAppointments.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.getAppointments();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  async getAppointments(state, instance) {
    this.setState({ loading: true });
    try {
      let response = await Axios.get("/api/v4/appointments/getAll", {
        params: {
          statuses: ["serviceIssue", "standingRequest"]
        }
      });

      if (this._isMounted) {
      if (response.status === 200) {
        this.setState({
          data: response.data.appointments,
          pages: response.data.pages
        });
      }
      this.setState({ loading: false });}
    } catch (error) {
      console.error(error);
      toast.error("Failed to retrieve service issues.");
      this.setState({ loading: false });
    }
  }
  onRowClick(state, rowInfo, column) {
    if (this.state.loading === true) return;
    if (rowInfo && rowInfo.original) {
      this.setState({ selectedRow: rowInfo.original });
    }
  }
  deselectStanding = () => {
    this.setState({ selectedRow: null });
  };
  onAppointmentAction = () => {
    this.getAppointments();
    this.deselectStanding();
  };
  render() {
    const { selectedRow, data, loading } = this.state;
    return (
      <SidebarLayout>
        <div>
          <Header as="h2">Service Issues</Header>
          <ReactTable
            getTheadThProps={(state, rowInfo, instance) => {
              return { className: "service-issues-table-" + instance.id };
            }}
            defaultFilterMethod={customFilter}
            data={data}
            filterable
            sortable
            defaultSorted={[{ id: "DateAdded" }]}
            defaultPageSize={window.innerHeight > 1000 ? 25 : 10}
            columns={[
              {
                Header: "Date Added",
                accessor: "DateAdded",
                Cell: props => moment(props.value).format("LLL")
              },
              {
                Header: "Status",
                id: "status",
                accessor: data => {
                  return data.Status ? _.startCase(data.Status.status) : "";
                },
                minWidth: 100,
                getProps: () => {
                  return {
                    style: {
                      textAlign: "center"
                    }
                  };
                }
              },
              {
                Header: "Stylist",
                headerClassName: "dropdown-filter",
                id: "employeeId",
                accessor: data => {
                  let fullName =
                    data && data.Employee && data.Employee.User
                      ? `${data.Employee.User.firstName || ""} ${data.Employee
                          .User.lastName || ""}`
                      : `${data.EmployeeFirstName ||
                          ""} ${data.EmployeeLastName || ""}`;
                  return fullName;
                },
                Filter: ({ filter, onChange }) => (
                  <Form size="small">
                    <Form.Select
                      className="filter-select"
                      fluid
                      clearable
                      search
                      name="employeeId"
                      placeholder="Select Stylist"
                      options={this.props.stylists.map(stylist => {
                        let fullName =
                          stylist && stylist.User
                            ? `${stylist.User.firstName || ""} ${stylist.User
                                .lastName || ""}`
                            : `${stylist.EmployeeFirstName ||
                                ""} ${stylist.EmployeeLastName || ""}`;
                        return {
                          key: stylist.ID,
                          value: fullName,
                          text: fullName
                        };
                      })}
                      value={filter ? filter.value : null}
                      onChange={(event, { name, value }) => onChange(value)}
                    />
                  </Form>
                )
              },
              {
                Header: "Customer",
                id: "customerId",
                accessor: data =>
                  data.Customer &&
                  data.Customer.User &&
                  `${data.Customer.User.firstName} ${data.Customer.User.lastName}`
              },
              {
                Header: "Pets",
                id: "pets",
                accessor: data =>
                  data.Estimate &&
                  data.Estimate.EstimatePets &&
                  data.Estimate.EstimatePets.map(
                    petEstimate => petEstimate.Pet
                  ),
                Cell: props => <PetList includeDetails pets={props.value} />
              },
              {
                Header: "Last Comment",
                id: "lastComment",
                accessor: data => {
                  if (
                    data.CommentGroup &&
                    data.CommentGroup.Comments &&
                    data.CommentGroup.Comments.length > 0
                  ) {
                    let lastComment = _.maxBy(
                      data.CommentGroup.Comments,
                      "DateAdded"
                    );
                    return lastComment.comment;
                  }
                  return "";
                },
                filterable: false,
                minWidth: 250
              }
            ]}
            loading={loading}
            className="-striped -highlight service-issues-table"
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.original.ID != null) {
                var style = { cursor: "pointer" };
                var classNames = " ";
                // Set a classname based on status
                let appointmentStatus = _.find(this.props.statuses, {
                  ID: rowInfo.original.statusId
                });
                if (appointmentStatus) {
                  classNames =
                    classNames +
                    " appointment-status-" +
                    appointmentStatus.status;
                }
                if (selectedRow && rowInfo.original.ID === selectedRow.ID) {
                  classNames = classNames + " selected-row";
                }
              }
              return {
                onClick: () => this.onRowClick(state, rowInfo, column),
                style: style,
                className: classNames
              };
            }}
          />
        </div>
        {selectedRow &&
        selectedRow.Status &&
        selectedRow.Status.status !== "standingRequest" ? (
          <AppointmentHistoryPreview
            appointment={selectedRow}
            key={selectedRow ? selectedRow.ID : undefined}
            onAppointmentAction={this.onAppointmentAction}
            requiredAppointmentFields={[
              "employeeId",
              "startTime",
              "appointmentDate"
            ]}
            estimateId={selectedRow ? selectedRow.Estimate.ID : null}
          />
        ) : (
          <AppointmentStandingPreview
            appointment={selectedRow}
            key={selectedRow ? selectedRow.ID : undefined}
            onAppointmentAction={this.onAppointmentAction}
            estimateId={
              selectedRow && selectedRow.Estimate
                ? selectedRow.Estimate.ID
                : null
            }
          />
        )}
      </SidebarLayout>
    );
  }
}

ServiceIssuesList.defaultProps = {
  onRowClick: null,
  statuses: [],
  stylists: []
};

export default props => (
  <OptionsContext.Consumer>
    {({ statuses, stylists }) => (
      <ServiceIssuesList {...props} statuses={statuses} stylists={stylists} />
    )}
  </OptionsContext.Consumer>
);
