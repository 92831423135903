import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import moment from "moment";
import Axios from "axios";
import { getToken } from "../lib/csrfToken";
import { Prompt } from "react-router-dom";

import {
  Header,
  Form,
  Button,
  Grid,
  Segment,
  Responsive,
  Popup
} from "semantic-ui-react";

import ErrorBoundary from "../Shared/ErrorBoundary";
import Input from "../Shared/Input";
import TextArea from "../Shared/TextArea";
import PetDetails from "./PetDetails";
import Services from "../Shared/Services";

import {
  calculatePetEstimate,
  getPackagePrices
} from "../lib/estimateCalculations";
import SelectedServices from "../Tables/SelectedServices";
import { toast } from "react-toastify";
import Money from "../Shared/Money";
import PackageInformation from "../Shared/PackageInformation";
import { OptionsContext } from "../OptionsContext";

class PetEstimateDetails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      Pet: props.Pet,
      estimatePackage: props.estimatePackage,
      otherFee: props.otherFee,
      otherDescription: props.otherDescription,
      services: props.services
    };
    this.changeEstimate = this.changeEstimate.bind(this);
    this.onPetUpdate = this.onPetUpdate.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  changeEstimate(e, { name, value }) {
    this.setState({ [name]: value });
  }
  /** Given pet details, update the current estimate with the  */
  onPetUpdate(pet) {
    if (this.props.index != null) {
      let tempPet = { ...this.state.Pet };
      // Copy all values to new one to trim unsused values
      _.forEach(tempPet, function(value, key) {
        tempPet[key] = pet[key];
      });
      this.setState({ Pet: tempPet });
    } else {
      this.setState({ Pet: pet });
    }
  }
  handleSave = () => {
    if (this.state.Pet.petName == null || this.state.Pet.petName === "") {
      toast.info("A pet name is required to add a new pet.");
      return;
    }
    if (this.state.Pet.breedId == null) {
      toast.info("A breed is required to add a new pet.");
      return;
    }
    if (this.state.Pet.customerId != null) {
      this.handleSavePet(response => {
        if (this._isMounted) {
          if (!response.error) {
            this.props.saveToEstimate(
              { ...this.state, Pet: response },
              this.props.index
            );
            this.props.cancel();
          }
        }
      });
    } else {
      this.props.saveToEstimate({ ...this.state }, this.props.index);
      this.props.cancel();
    }
  };
  handleServiceSelect = (e, { value }) => {
    let tempServices = {};

    for (let each of value) {
      let service = _.find(this.props.allServices, { ID: each });
      if (service) {
        tempServices[service.category] = service;
      }
    }

    this.setState({
      services: tempServices
    });
  };

  handleSavePet = async callback => {
    let { Pet } = this.state;
    if (moment(Pet.birthday).year() > 9999) {
      toast.info("Year on birthday must be less than 9999");
      return;
    }
    if (Pet.breedId == null) {
      toast.info("A breed is required.");
      return;
    }
    if (Pet.petName == null || Pet.petName === "") {
      toast.info("A name is required");
      return;
    }
    try {
      let response = await Axios.post(
        `/api/v4/pets/update`,

        _.mapValues({ ...Pet }, function(value) {
          return value == null ? undefined : value;
        }),
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      if (this._isMounted) {
        if (response.status === 200) {
          callback(response.data);
          toast.success("Successfully saved pet");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to save pet.");
      callback({ error: true });
    }
  };
  render() {
    let {
      Pet,
      estimatePackage,
      otherFee,
      otherDescription,
      services
    } = this.state;
    let { breeds, userId, allServices, customerId, isEditing } = this.props;

    let breedDetails = _.find(breeds, { ID: Pet.breedId });
    let packagePrices = {};

    if (!breedDetails) {
      breedDetails = null;
    } else {
      packagePrices = getPackagePrices(breedDetails, Pet.lastGroomDate);
    }

    let estimatePackagePrice = "";
    if (breedDetails != null) {
      if (estimatePackage.toLowerCase() === "b&b") {
        estimatePackagePrice = ` - $${packagePrices.bathBrushPrice}`;
      } else if (estimatePackage.toLowerCase() === "deluxe") {
        estimatePackagePrice = ` - $${packagePrices.deluxePrice}`;
      }
    }
    const packageOptions = [
      {
        text: "B&B",
        key: "b&b",
        value: "b&b",
        description: breedDetails ? `$${packagePrices.bathBrushPrice}` : ""
      },
      {
        text: "Deluxe",
        key: "deluxe",
        value: "deluxe",
        description: breedDetails ? `$${packagePrices.deluxePrice}` : ""
      },
      {
        text: `Add-on Special`,
        key: "add-on special",
        value: "add-on special",
        description: "Varying Prices"
      }
    ];
    let selectedPackage = _.find(packageOptions, {
      value: estimatePackage.toLowerCase()
    });
    let subtotal = calculatePetEstimate(
      { ...this.state, Pet: Pet },
      packagePrices
    );
    let useAddonPrices = estimatePackage.toLowerCase() === "add-on special";
    return (
      <ErrorBoundary>
        <Prompt when={!_.isEqual(Pet, this.props.Pet)} message="" />
        {/* Pet Estimate details */}
        <Segment.Group>
          <Segment clearing inverted color="purple">
            <Header>
              Pet Estimate
              <Header color="grey" inverted size="small" floated="right">
                Subtotal: <Money value={subtotal} />
              </Header>{" "}
            </Header>
          </Segment>
          <Segment>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form>
                    {/* Package */}
                    {isEditing === true ? (
                      <Form.Dropdown
                        required
                        placeholder="Select Package"
                        label={
                          <label>
                            <PackageInformation />
                            Package
                          </label>
                        }
                        name="estimatePackage"
                        fluid
                        search
                        selection
                        text={`${selectedPackage.text ||
                          "B&B"} ${estimatePackagePrice}`}
                        value={estimatePackage || "b&b"}
                        onChange={this.changeEstimate}
                        options={packageOptions}
                      />
                    ) : (
                      <Form.Field>
                        <label>Package</label>
                        <div>
                          {" "}
                          {`${selectedPackage.text ||
                            "B&B"} ${estimatePackagePrice}`}{" "}
                        </div>
                      </Form.Field>
                    )}
                    {/* Other and comment of why */}
                    <Input
                      type="number"
                      placeholder={(0.0).toFixed(2)}
                      label="Other Fees"
                      value={otherFee || 0}
                      name="otherFee"
                      onChange={this.changeEstimate}
                      validation="money"
                      isEditing={isEditing}
                      format="moneyInput"
                      controlChange
                    />

                    <TextArea
                      label="Reason for Other"
                      value={otherDescription || ""}
                      name="otherDescription"
                      inForm
                      onChange={this.changeEstimate}
                      isEditing={isEditing}
                      maxLength={250}
                    />
                  </Form>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form>
                    {/* Extra Services */}
                    {allServices != null && isEditing === true && (
                      <Services
                        useAddonPrices={useAddonPrices}
                        allServices={allServices}
                        selectedServices={services}
                        handleServiceSelect={this.handleServiceSelect}
                      />
                    )}
                    <SelectedServices
                      selectedServices={services}
                      useAddonPrices={useAddonPrices}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>

              {isEditing === true && (
                <Grid.Row>
                  <Grid.Column>
                    {/* Show pet details */}
                    <Segment color="purple">
                      <PetDetails
                        hideDetailsButton
                        hideButtonGroup={true}
                        pet={{ ...Pet, customerId: customerId }}
                        isEditing={isEditing}
                        required={["Breed"]}
                        breedId={breedDetails ? breedDetails.ID : null}
                        onUpdate={this.onPetUpdate}
                        userId={userId}
                      />
                    </Segment>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                      <Button
                        icon="minus"
                        content="Cancel"
                        onClick={this.props.cancel}
                      />
                      <Popup
                        content="Saves the pet's information to the customer's profile and updates the pet's estimate information on the estimate. This does not save the estimate."
                        inverted
                        trigger={
                          <Button
                            color="green"
                            icon="plus"
                            content={
                              Pet.ID
                                ? "Update Pet"
                                : "Save New Pet and Add to Estimate"
                            }
                            onClick={this.handleSave}
                          />
                        }
                      />
                    </Responsive>
                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                      <Button
                        icon="minus"
                        fluid
                        content="Cancel"
                        onClick={this.props.cancel}
                      />
                      <Popup
                        content="Saves the pet's information to the customer's profile and updates the pet's estimate information on the estimate. This does not save the estimate."
                        inverted
                        trigger={
                          <Button
                            color="green"
                            icon="plus"
                            fluid
                            content={
                              Pet.ID
                                ? "Update Pet"
                                : "Save New Pet and Add to Estimate"
                            }
                            onClick={this.handleSave}
                          />
                        }
                      />
                    </Responsive>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Segment>
        </Segment.Group>
      </ErrorBoundary>
    );
  }
}

PetEstimateDetails.defaultProps = {
  cancel: () => {},
  saveToEstimate: () => {},
  allServices: [],
  Pet: {},
  index: null,

  otherFee: 0,
  otherDescription: "",
  estimatePackage: "B&B",
  breeds: [],
  services: {},

  isEditing: true
};

PetEstimateDetails.propTypes = {
  cancel: PropTypes.func,
  saveToEstimate: PropTypes.func,
  allServices: PropTypes.array,
  Pet: PropTypes.object,
  index: PropTypes.number,

  otherDescription: PropTypes.string,
  estimatePackage: PropTypes.string,
  breeds: PropTypes.array,
  services: PropTypes.object,

  isEditing: PropTypes.bool
};

export default props => (
  <OptionsContext.Consumer>
    {({ breeds, services }) => {
      return (
        <PetEstimateDetails {...props} breeds={breeds} allServices={services} />
      );
    }}
  </OptionsContext.Consumer>
);
