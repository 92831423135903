import React from "react";

export const UserContext = React.createContext({
  user: {
    ID: null,
    firstName: "",
    lastName: "",
    permissions: ""
  },
  setUser: () => {}
});
