import React, { Component } from "react";
import ReactTable from "react-table";
import io from "../socketConnection";
import { toast } from "react-toastify";
import moment from "moment";
import { Header, Form, Grid } from "semantic-ui-react";
import _ from "lodash";
import Authorize from "../Shared/Authorize";
import Input from "../Shared/Input";
import Money from "../Shared/Money";

class OutstandingBalances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      startDate: moment().format("MM-DD-YYYY"),
      endDate: moment().format("MM-DD-YYYY"),
      loading: false
    };
  }
  componentDidMount() {
    this.getOutstandingBalances();
  }
  getOutstandingBalances = _.debounce(() => {
    let { startDate, endDate, loading } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true });

    if (!startDate || startDate === "" || !endDate || endDate === "") {
      return;
    }
    if (
      !moment(startDate, "MM-DD-YYYY").isValid() ||
      !moment(endDate, "MM-DD-YYYY").isValid()
    ) {
      return;
    }
    io.socket.get(
      "/api/v4/outstandingBalances/findByDate",
      {
        startDate: this.state.startDate,
        endDate: this.state.endDate
      },
      (body, jwr) => {
        if (jwr.statusCode !== 200) {
          toast.error("Failed to retrieve outstanding balances");
          this.setState({ loading: false });
        } else {
          this.setState({
            data: body || [],
            loading: false
          });
        }
      }
    );
  }, 400);
  handleDateChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    this.getOutstandingBalances();
  };

  onRowClick(state, rowInfo, column) {
    if (this.state.loading === true || !this.props.history) return;
    if (rowInfo && rowInfo.original && rowInfo.original.User) {
      this.props.history.push(
        "/dashboard/customers/" + rowInfo.original.User.ID
      );
    } else {
      toast.error(
        "Failed to navigate to user profile. Please refresh the page and try again"
      );
    }
  }
  render() {
    let { data, loading, startDate, endDate } = this.state;
    let totalOutstanding = 0;
    if (data) {
      totalOutstanding = _.sumBy(data, "balance");
    }
    return (
      <div>
        <Header as="h2">Outstanding Balances of Customers</Header>

        <Grid columns="equal" verticalAlign="bottom">
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <Input
                    label="Start Date"
                    validation="date"
                    placeholder="Start Date"
                    isEditing
                    value={startDate}
                    name="startDate"
                    onChange={this.handleDateChange}
                  />
                  <Input
                    label="End Date"
                    validation="date"
                    placeholder="End Date"
                    isEditing
                    value={endDate}
                    name="endDate"
                    onChange={this.handleDateChange}
                  />
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Header as="div" style={{ margin: "10px" }}>
                Total Outstanding:{" "}
                <span
                  style={{
                    color: parseFloat(totalOutstanding) < 0 ? "red" : "green"
                  }}
                >
                  <Money value={totalOutstanding} />
                </span>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ReactTable
          defaultSorted={[{ id: "User.firstName" }]}
          loading={loading}
          className="-striped -hover"
          data={data}
          filterable
          defaultPageSize={10}
          columns={[
            {
              Header: "First Name",
              accessor: "User.firstName"
            },
            {
              Header: "Last Name",
              accessor: "User.lastName"
            },
            {
              Header: "Balance",
              accessor: "balance",
              Cell: props => <Money value={props.value} />
            },
            {
              Header: "Last Transaction Date",
              accessor: "lastSeen",
              Cell: props =>
                props.value ? moment(props.value).format("LL") : ""
            }
          ]}
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo && rowInfo.original.ID != null) {
              var style = { cursor: "pointer" };
            }
            return {
              onClick: () => this.onRowClick(state, rowInfo, column),
              style: style
            };
          }}
        />
      </div>
    );
  }
}

export default props => (
  <Authorize permission="ViewOutstandingBalances">
    <OutstandingBalances {...props} />
  </Authorize>
);
