import React, { Component } from "react";

import Input from "./Input";
import PetList from "./PetList";
import { removeMapsco } from "../lib/helperFunctions";
import { Link } from "react-router-dom";
import { Form, Header, Segment, Button, Popup } from "semantic-ui-react";
import { callPhoneNumber } from "../lib/apiCalls.js";
import Authorize from "./Authorize";
import SendSMSModal from "../Forms/SendSMSModal";

class PreviewEstimate extends Component {
  handleCall = phoneNumber => {
    callPhoneNumber({ phoneNumber: phoneNumber });
  };

  render() {
    let { estimate, customer, appointmentId } = this.props;
    return (
      <Form>
        <Header as="h3" content="Estimate" block />
        <Button
          content="Estimate Details"
          as={Link}
          size="small"
          color="blue"
          to={{
            pathname: `/dashboard/estimate/${estimate.ID}/user/${
              customer && customer.User ? customer.User.ID : null
            }`,
            state: { appointmentId: appointmentId }
          }}
        />
        {customer && customer.User && (
          <Button
            size="small"
            content="Customer Profile"
            color="blue"
            as={Link}
            to={"/dashboard/customers/" + customer.User.ID}
          />
        )}
        {/* Force a little space between rows of buttons */}
        <div style={{ marginTop: "10px" }}></div>
        <Authorize permission="UsePhoneSystem">
          <Popup
            basic
            inverted
            content="Call Number"
            trigger={
              <Button
                size="small"
                icon="phone"
                content="Call"
                color="grey"
                onClick={() => this.handleCall(estimate.phoneNumberString)}
              />
            }
          />
          {customer && customer.User && (
            <SendSMSModal
              userId={customer.User.ID}
              phoneNumber={estimate.phoneNumberString} 
              buttonColor="grey"
            />
          )}
        </Authorize>
        <Segment basic>
          <Form.Group>
            <Input
              width={6}
              label="Customer"
              value={
                customer && customer.User
                  ? `${customer.User.firstName} ${customer.User.lastName}`
                  : ""
              }
            />
            <Input
              width={5}
              label="Phone Number"
              value={estimate.phoneNumberString}
            />
            <Input
              width={5}
              label="Email Address"
              value={estimate.emailString}
            />
          </Form.Group>
          <Form.Group>
            {/* Selected customer preferences for the estimate/appointment */}
            <Input
              width={11}
              label="Address"
              value={removeMapsco(estimate.addressString).address}
            />
            <Input
              width={5}
              label="Mapsco"
              value={removeMapsco(estimate.addressString).mapsco}
            />
          </Form.Group>
          <Segment>
            {/* List of pets for the estimate */}
            <Header as="h4" dividing content="Pets" />
            <PetList
              includeDetails
              includePetAvatar
              emptyLabel={"None Added to Estimate"}
              pets={estimate.PetEstimates.map(petEstimate => petEstimate.Pet)}
            />
          </Segment>
        </Segment>
      </Form>
    );
  }
}

export default PreviewEstimate;
