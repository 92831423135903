import React, { Component } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import Input from "../Input";
import { toast } from "react-toastify";
import { refundTransaction } from "../../lib/apiCalls";

class RefundModal extends Component {
  constructor(props) {
    super(props);

    this.state = { modalOpen: false, amount: props.defaultAmount };
  }

  handleOpen = () =>
    this.setState({ modalOpen: true, amount: this.props.defaultAmount });

  handleClose = () => this.setState({ modalOpen: false });

  onChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleRefund = () => {
    if (!this.props.refTransactionId) {
      toast.error("No Transaction ID available for refund");
      return;
    }
    refundTransaction(
      {
        amount: this.state.amount,
        refTransactionId: this.props.refTransactionId
      },
      (error, response) => {
        if (error) {
          console.error(error);
        } else {
          this.handleClose();
          this.props.onRefunded();
        }
      }
    );
  };
  render() {
    let { amount } = this.state;
    return (
      <div>
        <Modal
          closeIcon
          size="small"
          trigger={
            <Button
              content="Refund Transaction"
              color="blue"
              onClick={this.handleOpen}
              floated="right"
            />
          }
          open={this.state.modalOpen}
          onClose={this.handleClose}
        >
          <Modal.Header>Refund Transaction </Modal.Header>
          <Modal.Content>
            <Form>
              <Input
                label="Amount to Refund"
                name="amount"
                min={0}
                value={amount}
                isEditing
                type="number"
                format="moneyInput"
                validation={["money"]}
                onChange={this.onChange}
                placeholder="0.00"
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleRefund} color="green">
              Refund
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

RefundModal.defaultProps = {
  defaultAmount: 0,
  refTransactionId: null,
  onRefunded: () => {}
};

export default RefundModal;
