import React, { Component } from "react";
import { Container, Grid, Header, List, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <Segment
        inverted
        vertical
        style={{
          backgroundColor: "#dddddd",
          padding: "2em 0em"
        }}
      >
        <Container>
          <Grid divided>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h4" content="About" />
                <List link>
                  <List.Item as="a" href="https://petlove.com/about-pet-love/">
                    About Us
                  </List.Item>
                  <List.Item as="a" href="https://petlove.com/contact-us">
                    Contact Us
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as="h4" content="Services" />
                <List link>
                  <List.Item as={Link} to="/privacyPolicy">
                    Privacy Policy
                  </List.Item>
                  <List.Item as={Link} to="/termsOfService">
                    Terms of Service
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
}

export default Footer;
