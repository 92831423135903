import React, { Component } from "react";
import {
  Button,
  Header,
  Container,
  Segment,
  Message,
  Grid,
  List,
  Label
} from "semantic-ui-react";
import Axios from "axios";
import { toast } from "react-toastify";

import { getToken } from "../lib/csrfToken";
import { getUserDetails } from "../lib/apiCalls";
import CustomerTable from "../Tables/CustomerTable";

class MergeProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      submitting: false,
      primaryProfile: {},
      duplicateProfile: null
    };
  }
  componentDidMount() {
    let { match } = this.props;
    // Get the information of the customer for the Primary Profile
    if (match && match.params.userId) {
      getUserDetails({ userId: match.params.userId }, (error, response) => {
        if (error) {
          toast.error("Failed to retrieve user");
          this.setState({ loading: false });
          return;
        }
        this.setState({ primaryProfile: response, loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  }
  handleSubmit = async () => {
    let { primaryProfile, duplicateProfile } = this.state;
    if (!primaryProfile.ID || !duplicateProfile || !duplicateProfile.ID) {
      toast.info("A primary and duplicate profile must be selected");
      return;
    }
    if (primaryProfile.ID === duplicateProfile.ID) {
      toast.info(
        "Cannot merge a profile with itself. Please select another profile."
      );
      return;
    }
    this.setState({ submitting: true });
    try {
      const response = await Axios.post(
        `/api/v4/users/mergeCustomerProfiles`,
        {
          primaryProfileId: primaryProfile.ID,
          duplicateProfileId: duplicateProfile.ID
        },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      // Check if response was a success
      if (response.status === 200) {
        this.setState({
          submitting: false
        });
        toast.success("Successfully merged the profiles");
        // Navigate to the primary profile's profile
        this.props.history.push(
          `/dashboard/customers/${primaryProfile.ID}/overview`
        );
      } else {
        toast.error("Failed to merge the profiles properly");
        this.setState({ submitting: false });
      }
    } catch (error) {
      toast.error("Failed to merge the profiles");
      console.error(error);
      this.setState({ submitting: false });
    }
  };
  selectUser = (state, rowInfo) => {
    if (
      rowInfo.original &&
      rowInfo.original.ID === this.state.primaryProfile.ID
    ) {
      toast.info(
        "Cannot merge a profile with itself. Please select another profile."
      );
    }
    if (rowInfo.original && rowInfo.original) {
      this.setState({ duplicateProfile: rowInfo.original });
    }
  };
  render() {
    let { primaryProfile, loading, submitting, duplicateProfile } = this.state;
    let primaryName = primaryProfile
      ? `${primaryProfile.ID}: ${primaryProfile.firstName ||
          ""} ${primaryProfile.lastName || ""}`
      : "";
    let duplicateName = duplicateProfile
      ? `${duplicateProfile.ID}: ${duplicateProfile.firstName ||
          ""} ${duplicateProfile.lastName || ""}`
      : "";
    return (
      <Container>
        <Header as="h2">Merge Customer Profiles</Header>
        <List divided>
          <List.Item>
            <Label color="purple" horizontal>
              Primary
            </Label>
            {loading ? "Loading..." : primaryName}
          </List.Item>
          <List.Item>
            <Label color="orange" horizontal>
              Duplicate
            </Label>
            {primaryProfile &&
              duplicateProfile &&
              primaryProfile.ID === duplicateProfile.ID && (
                <Label color="red" horizontal>
                  Invalid
                </Label>
              )}
            {duplicateProfile ? duplicateName : "No duplicate selected yet"}
          </List.Item>
        </List>
        <Segment>
          <Message
            icon="search"
            header="Search for the Duplicate Profile"
            content="The profile that you select will be the profile to copy data from and deleted after merging."
          />

          <CustomerTable defaultPageSize={5} onRowClick={this.selectUser} />
        </Segment>
        <div>
          <Grid columns="equal" stackable>
            <Grid.Row>
              <Grid.Column>
                <Message warning style={{ height: "100%" }}>
                  <Message.Header>
                    Information that cannot be Merged
                  </Message.Header>
                  <Message.Content>
                    This information cannot be merged. The primary profile's
                    values will be used. The duplicate profile's value will only
                    be used if the primary profile has no value.
                  </Message.Content>
                  <Message.List>
                    <Message.Item>
                      Account Details (Name, Default Gratuity, etc.)
                    </Message.Item>
                    <Message.Item>Credit Cards</Message.Item>
                    <Message.Item>Login Email and Password</Message.Item>
                  </Message.List>
                </Message>
              </Grid.Column>
              <Grid.Column>
                <Message style={{ height: "100%" }}>
                  <Message.Header>Information to be Merged</Message.Header>
                  <Message.Content>
                    This information will be added to the primary profile, but
                    can be changed after merging to remove duplicates or old
                    data.
                  </Message.Content>
                  <Message.List>
                    <Message.Item>Addresses</Message.Item>
                    <Message.Item>Appointments</Message.Item>
                    <Message.Item>Email Addresses</Message.Item>
                    <Message.Item>Estimates</Message.Item>
                    <Message.Item>Pets</Message.Item>
                    <Message.Item>Phone Numbers</Message.Item>
                    <Message.Item>Todo Items (Can be Completed)</Message.Item>
                  </Message.List>
                </Message>
              </Grid.Column>
              <Grid.Column>
                <Message style={{ height: "100%" }}>
                  <Message.Header>Records to be Merged</Message.Header>
                  <Message.Content>
                    This information will be added to the primary profile and
                    cannot be changed after merging.
                  </Message.Content>
                  <Message.List>
                    <Message.Item>Appointment History</Message.Item>
                    <Message.Item>Comments</Message.Item>
                    <Message.Item>Complaints</Message.Item>
                    <Message.Item>Feedback</Message.Item>
                    <Message.Item>Ratings</Message.Item>
                    <Message.Item>Transactions</Message.Item>
                  </Message.List>
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div>
          <br />
          <Button
            color="green"
            onClick={this.handleSubmit}
            disabled={submitting}
          >
            Merge Profiles
          </Button>
        </div>
      </Container>
    );
  }
}

export default MergeProfiles;
