import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Rating as SemanticRating,
  Segment,
  Button,
  Header,
  Form,
  Message,
  Divider
} from "semantic-ui-react";
import TextArea from "../Shared/TextArea";

/**
 * Rating form with option for a user to explain their rating
 */
class AppointmentFeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      comment: "",
      setStylistAsPreferred: false
    };
    this.onRate = this.onRate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  onRate(event, { rating }) {
    this.setState({ rating: rating });
  }
  handleChange(event, { name, value }) {
    this.setState({ [name]: value });
  }
  handleSubmit() {
    this.props.handleSubmit(this.state);
  }
  render() {
    let { rating, comment, setStylistAsPreferred } = this.state;
    let {
      message,
      subMessage,
      submittedMessage,
      submitted,
      stylistName
    } = this.props;

    return (
      <Segment basic>
        <Header size="small">
          {message}
          <Header.Subheader>{subMessage}</Header.Subheader>
        </Header>
        <br />
        <SemanticRating
          icon="star"
          disabled={submitted}
          rating={rating}
          size="huge"
          maxRating={5}
          onRate={this.onRate}
        />
        <br />
        <br />
        <Form>
          <Form.Field>
            <label>Comments</label>
            <TextArea
              isEditing={!submitted}
              value={comment}
              name="comment"
              onChange={this.handleChange}
            />
          </Form.Field>
          <Divider hidden />
          <Form.Checkbox
            label={`Keep ${stylistName} as your preferred stylist for furture appointments`}
            checked={setStylistAsPreferred}
            name="setStylistAsPreferred"
            onChange={(e, data) =>
              this.handleChange(e, { ...data, value: data.checked })
            }
          />
          {submitted && submittedMessage && (
            <Message positive content={submittedMessage} />
          )}
          <Button
            disabled={submitted}
            content="Submit Feedback"
            onClick={this.handleSubmit}
            primary
          />
        </Form>
      </Segment>
    );
  }
}

AppointmentFeedbackForm.defaultProps = {
  message: "Rate Your Experience",
  subMessage: "How was the experience? What could we have done better?",
  submittedMessage: "Thank you for your feedback!",
  handleSubmit: () => {}
};

AppointmentFeedbackForm.propTypes = {
  message: PropTypes.string,
  subMessage: PropTypes.string,
  submittedMessage: PropTypes.string,
  handleSubmit: PropTypes.func
};

export default AppointmentFeedbackForm;
