import React, { Component } from "react";

import { Header } from "semantic-ui-react";

class FourOFour extends Component {
  render() {
    return <Header textAlign="center">Page not found</Header>;
  }
}

export default FourOFour;
