import React, { Component } from "react";
import { Form, TextArea, Header, Modal, Button } from "semantic-ui-react";
import { createServiceIssue } from "../lib/apiCalls";
import { toast } from "react-toastify";

/**
 * Modal to display a create service issue  form. Lets a user create a service issue for a completed appointment and add comments.
 */
class CreateServiceIssueModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      comment: ""
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleCreate = () => {
    createServiceIssue(
      {
        appointmentId: this.props.appointmentId,
        comment: this.state.comment
      },
      error => {
        if (this._isMounted) {
          if (error) {
            toast.error("Failed to create service issue standing appointment");
            return;
          }
          this.props.onClose();
          toast.success("Created service issue appointment");
        }
      }
    );
  };
  render() {
    let { comment } = this.state;
    let { open } = this.props;
    return (
      <Modal open={open} onClose={this.props.onClose} closeIcon>
        <Modal.Content>
          <Form>
            <Header as="h3" content="Creating a Service Issue" />
            <Form.Field>
              <label>Comments</label>{" "}
              <TextArea
                value={comment}
                name="comment"
                onChange={this.handleChange}
                placeholder="List all details necessary for this service issue."
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Create Service Issue"
            color="green"
            onClick={this.handleCreate}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

CreateServiceIssueModal.defaultProps = {
  appointmentId: null,
  open: false,
  onOpen: () => {},
  onClose: () => {}
};

export default CreateServiceIssueModal;
