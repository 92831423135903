import React, { Component } from "react";

import { Modal, Button, Segment, Menu, Grid } from "semantic-ui-react";
import { Route } from "react-router-dom";
import CustomerOverview from "./CustomerOverview";
import EstimatesTable from "../Tables/Estimates";
import PreviousAppointments from "../Tables/PreviousAppointments";
import CustomerTransactions from "../Tables/CustomerTransactions";
import UpcomingAppointments from "../Tables/UpcomingAppointments";

import AddressesTable from "../Tables/Addresses";
import PhoneNumbersTable from "../Tables/PhoneNumbers";
import EmailsTable from "../Tables/Emails";
import CreditCardsTable from "../Tables/CreditCards";

/**
 * Displays the customer profile in a modal
 */
class CustomerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      view: "overview"
    };
  }
  open = () => {
    this.setState({ open: true });
  };
  close = () => {
    this.props.onClose();
    this.setState({ open: false });
  };
  setView = (e, { name }) => {
    this.setState({ view: name });
  };
  render() {
    let { view } = this.state;
    let menuItems = [
      <Menu.Item
        header
        key="overview"
        name="overview"
        active={view === "overview"}
        onClick={this.setView}
      />,

      <Menu.Item key="information">
        <Menu.Header>Information</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            key="addresses"
            name="addresses"
            active={view === "addresses"}
            onClick={this.setView}
          />
          <Menu.Item
            key="phoneNumbers"
            name="phoneNumbers"
            active={view === "phoneNumbers"}
            onClick={this.setView}
          />
          <Menu.Item
            key="emailAddresses"
            name="emailAddresses"
            active={view === "emailAddresses"}
            onClick={this.setView}
          />
          <Menu.Item
            key="creditCards"
            name="creditCards"
            active={view === "creditCards"}
            onClick={this.setView}
          />
        </Menu.Menu>
      </Menu.Item>,

      <Menu.Item key="records">
        <Menu.Header>Records</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            key="upcomingAppointments"
            name="upcomingAppointments"
            active={view === "upcomingAppointments"}
            onClick={this.setView}
          />
          <Menu.Item
            key="appointmentHistory"
            name="appointmentHistory"
            active={view === "appointmentHistory"}
            onClick={this.setView}
          />
          <Menu.Item
            key="estimates"
            name="estimates"
            active={view === "estimates"}
            onClick={this.setView}
          />
          <Menu.Item
            key="transactionHistory"
            name="transactionHistory"
            active={view === "transactionHistory"}
            onClick={this.setView}
          />
        </Menu.Menu>
      </Menu.Item>
    ];
    return (
      <Modal
        onClose={this.close}
        size="large"
        centered={false}
        trigger={
          <Button
            onClick={this.open}
            color="purple"
            content="View Customer Information"
          />
        }
        content={
          <Segment basic>
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <Menu fluid size="large" color="purple" pointing vertical>
                    {menuItems}
                  </Menu>
                </Grid.Column>
                <Grid.Column tablet={16} computer={13}>
                  {view === "overview" && (
                    <Route component={CustomerOverview} />
                  )}
                  {view === "addresses" && <Route component={AddressesTable} />}
                  {view === "phoneNumbers" && (
                    <Route component={PhoneNumbersTable} />
                  )}
                  {view === "emailAddresses" && (
                    <Route component={EmailsTable} />
                  )}
                  {view === "creditCards" && (
                    <Route component={CreditCardsTable} />
                  )}
                  {view === "upcomingAppointments" && (
                    <Segment>
                      <UpcomingAppointments
                        userId={this.props.userId}
                        includeUserInPath
                      />
                    </Segment>
                  )}
                  {view === "appointmentHistory" && (
                    <Route
                      render={props => (
                        <PreviousAppointments
                          {...props}
                          userId={this.props.userId}
                          key={this.props.userId}
                          includeUserInPath
                        />
                      )}
                    />
                  )}
                  {view === "estimates" && (
                    <Route
                      render={props => (
                        <EstimatesTable
                          {...props}
                          userId={this.props.userId}
                          key={this.props.userId}
                          includeUserInPath
                        />
                      )}
                    />
                  )}
                  {view === "transactionHistory" && (
                    <Route
                      render={props => (
                        <CustomerTransactions
                          {...props}
                          userId={this.props.userId}
                          key={this.props.userId}
                        />
                      )}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        }
        actions={[{ key: "done", content: "Done", color: "blue" }]}
      />
    );
  }
}
CustomerModal.defaultProps = {
  onClose: () => {}
};
export default CustomerModal;
