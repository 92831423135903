import React, { Component } from "react";

import ReactTable from "react-table";
import moment from "moment";
import { toast } from "react-toastify";

import { Header, Form } from "semantic-ui-react";
import PetList from "../Shared/PetList";
import io from "../socketConnection";
import { OptionsContext } from "../OptionsContext";
import _ from "lodash";
import customFilter, { filterIncludesPet, sortByPetName } from "../lib/filters";
import { removeMapsco } from "../lib/helperFunctions";

class WaitingList extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], pages: -1, loading: false, selectedRow: null };
    this.getAppointments = this.getAppointments.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }
  componentDidMount() {
    this.getAppointments();
  }
  async getAppointments(state, instance) {
    this.setState({ loading: true });

    io.socket.get(
      "/api/v4/appointments/getAll",
      { statuses: ["waiting", "rescheduled"], includeCustomerRating: true },
      (responseData, jwres) => {
        if (jwres.error) {
          console.error(jwres.error);
          toast.error("Failed to retrieve appointments.");
          this.setState({ loading: false });
          return;
        }
        if (jwres.statusCode === 200) {
          this.setState({
            data: responseData.appointments,
            pages: responseData.pages,
          });
        }
        this.setState({ loading: false });
      }
    );
  }
  onRowClick(state, rowInfo, column) {
    if (this.state.loading === true) return;
    let url = `/dashboard/estimate/${rowInfo.original.estimateId}/user/${rowInfo.original.Customer.User.ID}`;
    this.props.history.push(url);
  }
  render() {
    const { selectedRow, data, loading } = this.state;
    return (
      <>
        <Header as="h2">Waiting List</Header>
        <OptionsContext.Consumer>
          {({ statuses, mapscoCities, stylists }) => {
            return (
              <ReactTable
                getTheadThProps={(state, rowInfo, instance) => {
                  return { className: "appointments-table-" + instance.id };
                }}
                data={data}
                filterable
                sortable
                defaultSorted={[{ id: "status" }, { id: "rating", desc: true }]}
                defaultPageSize={20}
                loading={loading}
                defaultFilterMethod={customFilter}
                className="-striped -highlight appointment-table"
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.original.ID != null) {
                    var style = { cursor: "pointer" };
                  }
                  return {
                    onClick: () => this.onRowClick(state, rowInfo, column),
                    style: style,
                    className:
                      rowInfo &&
                      selectedRow &&
                      rowInfo.original.ID === selectedRow.ID
                        ? "selected-row"
                        : undefined,
                  };
                }}
                columns={[
                  {
                    Header: "Date Added",
                    accessor: "DateAdded",
                    Cell: (props) =>
                      props.value ? moment(props.value).format("LLL") : "",
                  },
                  {
                    Header: "Status",
                    id: "status",
                    accessor: (data) => {
                      let appointmentStatus = _.find(statuses, {
                        ID: data.statusId,
                      });
                      return appointmentStatus
                        ? _.startCase(appointmentStatus.status)
                        : "";
                    },
                    minWidth: 100,
                    getProps: () => {
                      return {
                        style: {
                          textAlign: "center",
                        },
                      };
                    },
                  },
                  {
                    Header: "Preferred Stylist",
                    headerClassName: "dropdown-filter",
                    id: "Customer.preferredStylistId",
                    accessor: (data) => {
                      let employeeUser =
                        data.Customer && data.Customer.PreferredStylist
                          ? data.Customer.PreferredStylist.User
                          : null;
                      return employeeUser
                        ? `${employeeUser.firstName || ""} ${
                            employeeUser.lastName || ""
                          }`
                        : "";
                    },
                    Filter: ({ filter, onChange }) => (
                      <Form size="small">
                        <Form.Select
                          className="filter-select"
                          fluid
                          clearable
                          search
                          name="employeeId"
                          placeholder="Select Stylist"
                          options={stylists.map((stylist) => {
                            let fullName =
                              stylist && stylist.User
                                ? `${stylist.User.firstName || ""} ${
                                    stylist.User.lastName || ""
                                  }`
                                : `${stylist.EmployeeFirstName || ""} ${
                                    stylist.EmployeeLastName || ""
                                  }`;
                            return {
                              key: stylist.ID,
                              value: fullName,
                              text: fullName,
                            };
                          })}
                          value={filter ? filter.value : null}
                          onChange={(event, { name, value }) => onChange(value)}
                        />
                      </Form>
                    ),
                  },
                  {
                    Header: "Customer",
                    id: "customerId",
                    accessor: (data) =>
                      data.Customer &&
                      data.Customer.User &&
                      `${data.Customer.User.firstName} ${data.Customer.User.lastName}`,
                  },
                  {
                    Header: "Rating",
                    id: "rating",
                    accessor: (data) =>
                      data.Customer &&
                      data.Customer.User &&
                      data.Customer.User.rating
                        ? parseFloat(data.Customer.User.rating).toFixed(2) + ""
                        : "",
                  },
                  {
                    Header: "Pets",
                    id: "pets",
                    accessor: (data) =>
                      data.Estimate &&
                      data.Estimate.EstimatePets &&
                      data.Estimate.EstimatePets.map(
                        (petEstimate) => petEstimate.Pet
                      ),
                    Cell: (props) => (
                      <PetList includeDetails pets={props.value} />
                    ),
                    filterMethod: filterIncludesPet,
                    sortMethod: sortByPetName,
                  },
                  {
                    Header: "Mapsco",
                    id: "estimateAddressMapsco",
                    accessor: (data) =>
                      data.Estimate &&
                      data.Estimate.AddressString &&
                      removeMapsco(data.Estimate.AddressString).mapsco,
                    minWidth: 60,
                  },
                  {
                    Header: "Address",
                    id: "estimateAddress",
                    accessor: (data) =>
                      data.Estimate &&
                      data.Estimate.AddressString &&
                      removeMapsco(data.Estimate.AddressString).address,
                  },
                  {
                    Header: "Last Comment",
                    id: "lastComment",
                    accessor: (data) => {
                      if (
                        data.CommentGroup &&
                        data.CommentGroup.Comments &&
                        data.CommentGroup.Comments.length > 0
                      ) {
                        let lastComment = _.maxBy(
                          data.CommentGroup.Comments,
                          "DateAdded"
                        );
                        return lastComment.comment;
                      }
                      return "";
                    },
                    filterable: false,
                    minWidth: 250,
                  },
                ]}
              />
            );
          }}
        </OptionsContext.Consumer>
      </>
    );
  }
}

WaitingList.defaultProps = {
  onRowClick: null,
};

export default WaitingList;
