import React, { Component } from "react";
import { Form, Button, Divider } from "semantic-ui-react";

import Input from "../Shared/Input";
import { defaultEmail } from "../lib/defaultObjects";
import _ from "lodash";

class EmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultEmail(props.email)
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state, prevState)) {
      this.props.onUpdate(this.state);
    }
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleToggle = (e, { name }) => {
    this.setState(state => ({
      [name]: !state[name]
    }));
  };
  handleSave = () => {
    this.props.handleSave(this.state);
  };
  handleDelete = () => {
    this.props.handleDelete(this.state);
  };
  render() {
    let { emailAddress, label, isPrimary } = this.state;
    let { hideActionButtons } = this.props;

    return (
      <Form>
        <Button
          type="button"
          toggle
          content="Primary Email"
          name="isPrimary"
          active={isPrimary}
          onClick={this.handleToggle}
        />
        <Divider hidden />
        <Form.Group widths={2}>
          <Input
            label="Label"
            isEditing
            value={label}
            onChange={this.handleChange}
            name="label"
          />
          <Input
            label="Email"
            value={emailAddress}
            onChange={this.handleChange}
            name="emailAddress"
            isEditing
          />
        </Form.Group>
        {!hideActionButtons && (
          <>
            <Button color="green" content="Save" onClick={this.handleSave} />
            <Button color="red" content="Delete" onClick={this.handleDelete} />
          </>
        )}
      </Form>
    );
  }
}

EmailForm.defaultProps = {
  handleSave: () => {},
  handleDelete: () => {},
  onUpdate: () => {},
  hideActionButtons: false
};

export default EmailForm;
