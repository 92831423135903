const config = require("./localConfig.js");

var socketIOClient = require("socket.io-client");
var sailsIOClient = require("sails.io.js");

var io = sailsIOClient(socketIOClient);

io.sails.url = config.socketURL + ":" + config.port;
// Try to reconnect after a loss to the server
io.sails.reconnection = true;
//io.sails.useCORSRouteToGetCookie = true;

export default io;
