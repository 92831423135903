import React, { Component } from "react";
import { Container, Grid, Menu, Header } from "semantic-ui-react";
import { Route, Switch, Redirect } from "react-router-dom";

import CreditCardsTable from "../Tables/CreditCards";
import AddressesTable from "../Tables/Addresses";
import PhoneNumbersTable from "../Tables/PhoneNumbers";
import EmailsTable from "../Tables/Emails";
import { UserContext } from "../UserContext";

class CustomerInformation extends Component {
  constructor(props) {
    super(props);
    this.state = { view: "addresses" };
  }
  setView = (e, { name }) => {
    this.setState({ view: name });
  };
  render() {
    let { view } = this.state;
    return (
      <UserContext.Consumer>
        {({ user }) => {
          return (
            <div>
              <Header textAlign="center" as="h1">
                Information
              </Header>
              <Switch>
                <Route
                  path={"/dashboard/information/:userId(\\d+)"}
                  render={props => (
                    <Container>
                      <Grid columns="equal">
                        <Grid.Row>
                          <Grid.Column>
                            <Menu
                              fluid
                              size="large"
                              color="purple"
                              pointing
                              vertical
                            >
                              <Menu.Item
                                key="addresses"
                                name="addresses"
                                active={view === "addresses"}
                                onClick={this.setView}
                              />
                              <Menu.Item
                                key="phoneNumbers"
                                name="phoneNumbers"
                                active={view === "phoneNumbers"}
                                onClick={this.setView}
                              />
                              <Menu.Item
                                key="emailAddresses"
                                name="emailAddresses"
                                active={view === "emailAddresses"}
                                onClick={this.setView}
                              />
                              <Menu.Item
                                key="creditCards"
                                name="creditCards"
                                active={view === "creditCards"}
                                onClick={this.setView}
                              />
                            </Menu>
                          </Grid.Column>
                          <Grid.Column tablet={16} computer={13}>
                            {view === "addresses" && (
                              <Route component={AddressesTable} />
                            )}
                            {view === "phoneNumbers" && (
                              <Route component={PhoneNumbersTable} />
                            )}
                            {view === "emailAddresses" && (
                              <Route component={EmailsTable} />
                            )}
                            {view === "creditCards" && (
                              <Route component={CreditCardsTable} />
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Container>
                  )}
                />
                <Redirect to={"/dashboard/information/" + user.userId} />
              </Switch>
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default CustomerInformation;
