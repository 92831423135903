import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Header,
  Form,
  Checkbox,
  Select,
  Divider,
  Segment
} from "semantic-ui-react";

import Input from "../Shared/Input";
import { OptionsContext } from "../OptionsContext";
import Authorize from "../Shared/Authorize";
import { toast } from "react-toastify";
import TinyEditButtonGroup from "../Shared/TinyEditButtonGroup";
import { updateStylistDetails, getStylistDetails } from "../lib/apiCalls";

/**
 * Displays the fields relating to a stylist employee.
 */
class StylistDetails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isEditing: false,
      maxPetWeight: null,
      petsPerDay: null,
      averageGroomingTime: null,
      minutesPerPet: null,
      isSmoker: false,
      servicesCats: false,
      hasGeneratorVehicle: false,
      commissionRate: 45,
      stylistBreedRestrictions: [],
      User: {}
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getStylistDetails();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleCheckbox = (e, { name, checked }) => {
    this.setState({ [name]: checked });
  };
  handleToggle = (e, { name }) => {
    this.setState(state => ({ [name]: !state[name] }));
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleUserChange = (e, { name, value }) => {
    this.setState(state => ({ User: { ...state.User, [name]: value } }));
  };
  handleEdit = () => {
    this.setState({ isEditing: true });
  };
  handleCancel = () => {
    this.getStylistDetails();
  };
  handleSave = () => {
    updateStylistDetails(
      {
        employeeId: this.props.employeeId,
        ...this.state,
        User: this.state.User
      },
      (error, response) => {
        if (error) {
          toast.error("Failed to save stylist details");
          return;
        }
        toast.success("Updated stylist details");
        if (this._isMounted) {
          this.setState({ isEditing: false });
        }
      }
    );
  };
  getStylistDetails() {
    if (!this.props.employeeId) {
      this.setState({ loading: false });
      return;
    }
    getStylistDetails(
      { employeeId: this.props.employeeId },
      (error, response) => {
        if (error) {
          toast.error("Failed to retrieve stylist details");
          if (this._isMounted) {
            this.setState({ loading: false });
          }
          return;
        }
        if (this._isMounted) {
          this.setState({
            ...response,
            stylistBreedRestrictions: response.StylistBreedRestrictions
              ? response.StylistBreedRestrictions.map(rest => rest.BreedID)
              : [],
            isEditing: false,
            loading: false
          });
        }
      }
    );
  }
  render() {
    let {
      maxPetWeight,
      petsPerDay,
      averageGroomingTime,
      minutesPerPet,
      commissionRate,
      isSmoker,
      servicesCats,
      hasGeneratorVehicle,
      stylistBreedRestrictions,

      isEditing,
      loading
    } = this.state;

    return (
      <Segment loading={loading}>
        <Header floated="left">Stylist Details</Header>
        <Authorize permission="EditEmployees">
          <TinyEditButtonGroup
            isEditing={isEditing}
            onSave={this.handleSave}
            onEdit={this.handleEdit}
            onCancel={this.handleCancel}
          />
        </Authorize>
        <Divider clearing />
        <Form>
          <Form.Group widths="2">
            <Input
              label="Pets per Day"
              value={petsPerDay}
              name="petsPerDay"
              min={0}
              step={1}
              type="number"
              validation={["integer", "positive"]}
              onChange={this.handleChange}
              isEditing={isEditing}
            />
            <Input
              label="Minutes per Pet"
              value={minutesPerPet}
              placeholder="Minutes"
              min={0}
              step={1}
              type="number"
              validation={["integer", "positive"]}
              name="minutesPerPet"
              onChange={this.handleChange}
              isEditing={isEditing}
            />
          </Form.Group>
          <Form.Group widths="2">
            <Input
              label="Max Pet Weight"
              placeholder="Weight"
              unit="lbs"
              max={1000}
              min={0}
              step={1}
              type="number"
              validation={["integer", "positive"]}
              name="maxPetWeight"
              isEditing={isEditing}
              value={maxPetWeight}
              onChange={this.handleChange}
            />

            <Input
              label="Average Groom Time"
              value={averageGroomingTime}
              placeholder="Minutes"
              name="averageGroomingTime"
              onChange={this.handleChange}
              isEditing={false}
            />
          </Form.Group>

          <Input
            width={8}
            label="Commission Rate"
            name="commissionRate"
            attachedLabel="%"
            type="number"
            onChange={this.handleChange}
            value={commissionRate}
            validation={["positive"]}
            isEditing={isEditing}
            labelPosition="right"
            placeholder="Enter percent..."
          />

          <Form.Field>
            <Checkbox
              label="Services Cats"
              name="servicesCats"
              readOnly={!isEditing}
              checked={servicesCats}
              onChange={this.handleCheckbox}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Smoker"
              name="isSmoker"
              readOnly={!isEditing}
              checked={isSmoker}
              onChange={this.handleCheckbox}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              name="hasGeneratorVehicle"
              label="Has Generator Vehicle"
              readOnly={!isEditing}
              checked={hasGeneratorVehicle}
              onChange={this.handleCheckbox}
            />
          </Form.Field>
          <OptionsContext.Consumer>
            {({ breeds }) => {
              let options = breeds.map(breed => ({
                text: breed.breed,
                key: breed.ID,
                value: breed.ID
              }));
              return (
                <Form.Field>
                  <label>Restricted Breeds</label>
                  <Select
                    clearable
                    placeholder="Select Restricted Breeds"
                    name="stylistBreedRestrictions"
                    multiple
                    search
                    value={stylistBreedRestrictions}
                    options={options}
                    disabled={!isEditing}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              );
            }}
          </OptionsContext.Consumer>
        </Form>
      </Segment>
    );
  }
}

StylistDetails.defaultProps = {
  onUpdate: () => {},
  employee: {},
  isEditing: false
};
StylistDetails.propTypes = {
  onUpdate: PropTypes.func,
  employee: PropTypes.object,
  isEditing: PropTypes.bool
};

export default StylistDetails;
