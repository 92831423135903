import React from "react";
import { Table } from "semantic-ui-react";
import ErrorBoundary from "../Shared/ErrorBoundary";

function SelectedServices(props) {
  // Initialize empty row
  let rows = [];
  for (let i in props.selectedServices) {
    let price =
      props.useAddonPrices && props.selectedServices[i].addonPrice
        ? props.selectedServices[i].addonPrice
        : props.selectedServices[i].price;
    rows.push(
      <Table.Row key={props.selectedServices[i].ID}>
        <Table.Cell>{props.selectedServices[i].item}</Table.Cell>
        <Table.Cell>${price}</Table.Cell>
      </Table.Row>
    );
  }
  if (rows.length < 1) {
    rows.push(
      <Table.Row key="no">
        <Table.Cell colSpan="2">No Extra Services</Table.Cell>
      </Table.Row>
    );
  }
  return (
    <ErrorBoundary>
      <Table basic>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="4">Selected Services</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    </ErrorBoundary>
  );
}

SelectedServices.defaultProps = {
  selectedServices: {},
  useAddonPrices: false
};

export default SelectedServices;
