import React, { Component } from "react";

import { Form, Button, Modal, Segment } from "semantic-ui-react";
import Input from "../Input";
import { toast } from "react-toastify";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
import Recurring from "../../Forms/Recurring";
import { defaultEvent } from "../../lib/defaultObjects";
import Authorize from "../Authorize";

class ExceptionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: true,
      isWorking: props.isWorking,
      event: defaultEvent(props.event || {})
    };
  }
  updateRecurring = recurringPattern => {
    let event = { ...this.state.event };
    event.RecurringPattern = recurringPattern;
    this.setState({ event: event });
  };
  handleChange = (e, { name, value }) => {
    this.setState(state => ({ event: { ...state.event, [name]: value } }));
  };

  handleSave = () => {
    let { isWorking, event } = this.state;
    let failMessage = null;
    if (event.title == null || event.title === "") {
      failMessage = "Title is required";
    }
    if (event.startDate == null || event.startDate === "") {
      failMessage = "A start date is required";
    }
    if (
      event.isAllDay !== true &&
      (!event.startTime || event.startTime === "")
    ) {
      failMessage = "A start time is required";
    }
    if (event.endDate == null || event.endDate === "") {
      failMessage = "An end date is required";
    }
    if (event.isAllDay !== true && (!event.endTime || event.endTime === "")) {
      failMessage = "An end time is required";
    }
    if (
      event.isRecurring &&
      (event.RecurringPattern.startDate == null ||
        event.RecurringPattern.startDate === "")
    ) {
      failMessage = "A start date is required for the recurrence";
    }

    if (failMessage != null) {
      toast.info(failMessage);
      return;
    }

    this.props.handleSave({ event, isWorking });
  };
  handleCheckboxChange = (e, { checked, name }) => {
    this.setState({
      [name]: checked
    });
  };

  handleEventCheckboxChange = (e, { checked, name }) => {
    this.setState(state => ({
      event: {
        ...state.event,
        [name]: checked
      }
    }));
    if (checked === true && name === "isAllDay") {
      this.setState(state => ({
        event: {
          ...state.event,
          startTime: "12:00 AM",
          endTime: "11:59 PM"
        }
      }));
    }
  };

  render() {
    let { isEditing, event, isWorking } = this.state;
    let { open } = this.props;

    return (
      <div>
        <Modal open={open} closeIcon onClose={this.props.onClose}>
          <Modal.Header>{!event.ID && "New "}Exception</Modal.Header>{" "}
          <Modal.Content>
            <Form onSubmit={this.handleSave} autoComplete="off">
              <Input
                required
                isEditing={isEditing}
                label="Title"
                value={event.title}
                name="title"
                onChange={this.handleChange}
              />
              <Form.Checkbox
                label="Working"
                checked={isWorking}
                name="isWorking"
                onChange={this.handleCheckboxChange}
              />
              <Form.Checkbox
                label="All Day"
                checked={event.isAllDay}
                name="isAllDay"
                onChange={this.handleEventCheckboxChange}
              />
              <Form.Group widths="2">
                <DateInput
                  animation="none"
                  label="Start Date"
                  required
                  placeholder="Select Starting Date"
                  value={event.startDate}
                  dateFormat={"MM-DD-YYYY"}
                  name="startDate"
                  onChange={this.handleChange}
                />

                <TimeInput
                  animation="none"
                  label="Start Time"
                  required={event.isAllDay !== true}
                  disabled={event.isAllDay === true}
                  placeholder="Select Starting Time"
                  value={event.startTime}
                  timeFormat={"AMPM"}
                  name="startTime"
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group widths="2">
                <DateInput
                  animation="none"
                  label="End Date"
                  required
                  placeholder="Select Ending Date"
                  value={event.endDate}
                  dateFormat={"MM-DD-YYYY"}
                  name="endDate"
                  onChange={this.handleChange}
                />
                <TimeInput
                  animation="none"
                  label="End Time"
                  required={event.isAllDay !== true}
                  disabled={event.isAllDay === true}
                  placeholder="Select Ending Time"
                  value={event.endTime}
                  timeFormat={"AMPM"}
                  name="endTime"
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Checkbox
                label="Recurring"
                name="isRecurring"
                readOnly={event.ID !== null}
                disabled={event.ID !== null}
                checked={event.isRecurring}
                onChange={(e, data) =>
                  this.handleChange(e, { name: data.name, value: data.checked })
                }
              />
              {event.isRecurring === true && (
                <Segment>
                  <Recurring
                    onUpdate={this.updateRecurring}
                    recurringPattern={event.RecurringPattern}
                  />
                </Segment>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {event.ID && (
              <Button
                content="Delete"
                color="red"
                onClick={this.props.handleDelete}
              />
            )}
            <Button
              content={event.ID !== null ? "Save" : "Create"}
              color="green"
              onClick={this.handleSave}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

ExceptionDetails.defaultProps = {
  open: false,
  event: {},
  onClose: () => {},
  handleSave: () => {},
  handleDelete: () => {}
};
export default props => (
  <Authorize permission="ViewExceptions">
    <ExceptionDetails {...props} />
  </Authorize>
);
