import React, { Component } from "react";
import ReactTable from "react-table";

import _ from "lodash";

import io from "../socketConnection";
import { toast } from "react-toastify";
import { OptionsContext } from "../OptionsContext";
import customFilter from "../lib/filters";
import { formatPhoneNumber } from "../lib/helperFunctions";

class EmployeeTable extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { data: [], loading: false, selectedRow: null };
    this.getEmployees = this.getEmployees.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    this.getEmployees();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getEmployees = (state, instance) => {
    this.setState({ loading: true });

    io.socket.get("/api/v4/users/getEmployees", {}, (body, JWR) => {
      if (this._isMounted) {
        if (JWR.statusCode !== 200) {
          toast.error("Failed to receive employees.");
        }
        this.setState({
          data: body.rows,
          loading: false
        });
      }
    });
  };
  onRowClick(state, rowInfo, column) {
    if (this.state.loading === true) return;
    if (this.props.onRowClick != null) {
      this.setState({ selectedRow: rowInfo.original });
      this.props.onRowClick(state, rowInfo, column);
    } else {
      this.props.history.push(this.props.match.path + rowInfo.original.ID, {
        backPath: this.props.match.path
      });
    }
  }
  render() {
    const { selectedRow, data, loading } = this.state;
    return (
      <OptionsContext.Consumer>
        {({ userTypes }) => {
          return (
            <ReactTable
              getTheadThProps={(state, rowInfo, instance) => {
                return { className: "employee-table-" + instance.id };
              }}
              filterable
              defaultFilterMethod={customFilter}
              defaultSorted={[{ id: "userTypeId" }, { id: "firstName" }]}
              sortable
              data={data}
              columns={[
                { Header: "First Name", accessor: "firstName" },
                { Header: "Last Name", accessor: "lastName" },
                {
                  Header: "User Type",
                  id: "userTypeId",
                  accessor: data => {
                    if (!data.UserType) return;
                    let userType = _.find(userTypes, { ID: data.UserType.ID });
                    if (userType) {
                      return userType.UserType;
                    }
                    return "";
                  },
                  minWidth: 50
                },
                {
                  Header: "Address",
                  id: "address",
                  accessor: data =>
                    data.Addresses && data.Addresses[0]
                      ? data.Addresses[0].address
                      : ""
                },
                {
                  Header: "City",
                  id: "city",
                  maxWidth: 120,
                  accessor: data =>
                    data.Addresses && data.Addresses[0]
                      ? data.Addresses[0].city
                      : ""
                },
                {
                  Header: "State",
                  id: "state",
                  maxWidth: 75,
                  accessor: data =>
                    data.Addresses && data.Addresses[0]
                      ? data.Addresses[0].state
                      : ""
                },
                {
                  Header: "ZIP Code",
                  id: "zipcode",
                  maxWidth: 75,
                  accessor: data =>
                    data.Addresses && data.Addresses[0]
                      ? data.Addresses[0].zipcode
                      : ""
                },
                {
                  Header: "Phone",
                  id: "phoneNumber",
                  maxWidth: 100,
                  accessor: data =>
                    data.PhoneNumbers && data.PhoneNumbers[0]
                      ? data.PhoneNumbers[0].phoneNumber
                      : "",
                  Cell: props => formatPhoneNumber(props.value)
                },
                {
                  Header: "Email",
                  id: "emailAddress",
                  accessor: data =>
                    data.Emails && data.Emails[0]
                      ? data.Emails[0].emailAddress
                      : ""
                },
                {
                  Header: "Last Comment",
                  accessor: data => {
                    if (
                      data &&
                      data.CommentGroup &&
                      data.CommentGroup.Comments &&
                      data.CommentGroup.Comments.length > 0
                    ) {
                      let lastComment = _.maxBy(
                        data.CommentGroup.Comments,
                        "DateAdded"
                      );
                      return lastComment.comment;
                    }
                    return "";
                  },
                  id: "Comments",
                  minWidth: 200
                }
              ]}
              loading={loading}
              className="-striped -highlight employee-table"
              {...this.props.tableProps}
              getTrProps={(state, rowInfo, column) => {
                if (rowInfo && rowInfo.original.ID != null) {
                  var style = { cursor: "pointer" };
                }
                return {
                  onClick: () => this.onRowClick(state, rowInfo, column),
                  style: style,
                  className:
                    rowInfo &&
                    selectedRow &&
                    rowInfo.original.ID === selectedRow.ID
                      ? "selected-row"
                      : undefined
                };
              }}
            />
          );
        }}
      </OptionsContext.Consumer>
    );
  }
}

EmployeeTable.defaultProps = {
  tableProps: {},
  onRowClick: null
};

export default EmployeeTable;
