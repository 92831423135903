import React, { Component } from "react";

import { Header, Segment, Icon, Divider, Modal } from "semantic-ui-react";
import { defaultEstimate } from "../lib/defaultObjects";

import io from "../socketConnection";
import { toast } from "react-toastify";
import _ from "lodash";

import AppointmentStandingDetails from "../Forms/AppointmentStandingDetails";
import PreviewEstimate from "./PreviewEstimate";
import {
  updateStandingAppointment,
  deleteStandingAppointment
} from "../lib/apiCalls";
import StandingAppointment from "../Forms/StandingAppointment";

class AppointmentStandingPreview extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      estimate: null,
      customer: null,
      openStandingModal: false,
      newStandingAppointment: null
    };
    this.loadEstimate = this.loadEstimate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let { estimateId } = this.props;
    if (estimateId) {
      this.loadEstimate(estimateId);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  loadEstimate(id) {
    this.setState({ loading: true });
    io.socket.get(
      "/api/v4/estimate/findById",
      { estimateId: id },
      (responseData, jwres) => {
        if (jwres.error) {
          console.error(jwres.error);
          toast.error("Failed to load estimate.");
          this.setState({ estimate: null, loading: false });
          return;
        }
        if (this._isMounted) {
          let estimate = responseData.estimate;
          let customer = responseData.customer;
          if (jwres.statusCode === 200 && estimate) {
            if (estimate.EstimatePets) {
              for (let estimatePet in estimate.EstimatePets) {
                let services = {};
                for (let extra of estimate.EstimatePets[estimatePet]
                  .EstimatePetExtras) {
                  let foundService = _.find(this.props.services, {
                    ID: extra.EstimateExtraID
                  });
                  if (foundService) {
                    services[foundService.item] = foundService;
                  }
                }
                estimate.EstimatePets[estimatePet] = {
                  ...estimate.EstimatePets[estimatePet],
                  services: services
                };
              }
            }
            this.setState({
              loading: false,
              customer: customer,
              estimate: defaultEstimate({
                ...estimate,
                salesTax: this.props.salesTax,
                fuelCharge: this.props.fuelCharge,
                tripFee: this.props.tripFee,
                generatorFee: this.props.generatorFee
              })
            });
          }
        } else {
          toast.error("Failed to load estimate.");
          this.setState({ loading: false });
        }
      }
    );
  }

  handleDelete(data) {
    deleteStandingAppointment({ ...data }, (error, response) => {
      if (!error) {
        this.props.onAppointmentAction();
      }
    });
  }
  handleCreate(data) {
    this.openStandingModal(data);
  }
  handleSave(data) {
    if (data.isStanding === true) {
      // Update an existing standing appointment
      updateStandingAppointment(
        { ...data, estimateId: this.props.estimateId },
        (error, response) => {
          if (!error) {
            this.props.onAppointmentAction();
            toast.success("Successfully updated standing appointment.");
          } else {
            toast.error("Failed to update standing appointment.");
          }
        }
      );
    }
  }
  openStandingModal = newStandingAppointment => {
    this.setState({
      openStandingModal: true,
      newStandingAppointment: newStandingAppointment
    });
  };
  closeStandingModal = () => {
    this.setState({ openStandingModal: false });
  };
  render() {
    let {
      loading,
      estimate,
      customer,
      openStandingModal,
      newStandingAppointment
    } = this.state;
    let { appointment } = this.props;

    if (loading) {
      return <Segment loading placeholder basic />;
    }

    if (!appointment && !estimate) {
      return (
        <Segment basic placeholder>
          <Header icon>
            <Icon name="calendar check" />
            Select an Appointment to See Details
          </Header>
        </Segment>
      );
    }
    return (
      <div>
        {/* Form for creating a standing appointment */}
        <Modal
          size="large"
          onClose={this.closeStandingModal}
          open={openStandingModal}
          closeIcon
        >
          <Modal.Header>Creating Standing Appointment</Modal.Header>
          <Modal.Content>
            <StandingAppointment
              appointment={newStandingAppointment || appointment}
              onSave={this.props.onAppointmentAction}
            />
          </Modal.Content>
        </Modal>

        <Header as="h3" content={"Selected Appointment"} block />
        <Segment basic>
          <AppointmentStandingDetails
            key={
              appointment
                ? appointment.ID || appointment.appointmentDate
                : undefined
            }
            onDelete={this.handleDelete}
            onSave={this.handleSave}
            onCreate={this.handleCreate}
            columns={1}
            {...appointment}
          />
        </Segment>
        <Divider hidden />
        {estimate && (
          <PreviewEstimate
            estimate={estimate}
            customer={customer}
            appointmentId={appointment ? appointment.ID : null}
          />
        )}
      </div>
    );
  }
}

AppointmentStandingPreview.defaultProps = {
  appointment: null,
  estimateId: null,
  statuses: [],
  onAppointmentAction: () => {}
};

export default AppointmentStandingPreview;
