import React, { Component } from "react";

import customFilter from "../lib/filters";
import ReactTable from "react-table";
import moment from "moment";

class Pets extends Component {
  render() {
    let { pets, loading, handlePetSelect } = this.props;
    return (
      <div>
        <ReactTable
          loadingText="Loading Pets..."
          data={pets.length > 0 ? pets : [{ petName: "No Pets Yet" }]}
          defaultFilterMethod={customFilter}
          loading={loading}
          className="-highlight"
          filterable
          showPagination={pets.length > 20}
          minRows={1}
          getTrProps={(state, rowInfo) => {
            return {
              onClick:
                pets && pets.length > 0
                  ? (e, handleOriginal) => handlePetSelect(rowInfo.original)
                  : undefined
            };
          }}
          columns={[
            {
              Header: "Name",
              accessor: "petName"
            },
            {
              Header: "Breed",
              accessor: "Breed.breed"
            },
            {
              Header: "birthday",
              id: "birthday",
              accessor: row => moment(row.birthday).format("dddd, MMMM Do YYYY")
            },
            {
              Header: "Type",
              accessor: "animalType.type"
            },
            {
              Header: "Sex",
              accessor: "sex"
            },
            {
              Header: "Is Deceased",
              id: "isDeceased",
              accessor: row => (row.isDeceased === true ? "Yes" : "No")
            }
          ]}
        />
      </div>
    );
  }
}

Pets.defaultProps = {
  pets: [],
  handlePetSelect: () => {}
};

export default Pets;
