import React, { Component } from "react";
import { Form } from "semantic-ui-react";

import _ from "lodash";
import { moneyFormat } from "../lib/format";

const removed = ["none", "dip", "coupon"];

class Services extends Component {
  render() {
    let {
      handleServiceSelect,
      selectedServices,
      allServices,
      useAddonPrices
    } = this.props;
    let options = allServices
      .filter(service => !removed.includes(service.category))
      .map(service => {
        let price =
          useAddonPrices && service.addonPrice
            ? service.addonPrice
            : service.price;
        return {
          text: service.item,
          value: service.ID,
          key: service.ID,
          description: "$" + moneyFormat(price)
        };
      });
    let values = _.map(selectedServices, s => s.ID);
    return (
      <Form.Select
        multiple
        search
        fluid
        label="Products and Services"
        placeholder="Search for Products or Services"
        onChange={handleServiceSelect}
        options={options || []}
        value={values}
      />
    );
  }
}

Services.defaultProps = {
  allServices: [],
  selectedServices: {},
  handleServiceSelect: () => {},
  useAddonPrices: false
};

export default Services;
