import React, { Component } from "react";
import PropTypes from "prop-types";

import { Card, List, Button, Popup, Label } from "semantic-ui-react";
import _ from "lodash";
import {
  calculatePetEstimate,
  getPackagePrices
} from "../lib/estimateCalculations";

import moment from "moment";

/**
 * Takes details from a pet and an estimate and displays quick to view details
 * @prop {object} pet Details for the pet and its estimate
 * @prop {object} estimate Details for the estimate
 */
class EstimateCard extends Component {
  render() {
    let {
      Pet,
      services,
      estimatePackage,
      includeInEstimate,
      toggleIncludeInEstimate,
      handleSelect,
      breeds
    } = this.props;

    if (!Pet) return null;

    let breedDetails = _.find(breeds, { ID: Pet.breedId });
    let selectedServices = [];
    for (let each in services) {
      selectedServices.push(
        <List.Item key={services[each].ID}>
          <List.Content>{services[each].item}</List.Content>
        </List.Item>
      );
    }

    let petIcon = undefined;
    if (Pet.animalType != null) {
      if (Pet.animalType.type === "Cat") petIcon = "fas fa-cat";

      if (Pet.animalType.type === "Dog") petIcon = "fas fa-dog";
    }

    let packagePrices = breedDetails
      ? getPackagePrices(breedDetails, Pet.lastGroomDate)
      : null;

    let lastGroomed = Pet.lastGroomDate
      ? moment(Pet.lastGroomDate, "MM-DD-YYYY").format("LLL")
      : "N/A";
    if (lastGroomed.toLowerCase() === "invalid date") lastGroomed = "N/A";
    return (
      <Card>
        <Card.Content>
          <Card.Header>
            <i className={petIcon} /> {(Pet.petName || "Pet") + "'s Estimate  "}
            <Label color="green" size="small" tag>
              $
              {calculatePetEstimate(
                { ...this.props },
                packagePrices == null ? 0 : packagePrices
              )}
            </Label>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <List>
            <List.Item>
              <List.Content>
                <List.Header>Breed</List.Header>
                {_.find(breeds, { ID: Pet.breedId })
                  ? _.find(breeds, { ID: Pet.breedId }).breed
                  : "N/A"}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Package</List.Header>
                {estimatePackage}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Last Groomed</List.Header>
                {lastGroomed}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Extra Services</List.Header>
                {selectedServices.length > 0
                  ? selectedServices.map(service => service)
                  : "None"}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                {Pet.isDeceased === true && (
                  <Label size="mini" content="Deceased" color="red" />
                )}
              </List.Content>
            </List.Item>
          </List>
        </Card.Content>
        <Card.Content extra>
          <Button.Group size="small" fluid widths={2}>
            <Popup
              inverted
              trigger={
                <Button
                  basic={!includeInEstimate || Pet.isDeceased === true}
                  onClick={toggleIncludeInEstimate}
                  color={Pet.isDeceased !== true ? "green" : "grey"}
                  content="Include"
                  disabled={Pet.isDeceased === true}
                />
              }
              content="Toggle Including in the Final Estimate"
            />

            <Button onClick={handleSelect} color="blue" content="Details" />
          </Button.Group>
        </Card.Content>
      </Card>
    );
  }
}

EstimateCard.defaultProps = {
  Pet: {}
};

EstimateCard.propTypes = {
  Pet: PropTypes.object
};

export default EstimateCard;
