import PropTypes from "prop-types";
import React, { Component } from "react";

import { Menu, Responsive, Icon, Dropdown, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";

import MenuItems from "./MenuItems";
import Image from "../Shared/Image";

import Axios from "axios";
import { UserContext } from "../UserContext";
import Authorize from "../Shared/Authorize";
import { toast } from "react-toastify";

class DesktopContainer extends Component {
  state = {
    activeItem: "customer"
  };
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  handleClick = async () => {
    try {
      await Axios.get("/api/v4/account/logout");
      this.props.setUser({ userId: null });
    } catch (error) {
      console.error(error);
    }
  };
  updateExtension = e => {
    if (window.localStorage.getItem("extensionNumber") !== e.target.value) {
      window.localStorage.setItem("extensionNumber", e.target.value);
      if (e.target.value === "") {
        toast.info("Stopped listening for calls on an extension");
      } else {
        toast.info("Now listening for calls on extension " + e.target.value);
      }
    }
  };
  render() {
    const { children } = this.props;

    const { activeItem } = this.state;

    return (
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Menu
          className="navbar"
          fixed="top"
          color="purple"
          inverted
          size="large"
          id="desktop-navigation-menu"
        >
          <Menu.Item header as={Link} to="/dashboard">
            CRMS
          </Menu.Item>
          <MenuItems
            activeItem={activeItem}
            handleItemClick={this.handleItemClick}
          />
          <UserContext.Consumer>
            {({ user }) => (
              <Menu.Menu position="right">
                <Authorize permission="UsePhoneSystem">
                  <Menu.Item>
                    <Input
                      icon="phone"
                      iconPosition="left"
                      size="small"
                      id="navbar-extension"
                      defaultValue={window.localStorage.getItem(
                        "extensionNumber"
                      )}
                      maxLength="4"
                      placeholder="Extension"
                      name="extensionNumber"
                      onBlur={this.updateExtension}
                    />
                  </Menu.Item>
                </Authorize>

                <Dropdown
                  inline
                  item
                  icon={null}
                  trigger={
                    <>
                      <div className="avatar-icon">
                        <Image avatar src="/api/v4/profile/getProfilePicture">
                          <Icon
                            style={{ backgroundColor: "white" }}
                            circular
                            color="purple"
                            name="user"
                          />
                        </Image>
                      </div>
                      {user.firstName || user.lastName
                        ? `${user.firstName || ""} ${user.lastName || ""}`
                        : "User"}
                    </>
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon="sign-out"
                      text="Logout"
                      onClick={this.handleClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            )}
          </UserContext.Consumer>
        </Menu>

        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: null,
  setUser: () => {}
};

DesktopContainer.propTypes = {
  children: PropTypes.element.isRequired,
  setUser: PropTypes.func
};

export default DesktopContainer;
