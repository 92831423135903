import Axios from "axios";
import { toast } from "react-toastify";

import { getToken } from "./csrfToken";
import io from "../socketConnection";
import { defaultUser } from "./defaultObjects";

export async function onAddToWaitList(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/create",
      { ...data, addToWaitingList: true },
      {
        headers: { "X-CSRF-Token": getToken() }
      }
    );
    if (response.status === 200) {
      toast.success("Successfully Added to Waiting List");
    }
    callback(null, response);
  } catch (error) {
    console.error(error);
    toast.error("Failed to add to Waiting List");
    callback(error);
  }
}

export function findUserById(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/user/find",
    { ID: data.userId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      let user = responseData;
      let convertedUser = {
        ID: user.ID,
        Name: {
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName
        },
        Customer: {
          ...user.customer,
          Pets: user.customer ? user.customer.pets : []
        },
        notes: user.Notes,
        birthday: user.birthday,
        commentGroupId: user.commentGroupId,
        PhoneNumbers: user.phoneNumbers,
        CreditCards: user.creditCards,
        Addresses: user.addresses,
        Emails: user.emails,
        UserTypeID: user.UserTypeID
      };
      callback(null, defaultUser(convertedUser));
      return;
    }
  );
}

export function getPrivacyPolicy(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/entrance/privacyPolicy",
    null,
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}

export function getTermsOfService(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/entrance/termsOfService",
    null,
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export function getCustomerDetails(data, callback = () => {}) {
  io.socket.get(
    `/api/v4/user/getCustomerDetails`,
    { customerId: data.customerId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export async function updateCustomerDetails(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/user/updateCustomerDetails",
      {
        customerId: data.customerId,
        isSeniorCitizen: data.isSeniorCitizen,
        isMilitary: data.isMilitary,
        isTaxExempt: data.isTaxExempt,
        tripCharge: data.tripCharge,
        defaultGratuityPercent: data.defaultGratuityPercent,
        defaultGratuityDollar: data.defaultGratuityDollar,
        contactMethodId: data.contactMethodId,
        preferredStylistId: data.preferredStylistId,
        User: data.User
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export function getCustomerDefaultGratuity(data, callback = () => {}) {
  io.socket.get(
    `/api/v4/user/getCustomerDefaultGratuity`,
    { userId: data.userId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export function getCustomerPolicyStatus(data, callback = () => {}) {
  io.socket.get(
    `/api/v4/user/getCustomerPolicyStatus`,
    { userId: data.userId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export function getUserDetails(data, callback = () => {}) {
  io.socket.get(
    `/api/v4/user/getDetails`,
    { userId: data.userId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export async function updateUserDetails(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/user/updateDetails",
      {
        userId: data.userId,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        birthday: data.birthday,
        userTypeId: data.userTypeId
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function acceptPolicies(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/user/customerAcceptedPolicies",
      {
        userId: data.userId,
        acceptedPolicies: data.acceptedPolicies
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export function getEmployeeDetails(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/user/getEmployeeDetails",
    { employeeId: data.employeeId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export async function updateEmployeeDetails(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/user/updateEmployeeDetails",
      {
        employeeId: data.employeeId,
        ...data,
        User: data.User
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export function getStylistDetails(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/user/getStylistDetails",
    { employeeId: data.employeeId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export async function updateStylistDetails(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/user/updateStylistDetails",
      {
        employeeId: data.employeeId,
        ...data,
        User: data.User
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export function getContactMethods(callback = () => {}) {
  io.socket.get(
    "/api/v4/management/getContactMethods",
    null,
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export function getUpcomingAppointmentsByCustomer(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/appointments/findUpcomingByCustomer",
    {
      userId: data.userId
    },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
/**
 * Calls the request standing appointment route from the api to create a service issue for a manager
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function updateOtherOptions(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/management/updateOtherOptions",
      {
        defaultContactMethodId: data.defaultContactMethodId
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export function getBreeds(data, callback = () => {}) {
  io.socket.get("/api/v4/management/getBreeds", null, (responseData, jwres) => {
    if (jwres.error) {
      callback(jwres.error);
      console.error(jwres.error);
      return;
    }
    callback(null, responseData);
    return;
  });
}
/**
 * Calls the request standing appointment route from the api to create a service issue for a manager
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function requestStandingAppointment(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/requestStanding",
      {
        appointmentId: data.appointmentId,
        comment: data.comment
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function createServiceIssue(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/createServiceIssue",
      {
        appointmentId: data.appointmentId,
        comment: data.comment
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

/**
 * Calls the create standing appointment route from the api to create a new standing appointment
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function createStandingAppointment(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/createStanding",
      {
        appointmentId: data.appointmentId,
        employeeId: data.employeeId,
        startTime: data.startTime,
        endTime: data.endTime,
        event: data.event
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
/**
 * Calls the update standing appointment route from the api to update a existing standing appointment
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function updateStandingAppointment(data, callback = () => {}) {
  if (!data.startTime) {
    toast.info("A starting time is required for a standing appointment");
    callback("failed");
    return;
  }
  try {
    let response = await Axios.post(
      "/api/v4/appointments/updateStanding",
      {
        appointmentId: data.ID,
        resolveConfliction: data.resolveConfliction,
        statusId: data.statusId,
        employeeId: data.employeeId,
        startTime: data.startTime,
        endTime: data.endTime
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function updateStandingAppointmentEvent(
  data,
  callback = () => {}
) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/updateStandingEvent",
      {
        ...data,
        appointmentId: data.appointmentId,
        startTime: data.startTime,
        endTime: data.endTime
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export async function deleteStandingAppointment(data, callback = () => {}) {
  try {
    let response = await Axios.delete("/api/v4/appointments/deleteStanding", {
      params: { appointmentId: data.ID },
      headers: { "X-CSRF-Token": getToken() }
    });
    if (response.status === 200) {
      toast.success("Successfully deleted standing appointment.");
    }
    callback(null, response.data);
  } catch (error) {
    toast.error("Failed to delete standing appointment.");
    console.error(error);
    callback(error);
  }
}

/**
 * Calls the update appointment api with flag to be deleted
 * @param {object} data Appointment information to be saved. Must have an estimateId
 * @param {function} callback
 */
export async function deleteAppointment(data, callback = () => {}) {
  try {
    let response = await Axios.delete("/api/v4/appointments/delete", {
      params: { appointmentId: data.ID },
      headers: { "X-CSRF-Token": getToken() }
    });
    if (response.status === 200) {
      toast.success("Successfully deleted appointment.");
    }
    callback(null, response.data);
  } catch (error) {
    toast.error("Failed to delete appointment.");
    console.error(error);
    callback(error);
  }
}

export async function cancelAppointment(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/cancel",
      { appointmentId: data.ID },
      {
        headers: { "X-CSRF-Token": getToken() }
      }
    );
    if (response.status === 200) {
      toast.success("Successfully cancelled appointment.");
    }
    callback(null, response.data);
  } catch (error) {
    toast.error("Failed to cancel appointment.");
    console.error(error);
    callback(error);
  }
}
/**
 * Calls the create appointment api route with data and sets the status to scheduled
 * @param {object} data Appointment information to be saved. Must have an estimateId
 * @param {function} callback
 */
export async function createAppointment(data, callback = () => {}) {
  if (
    !data.estimateId ||
    !data.startTime ||
    !data.appointmentDate ||
    !data.employeeId
  ) {
    toast.info("Please fill out the required fields for the appointment.");
    callback("failed");
    return;
  }
  try {
    let response = await Axios.post(
      "/api/v4/appointments/create",
      {
        ...data
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.status === 200) {
      toast.success("Successfully created appointment.");
    }
    callback(null, response.data);
  } catch (error) {
    toast.error("Failed to create appointment.");
    console.error(error);
    callback(error);
  }
}
/**
 * Calls the update appointment api route with data
 * @param {object} data Appointment information to be saved. Must have an estimateId
 * @param {function} callback
 */
export async function updateAppointment(data, callback = () => {}) {
  if (!data.startTime || !data.appointmentDate || !data.employeeId) {
    toast.info("Please fill out the required fields for the appointment.");
    callback("failed");
    return;
  }
  try {
    let response = await Axios.post(
      "/api/v4/appointments/update",
      {
        ...data,
        appointmentId: data.ID
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.status === 200) {
      toast.success("Successfully updated appointment.");
    }
    callback(null, response.data);
  } catch (error) {
    toast.error("Failed to updated appointment.");
    console.error(error);
    callback(error);
  }
}

/**
 * Calls the reschedule appointment api route with data and sets the status to rescheduled.
 * @param {object} data Appointment information to be saved.
 * @param {function} callback
 */
export async function rescheduleAppointment(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/reschedule",
      { appointmentId: data.ID },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.status === 200) {
      toast.success("Successfully set appointment status to rescheduled.");
    }
    callback(null, response.data);
  } catch (error) {
    toast.error('Failed to set appointment to status of "rescheduled".');
    console.error(error);
    callback(error);
  }
}

export async function confirmAppointment(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/confirm",
      { appointmentId: data.ID },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.status === 200) {
      toast.success("Successfully set appointment status to confirmed.");
    }
    callback(null, response.data);
  } catch (error) {
    toast.error("Failed to set appointment to status of confirmed.");
    console.error(error);
    callback(error);
  }
}
export async function resolveServiceIssue(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/resolveServiceIssue",
      { appointmentId: data.ID },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.status === 200) {
      toast.success("Successfully resolved service issue.");
    }
    callback(null, response.data);
  } catch (error) {
    toast.error("Failed to resolve service issue.");
    console.error(error);
    callback(error);
  }
}
export async function createAppointmentInstance(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/createInstance",
      data,
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
/**
 * Calls the start appointment api route with data and sets the status to in progress
 * @param {object} data Appointment information to be saved
 * @param {function} callback
 */
export async function startAppointment(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/start",
      { appointmentId: data.ID },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.status === 200) {
      toast.success("Successfully started appointment.");
    }
    callback(null, response.data);
  } catch (error) {
    toast.error("Failed to start appointment.");
    console.error(error);
    callback(error);
  }
}
/**
 * Calls the complete appointment api route with data and sets the status to completed
 * @param {object} data Appointment information to be saved
 * @param {function} callback
 */
export async function completeAppointment(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/complete",
      {
        appointmentId: data.appointmentId
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.data.statusCode && response.data.statusCode === 400) {
      callback(response.data.message, response.data);
    } else {
      callback(null, response.data);
    }
  } catch (error) {
    callback(error);
  }
}
/**
 * Calls the create transaction for appointment route
 * @param {object} data Information to be saved
 * @param {function} callback
 */
export async function createTransactionForAppointment(
  data,
  callback = () => {}
) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/createTransaction",
      {
        appointmentId: data.appointmentId,
        transaction: data.transaction
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.data.statusCode && response.data.statusCode === 400) {
      callback(response.data.message, response.data);
    } else {
      callback(null, response.data);
    }
  } catch (error) {
    callback(error);
  }
}

/**
 * Send the receipt for a transaction
 * @param {object} data Information to be saved
 * @param {function} callback
 */
export async function sendReceipt(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/transactions/sendReceipt",
      {
        transactionId: data.transactionId,
        method: data.method,
        value: data.value
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.data.statusCode && response.data.statusCode === 400) {
      callback(response.data.message, response.data);
    } else {
      callback(null, response.data);
    }
  } catch (error) {
    callback(error);
  }
}
export async function completeAppointmentWithoutCustomer(
  data,
  callback = () => {}
) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/complete",
      {
        appointmentId: data.appointmentId,
        isCustomerAbsent: true
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.data.statusCode && response.data.statusCode === 400) {
      callback(response.data.message, response.data);
    } else {
      callback(null, response.data);
    }
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export async function getRatingsByUser(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/ratings/findByUser",
    {
      userId: data.userId
    },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export async function getAppointmentFeedbackInformation(
  data,
  callback = () => {}
) {
  io.socket.get(
    "/api/v4/entrance/findFeedbackInformation",
    { token: data.token },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export async function submitAppointmentFeedback(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/entrance/submitAppointmentFeedback",
      {
        token: data.token,
        rating: data.rating,
        comment: data.comment,
        setStylistAsPreferred: data.setStylistAsPreferred || false
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function subscribeToPhone(data, callback = () => {}) {
  io.socket.get("/api/v4/phone/subscribeToPhone", {}, (responseData, jwres) => {
    if (jwres.error) {
      callback(jwres.error);
      toast.error("Failed to subscribe to phone events");
      return;
    }
    callback(null, responseData);
    return;
  });
}
export async function callPhoneNumber(data, callback = () => {}) {
  try {
    // Let the server know that the person tried to make a call. Cannot get if it actually fails so add all
    let callResponse = await Axios.post(
      "/api/v4/phone/callPhoneNumber",
      {
        phoneNumber: data.phoneNumber,
        extensionNumber: window.localStorage.getItem("extensionNumber")
      },
      {
        headers: { "X-CSRF-Token": getToken() }
      }
    );
    callback(null, callResponse.data);
  } catch (error) {
    toast.error("Failed to call phone number");
    console.error(error);
    callback(error);
  }
}
export async function findTransactionById(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/transactions/findById",
    { transactionId: data.transactionId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export async function getUserBalance(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/user/getBalance",
    { userId: data.userId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export async function getCustomerInvoiceInfo(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/user/getCustomerInvoiceInfo",
    { customerId: data.customerId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}
export async function saveNewTransaction(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/transactions/create",
      {
        ...data,
        checkAmount: parseFloat(data.checkAmount || 0),
        creditAmount: parseFloat(data.creditAmount || 0),
        creditCardAmount: parseFloat(data.creditCardAmount || 0),
        cashAmount: parseFloat(data.cashAmount || 0),
        giftCardAmount: parseFloat(data.giftCardAmount || 0),
        serviceFee: parseFloat(data.serviceFee || 0),
        cancelFee: parseFloat(data.cancelFee || 0),
        returnedFee: parseFloat(data.returnedFee || 0),
        reprocessFeee: parseFloat(data.reprocessFeee || 0)
      },
      {
        headers: { "X-CSRF-Token": getToken() }
      }
    );
    if (response.data.statusCode && response.data.statusCode === 400) {
      callback(response.data.message, response.data);
      toast.error(response.data.message);
    } else {
      callback(null, response.data);
      toast.success("Successfully created new transaction.");
    }
  } catch (error) {
    toast.error("Failed to save transaction.");
    console.error(error);
    callback(error);
  }
}
export async function refundTransaction(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/transactions/refund",
      {
        amount: parseFloat(data.amount),
        refTransactionId: parseFloat(data.refTransactionId)
      },
      {
        headers: { "X-CSRF-Token": getToken() }
      }
    );
    if (response.data.statusCode && response.data.statusCode === 400) {
      callback(response.data.message, response.data);
      toast.error(response.data.message);
    } else {
      toast.success("Successfully refunded transaction");
      callback(null, response.data);
    }
  } catch (error) {
    console.error(error);
    toast.error("Failed to refund transaction");
    callback(error);
  }
}
export async function voidTransaction(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/transactions/void",
      {
        refTransactionId: parseFloat(data.refTransactionId)
      },
      {
        headers: { "X-CSRF-Token": getToken() },
        validateStatus: function(status) {
          return status >= 200 && status < 500;
        }
      }
    );
    if (response.data.statusCode && response.data.statusCode === 400) {
      callback(response.data.message, response.data);
      toast.error(response.data.message);
    } else {
      toast.success("Successfully voided transaction");
      callback(null, response.data);
    }
  } catch (error) {
    console.error(error);
    toast.error("Failed to void transaction");
    callback(error);
  }
}
export async function checkInTransactions(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/transactions/checkIn",
      {
        transactionIds: data.transactionIds
      },
      {
        headers: { "X-CSRF-Token": getToken() }
      }
    );
    callback(null, response.data);
    toast.success("Successfully checked in all transactions.");
  } catch (error) {
    toast.error("Failed to check in one or more transactions.");
    console.error(error);
    callback(error);
  }
}

/**
 * Calls the database with information to create an exception in an employee's schedule.
 * @param {object} data
 * @param {funciton} callback
 */
export async function createException(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/exceptions/create",
      {
        event: data.event,
        isWorking: data.isWorking,
        employeeId: data.employeeId
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
/**
 * Calls the database with information to update an existing exception in an employee's schedule.
 * @param {object} data
 * @param {funciton} callback
 */
export async function updateException(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/exceptions/update",
      {
        event: data.event,
        isWorking: data.isWorking,
        ID: data.ID
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function deleteException(data, callback = () => {}) {
  try {
    let response = await Axios.delete("/api/v4/exceptions/delete", {
      params: { exceptionId: data.ID },
      headers: { "X-CSRF-Token": getToken() }
    });
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

/**
 *
 * @param {object} data
 * @param {function} callback
 */
export async function getExceptions(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/exceptions/getAll",
    { employeeId: data.employeeId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}

/**
 * Calls the add new card api route with data
 * @param {object} data Card information to be used
 * @param {function} callback
 */
export async function addNewCard(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/user/addNewCard",
      {
        userId: data.userId,
        isPrimary: data.isPrimary,
        nameOnCard: data.nameOnCard,
        cardNumber: data.cardNumber,
        expiration: data.expiration,
        securityCode: data.securityCode,
        billingZipcode: data.billingZipcode
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    if (response.status === 200) {
      toast.success("Successfully added new card to account.");
    }
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export async function setPrimaryCreditCard(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/user/setPrimaryCard",
      {
        cardId: data.ID
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

/**
 * Calls the update breed route to create a new breed setting
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function createBreed(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/management/createBreed",
      {
        breed: data.breed,
        animalTypeId: data.animalTypeId,
        minutesToGroom: data.minutesToGroom,
        bathBrushPrice: data.bathBrushPrice,
        bathBrushPrice3Months: data.bathBrushPrice3Months,
        bathBrushPrice6Months: data.bathBrushPrice6Months,
        deluxePrice: data.deluxePrice,
        deluxePrice3Months: data.deluxePrice3Months,
        deluxePrice6Months: data.deluxePrice6Months
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
/**
 * Calls the update breed route to update breed settings
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function updateBreed(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/management/updateBreed",
      {
        ID: data.ID,
        breed: data.breed,
        animalTypeId: data.animalTypeId,
        minutesToGroom: data.minutesToGroom,
        bathBrushPrice: data.bathBrushPrice,
        bathBrushPrice3Months: data.bathBrushPrice3Months,
        bathBrushPrice6Months: data.bathBrushPrice6Months,
        deluxePrice: data.deluxePrice,
        deluxePrice3Months: data.deluxePrice3Months,
        deluxePrice6Months: data.deluxePrice6Months
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

/**
 * Calls the create product and service route to create a new product and service setting
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function createProductAndService(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/management/createProductAndService",
      {
        item: data.item,
        category: data.category,
        price: data.price,
        addonPrice: data.addonPrice,
        description: data.description,
        customerNote: data.customerNote
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
/**
 * Calls the update product and service route to update product and service settings
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function updateProductAndService(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/management/updateProductAndService",
      {
        ID: data.ID,
        item: data.item,
        category: data.category,
        price: data.price,
        addonPrice: data.addonPrice,
        description: data.description,
        customerNote: data.customerNote
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
/**
 * Calls the delete product and service route to delete product and service settings
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function deleteProductAndService(data, callback = () => {}) {
  try {
    await Axios.delete("/api/v4/management/deleteProductAndService", {
      headers: { "X-CSRF-Token": getToken() },
      data: { ID: data.ID }
    });
    callback(null);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export function getProductsAndServices(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/management/getProductsAndServices",
    null,
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}

export async function createTodoCategory(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/management/createTodoCategory",
      {
        category: data.category
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export async function deleteTodoCategory(data, callback = () => {}) {
  try {
    await Axios.delete("/api/v4/management/deleteTodoCategory", {
      headers: { "X-CSRF-Token": getToken() },
      data: { ID: data.ID }
    });
    callback(null);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export function getTodoCategories(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/management/getTodoCategories",
    null,
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}

/**
 * Calls the delete credit card route
 * @param {object} data Request information to be saved
 * @param {function} callback
 */
export async function deleteCreditCard(data, callback = () => {}) {
  try {
    let response = await Axios.delete("/api/v4/user/deleteCreditCard", {
      headers: { "X-CSRF-Token": getToken() },
      data: { ID: data.ID }
    });
    if (response.status === 200) {
      callback(null);
    } else {
      callback(true);
    }
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export function getCreditCards(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/user/getCreditCards",
    {
      userId: data.userId
    },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}

export async function submitAppointmentRating(
  data,
  callback = (error, response) => {}
) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/submitRating",
      {
        appointmentId: data.appointmentId,
        rating: data.rating,
        comment: data.comment
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export function getAddressesByUser(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/addresses/findByUser",
    {
      userId: data.userId
    },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}

export async function createAddress(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/addresses/create",
      {
        userId: data.userId,
        address: data.address,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
        mapsco: data.mapsco,
        mapscoCityId: data.mapscoCityId,
        needsGenerator: data.needsGenerator,
        isPrimary: data.isPrimary,
        notes: data.notes
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function updateAddress(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/addresses/update",
      {
        addressId: data.ID,
        address: data.address,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
        mapsco: data.mapsco,
        mapscoCityId: data.mapscoCityId,
        needsGenerator: data.needsGenerator,
        isPrimary: data.isPrimary,
        notes: data.notes
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function deleteAddress(data, callback = () => {}) {
  try {
    let response = await Axios.delete("/api/v4/addresses/delete", {
      data: { addressId: data.ID },
      headers: { "X-CSRF-Token": getToken() }
    });
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export function getEmailsByUser(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/emails/findByUser",
    { userId: data.userId },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}

export async function createEmail(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/emails/create",
      {
        userId: data.userId,
        isPrimary: data.isPrimary,
        emailAddress: data.emailAddress,
        label: data.label
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function updateEmail(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/emails/update",
      {
        emailId: data.ID,
        isPrimary: data.isPrimary,
        emailAddress: data.emailAddress,
        label: data.label
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export async function notifyStylistEnRoute(
  data,
  callback = (error, response) => {}
) {
  try {
    let response = await Axios.post(
      "/api/v4/appointments/stylistEnRoute",
      {
        appointmentId: data.appointmentId
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function deleteEmail(data, callback = () => {}) {
  try {
    let response = await Axios.delete("/api/v4/emails/delete", {
      data: { emailId: data.ID },
      headers: { "X-CSRF-Token": getToken() }
    });
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export function getPhoneNumbersByUser(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/phoneNumbers/findByUser",
    {
      userId: data.userId
    },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}

export async function createPhoneNumber(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/phoneNumbers/create",
      {
        userId: data.userId,
        isPrimary: data.isPrimary,
        phoneNumber: data.phoneNumber,
        extension: data.extension,
        label: data.label
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function updatePhoneNumber(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/phoneNumbers/update",
      {
        phoneNumberId: data.ID,
        isPrimary: data.isPrimary,
        phoneNumber: data.phoneNumber,
        extension: data.extension,
        label: data.label
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function deletePhoneNumber(data, callback = () => {}) {
  try {
    let response = await Axios.delete("/api/v4/phoneNumbers/delete", {
      data: { phoneNumberId: data.ID },
      headers: { "X-CSRF-Token": getToken() }
    });
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export function getPetsByCustomer(data, callback = () => {}) {
  io.socket.get(
    "/api/v4/pets/findByCustomer",
    {
      customerId: data.customerId
    },
    (responseData, jwres) => {
      if (jwres.error) {
        callback(jwres.error);
        console.error(jwres.error);
        return;
      }
      callback(null, responseData);
      return;
    }
  );
}

export async function createComplaint(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/complaints/create",
      {
        customerId: data.customerId,
        employeeId: data.employeeId,
        isBlocking: data.isBlocking,
        complaint: data.complaint
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function deleteUser(data, callback = () => {}) {
  try {
    let response = await Axios.delete("/api/v4/user/delete", {
      params: {
        userId: data.userId
      },
      headers: { "X-CSRF-Token": getToken() }
    });
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function restoreUser(data, callback = () => {}) {
  try {
    let response = await Axios.put(
      "/api/v4/user/restore",
      { userId: data.userId },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export async function getInvoice(data, callback = () => {}) {
  try {
    let response = await Axios.get("/api/v4/transactions/getInvoice", {
      params: {
        customerId: data.customerId,
        appointmentId: data.appointmentId
      },
      headers: { "X-CSRF-Token": getToken() }
    });
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function sendInvoice(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/transactions/sendInvoice",
      {
        customerId: data.customerId,
        appointmentId: data.appointmentId,
        emailAddress: data.emailAddress
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function getTransactionSummary(data, callback = () => {}) {
  try {
    let response = await Axios.get(
      "/api/v4/transactions/getTransactionSummary",
      {
        params: {
          customerId: data.customerId,
          startDate: data.startDate,
          endDate: data.endDate
        },
        headers: { "X-CSRF-Token": getToken() }
      }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function sendTransactionSummary(data, callback = () => {}) {
  try {
    let response = await Axios.post(
      "/api/v4/transactions/sendTransactionSummary",
      {
        customerId: data.customerId,
        startDate: data.startDate,
        endDate: data.endDate,
        emailAddress: data.emailAddress
      },
      { headers: { "X-CSRF-Token": getToken() } }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}
export async function createLoginAccount(data, callback = () => {}) {
  try {
    let response = await Axios.post("/api/v4/user/createLoginAccount", data, {
      headers: { "X-CSRF-Token": getToken() }
    });
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export async function createTodoItem(data, callback = () => {}) {
  try {
    let response = await Axios.post("/api/v4/todo/create", data, {
      headers: { "X-CSRF-Token": getToken() }
    });
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export async function completeTodoItem(data, callback = () => {}) {
  try {
    let response = await Axios.post("/api/v4/todo/complete", data, {
      headers: { "X-CSRF-Token": getToken() }
    });
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}

export async function getAllTodoItems(data, callback = () => {}) {
  io.socket.get("/api/v4/todo/getAll", {}, (responseData, jwres) => {
    if (jwres.error) {
      callback(jwres.error);
      return;
    }
    callback(null, responseData);
    return;
  });
}

export async function getSMSInfoByUser(data, callback = () => {}) {
  try {
    let response = await Axios.get(
      "/api/v4/phoneNumbers/SMSInfo",
      {
        params: {
          userId: data.userId,
          phoneNumber: data.phoneNumber
        },
        headers: { "X-CSRF-Token": getToken() }
      }
    );
    callback(null, response.data);
  } catch (error) {
    console.error(error);
    callback(error);
  }
}