import React, { Component } from "react";
import { Form, TextArea, Header, Modal, Button } from "semantic-ui-react";
import { requestStandingAppointment } from "../lib/apiCalls";
import { toast } from "react-toastify";

/**
 * Modal to display a request standing appointment form. Lets a user request a standing appointment for off of an existing appointment and add comments.
 */
class StandingAppointmentRequestModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      comment: ""
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleRequest = () => {
    requestStandingAppointment(
      {
        appointmentId: this.props.appointmentId,
        comment: this.state.comment
      },
      error => {
        if (error) {
          toast.error("Failed to create request for standing appointment");
          return;
        }
        if (this._isMounted) {
          this.props.onClose();
          toast.success("Created request for a standing appointment");
        }
      }
    );
  };
  render() {
    let { comment } = this.state;
    let { open } = this.props;
    return (
      <Modal open={open} onClose={this.props.onClose} closeIcon>
        <Modal.Content>
          <Form>
            <Header as="h3" content="Requesting a Standing Appointment" />

            {/* Comment on standing appointment */}
            <Form.Field>
              <label>Comments</label>{" "}
              <TextArea
                value={comment}
                name="comment"
                onChange={this.handleChange}
                placeholder="Add a comment for this request to list all necessary details."
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Create Request"
            color="green"
            onClick={this.handleRequest}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

StandingAppointmentRequestModal.defaultProps = {
  appointmentId: null,
  open: false,
  onOpen: () => {},
  onClose: () => {}
};

export default StandingAppointmentRequestModal;
