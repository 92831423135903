import React, { Component } from "react";

import ResponsiveContainer from "./Layout/ResponsiveContainer";
import Profile from "./Pages/Profile";
import Appointments from "./Pages/Appointments";
import Estimates from "./Pages/Estimates";
import Management from "./Pages/Management";
import Transactions from "./Pages/Transactions";
import Customers from "./Pages/Customers";
import Employees from "./Pages/Employees";
import Schedule from "./Pages/Schedule";

import { Segment, Button, Header, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "./UserContext";
import ErrorBoundary from "./Shared/ErrorBoundary";
import CustomerTransactions from "./Tables/CustomerTransactions";
import Documents from "./Pages/Documents";

import { OptionsContext } from "./OptionsContext";
import { AppointmentsContext } from "./AppointmentsContext";
import io from "./socketConnection";
import _ from "lodash";
import Entrance from "./Pages/Entrance";
import { HoverButtonContext } from "./HoverButtonContext";
import Transaction from "./Forms/Transaction";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomerSignOff from "./Forms/CustomerSignOff";
import { subscribeToPhone, getAllTodoItems } from "./lib/apiCalls";

import CustomerInformation from "./Pages/CustomerInformation";
import TodoList from "./Tables/TodoList";
import { TodoItemsContext } from "./TodoItemsContext";
import SalesTaxTable from "./Reports/SalesTaxTable";
import PayrollTable from "./Reports/PayrollTable";
import DailyTipsTable from "./Reports/DailyTipsTable";
import Footer from "./Layout/Footer";
import AllComplaints from "./Tables/AllComplaints";
import OutstandingBalances from "./Tables/OutstandingBalances";
import MergeProfiles from "./Shared/MergeProfiles";

let removedStatuses = ["rescheduled", "cancelled", "completed"];

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        breeds: [],
        userTypes: [],
        stylists: [],
        blockingComplaints: [],
        services: [],
        mapscoCities: [],
        todoCategories: [],
        packages: [],
        recurringTypes: [],
        statuses: [],
        contactMethods: [],

        fuelCharge: 0.0375,
        salesTax: 0.0825,
        generatorFee: 10,
      },
      loading: true,
      appointments: [],
      newAppointments: [],
      standings: [],
      todoItems: [],
      hoverButtonProps: { visible: false },
    };
    this.connectionToastId = null;
    this.setHoverButton = this.setHoverButton.bind(this);
    this.updateOptions = this.updateOptions.bind(this);
    this.subscribeToPhone = this.subscribeToPhone.bind(this);
    this.subscribeToTodoItems = this.subscribeToTodoItems.bind(this);
    this.getAllTodoItems = this.getAllTodoItems.bind(this);
    this.addTodo = this.addTodo.bind(this);
    this.completeTodo = this.completeTodo.bind(this);
  }
  async componentDidMount() {
    let { user } = this.props;

    if (user.userId) {
      io.socket.get("/api/v4/getOptions", (resData, jwres) => {
        if (jwres.statusCode === 200) {
          let options = resData.options;
          this.setState({ options: options });
          this.subscribeToOptionsUpdates();
          // Load the appointments and setup listening for updates to appointments on sockets
          if (
            options.userTypes &&
            user.permissions &&
            user.permissions["ViewAllAppointments"] === true
          ) {
            this.getAppointments();
            this.subscribeToAppointmentUpdates();
          } else {
            this.setState({ loading: false });
          }
          if (
            options.userTypes &&
            user.permissions &&
            user.permissions["UsePhoneSystem"] === true
          ) {
            subscribeToPhone();
            this.subscribeToPhone();
          }
          if (
            options.userTypes &&
            user.permissions &&
            user.permissions["ViewTodoItems"] === true
          ) {
            this.getAllTodoItems();
            this.subscribeToTodoItems();
          }
        } else {
          this.setState({ loading: false });
          toast.error("Failed to receive options for CRMS.");
        }
      });
      window.addEventListener("online", this.setOnlineStatus);
      window.addEventListener("offline", this.setOfflineStatus);
      io.socket.on("connect", this.setServerOnlineStatus);
      io.socket.on("disconnect", this.setServerOfflineStatus);
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    // Stop watching for appointments
    io.socket.off("createdAppointment", this.addAppointment);
    io.socket.off("updatedAppointment", this.updateAppointment);
    // Stop watching for option updates
    io.socket.off("updatedOptions", this.updateOptions);
    io.socket.off("phoneCall", this.handlePhone);
    // Stop watching for todo items
    io.socket.off("createdTodo", this.addTodo);
    io.socket.off("completedTodo", this.completeTodo);
    // Stop watching for server connection
    io.socket.off("connect", this.setServerOnlineStatus);
    io.socket.off("disconnect", this.setServerOfflineStatus);
  }

  setOnlineStatus = (event) => {
    if (this.connectionToastId) {
      toast.update(this.connectionToastId, {
        render: () => {
          return (
            <Segment basic>
              <Header size="small" inverted>
                <Icon name="wifi" />
                <Header.Content>Reconnected</Header.Content>
              </Header>
            </Segment>
          );
        },
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        draggable: true,
        closeOnClick: true,
        closeButton: true,
      });
    }
    this.connectionToastId = null;
  };

  setOfflineStatus = (event) => {
    this.connectionToastId = toast.error(
      () => {
        return (
          <Segment basic>
            <Header size="small" inverted>
              <Icon name="wifi" />
              <Header.Content>Not Connected</Header.Content>
            </Header>
          </Segment>
        );
      },
      {
        closeOnClick: false,
        closeButton: false,
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        draggable: false,
      }
    );
  };
  setServerOnlineStatus = (event) => {
    if (this.serverConnectionToastId) {
      toast.update(this.serverConnectionToastId, {
        render: () => {
          return (
            <Segment basic>
              <Header size="small" inverted>
                <Icon name="server" />
                <Header.Content>
                  Connection to Server Reestablished
                </Header.Content>
              </Header>
            </Segment>
          );
        },
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        draggable: true,
        closeOnClick: true,
        closeButton: true,
      });
    }
    this.serverConnectionToastId = null;
  };

  setServerOfflineStatus = (event) => {
    this.serverConnectionToastId = toast.error(
      () => {
        return (
          <Segment basic>
            <Header size="small" inverted>
              <Icon name="server" />
              <Header.Content>Connection to Server Lost</Header.Content>
            </Header>
          </Segment>
        );
      },
      {
        closeOnClick: false,
        closeButton: false,
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        draggable: false,
      }
    );
  };

  subscribeToAppointmentUpdates = () => {
    // When an appointment is created, add it to the list of appointments
    io.socket.on("createdAppointment", this.addAppointment);
    // Update the appointment in the list of appointments when one is updated
    io.socket.on("updatedAppointment", this.updateAppointment);
  };

  subscribeToOptionsUpdates = () => {
    // Update the appointment in the list of appointments when one is updated
    io.socket.on("updatedOptions", this.updateOptions);
  };
  subscribeToPhone() {
    io.socket.on("phoneCall", this.handlePhone);
  }
  subscribeToTodoItems() {
    io.socket.on("createdTodo", this.addTodo);
    io.socket.on("completedTodo", this.completeTodo);
  }
  /**
   * Sends out a toast message to the screen that shows the caller ID and phone number of a call
   * Only displays message if a user associated to the phone number was found
   */
  handlePhone = (data) => {
    if (
      data &&
      data.call &&
      data.userId &&
      parseInt(data.call.extensionNumber) ===
        parseInt(window.localStorage.getItem("extensionNumber"))
    ) {
      let { callerId, phoneNumber } = data.call;
      toast.info(
        ({ closeToast }) => {
          return (
            <div>
              <Segment basic>
                <Header size="small" inverted>
                  <Icon name="phone" />
                  <Header.Content>
                    {callerId} is calling
                    <Header.Subheader>{phoneNumber}</Header.Subheader>
                  </Header.Content>
                </Header>
                {/* Button to redirect ot the customer's profile */}
                <Button
                  basic
                  as={Link}
                  to={"/dashboard/customers/" + data.userId}
                  inverted
                  content="Click here to view profile"
                  onClick={closeToast}
                  fluid
                />
              </Segment>
            </div>
          );
        },
        {
          closeOnClick: false,
          autoClose: 60000,
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
    }
  };
  setHoverButton(props) {
    this.setState({ hoverButtonProps: props });
  }

  addTodo(data) {
    this.setState((prevState) => ({
      todoItems: [...prevState.todoItems, data.todo],
    }));
  }
  completeTodo(data) {
    this.setState((prevState) => {
      let temp = [...prevState.todoItems];
      let index = _.findIndex(temp, { ID: data.todo.ID });
      temp.splice(index, 1);
      return { todoItems: temp };
    });
  }
  addAppointment = (data) => {
    let appointmentData = { ...data.appointment };
    if (data.appointment.isStanding === true) {
      // Add a new standing appointment
      this.setState((prevState) => ({
        standings: [appointmentData, ...prevState.standings],
      }));
    } else {
      // Add a new appointment
      this.setState(
        (prevState) => {
          return {
            newAppointments: [appointmentData, ...prevState.newAppointments],
          };
        },
        () => {
          let newAppointmentFound = _.some(
            this.state.newAppointments,
            (app) => app.ID === appointmentData.ID
          );
          if (newAppointmentFound === false) {
            console.warn(
              `Appointment ${appointmentData.ID} was NOT added to the state!`
            );
          }
        }
      );
    }
  };
  updateStanding = (data) => {
    this.setState((prevState) => {
      let appointmentStatus = data.appointment.Status || {};
      //Update standing appointment list
      let tempAppointments = [...prevState.standings];
      let index = _.findIndex(tempAppointments, {
        standingId: data.appointment.standingId,
      });
      // Remove appointment from array when deleted
      if (
        removedStatuses.includes(appointmentStatus.status) ||
        data.appointment.isDeleted === true
      ) {
        tempAppointments.splice(index, 1);
      } else {
        tempAppointments.splice(index, 1, data.appointment);
      }
      return { standings: tempAppointments };
    });
  };
  updateInstance = (data) => {
    this.setState((prevState) => {
      let appointmentStatus = data.appointment.Status || {};

      // Check if the updated instance is an existing appointment
      let index = _.findIndex(prevState.appointments, {
        ID: data.appointment.ID,
      });

      // Check if the updated appointment is a newly created appointment
      let indexOfNew = _.findIndex(prevState.newAppointments, {
        ID: data.appointment.ID,
      });

      if (index > -1) {
        // Updated appointments was existing appointment
        let tempAppointments = [...prevState.appointments];
        if (
          removedStatuses.indexOf(appointmentStatus.status) >= 0 ||
          data.appointment.isDeleted === true
        ) {
          // Delete appointment from array if it is not supposed to appear in the schedule anymore
          tempAppointments.splice(index, 1);
        } else {
          // Update appointment in the array
          tempAppointments.splice(index, 1, data.appointment);
        }
        return { appointments: tempAppointments };
      } else if (indexOfNew > -1) {
        // Updated appointment is a new appointment
        let tempAppointments = [...prevState.newAppointments];
        if (
          removedStatuses.indexOf(appointmentStatus.status) >= 0 ||
          data.appointment.isDeleted === true
        ) {
          // Delete appointment from array if it is not supposed to appear in the schedule anymore
          tempAppointments.splice(indexOfNew, 1);
        } else {
          // Update appointment in the array
          tempAppointments.splice(indexOfNew, 1, data.appointment);
        }
        return { newAppointments: tempAppointments };
      }
      // Appointment was not found to update
      return {};
    });
  };
  updateAppointment = (data) => {
    if (data.appointment.isStanding === true) {
      this.updateStanding(data);
    } else {
      this.updateInstance(data);
    }
  };
  updateOptions(data) {
    if (data.options) this.setState({ options: data.options });
  }

  getAllTodoItems() {
    getAllTodoItems(null, (error, data) => {
      if (error) {
        toast.error("Failed to retrieve todo items");
        return;
      }
      this.setState({ todoItems: data });
    });
  }

  async getAppointments() {
    io.socket.get(
      "/api/v4/appointments/getAll",
      null,
      (responseData, jwres) => {
        if (jwres.error) {
          console.error(jwres.error);
          toast.error("Failed to retrieve appointments.");
          this.setState({ loading: false });
          return;
        }
        if (jwres.statusCode === 200) {
          this.setState({
            appointments: responseData.appointments,
            standings: responseData.standings,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      }
    );
  }
  render() {
    const { user, match } = this.props;
    let { loading, hoverButtonProps } = this.state;

    // Load the entrance view with loader when still loading application
    if (loading) {
      return (
        <Route
          render={(props) => <Entrance {...props} user={user} loading />}
        />
      );
    }
    // Redirect to login when no user ID
    if (!user || !user.userId) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { redirectToAfterLogin: this.props.location.pathname },
          }}
        />
      );
    }
    let permissions = user.permissions;

    let { visible, ...buttonProps } = hoverButtonProps;

    return (
      <HoverButtonContext.Provider
        value={{ setHoverButton: this.setHoverButton }}
      >
        {/* Must be outside of responsive container or does not stay on screen when scrolling */}
        {hoverButtonProps.visible === true && (
          <Button {...buttonProps} className="hover-button" />
        )}
        <ErrorBoundary>
          <TodoItemsContext.Provider
            value={{ todoItems: this.state.todoItems }}
          >
            <OptionsContext.Provider value={{ ...this.state.options }}>
              <AppointmentsContext.Provider
                value={{
                  appointments:
                    this.state.appointments.concat(
                      this.state.newAppointments || []
                    ) || [],
                  standings: this.state.standings || [],
                }}
              >
                <div className="background" />
                <div id="homepage" style={{ minHeight: "75vh" }}>
                  <Switch>
                    <Route
                      path={`/appointmentCompletion/:appointmentId(\\d+)`}
                      component={CustomerSignOff}
                    />
                    <Route
                      path={`/appointmentCompletionWithoutCustomer/:appointmentId(\\d+)`}
                      component={CustomerSignOff}
                    />
                    <Route
                      render={(props) => (
                        <ResponsiveContainer>
                          <div>
                            <Segment
                              basic
                              style={{ marginTop: 0, minHeight: "90vh" }}
                            >
                              <Switch>
                                {/* Stylist pages */}
                                {permissions["ViewDailyRoute"] === true && (
                                  <Route
                                    path={`${match.path}/schedule`}
                                    component={Schedule}
                                  />
                                )}

                                {/* Customer Pages */}
                                {permissions["ViewCustomerDashboard"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/profile/:userId(\\d+)`}
                                    component={Profile}
                                  />
                                )}
                                {permissions["ViewCustomerDashboard"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/information/:userId(\\d+)`}
                                    component={CustomerInformation}
                                  />
                                )}
                                {permissions["ViewCustomerDashboard"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/appointments`}
                                    component={Appointments}
                                  />
                                )}
                                {permissions["ViewCustomerDashboard"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/estimates`}
                                    component={Estimates}
                                  />
                                )}
                                {permissions["ViewCustomerDashboard"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/transactions/:transactionId(\\d+)`}
                                    component={Transaction}
                                  />
                                )}
                                {permissions["ViewCustomerDashboard"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/transactions`}
                                    component={CustomerTransactions}
                                  />
                                )}
                                {permissions["ViewCustomerDashboard"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/documents`}
                                    component={Documents}
                                  />
                                )}

                                {/* Other Pages */}
                                {permissions["ViewAllAppointments"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/appointments`}
                                    component={Appointments}
                                  />
                                )}
                                {permissions["ViewAppointments"] === true && (
                                  <Route
                                    path={`${match.path}/estimate`}
                                    component={Estimates}
                                  />
                                )}
                                {permissions["ViewTodoItems"] === true && (
                                  <Route
                                    path={`${match.path}/todoList`}
                                    component={TodoList}
                                  />
                                )}

                                {permissions["ViewCustomers"] === true && (
                                  <Route
                                    path={`${match.path}/customers`}
                                    component={Customers}
                                  />
                                )}
                                {/* Manager Pages */}
                                {permissions["ViewManagement"] === true && (
                                  <Route
                                    path={`${match.path}/management`}
                                    component={Management}
                                  />
                                )}
                                {permissions["ViewManagement"] === true && (
                                  <Route
                                    path={`${match.path}/employees`}
                                    component={Employees}
                                  />
                                )}
                                {permissions["MergeProfiles"] === true && (
                                  <Route
                                    path={`${match.path}/merge/:userId(\\d+)`}
                                    component={MergeProfiles}
                                  />
                                )}
                                {permissions["ViewAllTransactions"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/transactions`}
                                    component={Transactions}
                                  />
                                )}
                                {permissions["ViewComplaints"] === true && (
                                  <Route
                                    path={`${match.path}/complaints`}
                                    component={AllComplaints}
                                  />
                                )}
                                {permissions["ViewOutstandingBalances"] ===
                                  true && (
                                  <Route
                                    path={`${match.path}/outstandingBalances`}
                                    component={OutstandingBalances}
                                  />
                                )}
                                {permissions["ViewAdminReports"] === true && (
                                  <Route
                                    path={`${match.path}/reports/salesTax`}
                                    component={SalesTaxTable}
                                  />
                                )}
                                {permissions["ViewAdminReports"] === true && (
                                  <Route
                                    path={`${match.path}/reports/payroll`}
                                    component={PayrollTable}
                                  />
                                )}
                                {permissions["ViewManagerReports"] === true && (
                                  <Route
                                    path={`${match.path}/reports/dailyTips`}
                                    component={DailyTipsTable}
                                  />
                                )}

                                {/* Redirects */}
                                {permissions["ViewAllAppointments"] ===
                                  true && (
                                  <Redirect to={`${match.path}/appointments`} />
                                )}
                                {permissions["ViewDailyRoute"] === true && (
                                  <Redirect to={`${match.path}/schedule`} />
                                )}
                                <Redirect
                                  to={`${match.path}/profile/${user.userId}`}
                                />
                              </Switch>
                            </Segment>
                            <Footer />
                          </div>
                        </ResponsiveContainer>
                      )}
                    />
                  </Switch>
                </div>
              </AppointmentsContext.Provider>
            </OptionsContext.Provider>
          </TodoItemsContext.Provider>
        </ErrorBoundary>
      </HoverButtonContext.Provider>
    );
  }
}

class LayoutWithUser extends Component {
  render() {
    return (
      <UserContext.Consumer>
        {({ user, setUser }) => {
          return <Layout user={user} setUser={setUser} {...this.props} />;
        }}
      </UserContext.Consumer>
    );
  }
}

export default LayoutWithUser;
