import React, { Component } from "react";

import { Modal, Button } from "semantic-ui-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import ExceptionDetails from "./ExceptionDetails";
import { toast } from "react-toastify";
import {
  createException,
  getExceptions,
  updateException,
  deleteException,
} from "../../lib/apiCalls";
import { defaultException } from "../../lib/defaultObjects";
import {
  convertToCalendarEvent,
  getTrueIndexes,
  convertRRuleToCalenderEvent,
  convertMomentDateToUTC,
} from "../../lib/helperFunctions";
import _ from "lodash";
import { RRule } from "rrule";
import { OptionsContext } from "../../OptionsContext";

const localizer = momentLocalizer(moment);

class ExceptionCalendar extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    let currentDate = new Date();
    this.state = {
      instanceExceptions: [],
      recurringExceptions: [],
      selectedException: null,
      visibleRange: {
        start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      },
    };
    this.showDetails = this.showDetails.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.getRecurringCalenderEvents =
      this.getRecurringCalenderEvents.bind(this);
    this.getRRule = this.getRRule.bind(this);
    this.updateException = this.updateException.bind(this);
    this.createException = this.createException.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    if (this.props.employeeId) {
      getExceptions(
        { employeeId: this.props.employeeId },
        (error, response) => {
          if (error) {
            toast.error("Failed to retrieve exceptions");
            return;
          }

          if (this._isMounted) {
            // Find all the events that are recurring
            let split = _.partition(
              response,
              (o) => o.Event && o.Event.isRecurring === true
            );
            this.setState({
              instanceExceptions: split[1],
              // Create the recurrence rules for the exceptions
              recurringExceptions: split[0].map((o) => ({
                ...o,
                rrule: this.getRRule(o),
              })),
            });
          }
        }
      );
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  showDetails(event) {
    if (!event.original || !event.original.Event) return;
    if (event.original.Event.isRecurring) {
      var exception = _.find(this.state.recurringExceptions, {
        ID: event.original.ID,
      });
    } else {
      exception = _.find(this.state.instanceExceptions, {
        ID: event.original.ID,
      });
    }
    this.setState({ selectedException: exception });
  }
  updateException(exception) {
    let { event } = exception;
    updateException(
      {
        isWorking: exception.isWorking,
        event: exception.event,
        ID: this.state.selectedException.ID,
      },
      (error, response) => {
        if (error != null) {
          toast.error("Failed to update exception");
          return;
        }
        if (this._isMounted) {
          if (event.isRecurring === true) {
            let temp = [...this.state.recurringExceptions];
            // Find the old recurring exception
            let oldExceptionIndex = _.findIndex(temp, {
              ID: this.state.selectedException.ID,
            });

            let newException = {
              ID: exception.ID,
              isWorking: exception.isWorking,
              Event: event,
              employeeId: this.props.employeeId,
            };
            // Replace the old recurring exception with the new one
            temp.splice(oldExceptionIndex, 1, {
              ...newException,
              rrule: this.getRRule(newException),
            });
            this.setState({
              recurringExceptions: temp,
              selectedException: null,
            });
          } else {
            // Find the old exception
            let temp = [...this.state.instanceExceptions];
            let oldExceptionIndex = _.findIndex(temp, {
              ID: exception.ID,
            });

            // Replace the old exception with the new one
            temp.splice(oldExceptionIndex, 1, {
              ID: exception.ID,
              isWorking: exception.isWorking,
              Event: event,
              employeeId: this.props.employeeId,
            });
            this.setState({
              instanceExceptions: temp,
              selectedException: null,
            });
          }
        }
        toast.success("Successfully updated exception");
      }
    );
  }
  createException(exception) {
    let { event } = exception;
    createException(
      {
        isWorking: exception.isWorking,
        event: exception.event,
        employeeId: this.props.employeeId,
      },
      (error, response) => {
        if (error != null) {
          toast.error("Failed to create new exception");
          return;
        }
        if (this._isMounted) {
          if (exception.event.isRecurring === true) {
            let newException = {
              ID: response.ID,
              isWorking: response.isWorking,
              Event: { ...event, ID: response.eventId },
            };
            this.setState({
              recurringExceptions: [
                ...this.state.recurringExceptions,
                { ...newException, rrule: this.getRRule(newException) },
              ],
              selectedException: null,
            });
          } else {
            this.setState({
              instanceExceptions: [
                ...this.state.instanceExceptions,
                {
                  ID: response.ID,
                  isWorking: response.isWorking,
                  Event: { ...event, ID: response.eventId },
                },
              ],
              selectedException: null,
            });
          }
        }
        toast.success("Successfully created new exception");
      }
    );
  }
  handleSave(exception) {
    if (exception && exception.event.title) {
      // Update or create exception based on if it already exists
      if (this.state.selectedException.ID) {
        this.updateException({
          ...exception,
          ID: this.state.selectedException.ID,
        });
      } else {
        this.createException(exception);
      }
    } else {
      toast.info("Title is required");
    }
  }
  handleDelete = () => {
    let { selectedException } = this.state;
    // Update or create exception based on if it already exists
    deleteException(selectedException, (error, response) => {
      if (error != null) {
        toast.error("Failed to delete exception");
        return;
      }
      if (this._isMounted) {
        if (selectedException.Event.isRecurring === true) {
          let temp = [...this.state.recurringExceptions];
          let index = _.findIndex(temp, { ID: selectedException.ID });
          temp.splice(index, 1);
          this.setState({
            recurringExceptions: temp,
            selectedException: null,
          });
        } else {
          let temp = [...this.state.instanceExceptions];
          let index = _.findIndex(temp, { ID: selectedException.ID });
          temp.splice(index, 1);
          this.setState({
            instanceExceptions: temp,
            selectedException: null,
          });
        }
      }
      toast.success("Successfully deleted exception");
    });
  };
  handleSelect(selectedData) {
    let start = moment(selectedData.start);
    let end = moment(selectedData.end);
    this.setState({
      selectedException: defaultException({
        Event: {
          startDate: start.format("MM-DD-YYYY"),
          startTime: start.format("hh:mm A"),
          endDate: end.format("MM-DD-YYYY"),
          endTime: end.format("hh:mm A"),
        },
      }),
    });
  }
  onRangeChange(range) {
    if (!range.start) {
      this.setState({
        visibleRange: {
          start: range[0],
          end: range[range.length - 1],
        },
      });
    } else {
      this.setState({ visibleRange: range });
    }
  }
  getRRule(recurringException) {
    if (!recurringException.Event || !recurringException.Event.RecurringPattern)
      return;
    let recurrence = recurringException.Event.RecurringPattern;
    let recurringType = _.find(this.props.recurringTypes, {
      ID: recurrence.recurringTypeId,
    });
    if (!recurringType) return;
    return new RRule({
      freq: RRule[recurringType.type.toUpperCase()],
      interval: recurrence.separationCount || 1,
      byweekday: recurrence.daysOfWeek
        ? getTrueIndexes(recurrence.daysOfWeek, -1)
        : null,
      bymonth: recurrence.monthsOfYear
        ? getTrueIndexes(recurrence.monthsOfYear)
        : null,
      bymonthday: recurrence.daysOfMonth
        ? getTrueIndexes(recurrence.daysOfMonth)
        : null,
      dtstart: recurrence.startDate
        ? convertMomentDateToUTC(
            moment(recurrence.startDate, "MM-DD-YYYY").utc().startOf()
          )
        : null,
      until: recurrence.endDate
        ? convertMomentDateToUTC(
            moment(recurrence.endDate, "MM-DD-YYYY").utc().endOf()
          )
        : undefined,
    });
  }
  getRecurringCalenderEvents() {
    let { recurringExceptions, visibleRange } = this.state;
    let occurrences = [];
    if (!visibleRange.start) return [];
    let after = visibleRange.start;
    let before = visibleRange.end;
    before.setHours(24, 59, 59);

    for (let i = 0; i < recurringExceptions.length; i++) {
      let original = recurringExceptions[i];
      if (!original.rrule) continue;
      let event = original.Event;
      let visibleDates = original.rrule.between(after, before, true);
      // Account for daylight savings time conversions
      visibleDates.forEach((date) => {
        const hour = date.getHours();
        // If changing from CDT to CST, the time will be one hour behind where it should be
        if (hour === 23) {
          date.setHours(hour + 1);
        } else if (hour === 1) {
          // If changing from CST to CDT the time will be one hour forward of where it should be
          date.setHours(hour - 1);
        }
      });
      occurrences = occurrences.concat(
        visibleDates.map((d) => convertRRuleToCalenderEvent(d, event, original))
      );
    }
    return occurrences;
  }

  render() {
    let { instanceExceptions, selectedException } = this.state;

    let calendarEvents = instanceExceptions.map((exception) =>
      convertToCalendarEvent(exception.Event, exception)
    );

    let recurringExceptions = this.getRecurringCalenderEvents();
    return (
      <Modal
        closeIcon
        size="large"
        trigger={
          <Button content="Exceptions in Schedule" size="small" color="blue" />
        }
      >
        <Modal.Header>Exceptions in Schedule</Modal.Header>
        <Modal.Content>
          <div style={{ height: "500px", marginBottom: "40px" }}>
            <Calendar
              drilldownView="agenda"
              localizer={localizer}
              events={[...calendarEvents, ...recurringExceptions]}
              selectable
              onSelectEvent={this.showDetails}
              onSelectSlot={this.handleSelect}
              onRangeChange={this.onRangeChange}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <ExceptionDetails
            key={
              selectedException
                ? selectedException.ID ||
                  selectedException.startDate + selectedException.startTime
                : undefined
            }
            isWorking={selectedException && selectedException.isWorking}
            event={selectedException && selectedException.Event}
            open={selectedException !== null}
            handleSave={this.handleSave}
            handleDelete={this.handleDelete}
            onClose={() => this.setState({ selectedException: null })}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ExceptionCalendar.defaultProps = {
  employeeId: null,
  recurringTypes: [],
};

export default (props) => (
  <OptionsContext.Consumer>
    {({ recurringTypes }) => {
      return <ExceptionCalendar {...props} recurringTypes={recurringTypes} />;
    }}
  </OptionsContext.Consumer>
);
