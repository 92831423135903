import React, { Component } from "react";
import ReactTable from "react-table";

import _ from "lodash";

import io from "../socketConnection";
import { toast } from "react-toastify";
import { OptionsContext } from "../OptionsContext";
import { Popup } from "semantic-ui-react";
import Money from "../Shared/Money";
import { formatPhoneNumber } from "../lib/helperFunctions";

class CustomerTable extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { data: [], pages: -1, loading: true, selectedRow: null };
    this.getCustomers = this.getCustomers.bind(this);
    this.getFilterCategory = this.getFilterCategory.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getFilterCategory(filter) {
    // Get the filter category
    if (["firstName", "middleName", "lastName"].indexOf(filter) > -1) {
      return "User";
    }
    if (["phoneNumber"].indexOf(filter) > -1) {
      return "PhoneNumber";
    }
    if (["city", "address", "zipcode", "mapsco"].indexOf(filter) > -1) {
      return "Address";
    }
    if (["emailAddress"].indexOf(filter) > -1) {
      return "Email";
    }
    if (["petName"].indexOf(filter) > -1) {
      return "Pets";
    }
    if (["balance"].indexOf(filter) > -1) {
      return "Customer";
    }
    return "none";
  }
  getCustomers = (state, instance) => {
    this.setState({ loading: true });
    let filters = {};
    for (let each in state.filtered) {
      let category = this.getFilterCategory(state.filtered[each].id);

      if (filters[category] == null) filters[category] = [];
      filters[category].push(state.filtered[each]);
    }
    io.socket.get(
      "/api/v4/users/getCustomers",
      {
        page: state.page,
        pageSize: state.pageSize,
        sorted: state.sorted,
        filtered: filters
      },
      (body, JWR) => {
        if (this._isMounted) {
          if (JWR.statusCode !== 200) {
            toast.error("Failed to receive customers.");
          }
          this.setState({
            data: body.rows,
            userTypes: body.userTypes,
            pages: body.pages,
            loading: false
          });
        }
      }
    );
  };
  onRowClick(state, rowInfo, column) {
    if (this.state.loading === true) return;
    if (this.props.onRowClick != null) {
      this.setState({ selectedRow: rowInfo.original });
      this.props.onRowClick(state, rowInfo, column);
    } else {
      this.props.history.push(this.props.match.path + rowInfo.original.ID, {
        backPath: this.props.match.path
      });
    }
  }
  render() {
    const { selectedRow, pages, data, loading } = this.state;
    return (
      <OptionsContext.Consumer>
        {({ mapscoCities }) => {
          return (
            <ReactTable
              getTheadThProps={(state, rowInfo, instance) => {
                return { className: "customer-table-" + instance.id };
              }}
              sortable={false}
              manual
              filterable
              defaultPageSize={this.props.defaultPageSize}
              data={data}
              columns={[
                { Header: "First Name", accessor: "firstName" },
                { Header: "Last Name", accessor: "lastName" },
                {
                  Header: "Balance",
                  id: "balance",
                  accessor: "Customer.balance",
                  Cell: props => <Money value={props.value} />
                },
                {
                  Header: "Mapsco",
                  id: "mapsco",
                  maxWidth: 75,
                  accessor: data => {
                    let address = data.Addresses[0];
                    if (!address) return "";
                    let mapscoCity = _.find(mapscoCities, {
                      ID: address.mapscoCityId
                    });
                    return (
                      (mapscoCity ? mapscoCity.letter + " " : "") +
                      (address.mapsco || "")
                    );
                  },
                  Filter: ({ filter, onChange }) => (
                    <Popup
                      position="right center"
                      trigger={
                        <input
                          style={{ width: "100%" }}
                          onChange={event => onChange(event.target.value)}
                          value={filter ? filter.value : ""}
                        ></input>
                      }
                      inverted
                      content="Filtering searches by Mapsco without including the City"
                    />
                  )
                },
                {
                  Header: "Address",
                  id: "address",
                  accessor: data =>
                    data.Addresses && data.Addresses[0]
                      ? data.Addresses[0].address
                      : ""
                },
                {
                  Header: "City",
                  id: "city",
                  maxWidth: 120,
                  accessor: data =>
                    data.Addresses && data.Addresses[0]
                      ? data.Addresses[0].city
                      : ""
                },
                {
                  Header: "ZIP Code",
                  id: "zipcode",
                  maxWidth: 75,
                  accessor: data =>
                    data.Addresses && data.Addresses[0]
                      ? data.Addresses[0].zipcode
                      : ""
                },
                {
                  Header: "Phone",
                  id: "phoneNumber",
                  maxWidth: 100,
                  accessor: data =>
                    data.PhoneNumbers && data.PhoneNumbers[0]
                      ? data.PhoneNumbers[0].phoneNumber
                      : "",
                  Cell: props => formatPhoneNumber(props.value)
                },
                {
                  Header: "Email",
                  id: "emailAddress",
                  accessor: data =>
                    data.Emails && data.Emails[0]
                      ? data.Emails[0].emailAddress
                      : ""
                },
                {
                  Header: "Pets",
                  id: "petName",
                  accessor: data => {
                    let petNames = "";
                    if (data.Customer && data.Customer.Pets) {
                      for (let i = 0; i < data.Customer.Pets.length; i++) {
                        petNames +=
                          data.Customer.Pets[i].petName +
                          (i < data.Customer.Pets.length - 1 ? ", " : "");
                      }
                    }
                    return petNames;
                  }
                },
                {
                  Header: "Last Comment",
                  id: "lastComment",
                  accessor: data => {
                    if (
                      data.CommentGroup &&
                      data.CommentGroup.Comments &&
                      data.CommentGroup.Comments.length > 0
                    ) {
                      let lastComment = _.maxBy(
                        data.CommentGroup.Comments,
                        "DateAdded"
                      );
                      return lastComment.comment;
                    }
                    return "";
                  },
                  filterable: false,
                  minWidth: 250
                }
              ]}
              pages={pages}
              loading={loading}
              className="-striped -highlight customer-table"
              onFetchData={_.debounce(this.getCustomers, 500)}
              {...this.props.tableProps}
              getTrProps={(state, rowInfo, column) => {
                if (rowInfo && rowInfo.original.ID != null) {
                  var style = { cursor: "pointer" };
                }
                return {
                  onClick: () => this.onRowClick(state, rowInfo, column),
                  style: style,
                  className:
                    rowInfo &&
                    selectedRow &&
                    rowInfo.original.ID === selectedRow.ID
                      ? "selected-row"
                      : undefined
                };
              }}
            />
          );
        }}
      </OptionsContext.Consumer>
    );
  }
}

CustomerTable.defaultProps = {
  tableProps: {},
  onRowClick: null,
  defaultPageSize: 20
};

export default CustomerTable;
