import React, { Component } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { Form, Grid, Divider } from "semantic-ui-react";

import ErrorBoundary from "../Shared/ErrorBoundary";
import OrButtons from "../Shared/OrButtons";
import Input from "../Shared/Input";
import TextArea from "../Shared/TextArea";

/**
 * Displays details of a pet object
 */
class CustomerPetDetails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDogCat = value => {
    if (this.props.animalTypeId !== value) {
      this.props.handleChange(null, { name: "animalTypeId", value: value });
      this.props.handleChange(null, { name: "breedId", value: null });
    }
  };
  handleSexToggle = value => {
    this.props.handleChange(null, { name: "sex", value: value });
  };

  render() {
    let {
      ID,
      petName,
      breedId,
      age,
      grooming,
      habits,
      weight,
      isEditing,
      loading,
      animalTypeId
    } = this.props;

    const { breeds, animalTypes } = this.props;

    const sex = this.props.sex.toLowerCase();

    if (ID == null) isEditing = true;
    // Create the options for breeds

    let breedOptions = [];
    if (breeds.length > 0) {
      if (animalTypeId != null) {
        let filteredBreeds = _.filter(breeds, function(breed) {
          return breed.animalTypeId === animalTypeId;
        });
        breedOptions = filteredBreeds.map(breed => {
          return {
            key: breed.ID,
            text: breed.breed,
            value: breed.ID
          };
        });
      } else {
        breedOptions = breeds.map(breed => {
          return {
            key: breed.ID,
            text: breed.breed,
            value: breed.ID
          };
        });
      }
    }

    let selectedBreed = _.find(breeds, { ID: breedId });

    if (!animalTypeId && selectedBreed && selectedBreed.AnimalType) {
      animalTypeId = selectedBreed.AnimalType.ID;
    }
    let animalTypeButtons = animalTypes.map(type => {
      return {
        active: animalTypeId === type.ID,
        disabled: !isEditing,
        onClick: () => this.handleDogCat(type.ID),
        color: animalTypeId === type.ID ? "green" : undefined,
        content: _.startCase(type.type)
      };
    });
    return (
      <ErrorBoundary>
        <Grid stackable>
          <Grid.Row columns="2">
            <Grid.Column>
              <Form.Group widths={2}>
                <Form.Field>
                  {/* Dog or cat */}
                  <OrButtons
                    fluid
                    noActiveMessage="Unknown Animal Type"
                    isEditing={isEditing}
                    buttons={animalTypeButtons}
                  />
                </Form.Field>
                <Form.Field>
                  {/* Male or female */}
                  <OrButtons
                    fluid
                    isEditing={isEditing}
                    noActiveMessage="Unknown Sex"
                    buttons={[
                      {
                        active: sex === "male",
                        disabled: !isEditing,
                        onClick: () => this.handleSexToggle("male"),
                        color: sex === "male" ? "blue" : undefined,
                        content: "Male"
                      },
                      {
                        active: sex === "female",
                        disabled: !isEditing,
                        onClick: () => this.handleSexToggle("female"),
                        color: sex === "female" ? "pink" : undefined,
                        content: "Female"
                      }
                    ]}
                  />
                </Form.Field>
              </Form.Group>
              <Divider />
              <Form.Group widths={2}>
                <Input
                  isEditing={isEditing}
                  label="Name"
                  required
                  name="petName"
                  onChange={this.props.handleChange}
                  value={petName}
                />
                {isEditing ? (
                  <Form.Select
                    search
                    fluid
                    options={breedOptions}
                    required
                    placeholder="Select Breed"
                    label="Breed"
                    name="breedId"
                    loading={loading}
                    value={breedId || ""}
                    onChange={this.props.handleChange}
                  />
                ) : (
                  <Input
                    label="Breed"
                    value={
                      selectedBreed ? selectedBreed.breed : "None Selected"
                    }
                  />
                )}
              </Form.Group>
              <Input
                isEditing={isEditing}
                label="Weight"
                type="number"
                validation={["integer", "positive"]}
                min={0}
                step={1}
                value={weight}
                name="weight"
                // added arrow function to handle change and parseInt the value so we no longer see decimal on confirmation page
                onChange={(e, data) => {
                  this.props.handleChange(e, {
                    ...data,
                    value: parseInt(data.value)
                  });
                }}
              />
              <Input
                type="number"
                min={0}
                validation={["integer", "positive"]}
                isEditing={isEditing}
                label="Age"
                value={age}
                name="age"
                // added arrow function to handle change and parseInt the value so we no longer see decimal on confirmation page
                onChange={(e, data) => {
                  this.props.handleChange(e, {
                    ...data,
                    value: parseInt(data.value)
                  });
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Grooming and Cut</label>
                <TextArea
                  value={grooming === null ? "None" : grooming}
                  name="grooming"
                  onChange={this.props.handleChange}
                  isEditing={isEditing}
                />
              </Form.Field>
              <Form.Field>
                <label>Habits</label>
                <TextArea
                  value={habits === null ? "None" : habits}
                  name="habits"
                  onChange={this.props.handleChange}
                  isEditing
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ErrorBoundary>
    );
  }
}

CustomerPetDetails.defaultProps = {
  //Options
  breeds: [],
  animalTypes: [],

  handlePetSelect: () => {},
  changePetOwnerSuccess: () => {},
  handleChange: () => {},
  onUpdate: () => {},
  hideButtonGroup: false,
  hideDetailsButton: false,
  required: ["Breed"],

  pet: {}
};

CustomerPetDetails.propTypes = {
  //Options
  breeds: PropTypes.array,
  animalTypes: PropTypes.array,

  handlePetSelect: PropTypes.func,
  changePetOwnerSuccess: PropTypes.func,
  handleChange: PropTypes.func,
  onUpdate: PropTypes.func,
  hideButtonGroup: PropTypes.bool,
  hideDetailsButton: PropTypes.bool,
  required: PropTypes.array,
  pet: PropTypes.object
};

export default CustomerPetDetails;
