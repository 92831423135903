import React, { Component } from "react";
import Notification from "./Notification";
import Proptypes from "prop-types";
import { getToken } from "../lib/csrfToken";
import Axios from "axios";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }
  static getDerivedStateFromError(error) {
    return { error: error };
  }
  async componentDidCatch(error, info) {
    this.setState({ error: error, info: info });

    try {
      await Axios.post(
        "/api/v4/logClientError",
        {
          error: error,
          info: info
        },
        { headers: { "X-CSRF-Token": getToken() } }
      );
    } catch (error) {
      console.error("Failed to log client error", error);
    }
  }
  render() {
    if (this.state.error) {
      if (this.props.debug) {
        console.error(this.state.error, this.state.info);
      }
      return <Notification content="Something went wrong" error />;
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.defaultProps = {
  debug: false
};

ErrorBoundary.propTypes = {
  debug: Proptypes.bool
};

export default ErrorBoundary;
