import React, { Component } from "react";

import {
  Header,
  Modal,
  Divider,
  Button,
  Segment,
  Popup
} from "semantic-ui-react";
import ErrorBoundary from "../Shared/ErrorBoundary";
import ReactTable from "react-table";
import _ from "lodash";
import {
  getPhoneNumbersByUser,
  createPhoneNumber,
  updatePhoneNumber,
  deletePhoneNumber,
  callPhoneNumber
} from "../lib/apiCalls";
import { toast } from "react-toastify";
import PhoneNumberForm from "../Forms/PhoneNumberForm";
import Authorize from "../Shared/Authorize";
import { formatPhoneNumber } from "../lib/helperFunctions";

/**
 * Displays a table of phone numbers. If authorized for edit user, shows buttons and fields
 * for adding, deleting and editing phone numbers in the table.
 */
class PhoneNumbers extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isCustomerEditing: false,
      phoneNumbers: []
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.getPhoneNumbers();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  shouldComponentUpdate(prevProps, prevState) {
    return (
      !_.isEqual(prevProps, this.props) || !_.isEqual(prevState, this.state)
    );
  }
  getPhoneNumbers = () => {
    let { match } = this.props;
    if (match) {
      getPhoneNumbersByUser(
        { userId: match.params.userId },
        (error, response) => {
          if (error) {
            toast.error("Failed to retrieve phone numbers");
            return;
          }
          if (this._isMounted) {
            this.setState({ phoneNumbers: response });
          }
        }
      );
    }
  };

  handleCall = phoneNumber => {
    callPhoneNumber({ phoneNumber: phoneNumber });
  };

  handleDelete = phoneNumber => {
    deletePhoneNumber({ ID: phoneNumber.ID }, (error, response) => {
      if (error) {
        toast.error("Failed to remove phone number");
        return;
      }
      this.getPhoneNumbers();
      toast.success("Successfully removed phone number");
    });
  };

  updateNewPhoneNumber = data => {
    this.setState({ newPhoneNumber: data });
  };
  handleCreate = () => {
    let { newPhoneNumber } = this.state;
    let { match } = this.props;

    if (!match || !match.params.userId) {
      toast.info("A user is required to create new phone number");
      return;
    }
    createPhoneNumber(
      { ...newPhoneNumber, userId: match.params.userId },
      (error, response) => {
        if (error) {
          toast.error("Failed to create new phone number");
          return;
        }
        this.setState({
          openNewPhoneNumberModal: false
        });
        this.getPhoneNumbers();
        toast.success("Successfully created phone number");
      }
    );
  };
  handleSave = data => {
    updatePhoneNumber(data, (error, response) => {
      if (error) {
        toast.error("Failed to save phone number");
        return;
      }
      this.getPhoneNumbers();
      toast.success("Successfully saved phone number");
    });
  };
  openModal = () => {
    this.setState({ openNewPhoneNumberModal: true });
  };
  closeModal = () => {
    this.setState({ openNewPhoneNumberModal: false, newPhoneNumber: {} });
  };
  render() {
    let { phoneNumbers, openNewPhoneNumberModal, loading } = this.state;
    let { allowEditing } = this.props;
    let defaultPrimary = _.find(phoneNumbers, { isPrimary: true }) == null;

    return (
      <ErrorBoundary>
        <Segment>
          <Header as="h2">
            Phone Numbers
            {allowEditing && (
              <Modal
                open={openNewPhoneNumberModal}
                onClose={this.closeModal}
                trigger={
                  <Button
                    color="blue"
                    floated="right"
                    content="Add Phone Number"
                    onClick={this.openModal}
                  />
                }
              >
                <Modal.Header>New Phone Number</Modal.Header>
                <Modal.Content>
                  <PhoneNumberForm
                    onUpdate={this.updateNewPhoneNumber}
                    hideActionButtons
                    phoneNumber={{ isPrimary: defaultPrimary }}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content="Create"
                    color="green"
                    onClick={this.handleCreate}
                  />
                  <Button content="Cancel" onClick={this.closeModal} />
                </Modal.Actions>
              </Modal>
            )}
          </Header>
          <Divider hidden />
          <ReactTable
            loading={loading}
            data={phoneNumbers}
            minRows={4}
            collapseOnDataChange={false}
            pageSize={phoneNumbers.length}
            columns={[
              {
                Header: "",
                accessor: "phoneNumber",
                width: 45,
                sortable: false,
                filterable: false,
                getProps: () => {
                  return {};
                },
                Cell: props => (
                  <Authorize permission="UsePhoneSystem">
                    <Popup
                      basic
                      inverted
                      content="Call Number"
                      trigger={
                        <Button
                          fluid
                          size="tiny"
                          icon="phone"
                          color="grey"
                          onClick={() => this.handleCall(props.value)}
                        />
                      }
                    />
                  </Authorize>
                )
              },
              ...columns
            ]}
            showPagination={false}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                className:
                  rowInfo && rowInfo.original.isPrimary === true
                    ? "primary-row"
                    : ""
              };
            }}
            SubComponent={
              allowEditing
                ? row => {
                    return (
                      <Segment basic>
                        <PhoneNumberForm
                          phoneNumber={row.original}
                          handleSave={this.handleSave}
                          handleDelete={this.handleDelete}
                        />
                      </Segment>
                    );
                  }
                : undefined
            }
          />
        </Segment>
      </ErrorBoundary>
    );
  }
}
PhoneNumbers.defaultProps = {
  allowEditing: true
};

export default PhoneNumbers;

const columns = [
  {
    Header: "Label",
    accessor: "label",
    minWidth: 50
  },
  {
    Header: "Phone Number",
    accessor: "phoneNumber",
    Cell: props => formatPhoneNumber(props.value)
  }
];
