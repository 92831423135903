import React, { Component } from "react";
import { Form, Button, Divider, Dropdown } from "semantic-ui-react";

import Input from "../Shared/Input";
import { defaultAddress } from "../lib/defaultObjects";
import _ from "lodash";
import Authorize from "../Shared/Authorize";
import { validateZipCode, validateMapsco } from "../lib/validation";
import { toast } from "react-toastify";
import { OptionsContext } from "../OptionsContext";

class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultAddress(props.address)
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state, prevState)) {
      this.props.onUpdate(this.state);
    }
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleToggle = (e, { name }) => {
    this.setState(state => ({
      [name]: !state[name]
    }));
  };
  handleSave = () => {
    let { zipcode, mapsco } = this.state;
    if (zipcode && zipcode !== "" && !validateZipCode(zipcode)) {
      toast.info("Please enter a valid zipcode.");
      return;
    }
    if (mapsco && !validateMapsco(mapsco)) {
      toast.info("Please enter a valid Mapsco (Ex: 123-A or 123A-A)");
      return;
    }
    this.props.handleSave(this.state);
  };
  handleDelete = () => {
    this.props.handleDelete(this.state);
  };
  render() {
    let {
      address,
      city,
      state,
      zipcode,
      mapsco,
      mapscoCityId,
      needsGenerator,
      isPrimary,
      notes
    } = this.state;
    let { hideActionButtons, isEditing } = this.props;

    return (
      <OptionsContext.Consumer>
        {({ mapscoCities }) => {
          return (
            <Form>
              <Button
                type="button"
                toggle
                content="Primary Address"
                name="isPrimary"
                active={isPrimary}
                onClick={this.handleToggle}
                disabled={!isEditing}
              />
              <Authorize permission="ViewUsers">
                <Button
                  type="button"
                  toggle
                  content="Generator Required"
                  name="needsGenerator"
                  active={needsGenerator}
                  onClick={this.handleToggle}
                  disabled={!isEditing}
                />
              </Authorize>
              <Divider hidden />
              <Input
                label="Address"
                maxLength={100}
                value={address}
                onChange={this.handleChange}
                name="address"
                isEditing={isEditing}
              />
              <Form.Group widths={2}>
                <Input
                  label="City"
                  isEditing={isEditing}
                  value={city}
                  onChange={this.handleChange}
                  name="city"
                />
                <Input
                  label="State"
                  isEditing={isEditing}
                  value={state}
                  onChange={this.handleChange}
                  name="state"
                />
              </Form.Group>
              <Form.Group widths={2}>
                <Input
                  label="Zipcode"
                  validation="zipcode"
                  isEditing={isEditing}
                  value={zipcode}
                  onChange={this.handleChange}
                  name="zipcode"
                />
                <Authorize permission="ViewUsers">
                  <Input
                    label="Mapsco"
                    name="mapsco"
                    value={mapsco}
                    validation="mapsco"
                    isEditing={isEditing}
                    onChange={this.handleChange}
                    attachedLabel={
                      isEditing ? (
                        <Dropdown
                          placeholder="City"
                          selection
                          name="mapscoCityId"
                          onChange={this.handleChange}
                          value={mapscoCityId}
                          options={mapscoCities.map(city => ({
                            text: city.city,
                            key: city.ID,
                            value: city.ID
                          }))}
                        />
                      ) : _.find(mapscoCities, { ID: mapscoCityId }) ? (
                        _.find(mapscoCities, { ID: mapscoCityId }).city
                      ) : (
                        "No City"
                      )
                    }
                  />
                </Authorize>
              </Form.Group>
              <Input
                label="Notes"
                isEditing={isEditing}
                value={notes}
                onChange={this.handleChange}
                name="notes"
                maxLength={250}
              />
              {!hideActionButtons && isEditing && (
                <>
                  <Button
                    color="green"
                    content="Save"
                    onClick={this.handleSave}
                  />
                  <Button
                    color="red"
                    content="Delete"
                    onClick={this.handleDelete}
                  />
                </>
              )}
            </Form>
          );
        }}
      </OptionsContext.Consumer>
    );
  }
}

AddressForm.defaultProps = {
  handleSave: () => {},
  handleDelete: () => {},
  onUpdate: () => {},
  hideActionButtons: false,
  isEditing: true
};

export default AddressForm;
