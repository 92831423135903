import Cookies from "universal-cookie";
import Axios from "axios";

// import io from "../socketConnection";

const cookies = new Cookies();

export async function setToken() {
  let csrf = await Axios.get("/csrfToken", {
    xsrfHeaderName: "X-CSRF-Token"
  });
  if (csrf.error) {
    console.error("Failed to retrieve CSRF Token");
  } else {
    // io.sails.headers = { "X-CSRF-Token": csrf.data._csrf };

    cookies.set("securityToken", csrf.data._csrf, { path: "/" });
  }
  return;
}

export function getToken() {
  return cookies.get("securityToken");
}
