import _ from "lodash";
import moment from "moment";
import { isNumber } from "./validation";
import { moneyInputFormat } from "./format";
/**
 * Calculate a pet estimate subtotal
 * @param {object} petEstimate
 */
export function calculatePetEstimate(petEstimate = {}, breed = {}) {
  let subtotal = 0;

  if (
    !petEstimate.Pet.isOverridingGroomingFee === true ||
    !isNumber(petEstimate.Pet.overrideGroomingFeePrice)
  ) {
    // Get the breed package price
    if (!_.isEqual(breed, {}) && breed != null) {
      if (
        !petEstimate.estimatePackage ||
        petEstimate.estimatePackage.toLowerCase() === "b&b"
      ) {
        subtotal = breed.bathBrushPrice;
      } else if (petEstimate.estimatePackage.toLowerCase() === "deluxe") {
        subtotal = breed.deluxePrice;
      }
    }
  } else {
    subtotal = parseFloat(petEstimate.Pet.overrideGroomingFeePrice);
  }

  // Get all prices of the services
  if (petEstimate.services) {
    let useAddonPrices =
      petEstimate.estimatePackage.toLowerCase() === "add-on special";

    for (let each in petEstimate.services) {
      if (useAddonPrices && petEstimate.services[each].addonPrice) {
        if (isNumber(petEstimate.services[each].addonPrice)) {
          subtotal =
            parseFloat(subtotal) +
            parseFloat(petEstimate.services[each].addonPrice);
        }
      } else {
        if (isNumber(petEstimate.services[each].price)) {
          subtotal =
            parseFloat(subtotal) + parseFloat(petEstimate.services[each].price);
        }
      }
    }
  }

  // Discount for service pet
  if (petEstimate.Pet.isServicePet === true) {
    subtotal = subtotal - 5;
  }

  // Adding any other costs
  if (parseFloat(petEstimate.otherFee)) {
    subtotal = parseFloat(subtotal) + parseFloat(petEstimate.otherFee);
  }
  return subtotal < 0 || !subtotal ? 0 : roundToMoney(subtotal);
}

/**
 * Calculate totals for an estimate by totaling services and discounts
 *  and calculates tax, fuel, and overall total.
 * @param {object} estimate Estimate to get pet estimates and estimate options on
 * @param {object} customer Customer to check for isMilitary and isSeniorCitizen and isTaxExempt
 * @param {[object]} petEstimates All pet estimates to get the service fees for
 * @param {[object]} breeds Breeds to check the package prices for
 * @returns {object} Returns totals and options: service, trip, fuel, generator, discounts, tax, and total
 */
export function calculateTotals(
  estimate = {},
  customer = {},
  petEstimates = [],
  breeds = []
) {
  let service = 0;
  let discounts = 0;
  // Add pet subtotal to services
  for (let i = 0; i < petEstimates.length; i++) {
    // Skip adding the pet estimate to the total if you should not include it in the estimate or if the pet is deceased
    if (
      !petEstimates[i].includeInEstimate ||
      !petEstimates[i].Pet ||
      petEstimates[i].Pet.isDeceased === true
    )
      continue;
    let pet = petEstimates[i].Pet;

    // Find the details of the breed
    let breedDetails = pet.breedId ? _.find(breeds, { ID: pet.breedId }) : null;
    let packagePrices = {};
    if (breedDetails) {
      packagePrices = getPackagePrices(breedDetails, pet.lastGroomDate);
    }
    let petEstimate = calculatePetEstimate(petEstimates[i], packagePrices);
    if (petEstimate != null && isNumber(petEstimate)) {
      service = service + parseFloat(petEstimate);
    }
    // Add service pet discount to total
    // Add the discount price back to the service fee since it should not be included in services but is added during a pet estimate
    if (pet.isServicePet === true) {
      discounts = discounts + 5;
      service = service + 5;
    }
  }

  // Add Senior Citizen Discount
  if (customer.isSeniorCitizen) discounts = discounts + 5;
  // Add Military Discount
  if (customer.isMilitary) discounts = discounts + 5;

  let subtotal = service + parseFloat(estimate.tripFee || 0) - discounts;

  let generator = 0;
  // Add generator cost if the address needs a generator
  if (
    estimate.needsGenerator === true &&
    parseFloat(estimate.generatorFee || 0)
  ) {
    subtotal = subtotal + parseFloat(estimate.generatorFee || 0);
    generator = parseFloat(estimate.generatorFee || 0);
  }

  // Calculate the fuel fee
  let fuelFee =
    (parseFloat(service) +
      parseFloat(estimate.tripFee || 0) +
      parseFloat(generator)) *
    parseFloat(estimate.fuelCharge);

  // Get the sales tax
  let tax = 0;
  if (!customer.isTaxExempt) {
    tax = (subtotal + fuelFee) * estimate.salesTax;
  }

  //Add the sales tax to the total
  const total = subtotal + tax + fuelFee;

  return {
    service: roundToMoney(service),
    trip: moneyInputFormat(estimate.tripFee),
    fuel: roundToMoney(fuelFee),
    generator: roundToMoney(estimate.generatorFee),
    discounts: roundToMoney(discounts),
    tax: roundToMoney(tax < 0 ? 0 : tax),
    total: roundToMoney(total < 0 ? 0 : total)
  };
}

function roundToMoney(num) {
  return Math.round(num * 100) / 100;
}
/**
 * Calculates the months since the given date
 * @param {date} lastGroomed Date of the last groom for the pet
 */
export function monthsSinceGroomed(lastGroomed) {
  if (!moment(lastGroomed).isValid()) {
    return null;
  }
  let firstDate = moment(lastGroomed);
  let secondDate = moment();
  let duration = moment.duration(secondDate.diff(firstDate));
  return Math.floor(duration.asMonths());
}
/**
 * Calculates the years since the given date
 * @param {date} date
 */
export function yearsSinceDate(date) {
  if (!moment(date, "MM-DD-YYYY").isValid()) {
    return null;
  }
  let firstDate = moment(date, "MM-DD-YYYY");
  let secondDate = moment();
  let duration = moment.duration(secondDate.diff(firstDate));
  return Math.round(duration.asYears()) + "";
}

/**
 * Get the prices of the package based on the last groom date
 * @param {object} breedDetails Breed to get prices for
 * @param {date} lastGroomedDate  Date the pet was last groomed
 */
export function getPackagePrices(breedDetails, lastGroomedDate) {
  let packagePrices = {};

  if (breedDetails) {
    let months = monthsSinceGroomed(lastGroomedDate);
    packagePrices = {
      bathBrushPrice: breedDetails.bathBrushPrice,
      deluxePrice: breedDetails.deluxePrice
    };

    if (months && months >= 3 && months < 6) {
      packagePrices = {
        bathBrushPrice: breedDetails.bathBrushPrice3Months,
        deluxePrice: breedDetails.deluxePrice3Months
      };
    } else if (months && months >= 6) {
      packagePrices = {
        bathBrushPrice: breedDetails.bathBrushPrice6Months,
        deluxePrice: breedDetails.deluxePrice6Months
      };
    }
  }
  return packagePrices;
}
