import React, { Component } from "react";
import { Modal, Header, Button, Icon, Segment } from "semantic-ui-react";

class AppointmentCompletionModal extends Component {
  state = { modalOpen: false };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    return (
      <Modal
        closeIcon
        size="small"
        trigger={
          <Button onClick={this.handleOpen} color="green">
            Save and Complete
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Content>
          <Segment placeholder basic>
            <Header icon>
              <Icon name="home" />
              Complete Appointment With
            </Header>
            <Segment.Inline>
              <Button primary onClick={this.props.handleCustomerOnSite}>
                Customer Present
              </Button>
              <Button onClick={this.props.handleCustomerNotHome}>
                Customer Absent
              </Button>
            </Segment.Inline>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}

AppointmentCompletionModal.defaultProps = {
  handleCustomerOnSite: () => {},
  handleCustomerNotHome: () => {}
};

export default AppointmentCompletionModal;
