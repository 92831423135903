import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Header,
  Modal,
  Divider,
  Button,
  Popup,
  Icon,
  Segment
} from "semantic-ui-react";

import ErrorBoundary from "../Shared/ErrorBoundary";

import ReactTable from "react-table";

import _ from "lodash";
import {
  getAddressesByUser,
  createAddress,
  updateAddress,
  deleteAddress
} from "../lib/apiCalls";
import { toast } from "react-toastify";

import AddressForm from "../Forms/AddressForm";
import { validateZipCode, validateMapsco } from "../lib/validation";
import { OptionsContext } from "../OptionsContext";
import Authorize from "../Shared/Authorize";

/**
 * Displays a table of addresses. If authorized for edit user, shows buttons and fields
 * for adding, deleting and editing addresses in the table.
 */
class Addresses extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isCustomerEditing: false,
      addresses: []
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.getAddresses();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  shouldComponentUpdate(prevProps, prevState) {
    return (
      !_.isEqual(prevProps, this.props) || !_.isEqual(prevState, this.state)
    );
  }
  getAddresses = () => {
    let { match } = this.props;
    if (match) {
      getAddressesByUser({ userId: match.params.userId }, (error, response) => {
        if (error) {
          toast.error("Failed to retrieve addresses");
          return;
        }
        if (this._isMounted) {
          this.setState({ addresses: response });
        }
      });
    }
  };
  handleDelete = address => {
    deleteAddress({ ID: address.ID }, (error, response) => {
      if (error) {
        toast.error("Failed to remove address");
        return;
      }
      this.getAddresses();
      toast.success("Successfully removed address");
    });
  };

  updateNewAddress = data => {
    this.setState({ newAddress: data });
  };

  handleCreate = () => {
    let { newAddress } = this.state;
    let { match } = this.props;

    if (!match || !match.params.userId) {
      toast.info("A user is required to create new address");
      return;
    }
    let { zipcode, mapsco } = newAddress;
    if (zipcode && zipcode !== "" && !validateZipCode(zipcode)) {
      toast.info("Please enter a valid zipcode.");
      return;
    }
    if (mapsco && mapsco !== "" && !validateMapsco(mapsco)) {
      toast.info("Please enter a valid Mapsco (Ex: 123-A or 123A-A)");
      return;
    }
    createAddress(
      { ...newAddress, userId: match.params.userId },
      (error, response) => {
        if (error) {
          toast.error("Failed to create new address");
          return;
        }
        this.setState({
          openNewAddressModal: false
        });
        this.getAddresses();
        toast.success("Successfully added address");
      }
    );
  };
  handleSave = data => {
    updateAddress(data, (error, response) => {
      if (error) {
        toast.error("Failed to save address");
        return;
      }
      this.getAddresses();
      toast.success("Successfully saved address");
    });
  };
  openModal = () => {
    this.setState({ openNewAddressModal: true });
  };
  closeModal = () => {
    this.setState({ openNewAddressModal: false, newAddress: {} });
  };
  render() {
    let { addresses, openNewAddressModal, loading } = this.state;
    let { allowEditing, mapscoCities } = this.props;
    let defaultPrimary = _.find(addresses, { isPrimary: true }) == null;

    return (
      <ErrorBoundary>
        <Segment>
          <Header as="h2">
            Addresses
            <Authorize permission="EditAppointments">
              {allowEditing && (
                <Modal
                  open={openNewAddressModal}
                  onClose={this.closeModal}
                  trigger={
                    <Button
                      color="blue"
                      floated="right"
                      content="Add Address"
                      onClick={this.openModal}
                    />
                  }
                >
                  <Modal.Header>New Address</Modal.Header>
                  <Modal.Content>
                    <AddressForm
                      onUpdate={this.updateNewAddress}
                      hideActionButtons
                      address={{ isPrimary: defaultPrimary }}
                    />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      content="Create"
                      color="green"
                      onClick={this.handleCreate}
                    />
                    <Button content="Cancel" onClick={this.closeModal} />
                  </Modal.Actions>
                </Modal>
              )}
            </Authorize>
          </Header>
          <Divider hidden />
          <ReactTable
            loading={loading}
            data={addresses}
            minRows={4}
            collapseOnDataChange={false}
            pageSize={addresses.length}
            columns={[
              {
                Header: () => {
                  return (
                    <Popup
                      trigger={
                        <Icon.Group>
                          <Icon size="big" color="red" name="dont" />
                          <Icon color="black" name="plug" />
                        </Icon.Group>
                      }
                      inverted
                      content="No plug accessible. Generator needed."
                    />
                  );
                },
                accessor: "needsGenerator",
                className: "centered",
                minWidth: 50,
                Cell: props =>
                  props.value === true ? <Icon name="check" /> : <div />
              },
              {
                Header: "Address",
                accessor: "address",
                minWidth: 150
              },
              {
                Header: "City",
                accessor: "city"
              },
              {
                Header: "State",
                accessor: "state",
                minWidth: 50
              },
              {
                Header: "ZIP Code",
                accessor: "zipcode",
                minWidth: 75
              },
              {
                Header: "Mapsco",
                id: "mapsco",
                accessor: data => {
                  let mapscoCity = _.find(mapscoCities, {
                    ID: data.mapscoCityId
                  });
                  return `${
                    mapscoCity ? mapscoCity.letter + " " : ""
                  }${data.mapsco || ""}`;
                },
                minWidth: 75
              },
              {
                Header: "Notes",
                accessor: "notes",
                minWidth: 200
              }
            ]}
            showPagination={false}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                className:
                  rowInfo && rowInfo.original.isPrimary === true
                    ? "primary-row"
                    : ""
              };
            }}
            SubComponent={
              allowEditing
                ? row => {
                    return (
                      <Segment basic>
                        <Authorize
                          permission="EditAppointments"
                          else={
                            <AddressForm
                              address={row.original}
                              isEditing={false}
                            />
                          }
                        >
                          <AddressForm
                            address={row.original}
                            handleSave={this.handleSave}
                            handleDelete={this.handleDelete}
                          />
                        </Authorize>
                      </Segment>
                    );
                  }
                : undefined
            }
          />
        </Segment>
      </ErrorBoundary>
    );
  }
}

Addresses.defaultProps = {
  allowEditing: true
};

Addresses.propTypes = {
  allowEditing: PropTypes.bool
};

export default props => (
  <OptionsContext.Consumer>
    {({ mapscoCities }) => {
      return <Addresses {...props} mapscoCities={mapscoCities} />;
    }}
  </OptionsContext.Consumer>
);
