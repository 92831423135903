import moment from "moment";
import _ from "lodash";

export default function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  if (filter && filter.value && typeof row[id] === "string") {
    return (
      String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
    );
  }
  return false;
}

export function filterById(filter, row) {
  const id = filter.pivotId || filter.id;
  if (typeof row[id] === "number") {
    return parseInt(row[id]) === parseInt(filter.value);
  }
  return false;
}

/**
 * Checks if the filter value is included in the row array for that value. Value must be exact
 */
export function filterIncludesPet(filter, row) {
  const id = filter.pivotId || filter.id;
  if (Array.isArray(row[id])) {
    if (
      _.find(row[id], (e) => {
        return (
          e &&
          e.petName &&
          filter.value != null &&
          e.petName.toLowerCase().includes(filter.value.toLowerCase())
        );
      })
    ) {
      return true;
    }
  }
  return false;
}

/** Filter all dates same as the given date */
export function filterDate(filter, row) {
  if (moment(row[filter.id]).isSame(moment(filter.value, "LL").utc())) {
    return true;
  }
  return false;
}

/** Filter all dates same as the given date */
export function filterDateAfter(filter, row) {
  if (moment(row[filter.id]).isSameOrAfter(moment(filter.value, "LL").utc())) {
    return true;
  }
  return false;
}

export function sortTime(a, b) {
  // force null and undefined to the bottom
  a = a === null || a === undefined ? "12:00 A" : a;
  b = b === null || b === undefined ? "12:00 A" : b;
  // Return either 1 or -1 to indicate a sort priority
  if (moment(a, "h:mm A").isAfter(moment(b, "h:mm A"))) {
    return 1;
  }
  if (moment(a, "h:mm A").isBefore(moment(b, "h:mm A"))) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
}

export function sortByPetName(a, b) {
  let aPets = a ? _.sortBy(a, "petName") : [];
  let bPets = b ? _.sortBy(b, "petName") : [];

  aPets = aPets.map((e) => (e ? e.petName : null));
  bPets = bPets.map((e) => (e ? e.petName : null));

  aPets = aPets.filter((e) => e != null);
  bPets = bPets.filter((e) => e != null);

  aPets = aPets.join(", ");
  bPets = bPets.join(", ");

  if (aPets < bPets) {
    return -1;
  }

  if (aPets > bPets) {
    return 1;
  }
  return 0;
}
