import React, { Component } from "react";

import ReactTable from "react-table";
import moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";

import {
  Header,
  Form,
  Popup,
  Button,
  Modal,
  Divider,
  Checkbox,
  Grid
} from "semantic-ui-react";
import { OptionsContext } from "../OptionsContext";
import customFilter from "../lib/filters";
import { Link } from "react-router-dom";
import { completeTodoItem, createTodoItem } from "../lib/apiCalls";
import { TodoItemsContext } from "../TodoItemsContext";
import Input from "../Shared/Input";

class TodoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      open: false,
      note: "",
      date: "",
      repeatInDays: null,
      todoCategoryId: null,
      customerId: null,
      viewAll: false
    };
  }

  onChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  onToggle = (e, { name }) => {
    this.setState(state => ({ [name]: !state[name] }));
  };
  completeTodoItem = id => {
    completeTodoItem({ todoItemId: id }, (error, response) => {
      if (error) {
        toast.error("Failed to complete todo item");
        return;
      }
      toast.success("Completed todo item");
      if (this.state.open === true) {
        this.closeModal();
      }
    });
  };
  handleComplete = todoItem => {
    if (todoItem.repeatInDays != null) {
      this.openModal(todoItem);
    } else {
      this.completeTodoItem(todoItem.ID);
    }
  };
  handleCreate = () => {
    let {
      ID,
      note,
      date,
      repeatInDays,
      todoCategoryId,
      customerId
    } = this.state;
    if (!note) {
      toast.info("Todo is a required field");
      return;
    }
    createTodoItem(
      {
        todoCategoryId: parseInt(todoCategoryId),
        repeatInDays: parseInt(repeatInDays),
        date: date,
        note: note,
        customerId: customerId
      },
      (error, response) => {
        this.completeTodoItem(ID);
        this.closeModal();
      }
    );
  };
  openModal = todoItem => {
    this.setState({
      open: true,
      ID: todoItem.ID,
      note: todoItem.note,
      date: moment()
        .add(todoItem.repeatInDays, "days")
        .format("MM-DD-YYYY"),
      repeatInDays: todoItem.repeatInDays,
      todoCategoryId: todoItem.todoCategoryId,
      customerId: todoItem.customerId
    });
  };
  closeModal = () => {
    this.setState({
      open: false,
      ID: null,
      note: "",
      date: "",
      customerId: null,
      repeatInDays: null,
      todoCategoryId: null
    });
  };
  render() {
    let { todoCategories, todoItems } = this.props;
    let {
      note,
      repeatInDays,
      date,
      todoCategoryId,
      open,
      viewAll
    } = this.state;

    return (
      <div>
        <Modal open={open} onClose={this.closeModal} closeIcon>
          <Modal.Header>Repeating Todo Item</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Please confirm the details of the todo item to repeat.
            </Modal.Description>
            <Divider hidden />
            <Form>
              <Input
                isEditing
                required
                maxLength={250}
                label="Todo"
                value={note}
                placeholder="What needs to be done for this customer?"
                name="note"
                onChange={this.onChange}
              />
              <Form.Group widths="3">
                <Form.Select
                  clearable
                  options={todoCategories.map(category => ({
                    value: category.ID,
                    text: category.category,
                    key: category.ID
                  }))}
                  label="Category"
                  search
                  name="todoCategoryId"
                  value={todoCategoryId}
                  onChange={this.onChange}
                  placeholder="Select a Category"
                />
                <Input
                  validation="date"
                  label="Next Due Date"
                  value={date}
                  isEditing
                  name="date"
                  onChange={this.onChange}
                  placeholder="Select a Date"
                />
                <Input
                  label="Repeat in Days"
                  value={repeatInDays}
                  name="repeatInDays"
                  min={0}
                  placeholder="Days"
                  step={1}
                  type="number"
                  validation={["integer", "positive"]}
                  onChange={this.onChange}
                  isEditing
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Complete without Repeat"
              onClick={() => this.completeTodoItem(this.state.ID)}
            />
            <Button
              content="Complete"
              onClick={this.handleCreate}
              color="green"
            />
          </Modal.Actions>
        </Modal>

        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">Todo Items</Header>{" "}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Checkbox
                toggle
                label="View All"
                name="viewAll"
                onChange={this.onToggle}
                checked={viewAll}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ReactTable
          getTheadThProps={(state, rowInfo, instance) => {
            return { className: "todo-table-" + instance.id };
          }}
          defaultFilterMethod={customFilter}
          data={
            viewAll === true
              ? todoItems
              : todoItems.filter(item => {
                  if (
                    item.date &&
                    moment(item.date, "MM-DD-YYYY").isAfter(
                      moment().endOf("day")
                    )
                  ) {
                    return false;
                  }
                  return true;
                })
          }
          filterable
          sortable
          defaultSorted={[{ id: "DateAdded" }]}
          className="-striped todo-table"
          defaultPageSize={window.innerHeight > 1000 ? 20 : 10}
          columns={[
            {
              Header: "",
              accessor: "ID",
              width: 45,
              sortable: false,
              filterable: false,
              getProps: () => {
                return {};
              },
              Cell: props => (
                <Popup
                  basic
                  inverted
                  content="Complete the todo item"
                  trigger={
                    <Button
                      fluid
                      size="small"
                      icon="check"
                      color="green"
                      onClick={() => this.handleComplete(props.original)}
                    />
                  }
                />
              )
            },
            {
              Header: "",
              accessor: "Customer.User.ID",
              width: 45,
              sortable: false,
              filterable: false,
              getProps: () => {
                return {};
              },
              Cell: props => (
                <Popup
                  basic
                  inverted
                  content="View customer's profile"
                  trigger={
                    <Button
                      fluid
                      size="small"
                      icon="user"
                      color="blue"
                      as={Link}
                      to={`/dashboard/customers/${props.value}/overview`}
                    />
                  }
                />
              )
            },
            {
              Header: "Due Date",
              accessor: "date",
              Cell: props =>
                props.value && moment(props.value, "MM-DD-Y").format("LL")
            },
            {
              Header: "Repeat In Days",
              accessor: "repeatInDays",
              minWidth: 40,
              className: "centered-column",
              Cell: props => props.value && props.value + " days"
            },
            {
              Header: "Customer",
              id: "customerId",
              accessor: data =>
                data.Customer &&
                data.Customer.User &&
                `${data.Customer.User.firstName} ${data.Customer.User.lastName}`
            },
            {
              Header: "Category",
              id: "todoCategory",
              accessor: data => {
                let foundCategory = _.find(todoCategories, {
                  ID: data.todoCategoryId
                });
                return foundCategory ? foundCategory.category : "";
              },
              headerClassName: "dropdown-filter",
              Filter: ({ filter, onChange }) => (
                <Form size="small">
                  <Form.Select
                    className="filter-select"
                    fluid
                    clearable
                    search
                    name="todoCategoryId"
                    placeholder="Select Category"
                    options={todoCategories.map(category => {
                      return {
                        key: category.ID,
                        value: category.category,
                        text: category.category
                      };
                    })}
                    value={filter ? filter.value : null}
                    onChange={(event, { name, value }) => onChange(value)}
                  />
                </Form>
              )
            },
            {
              Header: "Todo",
              accessor: "note",
              minWidth: 250
            }
          ]}
        />
      </div>
    );
  }
}

TodoList.defaultProps = {
  onRowClick: null,
  todoCategories: [],
  todoItems: []
};

export default props => (
  <OptionsContext.Consumer>
    {({ todoCategories }) => (
      <TodoItemsContext.Consumer>
        {({ todoItems }) => {
          return (
            <TodoList
              {...props}
              todoItems={todoItems}
              todoCategories={todoCategories}
            />
          );
        }}
      </TodoItemsContext.Consumer>
    )}
  </OptionsContext.Consumer>
);
