import React, { Component } from "react";
import { Message, Transition } from "semantic-ui-react";

class Notification extends Component {
  render() {
    const {
      messageOptions,
      visible,
      error,
      content,
      success,
      color,
      ...props
    } = this.props;

    // Easy change of message types from error to success
    let passedColor = color;
    if (error) {
      passedColor = "red";
    }
    if (success) {
      passedColor = "green";
    }
    if (content == null) {
      return null;
    }
    return (
      <Transition {...props} visible={visible || content !== null}>
        <Message color={passedColor} content={content} {...messageOptions} />
      </Transition>
    );
  }
}

Notification.defaultProps = {
  duration: 500,
  animation: "swing down",
  visible: false,
  error: false,
  success: false,
  content: null,
  color: undefined,
  messageOptions: {}
};

export default Notification;
