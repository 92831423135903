import React, { Component } from "react";

import { Header, Modal, Divider, Button, Segment } from "semantic-ui-react";

import ErrorBoundary from "../Shared/ErrorBoundary";

import ReactTable from "react-table";

import _ from "lodash";
import {
  getEmailsByUser,
  createEmail,
  updateEmail,
  deleteEmail
} from "../lib/apiCalls";
import { toast } from "react-toastify";

import EmailForm from "../Forms/EmailForm";

/**
 * Displays a table of emails. If authorized for edit user, shows buttons and fields
 * for adding, deleting and editing emails in the table.
 */
class Emails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isCustomerEditing: false,
      emails: []
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.getEmails();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  shouldComponentUpdate(prevProps, prevState) {
    return (
      !_.isEqual(prevProps, this.props) || !_.isEqual(prevState, this.state)
    );
  }
  getEmails = () => {
    let { match } = this.props;
    if (match) {
      getEmailsByUser({ userId: match.params.userId }, (error, response) => {
        if (error) {
          toast.error("Failed to retrieve emails");
          return;
        }
        if (this._isMounted) {
          this.setState({ emails: response });
        }
      });
    }
  };
  handleDelete = email => {
    deleteEmail({ ID: email.ID }, (error, response) => {
      if (error) {
        toast.error("Failed to remove email");
        return;
      }
      this.getEmails();
      toast.success("Successfully removed email");
    });
  };

  updateNewEmail = data => {
    this.setState({ newEmail: data });
  };
  handleCreate = () => {
    let { newEmail } = this.state;
    let { match } = this.props;

    if (!match || !match.params.userId) {
      toast.info("A user is required to create new email");
      return;
    }
    createEmail(
      { ...newEmail, userId: match.params.userId },
      (error, response) => {
        if (error) {
          toast.error("Failed to create new email");
          return;
        }
        this.setState({
          openNewEmailModal: false
        });
        this.getEmails();
        toast.success("Successfully created email");
      }
    );
  };
  handleSave = data => {
    updateEmail(data, (error, response) => {
      if (error) {
        toast.error("Failed to save email");
        return;
      }
      this.getEmails();
      toast.success("Successfully saved email");
    });
  };
  openModal = () => {
    this.setState({ openNewEmailModal: true });
  };
  closeModal = () => {
    this.setState({ openNewEmailModal: false, newEmail: {} });
  };
  render() {
    let { emails, openNewEmailModal, loading } = this.state;
    let { allowEditing } = this.props;
    let defaultPrimary = _.find(emails, { isPrimary: true }) == null;

    return (
      <ErrorBoundary>
        <Segment>
          <Header as="h2">
            Emails
            {allowEditing && (
              <Modal
                open={openNewEmailModal}
                onClose={this.closeModal}
                trigger={
                  <Button
                    color="blue"
                    floated="right"
                    content="Add Email"
                    onClick={this.openModal}
                  />
                }
              >
                <Modal.Header>New Email</Modal.Header>
                <Modal.Content>
                  <EmailForm
                    onUpdate={this.updateNewEmail}
                    hideActionButtons
                    email={{ isPrimary: defaultPrimary }}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content="Create"
                    color="green"
                    onClick={this.handleCreate}
                  />
                  <Button content="Cancel" onClick={this.closeModal} />
                </Modal.Actions>
              </Modal>
            )}
          </Header>
          <Divider hidden />
          <ReactTable
            loading={loading}
            data={emails}
            minRows={4}
            collapseOnDataChange={false}
            pageSize={emails.length}
            columns={columns}
            showPagination={false}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                className:
                  rowInfo && rowInfo.original.isPrimary === true
                    ? "primary-row"
                    : ""
              };
            }}
            SubComponent={
              allowEditing
                ? row => {
                    return (
                      <Segment basic>
                        <EmailForm
                          email={row.original}
                          handleSave={this.handleSave}
                          handleDelete={this.handleDelete}
                        />
                      </Segment>
                    );
                  }
                : undefined
            }
          />
        </Segment>
      </ErrorBoundary>
    );
  }
}

Emails.defaultProps = {
  allowEditing: true
};

export default Emails;

const columns = [
  {
    Header: "Label",
    accessor: "label",
    minWidth: 50
  },
  {
    Header: "Email",
    accessor: "emailAddress"
  }
];
