/**
 * localConfig.js
 *
 * Copy this file to client/src
 * Replace the values for each key to the corresponding configurations
 *
 * This file serves as a configuration file for the client. When npm build is used to create an optimized build for a production server, these variables are inserted into the proper places.
 * This allows for easy changes between environments without having to search for all locations in the code.
 *
 */

module.exports = {
  /** Port that the server is running on. Leave blank if no port is used.
   * Example: '' or 1337
   */
  port: "",
  /** Url that the websocket connection should point to.
   *  Example: "http://localhost" or "https//example.com"
   */
  socketURL: "https://crms.petlove.com"
};
