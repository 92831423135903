import React, { Component } from "react";
import PropTypes from "prop-types";

import { Segment, Header, Rating, Divider, Comment } from "semantic-ui-react";
import { getRatingsByUser } from "../lib/apiCalls";
import _ from "lodash";
import { toast } from "react-toastify";
import RatingItem from "./RatingItem";
import Authorize from "./Authorize";

class RatingArea extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      ratings: [],
      loading: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.userId) {
      this.setState({ loading: false });
      return;
    }

    getRatingsByUser({ userId: this.props.userId }, (error, response) => {
      if (error) {
        toast.error("Failed to retrieve ratings");
        this.setState({ loading: false });
        return;
      }
      if (this._isMounted) {
        this.setState({ ratings: response || [], loading: false });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let { ratings, loading } = this.state;
    let rating = _.meanBy(ratings, "rating");

    return (
      <Segment loading={loading}>
        <Header floated="left" content="Ratings" />
        <Header floated="right">
          {rating ? parseFloat(rating).toFixed(1) : ""}{" "}
          <Rating
            maxRating={5}
            rating={rating}
            icon="star"
            size="large"
            disabled
          />
        </Header>
        <Divider clearing />
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
          <Comment.Group style={{ maxWidth: "none" }}>
            {ratings.length > 0 ? (
              ratings.map(rating => <RatingItem {...rating} key={rating.ID} />)
            ) : (
              <div>Not Yet Rated</div>
            )}
          </Comment.Group>
        </div>
      </Segment>
    );
  }
}

RatingArea.defaultProps = {
  userId: null
};

RatingArea.propTypes = {
  userId: PropTypes.number
};

export default props => (
  <Authorize permission="ViewRatings">
    <RatingArea {...props} />
  </Authorize>
);
