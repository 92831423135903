import React, { Component } from "react";
import {
  Form,
  Label,
  Grid,
  Button,
  Popup,
  Select,
  Modal,
  Divider
} from "semantic-ui-react";
import CommentGroup from "../Shared/CommentGroup";
import Input from "../Shared/Input";
import Authorize from "../Shared/Authorize";
import moment from "moment";
import { OptionsContext } from "../OptionsContext";
import _ from "lodash";
import { defaultAppointment } from "../lib/defaultObjects";

import { getStatusColor, getDuration } from "../lib/helperFunctions";

/**
 * Displays details for an appointment's schedule and comments for that appointment
 */
class AppointmentDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultAppointment(props),
      initialComment: ""
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e, { name, value }) {
    this.setState({ [name]: value });
  }
  componentDidUpdate(prevProps) {
    // When the appointment details have changed, update the state
    // This updates the UI when needed but still allows overriding the values in state
    let appointment = defaultAppointment(this.props);
    if (!_.isEqual(appointment, defaultAppointment(prevProps))) {
      this.setState(appointment);
    }
  }
  resolveConflictCheck = () => {
    this.setState({ openModal: true });
  };
  handleResolve = () => {
    this.props.onSave({ ...this.state, resolveConfliction: true });
    this.setState({ openModal: false });
  };
  handleKeep = () => {
    this.props.onSave(this.state);
    this.setState({ openModal: false });
  };
  canServiceAllPets = (stylist, pets) => {
    let canServiceAllPets = true;

    for (let i = 0; i < pets.length; i++) {
      if (!canServiceAllPets) break;
      let pet = pets[i];
      // Check if the pet's weight exceeds the stylist's max weight
      if (
        stylist.maxPetWeight &&
        pet.weight != null &&
        stylist.maxPetWeight &&
        stylist.maxPetWeight < pet.weight
      ) {
        canServiceAllPets = false;
      }
      // Check if the stylist can service cats
      if (
        pet.Breed &&
        pet.Breed.AnimalType &&
        pet.Breed.AnimalType.type.toLowerCase() === "cat" &&
        stylist.servicesCats !== true
      ) {
        canServiceAllPets = false;
      }

      // Check if the stylist can service the pet's breed
      if (
        pet.Breed &&
        stylist.Breeds &&
        _.find(stylist.Breeds, { ID: pet.Breed.ID })
      ) {
        canServiceAllPets = false;
      }
    }

    return canServiceAllPets;
  };
  handleOverride = (e, data) => {
    this.setState({ forceEmployeeId: data.value });
  };
  handleCancelForce = () => {
    this.setState({
      forceEmployeeId: null
    });
  };
  handleForceEmployeeId = () => {
    this.setState({
      employeeId: this.state.forceEmployeeId,
      forceEmployeeId: null
    });
  };
  getActionButtons = (ID, originalStatus, selectedStatus) => {
    let actions = [];
    let {
      onDelete,
      onCancel,
      onSave,
      onAddToWaitList,
      onReschedule,
      onConfirm,
      onCreateInstance,
      onResolveServiceIssue
    } = this.props;

    if (ID == null) {
      if (!originalStatus.status) {
        // Add all the buttons that a new appointment should see
        actions.push(
          <Popup
            key="save"
            content="Schedule"
            inverted
            basic
            trigger={
              <Button
                basic
                icon="save"
                onClick={
                  originalStatus.status !== "conflicting"
                    ? () => onSave(this.state)
                    : this.resolveConflictCheck
                }
                color="green"
              />
            }
          />
        );
        actions.push(
          <Popup
            key="waiting"
            content="Add Appointment to Waiting List"
            inverted
            basic
            trigger={
              <Button
                basic
                icon="clock"
                color="yellow"
                disabled={selectedStatus.status === "waiting"}
                onClick={() => onAddToWaitList(this.state)}
              />
            }
          />
        );
      } else {
        // Add all the buttons that a new instance of a standing should see
        actions.push(
          <Popup
            key="save"
            content={
              this.props.disabledInstanceButton
                ? "Instance already created"
                : "Create Instance"
            }
            inverted
            basic
            trigger={
              <span style={{ width: "100%" }}>
                <Button
                  style={{ width: "100%" }}
                  basic
                  disabled={this.props.disabledInstanceButton}
                  color="green"
                  icon="save"
                  onClick={
                    originalStatus.status !== "conflicting"
                      ? () => onCreateInstance(this.state)
                      : this.resolveConflictCheck
                  }
                />
              </span>
            }
          />
        );
      }
    } else {
      if (selectedStatus.status === "serviceIssue") {
        actions.push(
          <Popup
            key="resolve"
            content="Resolve Service Issue"
            inverted
            basic
            trigger={
              <Button
                basic
                icon="check"
                onClick={() => onResolveServiceIssue(this.state)}
                color="green"
              />
            }
          />
        );
      } else {
        actions.push(
          <Popup
            key="save"
            content={
              selectedStatus.status === "waiting"
                ? "Schedule"
                : "Save Appointment"
            }
            inverted
            basic
            trigger={
              <Button
                basic
                icon="save"
                onClick={
                  originalStatus.status !== "conflicting"
                    ? () => onSave(this.state)
                    : this.resolveConflictCheck
                }
                color="green"
              />
            }
          />
        );
      }
      if (selectedStatus.status === "scheduled") {
        actions.push(
          <Popup
            key="confirm"
            content="Confirm Appointment"
            inverted
            basic
            trigger={
              <Button
                basic
                color="grey"
                icon="check"
                onClick={() => onConfirm(this.state)}
              />
            }
          />
        );
        actions.push(
          <Popup
            key="reschedule"
            content="Reschedule Appointment"
            inverted
            basic
            trigger={
              <Button
                basic
                color="grey"
                icon="history"
                onClick={() => onReschedule(this.state)}
              />
            }
          />
        );

        actions.push(
          <Popup
            key="cancel"
            content="Cancel Appointment"
            inverted
            basic
            trigger={
              <Button
                basic
                color="grey"
                icon="cancel"
                onClick={() => onCancel(this.state)}
              />
            }
          />
        );
      } else if (selectedStatus.status === "inProgress") {
        actions.push(
          <Popup
            key="reschedule"
            content="Reschedule Appointment"
            inverted
            basic
            trigger={
              <Button
                basic
                color="grey"
                icon="history"
                onClick={() => onReschedule(this.state)}
              />
            }
          />
        );
        actions.push(
          <Popup
            key="cancel"
            content="Cancel Appointment"
            inverted
            basic
            trigger={
              <Button
                basic
                color="grey"
                icon="cancel"
                onClick={() => onCancel(this.state)}
              />
            }
          />
        );
      } else if (
        selectedStatus.status === "waiting" ||
        selectedStatus.status === "rescheduled"
      ) {
        actions.push(
          <Popup
            key="cancel"
            content="Cancel Appointment"
            inverted
            basic
            trigger={
              <Button
                basic
                color="grey"
                icon="cancel"
                onClick={() => onCancel(this.state)}
              />
            }
          />
        );
      }
      if (selectedStatus.status !== "serviceIssue") {
        actions.push(
          <Authorize key="delete" permission="DeleteAppointments">
            <Popup
              content="Delete Appointment"
              inverted
              basic
              trigger={
                <Button
                  basic
                  color="red"
                  icon="trash"
                  onClick={() => onDelete(this.state)}
                />
              }
            />
          </Authorize>
        );
      }
    }

    return actions;
  };
  render() {
    let {
      isEditing,
      stylistOptions,
      blockingComplaintOptions,
      statusOptions,
      columns,
      required,
      pets,
      Status
    } = this.props;
    let {
      ID,
      employeeId,
      appointmentDate,
      startTime,
      endTime,
      timeStarted,
      timeEnded,
      statusId,
      commentGroupId,
      isStanding,
      openModal,
      initialComment
    } = this.state;

    let duration = getDuration(startTime, endTime);

    if (!duration) {
      duration = this.props.duration;
    }

    // Find stylist in the options
    let selectedStylist = _.find(stylistOptions, { ID: employeeId });
    if (!selectedStylist) {
      selectedStylist = {};
    }

    // Find the status in the options
    let selectedStatus = _.find(statusOptions, { ID: statusId });
    if (!selectedStatus) {
      selectedStatus = {};
    }
    let originalStatus = Status ? _.find(statusOptions, { ID: Status.ID }) : {};

    let statusColor = getStatusColor(selectedStatus.status);

    let dayOfWeek = appointmentDate
      ? moment(appointmentDate, "MM-DD-YYYY").format("dddd")
      : null;

    let statuses = statusOptions.map(status => ({
      text: _.startCase(status.status),
      key: status.ID,
      value: status.ID
    }));
    let filteredStylists = stylistOptions
      .filter(
        //Filter out the stylists that have a complaint connection between the two
        stylist => {
          return (
            stylist.ID === this.props.employeeId ||
            _.find(blockingComplaintOptions, {
              employeeId: stylist.ID,
              customerId: this.props.customerId
            }) == null
         );
        }
      )
      .map(stylist => {
        // Filter the stylists by their working days
        let worksOnDay = true;
        let canServiceAllPets = true;
        if (stylist.ID !== employeeId && dayOfWeek != null) {
          worksOnDay = stylist["works" + dayOfWeek] === true;
        }
        // Filter the stylist based on the restrictions
        if (pets) {
          canServiceAllPets = this.canServiceAllPets(stylist, pets);
        }
        let option = {
          value: stylist.ID,
          text: `${stylist.User.firstName} ${stylist.User.lastName}`,
          key: stylist.ID
        };
        if (!worksOnDay || !canServiceAllPets) {
          option.disabled = true;
          option.onClick = this.handleOverride;
        }
        return option;
      });

    return (
      <Grid stackable columns={columns}>
        <Modal
          open={openModal}
          onClose={() => {
            this.setState({ openModal: false });
          }}
          closeIcon
          header="Warning!"
          content="This appointment has a conflicting status. Would you like to resolve this appointment and set to scheduled?"
          actions={[
            {
              key: "keepConflicting",
              content: "Keep Confliction and Save",
              color: "red",
              onClick: this.handleKeep
            },
            {
              key: "done",
              color: "green",
              content: "Resolve and Schedule",
              onClick: this.handleResolve
            }
          ]}
        />
        {selectedStylist.dateTerminated &&
          moment(selectedStylist.dateTerminated).isBefore(moment()) && (
            <Label content="Stylist No Longer Working" color="red" />
          )}
        <Modal
          open={this.state.forceEmployeeId != null}
          header="Warning!"
          content="This stylist fails the requirements to service this appointment. Are you sure you want to select this stylist?"
          actions={[
            {
              content: "Cancel",
              key: "cancel",
              onClick: this.handleCancelForce
            },
            {
              key: "done",
              content: "Select Stylist",
              color: "red",
              onClick: this.handleForceEmployeeId
            }
          ]}
        />
        <Grid.Column>
          <Form size="small">
            {/* Show the status of an existing appointment */}
            {selectedStatus.status && (
              <Authorize
                permission="EditAppointmentStatus"
                else={
                  <Label
                    content={_.startCase(selectedStatus.status)}
                    color={statusColor}
                    basic
                  />
                }
              >
                <Form.Field>
                  <label>Status</label>
                  {isEditing === false ? (
                    <Input
                      value={_.startCase(selectedStatus.status)}
                      isEditing={false}
                    />
                  ) : (
                    <Select
                      options={statuses}
                      value={statusId}
                      onChange={this.onChange}
                      name="statusId"
                    />
                  )}
                </Form.Field>
              </Authorize>
            )}

            {isEditing === true ? (
              <Form.Select
                clearable
                options={filteredStylists}
                label="Stylist"
                search
                required={required.includes("employeeId") && isEditing}
                name="employeeId"
                value={employeeId}
                onChange={this.onChange}
                placeholder="Select a Stylist"
              />
            ) : (
              <Form.Field>
                <label>Stylist</label>
                <div>
                  {selectedStylist &&
                    selectedStylist.User &&
                    `${selectedStylist.User.firstName} ${selectedStylist.User.lastName}`}
                </div>
              </Form.Field>
            )}
            <Input
              validation="date"
              label="Appointment Date"
              required={required.includes("appointmentDate") && isEditing}
              value={appointmentDate}
              isEditing={isEditing}
              name="appointmentDate"
              onChange={this.onChange}
              placeholder="Select a Date"
            />
            {selectedStatus.status === "completed" ? (
              <Form.Group widths="2">
                <Input
                  label="Time Started"
                  name="timeStarted"
                  isEditing={false}
                  value={timeStarted}
                />
                <Input
                  label="Time Ended"
                  isEditing={false}
                  name="timeEnded"
                  value={timeEnded}
                />
              </Form.Group>
            ) : (
              <>
                <Form.Group widths="2">
                  <Input
                    validation="time"
                    label="Start Time"
                    name="startTime"
                    required={required.includes("startTime") && isEditing}
                    isEditing={isEditing}
                    value={startTime}
                    onChange={this.onChange}
                    placeholder="Starting Time"
                  />
                  <Input
                    label="End Time"
                    placeholder="Ending Time"
                    validation="time"
                    required={required.includes("endTime") && isEditing}
                    isEditing={isEditing}
                    name="endTime"
                    value={endTime}
                    onChange={this.onChange}
                  />
                </Form.Group>
                <Form.Group widths="1">
                  <Form.Field>
                    <label>Duration</label>
                    <div>{endTime ? duration + " minutes" : "N/A"}</div>
                  </Form.Field>
                </Form.Group>
              </>
            )}
            {!ID && !isStanding && (
              <Form.Field>
                <label>Initial Comment</label>
                <Form.TextArea
                  rows={4}
                  placeholder="Add an initial comment to this appointment"
                  value={initialComment}
                  name="initialComment"
                  onChange={this.onChange}
                />
              </Form.Field>
            )}
          </Form>
          <Divider hidden />
          {/* Don't show any buttons if the appointment status is completed, cancelled or in progress */}
          {originalStatus.status !== "completed" &&
            originalStatus.status !== "cancelled" && (
              <Authorize permission="EditAppointments">
                <Button.Group fluid>
                  {this.getActionButtons(ID, originalStatus, selectedStatus)}
                </Button.Group>
              </Authorize>
            )}
          {!ID && !isStanding && (
            <div style={{ color: "#db2828" }}>
              <br />* Required only for appointment to be scheduled
            </div>
          )}
        </Grid.Column>
        {ID != null && (
          <Grid.Column>
            <CommentGroup
              key={commentGroupId}
              maxHeight="100px"
              commentGroupId={commentGroupId}
            />
          </Grid.Column>
        )}
      </Grid>
    );
  }
}
AppointmentDetails.defaultProps = {
  appointmentDate: null,
  startTime: null,
  endTime: null,
  duration: null,
  isConflicting: false,
  commentGroupId: null,
  statusId: null,
  isEditing: true,
  stylistOptions: [],
  blockingComplaintOptions: [],
  statusOptions: [],
  onSave: () => {},
  onCancel: () => {},
  onDelete: () => {},
  onAddToWaitList: () => {},
  onReschedule: () => {},
  onConfirm: () => {},
  onResolveServiceIssue: () => {},
  required: [],
  pets: null,
  columns: 2
};

export default props => (
  <Authorize permission="ViewAppointments">
    <OptionsContext.Consumer>
      {({ stylists, blockingComplaints, statuses }) => {
        return (
          <AppointmentDetails
            {...props}
            stylistOptions={stylists}
            blockingComplaintOptions={blockingComplaints}
            statusOptions={statuses}
          />
        );
      }}
    </OptionsContext.Consumer>
  </Authorize>
);
