import { moneyFormat } from "../lib/format";

function Money(props) {
  if (props.value < 0) {
    return "-$" + moneyFormat(Math.abs(props.value));
  }
  return "$" + moneyFormat(props.value);
}

export default Money;
