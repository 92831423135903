import React, { Component } from "react";
import moment from "moment";

class DatePagination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.changePage = this.changePage.bind(this);
  }

  changePage(activeDay) {
    this.props.onPageChange(activeDay);
  }

  render() {
    const { activeDay } = this.props;
    const activeDate = moment().add(activeDay, "days");
    let paginationProps = { ...this.props.paginationProps };
    if (
      paginationProps.minActiveDay == null &&
      !paginationProps.maxActiveDay == null
    ) {
      paginationProps.minActiveDay = 0;
    }

    return (
      <div className="-pagination">
        <div className="-previous">
          <button
            className="-btn"
            disabled={
              paginationProps.minActiveDay != null &&
              activeDay <= paginationProps.minActiveDay
            }
            onClick={() => {
              if (
                paginationProps.minActiveDay != null &&
                activeDay <= paginationProps.minActiveDay
              )
                return;
              this.changePage(activeDay - 1);
            }}
          >
            Previous
          </button>
        </div>
        <div className="-center">{activeDate.format("dddd, LL")}</div>
        <div className="-next">
          <button
            className="-btn"
            disabled={
              paginationProps.maxActiveDay != null &&
              activeDay >= paginationProps.maxActiveDay
            }
            onClick={() => {
              if (
                paginationProps.maxActiveDay != null &&
                activeDay >= paginationProps.maxActiveDay
              )
                return;
              this.changePage(activeDay + 1);
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

DatePagination.defaultProps = {
  activeDay: 0,
  paginationProps: {},
  syncDateFilter: () => {}
};

export default DatePagination;
