import PropTypes from "prop-types";
import { isEmpty, checkIfValid } from "../lib/validation";

/**
 * Validation component to test the value against a regex for the type. Passes an error to the child
 * @param {Object} props
 * @prop {function} children - Render prop to pass error to
 * @prop {boolean} required - Does not allow an empty value
 * @prop {string|RegExp} validation - Type for the value or regular expression to test against
 * @prop {any} value - Value to test validation against
 * @returns {boolean} Returns true if erroring on any validation
 */
function Validation(props) {
  let { validation, value, required, children } = props;
  if (!validation) return children(undefined);

  // Handle an empty field for required or not
  if (isEmpty(value)) {
    if (required) {
      return children(true);
    } else {
      return children(false);
    }
  }
  // Check for each validation if array of validation
  if (validation.constructor === Array) {
    for (let i = 0; i < validation.length; i++) {
      if (checkIfValid(validation[i], value) === false) {
        return children(true);
      }
    }
  } else {
    // Check the one validation
    if (checkIfValid(validation, value) === false) {
      return children(true);
    }
  }
  // Pass the error along
  return children(false);
}

Validation.defaultProps = {
  children: () => {
    return null;
  },
  required: false,
  validation: null,
  value: undefined
};

Validation.propTypes = {
  children: PropTypes.func,
  required: PropTypes.bool,
  validation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.instanceOf(RegExp)
  ]),
  value: PropTypes.any
};
export default Validation;
