import React, { Component } from "react";
import { Header, Button, Icon, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";

import queryString from "query-string";
import AppointmentFeedbackForm from "../Forms/AppointmentFeedbackForm";
import { toast } from "react-toastify";
import {
  getAppointmentFeedbackInformation,
  submitAppointmentFeedback
} from "../lib/apiCalls";

class AppointmentFeedback extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { status: null };
  }
  componentDidMount() {
    this._isMounted = true;
    let search = this.props.location.search;
    let token = queryString.parse(search).token;
    if (!token) return;
    getAppointmentFeedbackInformation({ token: token }, (error, response) => {
      if (error) {
        toast.error(
          "Failed to retrieve information for this appointment feedback"
        );
        return;
      }
      this.setState(response);
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onSubmit = async data => {
    if (!this.props.location) {
      this.setState({ status: "error" });
      return;
    }
    let search = this.props.location.search;
    let token = queryString.parse(search).token;

    submitAppointmentFeedback(
      {
        token: token,
        rating: data.rating,
        comment: data.comment,
        setStylistAsPreferred: data.setStylistAsPreferred
      },
      (error, response) => {
        if (error) {
          toast.error("Failed to submit appointment feedback");
          if (this._isMounted) {
            this.setState({ status: "error", loading: false });
          }
          return;
        }
        if (this._isMounted) {
          this.setState({ status: "success", loading: false });
          this.props.setUser({ ...response.data });
        }
      }
    );
  };
  render() {
    let { status, stylistName } = this.state;
    return (
      <Segment basic>
        {!status && (
          <>
            <Header
              textAlign="center"
              size="large"
              content="Appointment Feedback"
            />
            <AppointmentFeedbackForm
              handleSubmit={this.onSubmit}
              stylistName={stylistName || "stylist"}
            />
          </>
        )}
        {/* Successful confirmation */}
        {status === "success" && (
          <Header icon as="h2" textAlign="center">
            <Icon color="green" name="check" circular />
            <Header.Content>Feedback Sent</Header.Content>
            <Header.Subheader>Thank you for your feedback!</Header.Subheader>
            <br />
            <Button as={Link} to="/login" color="purple">
              Login
            </Button>
          </Header>
        )}
        {/* Errored while confirming */}
        {status === "error" && (
          <Header icon as="h2" textAlign="center">
            <Icon color="red" name="x" circular />
            <Header.Content>Error</Header.Content>
            <Header.Subheader>
              An error occured while submitting feedback.
            </Header.Subheader>
          </Header>
        )}
      </Segment>
    );
  }
}

export default AppointmentFeedback;
