import React, { Component } from "react";
import { Button, Header, Select, Segment } from "semantic-ui-react";
import _ from "lodash";

/**
 * Shows a list of receipt options. When an option is selected, that one becomes the highlighted option.
 * @prop {function} onSelect Called when an option is clicked on
 * @prop {boolean} disabled Disables all buttons and the onSelect function. Used to show that the selection cannot be changed anymore.
 */
class ReceiptOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      emailAddress: "",
      phoneNumber: ""
    };
  }

  handleNone = (e, { name }) => {
    if (this.props.disabled === false) {
      this.setState({ selected: name });
      this.props.onSelect(name);
    }
  };
  handleSelect = (e, { name }) => {
    this.setState({ selected: name });
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleReceipt = () => {
    if (this.props.disabled === false) {
      if (this.state.selected === "Text") {
        this.props.onSelect(this.state.selected, this.state.phoneNumber);
      } else if (this.state.selected === "Email") {
        this.props.onSelect(this.state.selected, this.state.emailAddress);
      }
    }
  };
  render() {
    let { disabled, emailAddresses, phoneNumbers } = this.props;
    let { selected, emailAddress, phoneNumber } = this.state;
    let emailAddressOptions = emailAddresses.map(email => ({
      key: email.ID,
      text: email.emailAddress,
      value: email.emailAddress
    }));

    let phoneNumbersOptions = phoneNumbers.map(phoneNumber => ({
      key: phoneNumber.ID,
      text: phoneNumber.phoneNumber,
      value: phoneNumber.phoneNumber
    }));

    // Add the email address value to the dropdown if not included
    if (emailAddress !== "") {
      let selectedEmail = _.find(emailAddressOptions, { value: emailAddress });
      if (!selectedEmail) {
        emailAddressOptions.push({
          text: emailAddress,
          value: emailAddress
        });
      }
    }

    // Add the phone number value to the dropdown if not included
    if (phoneNumber !== "") {
      let selectedPhoneNumber = _.find(phoneNumbersOptions, {
        value: phoneNumber
      });
      if (!selectedPhoneNumber) {
        phoneNumbersOptions.push({
          text: phoneNumber,
          value: phoneNumber
        });
      }
    }
    return (
      <div>
        <Header content="How would you like your receipt?" />
        <Button
          content="Email"
          name="Email"
          onClick={this.handleSelect}
          disabled={disabled && selected !== "Email"}
          color={selected === "Email" || selected == null ? "blue" : undefined}
        />
        <Button
          content={"Text"}
          name={"Text"}
          onClick={this.handleSelect}
          disabled={disabled && selected !== "Text"}
          color={selected === "Text" || selected == null ? "blue" : undefined}
        />
        <Button
          content={"None"}
          name={"None"}
          onClick={this.handleNone}
          disabled={disabled && selected !== "None"}
          color={selected === "None" || selected == null ? "blue" : undefined}
        />
        <Segment basic>
          {selected === "Email" && (
            <Select
              search
              disabled={disabled}
              allowAdditions
              name="emailAddress"
              value={emailAddress}
              onChange={this.handleChange}
              options={emailAddressOptions}
              onAddItem={this.handleChange}
              placeholder="Select an email"
              noResultsMessage="Type to add new"
            />
          )}
          {selected === "Text" && (
            <Select
              disabled={disabled}
              search
              allowAdditions
              name="phoneNumber"
              value={phoneNumber}
              onChange={this.handleChange}
              options={phoneNumbersOptions}
              onAddItem={this.handleChange}
              placeholder="Select a phone number"
              noResultsMessage="Type to add new"
            />
          )}
          {!disabled && (selected === "Email" || selected === "Text") && (
            <div>
              <br />
              <Button
                content="Submit Method"
                color="green"
                onClick={this.handleReceipt}
              />
            </div>
          )}
        </Segment>
      </div>
    );
  }
}
ReceiptOptions.defaultProps = {
  onSelect: () => {},
  disabled: false,
  emailAddresses: [],
  phoneNumbers: [],
  creditCards: []
};

export default ReceiptOptions;
