import React, { Component } from "react";
import {
  Grid,
  Menu,
  Container,
  Header,
  Button,
  Segment,
  Divider,
  Dropdown,
  Icon,
  Popup,
} from "semantic-ui-react";
import { Switch, Route, Link, Redirect } from "react-router-dom";

import PetsView from "./PetsView";
import CustomerOverview from "./CustomerOverview";

import EstimatesTable from "../Tables/Estimates";
import PreviousAppointments from "../Tables/PreviousAppointments";
import CustomerTransactions from "../Tables/CustomerTransactions";
import UpcomingAppointments from "../Tables/UpcomingAppointments";

import AddressesTable from "../Tables/Addresses";
import PhoneNumbersTable from "../Tables/PhoneNumbers";
import EmailsTable from "../Tables/Emails";
import CreditCardsTable from "../Tables/CreditCards";

import ComplaintModal from "./ComplaintModal";
import { getUserDetails, restoreUser } from "../lib/apiCalls";
import Authorize from "./Authorize";
import { toast } from "react-toastify";
import TodoItemModal from "../Forms/TodoItemModal";
import CreateAccountModal from "../Forms/CreateAccountModal";
import TransactionSummaryModal from "./TransactionSummaryModal";
import DeleteUserModal from "./DeleteUserModal";

class CustomerPage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: true,
      openComplaintModal: false,
      openTransactionSummaryModal: false,
      openDeleteUserModal: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { match } = this.props;
    if (match && match.params.userId) {
      getUserDetails({ userId: match.params.userId }, (error, response) => {
        if (error) {
          toast.error("Failed to retrieve user");
          this.setState({ loading: false });
          return;
        }
        if (this._isMounted) {
          this.setState({ user: response, loading: false });
        }
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let { match } = this.props;
    if (match && match.params.userId) {
      if (prevProps.match.params.userId !== match.params.userId) {
        getUserDetails({ userId: match.params.userId }, (error, response) => {
          if (error) {
            toast.error("Failed to retrieve user");
            this.setState({ loading: false });
            return;
          }
          if (this._isMounted) {
            this.setState({ user: response, loading: false });
          }
        });
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  closeComplaintModal = () => {
    this.setState({ openComplaintModal: false });
  };
  openComplaintModal = () => {
    this.setState({ openComplaintModal: true });
  };
  closeTransactionSummaryModal = () => {
    this.setState({ openTransactionSummaryModal: false });
  };
  openTransactionSummaryModal = () => {
    this.setState({ openTransactionSummaryModal: true });
  };
  closeDeleteUserModal = () => {
    this.setState({ openDeleteUserModal: false });
  };
  openDeleteUserModal = () => {
    this.setState({ openDeleteUserModal: true });
  };
  handleRestore = () => {
    this.setState({ loading: true });
    restoreUser({ userId: this.state.user.ID }, (error, response) => {
      if (error) {
        toast.error("Failed to restore user");
        return;
      }
      toast.success("Successfully restored user");
      getUserDetails({ userId: this.state.user.ID }, (error, response) => {
        if (error) {
          toast.error("Failed to retrieve user");
          this.setState({ loading: false });
          return;
        }
        if (this._isMounted) {
          this.setState({ user: response, loading: false });
        }
      });
    });
  };
  render() {
    let {
      user,
      openComplaintModal,
      openTransactionSummaryModal,
      openDeleteUserModal,
      loading,
    } = this.state;
    let { location, match } = this.props;

    let pathname = location.pathname || "";
    let fullName = `${user.firstName || ""} ${user.lastName || ""}`;

    if (loading) {
      return (
        <Container>
          <Header as="h2">Loading Profile...</Header>
          <Segment placeholder loading padded="very"></Segment>
        </Container>
      );
    }
    if (user.IsDeleted) {
      return (
        <Container>
          <Segment placeholder padded="very">
            <Header icon>
              <Icon name="user delete" />
              {user ? fullName + "'s" : "This user's"} profile has been marked
              as deleted. Please contact an administrator if there are any
              issues.
              <Authorize permission="RestoreProfiles">
                <Popup
                  inverted
                  position="bottom center"
                  content='Note that this only removes the status of "Deleted" on the account. If the account had previously been merged, it will not restore merged data.'
                  trigger={
                    <Button
                      content="Restore Profile"
                      color="red"
                      onClick={this.handleRestore}
                    />
                  }
                />
              </Authorize>
            </Header>
          </Segment>
        </Container>
      );
    }

    let menuItems = [
      <Menu.Item
        header
        key="overview"
        name="overview"
        active={pathname.indexOf("overview") > 0}
        as={Link}
        to={`${match.url}/overview`}
      />,

      <Menu.Item
        key="pets"
        name="pets"
        header
        active={pathname.indexOf("pets") > 0}
        as={Link}
        to={`${match.url}/pets`}
      />,
      <Menu.Item key="information">
        <Menu.Header>Information</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            key="addresses"
            name="addresses"
            active={pathname.indexOf("addresses") > 0}
            as={Link}
            to={`${match.url}/addresses`}
          />
          <Menu.Item
            key="phoneNumbers"
            name="phoneNumbers"
            active={pathname.indexOf("phoneNumbers") > 0}
            as={Link}
            to={`${match.url}/phoneNumbers`}
          />
          <Menu.Item
            key="emailAddresses"
            name="emailAddresses"
            active={pathname.indexOf("emailAddresses") > 0}
            as={Link}
            to={`${match.url}/emailAddresses`}
          />
          <Menu.Item
            key="creditCards"
            name="creditCards"
            active={pathname.indexOf("creditCards") > 0}
            as={Link}
            to={`${match.url}/creditCards`}
          />
        </Menu.Menu>
      </Menu.Item>,

      <Menu.Item key="records">
        <Menu.Header>Records</Menu.Header>
        <Menu.Menu>
          <Menu.Item
            key="upcomingAppointments"
            name="upcomingAppointments"
            active={pathname.indexOf("upcomingAppointments") > 0}
            as={Link}
            to={`${match.url}/upcomingAppointments`}
          />
          <Menu.Item
            key="appointmentHistory"
            name="appointmentHistory"
            active={pathname.indexOf("appointmentHistory") > 0}
            as={Link}
            to={`${match.url}/appointmentHistory`}
          />
          <Menu.Item
            key="estimateHistory"
            name="estimateHistory"
            active={pathname.indexOf("estimateHistory") > 0}
            as={Link}
            to={`${match.url}/estimateHistory`}
          />
          <Menu.Item
            key="transactionHistory"
            name="transactionHistory"
            active={pathname.indexOf("transactionHistory") > 0}
            as={Link}
            to={`${match.url}/transactionHistory`}
          />
        </Menu.Menu>
      </Menu.Item>,
    ];
    return (
      <Container>
        <ComplaintModal
          open={openComplaintModal}
          onClose={this.closeComplaintModal}
          customerId={user.Customer ? user.Customer.ID : null}
        />
        {openTransactionSummaryModal && (
          <TransactionSummaryModal
            key={user.Customer ? user.Customer.ID : 0}
            open={openTransactionSummaryModal}
            onClose={this.closeTransactionSummaryModal}
            customerId={user.Customer ? user.Customer.ID : null}
            userName={fullName}
          />
        )}
        <Route
          render={(props) => (
            <DeleteUserModal
              {...props}
              open={openDeleteUserModal}
              onClose={this.closeDeleteUserModal}
              userId={user.ID}
            />
          )}
        />
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Authorize permission="ViewCustomers">
                <Menu text stackable className="responsive-header">
                  <Menu.Item>
                    <Header as="h2">{fullName}'s Profile</Header>
                  </Menu.Item>
                  <Menu.Menu position="right">
                    <Authorize permission="EditUsers">
                      {user.isAccount !== true && (
                        <Menu.Item>
                          <CreateAccountModal
                            userId={user.ID ? user.ID : null}
                          />
                        </Menu.Item>
                      )}
                    </Authorize>
                    <Authorize permission="EditTodoItems">
                      <Menu.Item>
                        <TodoItemModal
                          customerId={user.Customer ? user.Customer.ID : null}
                        />
                      </Menu.Item>
                    </Authorize>
                    <Authorize permission="EditEstimates">
                      <Menu.Item>
                        <Button
                          fluid
                          as={Link}
                          color="blue"
                          to={"/dashboard/estimate/user/" + user.ID + "/info"}
                          content="New Estimate"
                        />
                      </Menu.Item>
                    </Authorize>
                    <Authorize
                      permissions={[
                        "CreateComplaints",
                        "SendTransactionSummaries",
                        "CreateTransactions",
                        "MergeProfiles",
                        "DangerousEditing",
                      ]}
                    >
                      <Dropdown
                        trigger={
                          <Menu.Item>
                            <Button icon="ellipsis horizontal" />
                          </Menu.Item>
                        }
                        icon={null}
                        options={[
                          <Authorize
                            permission="CreateTransactions"
                            key="transactions"
                          >
                            <Dropdown.Item
                              content="New Transaction"
                              as={Link}
                              to={"/dashboard/transactions/new/user/" + user.ID}
                            />
                          </Authorize>,
                          <Authorize
                            permission="SendTransactionSummaries"
                            key="SendTransactionSummaries"
                          >
                            <Dropdown.Item
                              content="Send Transaction Summary"
                              onClick={this.openTransactionSummaryModal}
                            />
                          </Authorize>,
                          <Authorize
                            permission="CreateComplaints"
                            key="complaints"
                          >
                            <Dropdown.Item
                              onClick={this.openComplaintModal}
                              content="New Complaint"
                            />
                          </Authorize>,
                          <Authorize
                            permission="MergeProfiles"
                            key="mergeProfiles"
                          >
                            <Dropdown.Item
                              content="Merge Profile"
                              as={Link}
                              to={"/dashboard/merge/" + user.ID}
                            />
                          </Authorize>,
                          <Authorize
                            permission="DangerousEditing"
                            key="deleteUser"
                          >
                            <Dropdown.Item
                              onClick={this.openDeleteUserModal}
                              content="Delete User"
                            />
                          </Authorize>,
                        ]}
                      />
                    </Authorize>
                  </Menu.Menu>
                </Menu>
              </Authorize>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Menu fluid size="large" color="purple" pointing vertical>
                {menuItems}
              </Menu>
              <Divider hidden />
            </Grid.Column>
            <Grid.Column mobile={16} computer={13}>
              <Switch>
                <Route
                  path={`${match.path}/overview`}
                  component={CustomerOverview}
                />
                <Route
                  path={`${match.path}/addresses`}
                  component={AddressesTable}
                />
                <Route
                  path={`${match.path}/phoneNumbers`}
                  component={PhoneNumbersTable}
                />
                <Route
                  path={`${match.path}/emailAddresses`}
                  component={EmailsTable}
                />
                <Route
                  path={`${match.path}/creditCards`}
                  render={(props) => (
                    <CreditCardsTable {...props} fullName={fullName} />
                  )}
                />
                <Route
                  path={`${match.path}/upcomingAppointments`}
                  render={(props) => (
                    <Segment>
                      <UpcomingAppointments
                        userId={user.ID}
                        includeUserInPath
                        {...props}
                      />
                    </Segment>
                  )}
                />
                <Route
                  path={`${match.path}/appointmentHistory`}
                  render={(props) => (
                    <PreviousAppointments
                      userId={user.ID}
                      key={user.ID}
                      includeUserInPath
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${match.path}/estimateHistory`}
                  render={(props) => (
                    <EstimatesTable
                      userId={user.ID}
                      key={user.ID}
                      includeUserInPath
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${match.path}/transactionHistory`}
                  render={(props) => (
                    <CustomerTransactions
                      userId={user.ID}
                      key={user.ID}
                      {...props}
                    />
                  )}
                />
                <Route
                  path={`${match.path}/pets`}
                  render={(props) => (
                    <PetsView
                      {...props}
                      userId={user.ID}
                      customerId={user.Customer ? user.Customer.ID : null}
                      key={user.ID}
                    />
                  )}
                />

                <Redirect to={`${match.path}/overview`} />
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default CustomerPage;
