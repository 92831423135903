import PropTypes from "prop-types";
import React from "react";

import DesktopContainer from "./DesktopContainer";
import MobileContainer from "./MobileContainer";
import { UserContext } from "../UserContext";

const ResponsiveContainer = ({ children }) => (
  <UserContext.Consumer>
    {({ user, setUser }) => (
      <div>
        <DesktopContainer user={user} setUser={setUser}>
          {children}
        </DesktopContainer>
        <MobileContainer user={user} setUser={setUser}>
          {children}
        </MobileContainer>
      </div>
    )}
  </UserContext.Consumer>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.element.isRequired
};

export default ResponsiveContainer;
