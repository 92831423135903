import React, { Component } from "react";
import UserTypePermissions from "../Forms/UserTypePermissions";
import { Grid, Menu, Segment } from "semantic-ui-react";
import Authorize from "../Shared/Authorize";
import BreedSettings from "../Shared/ManagementSettings/BreedSettings";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import OtherManagementOptions from "../Shared/ManagementSettings/OtherManagementOptions";
import ProductsAndServicesSettings from "../Shared/ManagementSettings/ProductsAndServicesSettings";
import TodoCategorySettings from "../Shared/ManagementSettings/TodoCategorySettings";

/**
 * Screen available to managers and admins to change settings
 */
class Management extends Component {
  render() {
    let { location } = this.props;
    let pathname = location.pathname || "";
    let menuItems = [
      <Menu.Item
        key="servicesAndProducts"
        name="servicesAndProducts"
        active={pathname.indexOf("servicesAndProducts") > 0}
        as={Link}
        to="/dashboard/management/servicesAndProducts"
      />,
      <Menu.Item
        key="breedSettings"
        name="breedSettings"
        active={pathname.indexOf("breedSettings") > 0}
        as={Link}
        to="/dashboard/management/breedSettings"
      />,
      <Menu.Item
        key="todoCategories"
        name="todoCategories"
        active={pathname.indexOf("todoCategories") > 0}
        as={Link}
        to="/dashboard/management/todoCategories"
      />,
      <Menu.Item
        key="permissions"
        name="permissions"
        active={pathname.indexOf("permissions") > 0}
        as={Link}
        to="/dashboard/management/permissions"
      />,
      <Menu.Item
        key="other"
        name="other"
        active={pathname.indexOf("other") > 0}
        as={Link}
        to="/dashboard/management/other"
      />
    ];
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column only="computer" width={3}>
              <Menu pointing secondary vertical fluid stackable color="purple">
                {menuItems}
              </Menu>
            </Grid.Column>
            <Grid.Column only="mobile tablet" width={16}>
              <Menu vertical stackable fluid color="purple">
                {menuItems}
              </Menu>
            </Grid.Column>
            <Grid.Column mobile={16} computer={13}>
              <Segment>
                <Switch>
                  <Route
                    path="/dashboard/management/permissions"
                    component={UserTypePermissions}
                  />
                  <Route
                    path="/dashboard/management/breedSettings"
                    component={BreedSettings}
                  />
                  <Route
                    path="/dashboard/management/servicesAndProducts"
                    component={ProductsAndServicesSettings}
                  />
                  <Route
                    path="/dashboard/management/todoCategories"
                    component={TodoCategorySettings}
                  />
                  <Route
                    path="/dashboard/management/other"
                    component={OtherManagementOptions}
                  />
                  <Redirect to="/dashboard/management/servicesAndProducts" />
                </Switch>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

export default props => (
  <Authorize permission="EditSettings">
    <Management {...props} />
  </Authorize>
);
