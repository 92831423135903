import React, { Component } from "react";
import { Header, Icon } from "semantic-ui-react";
import Image from "./Image";

/**
 * Displays a thank you message to the customer.
 */
class CustomerThankYou extends Component {
  render() {
    return (
      <div>
        <Image
          centered
          key={this.props.customerUserId}
          bordered
          circular
          src={
            "/api/v4/user/getProfilePicture?userId=" +
            encodeURI(this.props.customerUserId)
          }
        >
          <Icon name="user" size="huge" circular />
        </Image>
        <Header as="h1" textAlign="center">
          <Header.Content>Thank you!</Header.Content>
          <Header.Subheader>
            We hope to have the pleasure of grooming and styling your pets for
            many years to come.
          </Header.Subheader>
          <Header.Subheader>-Pet Love</Header.Subheader>
        </Header>
      </div>
    );
  }
}

CustomerThankYou.defaultProps = {
  customerUserId: null
};

export default CustomerThankYou;
