import React, { Component } from "react";
import PropTypes from "prop-types";

import moment from "moment";

import {
  Button,
  Form,
  Header,
  Divider,
  Segment,
  Responsive
} from "semantic-ui-react";
import Authorize from "../Shared/Authorize";
import Input from "../Shared/Input";
import TinyEditButtonGroup from "../Shared/TinyEditButtonGroup";
import { defaultEmployee } from "../lib/defaultObjects";
import UserDetails from "../Shared/UserDetails";
import { updateEmployeeDetails, getEmployeeDetails } from "../lib/apiCalls";

import { toast } from "react-toastify";
import ExceptionCalendar from "../Shared/EmployeeSchedule/ExceptionCalendar";

/**
 * Displays employee details such as senior citizen and military
 */
class EmployeeDetails extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ...defaultEmployee(),
      isEditing: false,
      User: {}
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // Get employee details
    this.getEmployeeDetails();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  handleToggle = (e, { name }) => {
    this.setState(state => ({ [name]: !state[name] }));
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleUserChange = (e, { name, value }) => {
    this.setState(state => ({ User: { ...state.User, [name]: value } }));
  };
  handleEdit = () => {
    this.setState({ isEditing: true });
  };
  handleCancel = () => {
    this.getEmployeeDetails();
  };
  handleSave = () => {
    updateEmployeeDetails(
      {
        employeeId: this.props.employeeId,
        ...this.state,
        User: this.state.User
      },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            toast.error("Failed to save employee details");
            return;
          }
          toast.success("Updated employee details");
          this.setState({ isEditing: false });
        }
      }
    );
  };
  getEmployeeDetails() {
    if (!this.props.employeeId) {
      this.setState({ loading: false });
      return;
    }
    getEmployeeDetails(
      { employeeId: this.props.employeeId },
      (error, response) => {
        if (this._isMounted) {
          if (error) {
            toast.error("Failed to retrieve employee details");
            this.setState({ loading: false });
            return;
          }
          this.setState({ ...response, isEditing: false, loading: false });
        }
      }
    );
  }
  render() {
    let {
      dateHired,
      dateTerminated,
      emergencyContactPhone,
      emergencyContactName,
      workDayStartTime,
      workDayEndTime,

      User,
      isEditing,
      loading
    } = this.state;

    return (
      <Segment loading={loading}>
        <Header
          content="Employee Details"
          subheader="Changing the schedule updates assigned appointments outside of the new schedule to be conflicting."
          floated="left"
        />
        <Authorize permission="EditEmployees">
          <TinyEditButtonGroup
            isEditing={isEditing}
            onSave={this.handleSave}
            onEdit={this.handleEdit}
            onCancel={this.handleCancel}
          />
        </Authorize>
        <Divider clearing />
        <UserDetails
          isEditing={isEditing}
          {...User}
          showUserType={true}
          handleChange={this.handleUserChange}
        />
        <Divider clearing />
        <Form>
          <Form.Group widths="2">
            <Input
              label="Date Hired"
              value={dateHired}
              name="dateHired"
              placeholder="Time"
              validation="date"
              onChange={this.handleChange}
              isEditing={isEditing}
            />
            <Input
              label="Date Terminated"
              value={dateTerminated}
              name="dateTerminated"
              placeholder="Time"
              validation="date"
              onChange={this.handleChange}
              isEditing={isEditing}
            />
          </Form.Group>

          {/* Scheduling fields */}
          <Divider horizontal>
            <Header as="h4">Schedule</Header>
          </Divider>
          <Form.Field>
            <label>Working Days</label>
            <Button.Group
              fluid
              vertical={window.innerWidth <= Responsive.onlyMobile.maxWidth}
              widths="7"
            >
              {moment.weekdays().map(day => (
                <Button
                  disabled={!isEditing}
                  key={day}
                  icon
                  type="button"
                  content={day}
                  toggle
                  active={this.state["works" + day]}
                  name={"works" + day}
                  onClick={this.handleToggle}
                />
              ))}
            </Button.Group>
          </Form.Field>
          <Form.Group widths="2">
            <Input
              label="Start Time"
              value={workDayStartTime}
              name="workDayStartTime"
              validation="time"
              placeholder="Time"
              onChange={this.handleChange}
              isEditing={isEditing}
            />
            <Input
              label="End Time"
              value={workDayEndTime}
              name="workDayEndTime"
              validation="time"
              placeholder="Time"
              onChange={this.handleChange}
              isEditing={isEditing}
            />
          </Form.Group>
          <ExceptionCalendar
            employeeId={this.props.employeeId}
            key={this.props.employeeId}
          />
          {/* Emergency Contact */}
          <Divider horizontal>
            <Header as="h4">Emergency Contact</Header>
          </Divider>

          <Form.Group widths="2">
            <Input
              label="Name"
              placeholder="Name"
              value={emergencyContactName}
              name="emergencyContactName"
              fluid
              onChange={this.handleChange}
              isEditing={isEditing}
            />
            <Input
              label="Phone Number"
              placeholder="Phone"
              value={emergencyContactPhone}
              name="emergencyContactPhone"
              fluid
              onChange={this.handleChange}
              isEditing={isEditing}
            />
          </Form.Group>

          {/* Update button if this is an employees profile */}
          <Authorize userRole="Employee">
            <Form.Button
              color="purple"
              type="submit"
              content="Update"
              disabled
            />
          </Authorize>
        </Form>
      </Segment>
    );
  }
}

EmployeeDetails.defaultProps = {
  onUpdate: () => {},
  employee: {},
  isEditing: false
};
EmployeeDetails.propTypes = {
  onUpdate: PropTypes.func,
  employee: PropTypes.object,
  isEditing: PropTypes.bool
};

export default EmployeeDetails;
