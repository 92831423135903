import React from "react";

import { Step } from "semantic-ui-react";
import { Link } from "react-router-dom";

/**
 * Steps used in the estimates screen.
 * @param {*} props
 */
function Steps(props) {
  const {
    activeIndex,
    completeIndex,
    steps,
    vertical,
    handleStepClick,
    state
  } = props;
  return (
    <Step.Group vertical={vertical} fluid>
      {steps.map((step, i) => (
        <Step
          key={i}
          as={Link}
          to={{ pathname: step.path, state: state }}
          onClick={() => handleStepClick(i)}
          completed={completeIndex > i}
          disabled={completeIndex < i}
          active={activeIndex === i}
          icon={{ name: step.icon }}
          title={step.title}
        />
      ))}
    </Step.Group>
  );
}

export default Steps;
