import _ from "lodash";
export function defaultUser(user = {}) {
  return {
    ID: user.ID || null,
    Name: defaultName(user.Name),
    phoneNumber: user.phoneNumber || "",
    emailAddress: user.emailAddress || "",
    Customer: defaultCustomer(user.Customer),
    Employee: defaultEmployee(user.Employee),
    Address: defaultAddress(user.Address),
    Addresses: user.Addresses
      ? user.Addresses.map((address) => defaultAddress(address))
      : [],
    Emails: user.Emails ? user.Emails.map((email) => defaultEmail(email)) : [],
    PhoneNumbers: user.PhoneNumbers
      ? user.PhoneNumbers.map((phone) => defaultPhoneNumber(phone))
      : [],
    CreditCards: user.CreditCards
      ? user.CreditCards.map((creditCard) => defaultCreditCard(creditCard))
      : [],
    birthday: user.birthday || null,
    notes: user.notes || "",
    userTypeId: user.UserTypeID || null,
    commentGroupId: user.commentGroupId || null,
  };
}

export function defaultName(name = {}) {
  return {
    firstName: name.firstName || "",
    middleName: name.middleName || "",
    lastName: name.lastName || "",
  };
}

export function defaultAddress(address = {}) {
  return {
    ID: address.ID || null,
    address: address.address || "",
    city: address.city || "",
    state: address.state || "",
    zipcode: address.zipcode || "",
    mapsco: address.mapsco || "",
    mapscoCityId: address.mapscoCityId || null,
    notes: address.notes || "",
    isPrimary: address.isPrimary != null ? address.isPrimary : false,
    needsGenerator:
      address.needsGenerator != null ? address.needsGenerator : false,
  };
}
export function defaultPhoneNumber(number = {}) {
  return {
    ID: number.ID || null,
    phoneNumber: number.phoneNumber || "",
    extension: number.extension || "",
    label: number.label || "",
    isPrimary: number.isPrimary != null ? number.isPrimary : false,
  };
}
export function defaultEmail(email = {}) {
  return {
    ID: email.ID || null,
    label: email.label || "",
    emailAddress: email.emailAddress || "",
    isPrimary: email.isPrimary != null ? email.isPrimary : false,
  };
}

export function defaultCreditCard(card = {}) {
  return {
    ID: card.ID || null,
    nameOnCard: card.nameOnCard || "",
    expiration: card.expiration || "",
    cardNumber: card.cardNumber || "",
    securityCode: "",
    billingZipcode: card.billingZipcode || "",
    isPrimary: card.isPrimary != null ? card.isPrimary : false,
  };
}

export function defaultCustomer(customer = {}) {
  return {
    ID: customer.ID || null,
    userId: customer.userId || null,
    balance: customer.balance || 0,
    isSeniorCitizen:
      customer.isSeniorCitizen != null ? customer.isSeniorCitizen : false,
    isMilitary: customer.isMilitary != null ? customer.isMilitary : false,
    isTaxExempt: customer.isTaxExempt != null ? customer.isTaxExempt : false,
    defaultGratuityPercent: customer.defaultGratuityPercent || null,
    defaultGratuityDollar: customer.defaultGratuityDollar || null,
    tripCharge: customer.tripCharge || 39.0,
    preferredStylistId: customer.preferredStylistId || null,
    contactMethodId: customer.contactMethodId || null,
    Pets: customer.Pets ? customer.Pets.map((pet) => defaultPet(pet)) : [],
    rating: customer.rating || 0,
  };
}

export function defaultEmployee(employee = {}) {
  return {
    ID: employee.ID || null,
    userId: employee.userId || null,
    dateHired: employee.dateHired,
    dateTerminated: employee.dateTerminated,
    emergencyContactPhone: employee.emergencyContactPhone,
    emergencyContactName: employee.emergencyContactName,
    workDayStartTime: employee.workDayStartTime,
    workDayEndTime: employee.workDayEndTime,

    worksSunday: employee.worksSunday || false,
    worksMonday: employee.worksMonday || false,
    worksTuesday: employee.worksTuesday || false,
    worksWednesday: employee.worksWednesday || false,
    worksThursday: employee.worksThursday || false,
    worksFriday: employee.worksFriday || false,
    worksSaturday: employee.worksSaturday || false,

    // Stylist fields
    maxPetWeight: employee.maxPetWeight || null,
    petsPerDay: employee.petsPerDay,
    minutesPerPet: employee.minutesPerPet || 90,
    averageGroomingTime: employee.averageGroomingTime,
    isSmoker: employee.isSmoker || false,
    servicesCats: employee.servicesCats != null ? employee.servicesCats : false,
    hasGeneratorVehicle: employee.hasGeneratorVehicle || false,
    stylistBreedRestrictions: employee.stylistBreedRestrictions
      ? employee.stylistBreedRestrictions.map((breed) => breed.BreedID)
      : [],
  };
}

export function defaultPet(pet = {}) {
  return {
    ID: pet.ID,
    commentGroupId: pet.commentGroupId || null,
    age: pet.age,
    birthday: pet.birthday,
    breedId: pet.breedId,
    customerId: pet.customerId,
    grooming: pet.grooming || "",
    habits: pet.habits || "",
    priceHistory: pet.priceHistory,
    isDeceased: pet.isDeceased != null ? pet.isDeceased : false,
    isServicePet: pet.isServicePet != null ? pet.isServicePet : false,
    notes: pet.notes,
    petName: pet.petName,
    sex: pet.sex || "unknown",
    weight: pet.weight,
    lastFee: pet.lastFee,
    lastGroomDate: pet.lastGroomDate,
    isOverridingGroomingFee:
      pet.isOverridingGroomingFee != null ? pet.isOverridingGroomingFee : false,
    overrideGroomingFeePrice: pet.overrideGroomingFeePrice || 0,
  };
}

export function defaultAppointment(appointment = {}) {
  return {
    ID: appointment.ID || null,
    appointmentDate: appointment.appointmentDate || null,
    startTime: appointment.startTime,
    endTime: appointment.endTime,
    timeStarted: appointment.timeStarted,
    timeEnded: appointment.timeEnded,
    statusId: appointment.statusId || null,
    employeeId: appointment.employeeId || null,
    customerId: appointment.customerId || null,
    commentGroupId: appointment.commentGroupId || null,
    isStanding: appointment.isStanding === true,
    standingId: appointment.standingId || null,
    estimateId: appointment.estimateId || null, // For now, save to estimateId instead of object since estimate page converts the data itself
  };
}

export function defaultEstimate(estimate = {}) {
  return {
    ID: estimate.ID || null,
    // If sales tax is specified, add it, otherwise default to Texas sales tax
    salesTax: estimate.salesTax || 0.0825,
    fuelCharge: estimate.fuelCharge || 0.0375,
    PetEstimates: estimate.EstimatePets
      ? estimate.EstimatePets.map((petEstimate) =>
          defaultPetEstimate(petEstimate)
        )
      : [],
    Address: defaultAddress(estimate.Address),
    Customer: estimate.Customer,

    needsGenerator: estimate.needsGenerator === true,
    // Stringifed records
    addressString: estimate.AddressString || null,
    phoneNumberString: estimate.PhoneNumberString || null,
    emailString: estimate.EmailString || null,
    addressNotes: estimate.AddressNotes || null,
    // -- Credit card ID/String

    // Saved fees and totals
    serviceFee: estimate.FeeGroom || 0,
    tripFee:
      estimate.FeeTrip !== null && estimate.FeeTrip !== undefined
        ? estimate.FeeTrip
        : 39.0,
    generatorFee: estimate.FeeGenerator || 10,
    fuelFee: estimate.FeeFuel || 0,
    salesTaxFee: estimate.FeeSalesTax || 0,
    discounts: estimate.DiscountTotal || 0,
    totalFee: estimate.TotalEstimate || 0,
  };
}

/** Creates a pet estimate in proper format with default values. Expects pet to be in default format. */
export function defaultPetEstimate(petEstimate = {}) {
  return {
    ID: petEstimate.ID || null,
    subtotal: petEstimate.subtotal || 0,
    otherFee: petEstimate.OtherFee || 0,
    otherDescription: petEstimate.OtherDescription || "",
    Pet: petEstimate.Pet || {},
    estimatePackage: petEstimate.GroomPackage || "b&b",
    includeInEstimate: petEstimate.includeInEstimate === false ? false : true,
    services: petEstimate.services || {},
  };
}

export function defaultTransaction(transaction = {}) {
  return {
    ID: transaction.ID || null,
    userId: transaction.userId || null,
    checkNumber: transaction.checkNumber || null,
    giftCardNumber: transaction.giftCardNumber || null,
    checkDate: transaction.checkDate || null,
    checkAmount: transaction.checkAmount || 0,
    creditCardAmount: transaction.creditCardAmount || 0,
    cashAmount: transaction.cashAmount || 0,
    giftCardAmount: transaction.giftCardAmount || 0,
    creditAmount: transaction.creditAmount || 0,
    serviceFee: transaction.serviceFee || 0,
    cancelFee: transaction.cancelFee || 0,
    returnedFee: transaction.returnedFee || 0,
    reprocessFee: transaction.reprocessFee || 0,
    transactionBalance: transaction.transactionBalance || 0,
    openingBalance: transaction.openingBalance || 0,
    gratuity: transaction.gratuity || 0,

    // Service breakdown
    serviceFeeGroom: transaction.serviceFeeGroom || 0,
    serviceFeeTrip: transaction.serviceFeeTrip || 0,
    serviceFeeGenerator: transaction.serviceFeeGenerator || 0,
    serviceFeeFuel: transaction.serviceFeeFuel || 0,
    serviceDiscountTotal: transaction.serviceDiscountTotal || 0,
    serviceFeeSalesTax: transaction.serviceFeeSalesTax || 0,

    notes: transaction.notes || null,
    reasonId: transaction.reasonId || null,
  };
}

/** Creates an exception object */
export function defaultException(exception = {}) {
  return {
    ID: exception.ID || null,
    employeeId: exception.employeeId || null,
    Event: defaultEvent(exception.Event),
  };
}
/** Creates an event object */
export function defaultEvent(event = {}) {
  return {
    ID: event.ID || null,
    parentId: event.parentId || null,
    title: event.title || "",
    description: event.description || null,
    startDate: event.startDate || "",
    endDate: event.endDate || "",
    startTime: event.startTime || "",
    endTime: event.endTime || "",
    isAllDay: event.isAllDay === true,
    isRecurring: event.isRecurring === true,
    RecurringPattern: defaultRecurring(event.RecurringPattern || {}),
  };
}

/**
 * Creates a recurring pattern.
 * @param {object} recurringPattern
 */
export function defaultRecurring(recurringPattern = {}) {
  return {
    ID: recurringPattern.ID || null,
    recurringTypeId: recurringPattern.recurringTypeId || null,
    separationCount: recurringPattern.separationCount || 1,
    startDate: recurringPattern.startDate || null,
    endDate: recurringPattern.endDate || null,
    daysOfWeek: recurringPattern.daysOfWeek || _.repeat("0", 7),
    daysOfMonth: recurringPattern.daysOfMonth || _.repeat("0", 31),
    weeksOfMonth: recurringPattern.weeksOfMonth || _.repeat("0", 5),
    monthsOfYear: recurringPattern.monthsOfYear || _.repeat("0", 12),
  };
}

/**
 * Creates a breed.
 * @param {object} breed
 */
export function defaultBreed(breed = {}) {
  return {
    ID: breed.ID,
    animalTypeId: breed.animalTypeId,
    breed: breed.breed || "",
    minutesToGroom: breed.minutesToGroom || 90,
    bathBrushPrice: breed.bathBrushPrice,
    bathBrushPrice3Months: breed.bathBrushPrice3Months,
    bathBrushPrice6Months: breed.bathBrushPrice6Months,
    deluxePrice: breed.deluxePrice,
    deluxePrice3Months: breed.deluxePrice3Months,
    deluxePrice6Months: breed.deluxePrice6Months,
  };
}
