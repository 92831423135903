import React, { Component } from "react";
import { Tab, Form, Menu, Label, Divider } from "semantic-ui-react";

import Input from "../Input";
import { moneyFormat } from "../../lib/format";
import { getCreditCards } from "../../lib/apiCalls";
import { toast } from "react-toastify";

import { getCreditCardType } from "../../lib/helperFunctions";
import _ from "lodash";

class TransactionPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCards: []
    };
  }

  componentDidMount() {
    if (!this.props.userId) return;
    getCreditCards({ userId: this.props.userId }, (error, response) => {
      if (error) {
        toast.error("Failed to get customer's credit cards");
        return;
      }
      let primaryCard = _.find(response, { isPrimary: true });
      if (primaryCard) {
        this.props.onChange(null, {
          name: "creditCardId",
          value: primaryCard.ID
        });
      }
      this.setState({ creditCards: response });
    });
  }

  onExpirationChange = (e, data) => {
    let value = data.value;
    if (data.value && data.value.length > 2) {
      value = value.replace(/\D+/g, "");
      value = `${value.substring(0, 2)}/${value.substring(2, 4)}`;
    }
    this.props.onChange(e, { ...data, value: value });
  };
  onMoneyChange = (e, data) => {
    let value = data.value;
    if (value === "") {
      value = 0;
    }
    this.props.onChange(e, { ...data, value: value });
  };
  render() {
    let { creditCards } = this.state;
    let {
      CreditCard,
      refTransactionId,
      status,
      onChange,
      onAutoFill,
      creditCardAmount,
      cashAmount,
      checkAmount,
      isEditing,
      checkDate,
      checkNumber,
      giftCardAmount,
      giftCardNumber,
      // Credit card information
      isOneTimePayment,
      creditCardId,
      nameOnCard,
      cardNumber,
      expiration,
      securityCode,
      billingZipcode
    } = this.props;

    let checkRequired = checkAmount != null && parseFloat(checkAmount) !== 0;
    let giftCardRequired =
      giftCardAmount != null && parseFloat(giftCardAmount) !== 0;

    let type = getCreditCardType(cardNumber);

    let selectedCreditCard =
      CreditCard || _.find(creditCards, { ID: creditCardId });

    return (
      <div>
        <Tab
          panes={[
            {
              menuItem: (
                <Menu.Item key="creditCard">
                  Credit Card{" "}
                  <Label color={creditCardAmount > 0 ? "green" : "grey"}>
                    ${moneyFormat(creditCardAmount)}
                  </Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Input
                    label="Credit Card Amount"
                    name="creditCardAmount"
                    value={creditCardAmount}
                    type="number"
                    format="moneyInput"
                    validation="money"
                    onChange={this.onMoneyChange}
                    placeholder="0.00"
                    isEditing={isEditing}
                    action={
                      isEditing === true
                        ? {
                            color: "purple",
                            content: "Auto-Fill",
                            onClick: () =>
                              onAutoFill("creditCardAmount", creditCardAmount)
                          }
                        : null
                    }
                  />
                  <Form.Checkbox
                    label="Use one time payment method"
                    checked={isOneTimePayment}
                    readOnly={!isEditing}
                    name="isOneTimePayment"
                    onChange={(e, data) => {
                      onChange(e, { ...data, value: data.checked });
                    }}
                  />
                  <label>
                    <b>Credit Card</b>
                  </label>
                  {isEditing !== true ? (
                    <div>
                      {selectedCreditCard
                        ? selectedCreditCard.nameOnCard
                        : "No card used"}{" "}
                      {selectedCreditCard ? selectedCreditCard.cardNumber : ""}
                    </div>
                  ) : isOneTimePayment !== true ? (
                    <Form.Select
                      placeholder={
                        creditCards.length > 0
                          ? "Select a credit card on file"
                          : "No cards on file"
                      }
                      disabled={creditCards.length < 1}
                      options={creditCards.map(creditCard => ({
                        text: creditCard.nameOnCard,
                        description: creditCard.cardNumber,
                        value: creditCard.ID
                      }))}
                      value={creditCardId}
                      name="creditCardId"
                      onChange={onChange}
                    />
                  ) : (
                    <Form.Group widths={4}>
                      <Input
                        required
                        label="Name on Card"
                        name="nameOnCard"
                        isEditing={isEditing}
                        value={nameOnCard}
                        onChange={onChange}
                      />
                      <Input
                        required
                        label={
                          <>
                            Card Number{" "}
                            {type && (
                              <Label color="blue" size="small" content={type} />
                            )}
                          </>
                        }
                        name="cardNumber"
                        format="creditCard"
                        placeholder="0000 0000 0000 0000"
                        validation="creditCard"
                        maxLength={16}
                        isEditing={isEditing}
                        value={cardNumber}
                        onChange={onChange}
                      />
                      <Input
                        label="Expiration Date"
                        name="expiration"
                        isEditing={isEditing}
                        placeholder="MM/YY"
                        value={expiration}
                        onChange={this.onExpirationChange}
                        maxLength={5}
                        required
                      />
                      <Input
                        label="Security Code"
                        required
                        name="securityCode"
                        isEditing={isEditing}
                        value={securityCode}
                        onChange={onChange}
                        maxLength={4}
                      />
                      <Input
                        label="Billing Zipcode"
                        required
                        name="billingZipcode"
                        validation="zipcode"
                        isEditing={isEditing}
                        value={billingZipcode}
                        onChange={onChange}
                        maxLength={10}
                      />
                    </Form.Group>
                  )}
                  {isEditing !== true && refTransactionId && (
                    <div>
                      <Divider />
                      <div>
                        <label>
                          <strong>Authorize Transaction ID</strong>
                        </label>
                        <div>{refTransactionId}</div>
                      </div>
                      {status && (
                        <div>
                          <br />
                          <label>
                            <strong>Authorize Status</strong>
                          </label>
                          <div>{_.startCase(status)}</div>
                        </div>
                      )}
                    </div>
                  )}
                </Tab.Pane>
              )
            },
            {
              menuItem: (
                <Menu.Item key="cash">
                  Cash{" "}
                  <Label color={cashAmount > 0 ? "green" : "grey"}>
                    ${moneyFormat(cashAmount)}
                  </Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Input
                    label="Cash Amount"
                    name="cashAmount"
                    value={cashAmount}
                    isEditing={isEditing}
                    type="number"
                    format="moneyInput"
                    validation="money"
                    onChange={this.onMoneyChange}
                    placeholder="0.00"
                    action={
                      isEditing === true
                        ? {
                            color: "purple",
                            content: "Auto-Fill",
                            onClick: () =>
                              this.props.onAutoFill("cashAmount", cashAmount)
                          }
                        : null
                    }
                  />
                </Tab.Pane>
              )
            },
            {
              menuItem: (
                <Menu.Item key="check">
                  Check Amount{" "}
                  <Label color={checkAmount > 0 ? "green" : "grey"}>
                    ${moneyFormat(checkAmount)}
                  </Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Input
                    label="Check Amount"
                    name="checkAmount"
                    value={checkAmount}
                    isEditing={isEditing}
                    type="number"
                    format="moneyInput"
                    validation="money"
                    onChange={this.onMoneyChange}
                    placeholder="0.00"
                    action={
                      isEditing === true
                        ? {
                            color: "purple",
                            content: "Auto-Fill",
                            onClick: () =>
                              this.props.onAutoFill("checkAmount", checkAmount)
                          }
                        : null
                    }
                  />
                  <Form.Group widths={2}>
                    <Input
                      isEditing={isEditing}
                      onChange={onChange}
                      type="number"
                      required={checkRequired}
                      step="1"
                      label="Check Number"
                      name="checkNumber"
                      value={checkNumber}
                    />
                    <Input
                      isEditing={isEditing}
                      onChange={onChange}
                      validation="date"
                      required={checkRequired}
                      label="Check Date"
                      name="checkDate"
                      value={checkDate}
                    />
                  </Form.Group>
                </Tab.Pane>
              )
            },
            {
              menuItem: (
                <Menu.Item key="giftCard">
                  Gift Card{" "}
                  <Label color={giftCardAmount > 0 ? "green" : "grey"}>
                    ${moneyFormat(giftCardAmount)}
                  </Label>
                </Menu.Item>
              ),
              render: () => (
                <Tab.Pane>
                  <Input
                    label="Gift Card Amount"
                    name="giftCardAmount"
                    value={giftCardAmount}
                    isEditing={isEditing}
                    type="number"
                    format="moneyInput"
                    validation="money"
                    onChange={this.onMoneyChange}
                    placeholder="0.00"
                  />
                  <Input
                    isEditing={isEditing}
                    onChange={onChange}
                    type="number"
                    required={giftCardRequired}
                    step="1"
                    label="Gift Card Number"
                    name="giftCardNumber"
                    value={giftCardNumber}
                  />
                </Tab.Pane>
              )
            }
          ]}
        />
      </div>
    );
  }
}

TransactionPayment.defaultProps = {
  onChange: () => {},
  onAutoFill: () => {},
  handleCreditCardChange: () => {}
};

export default TransactionPayment;
