import React, { Component } from "react";
import {
  Header,
  Segment,
  Grid,
  Icon,
  Form,
  Popup,
  Label,
  Responsive,
  Button
} from "semantic-ui-react";
import PetList from "./PetList";
import Input from "./Input";
import { moneyFormat } from "../lib/format";
import RatingModal from "./RatingModal";
import _ from "lodash";

class ScheduleItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRatingModal: false,
      completed: false
    };
  }

  openRatingModal = () => {
    this.setState({ openRatingModal: true });
  };
  closeRatingModal = completed => {
    this.setState({ openRatingModal: false, completed: completed });
  };

  render() {
    let { appointment, complete } = this.props;
    let { openRatingModal, completed } = this.state;
    let user =
      appointment && appointment.Customer ? appointment.Customer.User : null;
    let estimate = appointment.Estimate;
    let time = !complete ? appointment.startTime : appointment.timeEnded;

    let moneyColor = "blue";
    let total = 0;

    let firstTransaction = appointment.Transactions
      ? _.minBy(appointment.Transactions, "DateAdded")
      : {};
    if (complete === true) {
      if (firstTransaction) {
        total =
          parseFloat(firstTransaction.cashAmount || 0) +
          parseFloat(firstTransaction.creditCardAmount || 0) +
          parseFloat(firstTransaction.checkAmount || 0) +
          parseFloat(firstTransaction.giftCardAmount || 0);
        if (
          firstTransaction.transactionBalance &&
          firstTransaction.transactionBalance < 0
        ) {
          moneyColor = "red";
        } else {
          moneyColor = "green";
        }
      }
    } else {
      total = estimate.TotalEstimate;
    }
    let moneyHeader = (
      <Header.Content>
        {complete === true && (
          <>
            {firstTransaction && firstTransaction.cashAmount > 0 && (
              <Popup inverted trigger={<Label horizontal content="Cash" />}>
                Paid with cash
              </Popup>
            )}
            {firstTransaction && firstTransaction.creditCardAmount > 0 && (
              <Popup
                inverted
                trigger={<Label horizontal content="Credit Card" />}
              >
                Paid with credit card
              </Popup>
            )}
            {firstTransaction && firstTransaction.checkAmount > 0 && (
              <Popup inverted trigger={<Label horizontal content="Check" />}>
                Paid with check
              </Popup>
            )}
            {firstTransaction && firstTransaction.giftCardAmount > 0 && (
              <Popup
                inverted
                trigger={<Label horizontal content="Gift Card" />}
              >
                Paid with gift card
              </Popup>
            )}
          </>
        )}
        <Icon fitted name="dollar" />
        {moneyFormat(total)}
      </Header.Content>
    );
    return (
      <Segment>
        <RatingModal
          key={appointment.ID}
          appointmentId={appointment.ID}
          open={openRatingModal}
          onClose={this.closeRatingModal}
        />
        <Header size="medium" dividing>
          {user ? `${user.firstName} ${user.lastName}` : "No User"}

          {/* Display the estimate on the right when on desktop */}
          <Responsive
            as={Header}
            size="tiny"
            color={moneyColor}
            floated="right"
            minWidth={Responsive.onlyTablet.minWidth}
          >
            {moneyHeader}{" "}
          </Responsive>

          {/* Display the estimate under next appointment when on mobile */}
          <Responsive
            as={Header}
            size="tiny"
            color={moneyColor}
            {...Responsive.onlyMobile}
          >
            {moneyHeader}
          </Responsive>
        </Header>

        {/* <Label attached="top left" content="Time" /> */}
        <Grid columns="equal" stackable>
          <Grid.Column width="3" verticalAlign="middle">
            {/* Display the start time */}
            <Header size="small" color={complete ? "green" : "blue"}>
              <Icon fitted name="clock" />
              <Header.Content>
                {time || "No Time Saved"}
                <Header.Subheader>
                  {!complete ? "Start" : "End"} Time
                </Header.Subheader>
              </Header.Content>
            </Header>
            {complete && (
              <Button
                content="Rate Appointment"
                color="blue"
                disabled={
                  (appointment.Rating && appointment.Rating.ID
                    ? true
                    : false) || completed === true
                }
                onClick={this.openRatingModal}
              />
            )}
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Input
                isEditing={false}
                label="Address"
                value={estimate ? estimate.AddressString : "No Address"}
              />
              <Input
                isEditing={false}
                label="Phone Number"
                value={
                  estimate ? estimate.PhoneNumberString : "No Phone Number"
                }
              />
            </Form>
          </Grid.Column>

          <Grid.Column>
            <Header content="Pets" size="tiny" dividing />
            <PetList
              includeDetails
              includePetAvatar
              emptyLabel={"None Added to Estimate"}
              pets={
                estimate && estimate.EstimatePets
                  ? estimate.EstimatePets.map(petEstimate => petEstimate.Pet)
                  : []
              }
            />
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

ScheduleItem.defaultProps = {
  appointment: {}
};

export default ScheduleItem;
