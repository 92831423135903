import moment from "moment";

/**
 * Formats an address object as a string
 * @param {object} address
 */
export function makeAddressString(address, mapscoCity) {
  return `[${mapscoCity ? mapscoCity.letter + " " : ""}${address.mapsco ||
    "No Mapsco"}] ${address.address || ""}
  ${address.city || ""}${address.address && address.city ? "," : ""}  ${
    address.state
  } ${address.zipcode}`;
}

/**
 * Gets the zipcode assuming it is the last part of the address
 * @param {string} address
 */
export function getZipCode(address) {
  if (!address) return;
  let splitAddress = address.split(" ");
  return splitAddress[splitAddress.length - 1];
}

/**
 * Removes a mapsco in []'s from an address and returns an object with the split pieces
 * @param {string} address String in format of "[MAPSCOCITY MAPSCO]ADDRESS"
 */
export function removeMapsco(address) {
  if (!address) {
    return { address: "", mapsco: "" };
  }
  // Take the mapsco out of the [ ] s and return as an object
  let firstIndex = address.indexOf("[");
  let secondIndex = address.indexOf("]");
  let mapsco = address.substring(firstIndex + 1, secondIndex);
  return {
    address: address.substring(secondIndex + 1, address.length),
    mapsco: mapsco
  };
}

// Placing here to remove from another file in case needed
export function getAddress({ address, city, state, zipcode }) {
  return `${address} ${city}${state || zipcode ? "," : " "}${state} ${zipcode}`;
}

/** Gets the duration in minutes from a starting time and an ending time if they are valid times */
export function getDuration(startTime, endTime) {
  let formattedStartTime = moment(startTime, "h:mm A");
  let formattedEndTime = moment(endTime, "h:mm A");
  if (formattedStartTime.isValid() && formattedEndTime.isValid) {
    return formattedEndTime.diff(formattedStartTime, "minutes");
  }
  return false;
}

/**
 * Returns the color that should be associated with a status
 * @param {string} status
 */
export function getStatusColor(status) {
  if (status != null) {
    status = status.toLowerCase();
  }
  switch (status) {
    case "waiting":
      return "yellow";
    case "scheduled":
      return "blue";
    case "conflicting":
      return "red";
    case "inProgress":
      return "purple";
    case "confirmed":
      return "green";
    case "cancelled":
      return "grey";
    case "completed":
      return "green";
    default:
      return;
  }
}

/**
 * Replaces a character at index in a string
 * @param {string} str The string to replace the value in
 * @param {integer} index The index in the string to be replaced
 * @param {char} character The character to replace the existing character at the index
 */
export function replaceAt(str, index, character) {
  return str.substring(0, index) + character + str.substring(index + 1);
}

export function convertToCalendarEvent(event, original) {
  return {
    id: event.ID,
    title: event.title,
    start: moment(
      event.startDate + " " + event.startTime,
      "MM-DD-YYYY hh:mm A"
    ).toDate(),
    end: moment(
      (event.endDate || event.startDate) + " " + event.endTime,
      "MM-DD-YYYY hh:mm A"
    ).toDate(),
    allDay: event.isAllDay,
    original: original
  };
}

/**
 *
 * @param {Date} date Date of the event
 * @param {object} event Data for the event
 * @param {object} original Original record
 */
export function convertRRuleToCalenderEvent(date, event, original) {
  let dtstring = `${date.getMonth() +
    1}-${date.getDate()}-${date.getFullYear()}`;
  let eventStart = moment(
    `${event.startDate} ${event.startTime}`,
    "MM-DD-YYYY h:mm A"
  );
  let dateInstance = moment(
    dtstring + " " + event.startTime,
    "MM-DD-YYYY hh:mm A"
  );
  let eventEnd = moment(
    `${event.endDate} ${event.endTime}`,
    "MM-DD-YYYY h:mm A"
  );
  let eventDuration = Math.abs(eventStart.diff(eventEnd));
  return {
    id: event.ID,
    title: event.title,
    start: moment(dateInstance).toDate(),
    end: moment(dateInstance)
      .add(eventDuration)
      .toDate(),
    allDay: event.isAllDay,
    original: original
  };
}

export function convertMomentDateToUTC(momentDate) {
  let copy = moment(momentDate);
  return new Date(
    Date.UTC(
      copy.year(),
      copy.month(),
      copy.date(),
      copy.hour(),
      copy.minute(),
      copy.second()
    )
  );
}

/**
 * Returns true indexes in a bitmap
 * @param {string} str Binary string as bitmap
 */
export function getTrueIndexes(str, offset = 0) {
  let found = [];
  for (let i = 0; i < str.length; i++) {
    if (str[i] === "1") {
      let index = i + offset;
      found.push(index < 0 ? index + 7 : index);
    }
  }
  return found.length > 0 ? found : null;
}

export function getCreditCardType(cardNumber = "") {
  if (cardNumber && cardNumber.length > 0) {
    switch (parseInt(cardNumber[0])) {
      // case 3:
      // travel/entertainment cards (such as American Express and Diners Club)
      // return "travel/entertainment";
      case 4:
        return "Visa";
      case 5:
        return "MasterCard";
      case 6:
        return "Discover Card";
      default:
        return null;
    }
  }
  return null;
}

// Check digits of a credit card for errors
export function checkLuhn(ccString) {
  let ccNumber = ccString.replace(/\D/g, "");
  let sum = parseInt(ccNumber[ccNumber.length - 1]);
  let length = ccNumber.length;
  let parity = length % 2;
  for (let i = length - 2; i >= 0; i--) {
    let digit = parseInt(ccNumber[i]);
    if (i % 2 === parity) {
      digit = digit * 2;
    }
    if (digit > 9) {
      digit = digit - 9;
    }
    sum = sum + digit;
  }
  return sum % 10 === 0;
}

export function formatPhoneNumber(phoneNumber) {
  var phoneNumberString = ("" + phoneNumber).replace(/\D/g, "");
  var split = phoneNumberString.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (split) {
    var code = split[1] ? "+1 " : "";
    return [code, "(", split[2], ") ", split[3], "-", split[4]].join("");
  }
  return null;
}
