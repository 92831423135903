import React, { Component } from "react";
import _ from "lodash";

import { Header, Segment, Card, Icon, Button } from "semantic-ui-react";

import PetCard from "../Shared/PetCard";
import PetsTable from "../Tables/Pets";
import PetDetails from "../Forms/PetDetails";
import ErrorBoundary from "./ErrorBoundary";
import Authorize from "./Authorize";
import { OptionsContext } from "../OptionsContext";
import { getPetsByCustomer } from "../lib/apiCalls";
import { toast } from "react-toastify";

/**
 * Displays a view for pets. Allows changing the view from table format to card format
 */
class PetsView extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      selectedPet: null,
      isGridView: true,
      newPet: false,
      loading: true,
      pets: []
    };
  }
  componentDidMount() {
    this._isMounted = true;
    if (this.props.customerId) {
      getPetsByCustomer(
        { customerId: this.props.customerId },
        (error, response) => {
          if (error) {
            toast.error("Failed to retrieve pets");
            this.setState({ loading: false });
            return;
          }
          if (this._isMounted) {
            this.setState({ pets: response, loading: false });
          }
        }
      );
    } else {
      this.setState({ loading: false });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handlePetSelect = pet => {
    this.setState({ selectedPet: pet, newPet: false });
  };
  toggleView = () => {
    this.setState({ isGridView: !this.state.isGridView });
  };
  onUpdate = pet => {
    let updatePetIndex = _.findIndex(this.state.pets, {
      ID: pet.ID
    });

    if (updatePetIndex > -1) {
      // Update an existing pet
      let temp = [...this.state.pets];
      temp.splice(updatePetIndex, 1, pet);
      this.setState({ pets: temp });
    } else {
      // Add pet to array
      this.setState(state => ({
        pets: [...state.pets, pet]
      }));
    }
  };
  onSaveSuccess = pet => {
    this.handlePetSelect(null);
    this.onUpdate(pet);
  };
  render() {
    let { error, customerId, userId } = this.props;
    let { selectedPet, isGridView, newPet, pets, loading } = this.state;
    return (
      <ErrorBoundary>
        <OptionsContext.Consumer>
          {({ breeds }) => (
            <>
              <Header attached="top" as="h2">
                Pets
                <Authorize permission="EditPets">
                  <span>
                    <Button
                      floated="right"
                      icon="paw"
                      content="Add New Pet"
                      onClick={() =>
                        this.setState({ newPet: true, selectedPet: null })
                      }
                    />
                    {/* <Popup
                      inverted
                      trigger={
                        <Button
                          floated="right"
                          onClick={this.toggleView}
                          icon={!isGridView ? "grid layout" : "table"}
                        />
                      }
                      content={
                        !isGridView ? "Swap to Card View" : "Swap to Table View"
                      }
                    /> */}
                  </span>
                </Authorize>
              </Header>
              <Segment
                attached="bottom"
                loading={loading}
                placeholder={loading}
              >
                {isGridView ? (
                  <Card.Group doubling stackable itemsPerRow={4}>
                    {pets &&
                      pets.map(pet =>
                        customerId === pet.customerId ? (
                          <PetCard
                            {...pet}
                            breed={_.find(breeds, { ID: pet.breedId })}
                            key={pet.ID || pet.petName}
                            handleClick={this.handlePetSelect}
                          />
                        ) : null
                      )}
                    {!loading && (!pets || pets.length <= 0) && (
                      <Authorize
                        userRole="Customer"
                        else={
                          <Card
                            link
                            onClick={() =>
                              this.setState({
                                newPet: true,
                                selectedPet: null
                              })
                            }
                          >
                            <Card.Content>
                              <Header
                                as="h2"
                                icon
                                textAlign="center"
                                color="blue"
                              >
                                <Icon name="paw" />
                                <Header.Content>No Pets Yet</Header.Content>
                                <Header.Subheader>
                                  Would you like to add a pet?
                                </Header.Subheader>
                              </Header>
                            </Card.Content>
                          </Card>
                        }
                      >
                        <Card>
                          <Card.Content>
                            <Header
                              as="h2"
                              icon
                              textAlign="center"
                              color="blue"
                            >
                              <Icon name="paw" />
                              <Header.Content>No Pets Yet</Header.Content>
                              <Header.Subheader>
                                Contact Pet Love to Add New Pets!
                              </Header.Subheader>
                            </Header>
                          </Card.Content>
                        </Card>
                      </Authorize>
                    )}
                    {error && (
                      <Card>
                        <Card.Content>
                          <Header as="h2" icon textAlign="center" color="red">
                            <Icon name="exclamation triangle" />
                            <Header.Content>Failed to Load Pets</Header.Content>
                          </Header>
                        </Card.Content>
                      </Card>
                    )}
                  </Card.Group>
                ) : (
                  <PetsTable
                    pets={pets}
                    handlePetSelect={this.handlePetSelect}
                    handleSave={this.handleSave}
                  />
                )}
                {(selectedPet || newPet) && (
                  <Segment color="purple">
                    <PetDetails
                      key={selectedPet && selectedPet.ID}
                      customerId={customerId}
                      userId={userId}
                      pet={{ ...selectedPet, customerId: customerId }}
                      onUpdate={this.onPetUpdate}
                      breeds={breeds}
                      onSaveSuccess={this.onSaveSuccess}
                      handlePetSelect={this.handlePetSelect}
                    />
                  </Segment>
                )}
              </Segment>
            </>
          )}
        </OptionsContext.Consumer>
      </ErrorBoundary>
    );
  }
}

PetsView.defaultProps = {
  customerId: null,
  userId: null
};

export default PetsView;
