import React, { Component } from "react";
import { Container, Image, Segment } from "semantic-ui-react";
import { getTermsOfService } from "../lib/apiCalls";
import { toast } from "react-toastify";

class TermsOfService extends Component {
  componentDidMount() {
    getTermsOfService(null, (error, response) => {
      if (error) {
        console.error(error);
        toast.error("Failed to retrieve Terms of Service");
      } else if (response) {
        let div = document.getElementById("terms-of-service");
        div.innerHTML = response.trim();
      }
    });
  }
  render() {
    return (
      <Container text>
        <Segment basic>
          <Image src="/images/pet_love_logo.png" centered />
        </Segment>
        <div id="terms-of-service"></div>
      </Container>
    );
  }
}

export default TermsOfService;
