import React, { Component } from "react";
import PropTypes from "prop-types";

import ErrorBoundary from "./ErrorBoundary";
import { Card, Icon, Header, Grid } from "semantic-ui-react";

class CardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCard: null
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.addNewCard = this.addNewCard.bind(this);
  }

  /**
   * Sets the details in state to be passed to details view on render
   * @param {object} details
   */
  handleSelect(details, index) {
    this.setState({ selectedCard: { ...details, index: index } });
  }

  /**
   * Clears the selected card to hide the details view
   */
  clearSelected() {
    this.setState({ selectedCard: null });
  }

  /**
   * Sets empty object to state to declare a new card is being added
   */
  addNewCard() {
    this.setState({ selectedCard: {} });
  }

  render() {
    let { selectedCard } = this.state;
    let {
      allCards,
      details,
      display,
      error,
      itemsPerRow,
      icon,
      loading,
      addMessage,
      addMessageExtra,
      showAddCard,
      showErrorCard
    } = this.props;

    // TODO add loading component
    // TODO display details next to selected card
    return (
      <ErrorBoundary>
        {/* List of cards */}
        <Card.Group itemsPerRow={itemsPerRow}>
          {allCards &&
            allCards.map((card, i) =>
              display(card, i, () => this.handleSelect(card, i))
            )}
          {/* Error card */}
          {error && showErrorCard && (
            <Card link onClick={this.addNewCard}>
              <Card.Content>
                <Grid style={{ height: "100%" }} verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h2" icon textAlign="center" color="red">
                        <Icon name="exclamation triangle" />
                        <Header.Content>Failed to Load</Header.Content>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          )}
          {/* Add card */}
          {!loading && showAddCard && (
            <Card link onClick={this.addNewCard}>
              <Card.Content>
                <Grid style={{ height: "100%" }} verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h2" icon textAlign="center" color="blue">
                        <Icon name={icon} />
                        <Header.Content>{addMessage}</Header.Content>
                        <Header.Subheader>{addMessageExtra}</Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          )}
        </Card.Group>
        {/* Details view */}
        {selectedCard && details(selectedCard, this.clearSelected)}
      </ErrorBoundary>
    );
  }
}

CardLayout.defaultProps = {
  allCards: [],
  addMessage: "Add New",
  addMessageExtra: null,
  details: () => null,
  display: () => null,
  icon: "plus",
  itemsPerRow: undefined,
  loading: false,
  showAddCard: true,
  showErrorCard: true
};

CardLayout.propTypes = {
  allCards: PropTypes.array,
  addMessage: PropTypes.string,
  addMessageExtra: PropTypes.string,
  details: PropTypes.func,
  display: PropTypes.func,
  icon: PropTypes.string,
  itemsPerRow: PropTypes.number,
  loading: PropTypes.bool,
  showAddCard: PropTypes.bool,
  showErrorCard: PropTypes.bool
};

export default CardLayout;
