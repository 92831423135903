import React, { Component } from "react";
import {
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Dimmer,
  Loader,
  Responsive
} from "semantic-ui-react";

import { Route, Switch, Link, Redirect } from "react-router-dom";
import Login from "../Forms/Login";
import ForgotPassword from "../Forms/ForgotPassword";
import ResetPassword from "../Forms/ResetPassword";
import { UserContext } from "../UserContext";
import UserSignup from "../Forms/UserSignup";
import ConfirmedEmail from "./ConfirmedEmail";
import ConfirmedAppointment from "./ConfirmedAppointment";
import AppointmentFeedback from "./AppointmentFeedback";

const skipRedirect = [
  "/emailConfirmation",
  "/appointmentConfirmation",
  "/password/reset",
  "/appointmentFeedback",
  "/emailConfirmation"
];

class Entrance extends Component {
  render() {
    const { user, location, loading, match } = this.props;

    if (
      !loading &&
      user &&
      user.userId &&
      !skipRedirect.includes(location.pathname)
    ) {
      return (
        <Redirect
          to={
            location.state && location.state.redirectToAfterLogin
              ? location.state.redirectToAfterLogin
              : "/dashboard"
          }
        />
      );
    } else {
      return (
        <div className="login-form">
          <br />
          {/*
          Heads up! The styles below are necessary for the correct render of this component.
          You can do same with CSS, the main idea is that all the elements up to the `Grid`
          below must have a height of 100%.
        */}
          <style>{`
          body > div,
          body > div > div,
          body > div > div > div.login-form {
            height: 100%;
          }
        `}</style>
          <Grid
            container
            verticalAlign="middle"
            columns={match.path === "/sign-up" ? 1 : 2}
            centered
            stackable
          >
            <Grid.Row>
              <Image src="/images/pet_love_logo.png" />
              <Header as="h1" color="purple" textAlign="center">
                Customer Relations Management System
              </Header>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column stretched>
                {!skipRedirect.includes(location.pathname) && !loading && (
                  <Message color="purple">
                    <Switch>
                      <Route
                        path="/login"
                        component={() => (
                          <div>
                            New to us? <Link to="/sign-up">Sign-up</Link>
                          </div>
                        )}
                      />
                      <Route
                        component={() => (
                          <div>
                            Already have an account?{" "}
                            <Link to="/login">Login</Link>
                          </div>
                        )}
                      />
                    </Switch>

                    <Route
                      path="/login"
                      component={() => (
                        <div>
                          Forgot password?{" "}
                          <Link to="/password/forgot">Reset</Link>
                        </div>
                      )}
                    />
                  </Message>
                )}
                {loading ? (
                  <Route
                    render={() => (
                      <Segment color="purple" padded="very" placeholder>
                        <Dimmer active>
                          <Loader size="large">Loading CRMS</Loader>
                        </Dimmer>
                      </Segment>
                    )}
                  />
                ) : (
                  <UserContext.Consumer>
                    {({ setUser }) => (
                      <Segment color="purple" raised padded="very">
                        <Switch>
                          <Route
                            path="/login"
                            render={() => <Login setUser={setUser} />}
                          />
                          <Route
                            path="/sign-up"
                            render={props => (
                              <UserSignup {...props} setUser={setUser} />
                            )}
                          />
                          <Route
                            path="/password/reset"
                            render={props => (
                              <ResetPassword {...props} setUser={setUser} />
                            )}
                          />
                          <Route
                            path="/password/forgot"
                            component={ForgotPassword}
                          />
                          <Route
                            path="/emailConfirmation"
                            render={props => (
                              <ConfirmedEmail {...props} setUser={setUser} />
                            )}
                          />
                          <Route
                            path="/appointmentConfirmation"
                            component={ConfirmedAppointment}
                          />
                          <Route
                            path="/appointmentFeedback"
                            render={props => (
                              <AppointmentFeedback
                                {...props}
                                setUser={setUser}
                              />
                            )}
                          />
                          <Route component={Default} />
                        </Switch>
                      </Segment>
                    )}
                  </UserContext.Consumer>
                )}
              </Grid.Column>
              {match.path !== "/sign-up" && (
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                  <Grid.Column>
                    <Segment basic>
                      <Image
                        size="medium"
                        src="/images/PETE_waveright.png"
                        centered
                      />
                    </Segment>
                  </Grid.Column>
                </Responsive>
              )}
            </Grid.Row>
          </Grid>
        </div>
      );
    }
  }
}

const Default = ({ match }) => {
  return <Redirect to={"/login"} />;
};
export default Entrance;
