import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Header,
  Icon,
  Popup,
  Button,
  Modal,
  Divider,
  Menu
} from "semantic-ui-react";
import { getBreeds, updateBreed, createBreed } from "../../lib/apiCalls";
import { toast } from "react-toastify";
import Money from "../Money";
import { OptionsContext } from "../../OptionsContext";
import _ from "lodash";
import BreedForm from "../../Forms/BreedForm";

class BreedSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breeds: [],
      loading: true,
      openNewBreedModal: false,
      newBreed: {}
    };
    this.getBreeds = this.getBreeds.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }
  componentDidMount() {
    this.getBreeds();
  }
  handleSave(data) {
    if (!data.breed || !data.animalTypeId || !data.minutesToGroom) {
      toast.info("Please fill in the required fields");
      return;
    }
    updateBreed(data, (error, response) => {
      if (error) {
        toast.error("Failed to update breed");
        return;
      }
      let updateBreedIndex = _.findIndex(this.state.breeds, { ID: data.ID });
      let temp = [...this.state.breeds];
      temp.splice(updateBreedIndex, 1, data);
      this.setState({ breeds: temp });

      toast.success("Successfully updated breed");
    });
  }
  handleCreate() {
    let { newBreed } = this.state;
    if (!newBreed.breed || !newBreed.animalTypeId || !newBreed.minutesToGroom) {
      toast.info("Please fill in the required fields");
      return;
    }
    createBreed(newBreed, (error, response) => {
      if (error) {
        toast.error("Failed to create breed");
        return;
      }
      toast.success("Successfully create breed");
      this.setState({
        breeds: [...this.state.breeds, { ...newBreed, ID: response.ID }]
      });
      this.closeNewBreedModal();
    });
  }
  getBreeds() {
    getBreeds(null, (error, response) => {
      if (error) {
        toast.error("Failed to retrieve breeds");
        this.setState({ loading: false });
      } else {
        this.setState({ breeds: response, loading: false });
      }
    });
  }
  openNewBreedModal = () => {
    this.setState({ openNewBreedModal: true });
  };
  closeNewBreedModal = () => {
    this.setState({ openNewBreedModal: false, newBreed: {} });
  };
  updateNewBreed = data => {
    this.setState({ newBreed: data });
  };
  render() {
    let { loading, breeds, openNewBreedModal } = this.state;
    return (
      <OptionsContext.Consumer>
        {({ animalTypes }) => {
          return (
            <div>
              <Menu text stackable className="responsive-header">
                <Menu.Item>
                  <Header as="h2">Breeds</Header>
                </Menu.Item>
                <Menu.Menu position="right">
                  <Menu.Item>
                    <Modal
                      open={openNewBreedModal}
                      trigger={
                        <Button
                          fluid
                          color="blue"
                          content="Add Breed"
                          onClick={this.openNewBreedModal}
                        />
                      }
                    >
                      <Modal.Header>New Breed</Modal.Header>
                      <Modal.Content>
                        <BreedForm
                          animalTypes={animalTypes}
                          onUpdate={this.updateNewBreed}
                          hideSaveButton
                        />
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          content="Create"
                          color="green"
                          onClick={this.handleCreate}
                        />
                        <Button
                          content="Cancel"
                          onClick={this.closeNewBreedModal}
                        />
                      </Modal.Actions>
                    </Modal>
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              <Divider hidden fitted clearing />
              <ReactTable
                loading={loading}
                data={breeds}
                minRows={10}
                pageSize={breeds.length}
                style={{
                  height: window.innerHeight - 175 // This will force the table body to overflow and scroll, since there is not enough room
                }}
                columns={[
                  {
                    Header: (
                      <Popup
                        trigger={<Icon name="paw" />}
                        content="Animal Type"
                      ></Popup>
                    ),
                    accessor: "animalTypeId",
                    Cell: props => {
                      let animalType = _.find(animalTypes, { ID: props.value });
                      if (animalType) {
                        animalType = animalType.type.toLowerCase();
                      }
                      return <i className={"fas fa-" + animalType} />;
                    },
                    className: "centered",
                    minWidth: 50
                  },
                  ...columns
                ]}
                showPagination={false}
                className="-striped -highlight"
                SubComponent={row => {
                  return (
                    <div>
                      <BreedForm
                        animalTypes={animalTypes}
                        breed={row.original}
                        handleSave={this.handleSave}
                      />
                    </div>
                  );
                }}
              />
            </div>
          );
        }}
      </OptionsContext.Consumer>
    );
  }
}

export default BreedSettings;

const columns = [
  {
    Header: "Breed",
    accessor: "breed"
  },
  {
    Header: "Minutes to Groom",
    accessor: "minutesToGroom",
    className: "money-column"
  },
  {
    Header: "B&B Price",
    accessor: "bathBrushPrice",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "B&B Price (3+)",
    accessor: "bathBrushPrice3Months",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "B&B Price (6+)",
    accessor: "bathBrushPrice6Months",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Deluxe Price",
    accessor: "deluxePrice",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Deluxe Price (3+)",
    accessor: "deluxePrice3Months",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  },
  {
    Header: "Deluxe Price (6+)",
    accessor: "deluxePrice6Months",
    className: "money-column",
    Cell: props => <Money value={props.value} />
  }
];
