import React, { Component } from "react";

import { toast } from "react-toastify";
import Axios from "axios";
import { defaultEstimate } from "../lib/defaultObjects";
import EstimateConfirmation from "../Shared/EstimateConfirmation";
import { Segment } from "semantic-ui-react";

import _ from "lodash";
import { OptionsContext } from "../OptionsContext";

class CustomerEstimate extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      estimate: {}
    };
    this.getEstimate = this.getEstimate.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getEstimate();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  async getEstimate() {
    if (this.props.match && this.props.match.params.id) {
      this.setState({ loading: true });
      try {
        let response = await Axios.get("/api/v4/estimate/findById", {
          params: { estimateId: this.props.match.params.id }
        });

        if (this._isMounted) {
          if (response.status === 200) {
            let estimate = response.data.estimate;
            // Convert the estimate pet extra to services
            if (estimate.EstimatePets) {
              for (let estimatePet in estimate.EstimatePets) {
                let services = {};
                for (let extra of estimate.EstimatePets[estimatePet]
                  .EstimatePetExtras) {
                  let foundService = _.find(this.props.services, {
                    ID: extra.EstimateExtraID
                  });
                  if (foundService) {
                    services[foundService.item] = foundService;
                  }
                }
                estimate.EstimatePets[estimatePet] = {
                  ...estimate.EstimatePets[estimatePet],
                  services: services
                };
              }
            }

            this.setState({
              loading: false,
              estimate: defaultEstimate(estimate),
              user: response.data.user
            });
          }
        }
      } catch (error) {
        if (this._isMounted) {
          console.error(error);
          this.setState({ loading: false });
          toast.error("Failed to get estimate.");
        }
      }
    }
  }
  render() {
    let { estimate, loading } = this.state;
    let totals = {
      total: estimate.totalFee,
      service: estimate.serviceFee,
      trip: estimate.tripFee,
      generator: estimate.generatorFee,
      fuel: estimate.fuelFee,
      discounts: estimate.discounts,
      tax: estimate.salesTaxFee
    };
    return (
      <Segment loading={loading}>
        <EstimateConfirmation
          user={
            estimate && estimate.Customer
              ? {
                  ID: estimate.Customer.User.ID,
                  Name: {
                    firstName: estimate.Customer.User.firstName,
                    lastName: estimate.Customer.User.lastName
                  }
                }
              : {}
          }
          estimate={estimate}
          petEstimates={estimate.PetEstimates}
          totals={totals}
        />
      </Segment>
    );
  }
}

CustomerEstimate.defaultProps = {
  services: []
};

export default props => (
  <OptionsContext.Consumer>
    {({ services }) => <CustomerEstimate {...props} services={services} />}
  </OptionsContext.Consumer>
);
