import React, { Component } from "react";
import PropTypes from "prop-types";

import { Modal, Icon, Button } from "semantic-ui-react";

/**
 * A modal for canceling navigation with unsaved changes
 */
class UnsavedChangesConfirmationModal extends Component {
  render() {
    return (
      <>
        <Modal open={this.props.open} closeOnDimmerClick={false}>
          <Modal.Header
            content={
              <>
                <Icon name="warning circle" />
                Warning: Unsaved Changes
              </>
            }
          />
          <Modal.Content>
            {this.props.message !== ""
              ? this.props.message
              : "Are you sure you want to continue without saving your changes?"}
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon="left chevron"
              content="Back"
              onClick={() => this.props.onConfirm(false)}
            />
            <Button
              content="Continue without Saving"
              negative
              onClick={() => this.props.onConfirm(true)}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

UnsavedChangesConfirmationModal.defaultProps = {
  open: false,
  message: "",
  onConfirm: () => {}
};

UnsavedChangesConfirmationModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onConfirm: PropTypes.func
};
export default UnsavedChangesConfirmationModal;
