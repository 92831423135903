import React, { Component } from "react";
import { Modal, Button, Icon, Message, Divider } from "semantic-ui-react";
import { getCustomerPolicyStatus } from "../lib/apiCalls";
import { toast } from "react-toastify";
import AcceptPoliciesModal from "../Forms/AcceptPoliciesModal";

/**
 * Displays a button that triggers a modal to appear requesting confirmation of confirming an estimate with a customer
 */
class EstimateCustomerConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = { acceptedPolicies: false };
  }
  componentDidMount() {
    this.getCustomerPolicyStatus();
  }
  getCustomerPolicyStatus = () => {
    if (this.props.userId) {
      getCustomerPolicyStatus(
        { userId: this.props.userId },
        (error, response) => {
          if (error) {
            toast.error("Failed to retrieve customer policy status");
            return;
          }
          this.setState({ acceptedPolicies: response.acceptedPolicies });
        }
      );
    }
  };
  openPolicyModal = () => {
    this.setState({ openPolicyModal: true });
  };
  closePolicyModal = () => {
    this.setState({ openPolicyModal: false, acceptedPolicies: true });
  };
  render() {
    return (
      <Modal
        trigger={
          <Button
            fluid
            content="Save and Start Appointment"
            color="green"
            disabled={this.props.loading}
          />
        }
        closeIcon
      >
        <Modal.Header>
          <Icon name="exclamation" />
          Important
        </Modal.Header>
        <Modal.Content>
          {this.state.openPolicyModal && (
            <AcceptPoliciesModal
              userId={this.props.userId}
              open={this.state.openPolicyModal}
              onAcceptedPolicies={this.closePolicyModal}
            />
          )}
          Have you confirmed with the customer the details of the estimate?
          <Divider hidden />
          {this.state.acceptedPolicies !== true && (
            <Message warning>
              <Message.Header>Customer Has Unsigned Policies</Message.Header>
              <Divider hidden />
              <Button
                color="green"
                onClick={this.openPolicyModal}
                content="Allow Customer to Sign Policies"
              />
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.props.handleStartAppointment}>
            I have confirmed details with customer
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

EstimateCustomerConfirmationModal.defaultProps = {
  handleStartAppointment: () => {},
  loading: false
};

export default EstimateCustomerConfirmationModal;
