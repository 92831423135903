import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  Icon,
  Message,
  Grid,
  Header,
  Segment,
  Divider
} from "semantic-ui-react";

import UserCreate from "../Forms/UserCreate";
import CustomerTable from "../Tables/CustomerTable";
import _ from "lodash";
import Axios from "axios";
import { getToken } from "../lib/csrfToken";
import { toast } from "react-toastify";

/**
 * Displays a modal that handles functionality for changing a pet's owner
 * @prop {number} petID ID of the pet to update the customer who own's the pet
 * @prop {boolean} isEditing  Determines if the button to trigger the modal opening is disabled or not
 * @prop {function} handleSuccess Function to be called when a success is returned for changing the pet's owner. Passes the new owner's ID and the pet's ID back
 */
class ChangePetOwnerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: null,
      user: {},
      open: false,
      loading: false
    };
  }
  handleUserChange = user => {
    this.setState({ user: user });
  };
  handleSubmit = async () => {
    let { user } = this.state;
    if (this.props.petID == null) {
      toast.error("A pet is required to change the pet's owner");
      return;
    }
    this.setState({ loading: true });
    try {
      const response = await Axios.post(
        `/api/v4/pets/updateOwner`,
        {
          newCustomer: {
            Address: user.Address,
            ...user.Name,
            emailAddress: user.emailAddress,
            phoneNumber: user.phoneNumber,
            isAccount: user.isAccount
          },
          petID: this.props.petID,
          customerID: this.state.customerId
        },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      // Check if response was a success
      if (response.status === 200) {
        this.setState({
          open: false,
          loading: false
        });
        toast.success("Successfully changed the pet's owner.");
        this.props.handleSuccess(response.data.customerID);
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      toast.error("Failed to change the pet's owner.");
      console.error(error);
      this.setState({ loading: false });
    }
  };
  render() {
    let { view, customerId, user, open, loading } = this.state;
    return (
      <Modal
        closeOnDimmerClick={false}
        open={open}
        trigger={
          <Button
            disabled={!this.props.isEditing}
            content="Change Pet Owner"
            name="ChangePetOwner"
            onClick={() => this.setState({ open: true })}
          />
        }
      >
        <Modal.Header>
          <Icon.Group>
            <Icon name="user" />
            <Icon corner name="edit" />
          </Icon.Group>
          Change Pet Owner
        </Modal.Header>
        <Modal.Content>
          {view === "newCustomer" && (
            <>
              <Button
                icon="chevron left"
                labelPosition="left"
                color="blue"
                onClick={() => this.setState({ view: null })}
                content="Back"
              />
              <br />
              <br />
              <UserCreate hideCreateButton onChange={this.handleUserChange} />
            </>
          )}
          {view === "selectNewOwner" && (
            <>
              <Button
                icon="chevron left"
                labelPosition="left"
                color="blue"
                onClick={() => this.setState({ view: null })}
                content="Back"
              />
              <br />
              <br />
              <CustomerTable
                onRowClick={(state, rowInfo) => {
                  if (rowInfo.original && rowInfo.original.Customer)
                    this.setState({ customerId: rowInfo.original.Customer.ID });
                }}
              />
            </>
          )}

          {view == null && (
            <Segment basic>
              <Grid columns={2} stackable textAlign="center">
                <Divider vertical>Or</Divider>
                <Grid.Row>
                  <Grid.Column>
                    <Header icon>
                      <Icon name="search" />
                      Search for an Existing Customer
                    </Header>
                    <br />
                    <Button
                      fluid
                      color="purple"
                      onClick={() => this.setState({ view: "selectNewOwner" })}
                    >
                      Search
                    </Button>
                  </Grid.Column>{" "}
                  <Grid.Column>
                    <Header icon>
                      <Icon name="plus" />
                      Create a New Customer
                    </Header>
                    <br />
                    <Button
                      color="purple"
                      fluid
                      onClick={() => this.setState({ view: "newCustomer" })}
                    >
                      Create
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          )}

          <Message
            warning
            header="Warning"
            content="This will immediately change the pet owner and remove it
    from the current customer's profile!"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => this.setState({ open: false })}
            content="Cancel"
            icon="delete"
          />
          <Button
            disabled={customerId == null && _.isEqual(user, {})}
            color="green"
            loading={loading}
            onClick={this.handleSubmit}
            content={
              view === "newCustomer"
                ? "Create Customer and Change Pet's Owner"
                : "Change Pet's Owner"
            }
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ChangePetOwnerModal.defaultProps = {
  petID: null,
  isEditing: false,
  handleSuccess: () => {}
};

ChangePetOwnerModal.propTypes = {
  petID: PropTypes.number,
  isEditing: PropTypes.bool,
  handleSuccess: PropTypes.func
};

export default ChangePetOwnerModal;
