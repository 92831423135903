import React, { Component } from "react";
import Authorize from "../Shared/Authorize";
import ErrorBoundary from "../Shared/ErrorBoundary";

import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Header, Segment, Button } from "semantic-ui-react";

import CustomerTable from "../Tables/CustomerTable";
import UserCreate from "../Forms/UserCreate";
import CustomerPage from "../Shared/CustomerPage";

/**
 * Views table with all users and a user form that displays data for a selected user
 */
class Customers extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route
          path={`${match.path}/`}
          exact
          render={props => (
            <Header as="h2">
              Customers
              <Button
                floated="right"
                content="Create New Customer"
                color="blue"
                as={Link}
                to={`${match.path}/new`}
              />
            </Header>
          )}
        />
        <Switch>
          <Route path={`${match.path}/`} exact component={CustomerTable} />
          <Route
            path={`${match.path}/new`}
            render={props => (
              <Segment>
                <UserCreate {...props} signUp backPath={`${match.path}/`} />
              </Segment>
            )}
          />
          <Route
            path={`${match.path}/:userId(\\d+)`}
            component={CustomerPage}
          />
          <Redirect to={`${match.path}/`} />
        </Switch>
      </div>
    );
  }
}
export default props => (
  <Authorize permission="ViewCustomers">
    <ErrorBoundary>
      <Customers {...props} />
    </ErrorBoundary>
  </Authorize>
);
