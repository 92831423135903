import React, { Component } from "react";
import { Segment, Container } from "semantic-ui-react";

import ErrorBoundary from "../Shared/ErrorBoundary";
import Authorize from "../Shared/Authorize";

import { Route, Switch, Redirect } from "react-router-dom";
import CustomerProfile from "../Shared/CustomerProfile";
import { UserContext } from "../UserContext";

class Profile extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Authorize userRole="Customer">
          <UserContext.Consumer>
            {({ user }) => {
              return (
                <Container>
                  <Segment basic style={{ minHeight: "50em" }}>
                    <Switch>
                      <Route component={CustomerProfile} />
                      <Redirect to={"/dashboard/profile/" + user.userId} />
                    </Switch>
                  </Segment>
                </Container>
              );
            }}
          </UserContext.Consumer>
        </Authorize>
      </ErrorBoundary>
    );
  }
}

Profile.defaultProps = {
  showHeader: true
};

export default props => (
  <Authorize permission="ViewProfile">
    <Profile {...props} />
  </Authorize>
);
