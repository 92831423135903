import { checkLuhn } from "./helperFunctions";
let moment = require("moment");

/**
 * Checks if the value is empty
 * @param {any} value Value to check
 */
export function isEmpty(value) {
  if (value === "" || value === null || value === undefined) return true;
  return false;
}

export function validateMapsco(value) {
  var regex = /^\d{3}\w?-\w{1}$/g;
  return regex.test(value);
}

/**
 * Checks the value for an email format
 * @param {any} value Value to check
 */
export function validateEmail(value) {
  var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(value).toLowerCase());
}

/**
 * Checks the value for a basic phone format
 * @param {any} value Value to check
 */
export function validatePhone(value) {
  var regex = /^([0-9]( |-|.)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-|.)?([0-9]{3}( |-|.)?[0-9]{4}|[a-zA-Z0-9]{7})$/;
  return regex.test(value);
}

/**
 * Checks the value for an integer
 * @param {any} value Value to check
 */
export function validateInteger(value) {
  var regex = /^[-0-9]*$/g;
  return regex.test(value);
}

/**
 * Checks the value to make sure its at least 10 characters in length
 * @param {any} value Value to check
 */
export function validatePassword(value) {
  return value.length >= 10;
}

/**
 * Checks the value for a decimal
 * @param {any} value Value to check
 */
export function validateDecimal(value) {
  // /^(\d+\.?\d{0,9}|\.\d{1,9})$/    Decimal with limit
  var regex = /^(\d+\.?\d*|\.\d+)$/;
  return regex.test(value);
}

/**
 * Checks the value for a valid date format using moment
 * @param {any} value
 */
export function validateDate(value) {
  const date = moment(value);
  return date.isValid();
}

/**
 * Checks the value for a valid ZIP Code
 * @param {any} value
 */
export function validateZipCode(value) {
  let regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return regex.test(value);
}
/**
 * Checks the value for a money format
 * @param {any} value
 */
export function validateMoney(value) {
  let regex = /^[0-9]+(\.[0-9]{1,2})?$/gm;
  return regex.test(value);
}

/**
 * Checks the value is positive
 * @param {any} value
 */
export function validatePositive(value) {
  return value >= 0;
}

export function validateCreditCard(value) {
  var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  var amexpRegEx = /^(?:3[47][0-9]{13})$/;
  var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

  var isValid = false;

  if (visaRegEx.test(value)) {
    isValid = true;
  } else if (mastercardRegEx.test(value)) {
    isValid = true;
  } else if (amexpRegEx.test(value)) {
    isValid = true;
  } else if (discovRegEx.test(value)) {
    isValid = true;
  }
  if (!isValid) return false;

  return checkLuhn(value) && value.length > 13 && value.length < 17;
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function checkIfValid(validation, value) {
  // Check the type if it passes the required check
  switch (validation) {
    case validation instanceof RegExp:
      return validation.test(value);
    case "mapsco":
      return validateMapsco(value);
    case "email":
      return validateEmail(value);
    case "phone":
      return validatePhone(value);
    case "date":
      return validateDate(value);
    case "integer":
      return validateInteger(value);
    case "zipcode":
      return validateZipCode(value);
    case "positive":
      return validatePositive(value);
    case "money":
      return validateMoney(value);
    case "password":
      return validatePassword(value);
    case "creditCard":
      return validateCreditCard(value);
    default:
      return;
  }
}
