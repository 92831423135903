import React, { Component } from "react";
import {
  Form,
  Label,
  Grid,
  Button,
  Popup,
  Select,
  Modal
} from "semantic-ui-react";
import CommentGroup from "../Shared/CommentGroup";
import Input from "../Shared/Input";
import Authorize from "../Shared/Authorize";
import moment from "moment";
import { OptionsContext } from "../OptionsContext";
import _ from "lodash";
import { defaultAppointment } from "../lib/defaultObjects";

import { getStatusColor, getDuration } from "../lib/helperFunctions";

/**
 * Displays details for an appointment's schedule and comments for that appointment
 */
class AppointmentStandingDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultAppointment(props)
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e, { name, value }) {
    this.setState({ [name]: value });
  }
  componentDidUpdate(prevProps) {
    // When the appointment details have changed, update the state
    // This updates the UI when needed but still allows overriding the values in state
    let appointment = defaultAppointment(this.props);
    if (!_.isEqual(appointment, defaultAppointment(prevProps))) {
      this.setState(appointment);
    }
  }
  resolveConflictCheck = () => {
    this.setState({ openModal: true });
  };
  handleResolve = () => {
    this.props.onSave({ ...this.state, resolveConfliction: true });
    this.setState({ openModal: false });
  };
  handleKeep = () => {
    this.props.onSave(this.state);
    this.setState({ openModal: false });
  };
  canServiceAllPets = (stylist, pets) => {
    let canServiceAllPets = true;

    for (let i = 0; i < pets.length; i++) {
      if (!canServiceAllPets) break;
      let pet = pets[i];
      // Check if the pet's weight exceeds the stylist's max weight
      if (
        stylist.maxPetWeight &&
        pet.weight != null &&
        stylist.maxPetWeight &&
        stylist.maxPetWeight < pet.weight
      ) {
        canServiceAllPets = false;
      }
      // Check if the stylist can service cats
      if (
        pet.Breed &&
        pet.Breed.AnimalType &&
        pet.Breed.AnimalType.type.toLowerCase() === "cat" &&
        stylist.servicesCats !== true
      ) {
        canServiceAllPets = false;
      }

      // Check if the stylist can service the pet's breed
      if (
        pet.Breed &&
        stylist.Breeds &&
        _.find(stylist.Breeds, { ID: pet.Breed.ID })
      ) {
        canServiceAllPets = false;
      }
    }

    return canServiceAllPets;
  };
  handleOverride = (e, data) => {
    this.setState({ forceEmployeeId: data.value });
  };
  handleCancelForce = () => {
    this.setState({
      forceEmployeeId: null
    });
  };
  handleForceEmployeeId = () => {
    this.setState({
      employeeId: this.state.forceEmployeeId,
      forceEmployeeId: null
    });
  };
  getActionButtons = originalStatus => {
    let actions = [];
    let { onDelete, onSave, onCreate } = this.props;
    if (originalStatus.status === "standingRequest") {
      actions.push(
        <Popup
          key="save"
          content="Input recurrence information."
          inverted
          trigger={
            <Button
              basic
              icon="clock"
              onClick={() => onCreate(this.state)}
              color="blue"
            />
          }
        />
      );
    } else {
      actions.push(
        <Popup
          key="save"
          content="Save the details of the standing appointment."
          inverted
          trigger={
            <Button
              basic
              icon="save"
              onClick={
                originalStatus.status !== "conflicting"
                  ? () => onSave(this.state)
                  : this.resolveConflictCheck
              }
              color="green"
            />
          }
        />
      );
    }
    actions.push(
      <Popup
        key="delete"
        content="Delete the standing appointment."
        inverted
        trigger={
          <Button
            basic
            icon="trash"
            onClick={() => onDelete(this.state)}
            color="red"
          />
        }
      />
    );
    return actions;
  };
  render() {
    let {
      isEditing,
      stylistOptions,
      blockingComplaintOptions,
      statusOptions,
      columns,
      pets,
      Status
    } = this.props;
    let {
      ID,
      employeeId,
      startTime,
      endTime,
      statusId,
      commentGroupId,
      openModal
    } = this.state;

    let duration = getDuration(startTime, endTime);
    if (!duration) {
      duration = this.props.duration;
    }

    // Find stylist in the options
    let selectedStylist = _.find(stylistOptions, { ID: employeeId });
    if (!selectedStylist) {
      selectedStylist = {};
    }

    // Find the status in the options
    let selectedStatus = _.find(statusOptions, { ID: statusId });
    if (!selectedStatus) {
      selectedStatus = {};
    }
    let originalStatus = Status ? _.find(statusOptions, { ID: Status.ID }) : {};
    let statusColor = getStatusColor(selectedStatus.status);

    let statuses = statusOptions.map(status => ({
      text: _.startCase(status.status),
      key: status.ID,
      value: status.ID
    }));
    let filteredStylists = stylistOptions
      .filter(
        //Filter out the stylists that have a complaint connection between the two
       stylist => {
          return (
            stylist.ID === this.props.employeeId ||
            _.find(blockingComplaintOptions, {
              employeeId: stylist.ID,
              customerId: this.props.customerId
            }) == null
         );
        }
      )
      .map(stylist => {
        let canServiceAllPets = true;

        // Filter the stylist based on the restrictions
        if (pets) {
          canServiceAllPets = this.canServiceAllPets(stylist, pets);
        }
        let option = {
          value: stylist.ID,
          text: `${stylist.User.firstName} ${stylist.User.lastName}`,
          key: stylist.ID
        };
        // if (!worksOnDay || !canServiceAllPets) {
        if (!canServiceAllPets) {
          option.disabled = true;
          option.onClick = this.handleOverride;
        }
        return option;
      });

    return (
      <Grid stackable columns={columns}>
        <Modal
          open={openModal}
          onClose={() => {
            this.setState({ openModal: false });
          }}
          closeIcon
          header="Warning!"
          content="This appointment has a conflicting status. Would you like to resolve this appointment and set to scheduled?"
          actions={[
            {
              key: "keepConflicting",
              content: "Keep Confliction and Save",
              color: "red",
              onClick: this.handleKeep
            },
            {
              key: "done",
              color: "green",
              content: "Resolve and Schedule",
              onClick: this.handleResolve
            }
          ]}
        />
        {selectedStylist.dateTerminated &&
          moment(selectedStylist.dateTerminated).isBefore(moment()) && (
            <Label content="Stylist No Longer Working" color="red" />
          )}
        <Modal
          open={this.state.forceEmployeeId != null}
          header="Warning!"
          content="This stylist fails the requirements to service this appointment. Are you sure you want to select this stylist?"
          actions={[
            {
              content: "Cancel",
              key: "cancel",
              onClick: this.handleCancelForce
            },
            {
              key: "done",
              content: "Select Stylist",
              color: "red",
              onClick: this.handleForceEmployeeId
            }
          ]}
        />
        <Grid.Column>
          <Form size="small">
            {/* Show the status of an existing appointment */}
            {selectedStatus.status && (
              <Authorize
                permission="EditAppointmentStatus"
                else={
                  <Label
                    content={_.startCase(selectedStatus.status)}
                    color={statusColor}
                    basic
                  />
                }
              >
                <Form.Field>
                  <label>Status</label>
                  {isEditing === false ? (
                    <Input
                      value={_.startCase(selectedStatus.status)}
                      isEditing={false}
                    />
                  ) : (
                    <Select
                      options={statuses}
                      value={statusId}
                      onChange={this.onChange}
                      name="statusId"
                    />
                  )}
                </Form.Field>
              </Authorize>
            )}
            <Form.Select
              clearable
              options={filteredStylists}
              label="Stylist"
              search
              name="employeeId"
              value={employeeId}
              onChange={this.onChange}
              placeholder="Select a Stylist"
            />
            <Form.Group widths="2">
              <Input
                validation="time"
                label="Start Time"
                name="startTime"
                required
                isEditing={isEditing}
                value={startTime}
                onChange={this.onChange}
                placeholder="Starting Time"
              />
              <Input
                label="End Time"
                placeholder="Ending Time"
                validation="time"
                isEditing={isEditing}
                name="endTime"
                value={endTime}
                onChange={this.onChange}
              />
            </Form.Group>
            <Form.Group widths="1">
              <Form.Field>
                <label>Duration</label>
                <div>{endTime ? duration + " minutes" : "N/A"}</div>
              </Form.Field>
            </Form.Group>
          </Form>
          <Authorize permission="EditAppointments">
            <Button.Group fluid>
              {this.getActionButtons(originalStatus)}
            </Button.Group>
          </Authorize>
        </Grid.Column>
        {ID != null && (
          <Grid.Column>
            <CommentGroup
              key={commentGroupId}
              maxHeight="100px"
              commentGroupId={commentGroupId}
            />
          </Grid.Column>
        )}
      </Grid>
    );
  }
}
AppointmentStandingDetails.defaultProps = {
  startTime: null,
  endTime: null,
  duration: null,
  isConflicting: false,
  commentGroupId: null,
  statusId: null,
  isEditing: true,
  stylistOptions: [],
  statusOptions: [],
  onSave: () => {},
  onCreate: () => {},
  onDelete: () => {},
  pets: null,
  columns: 2
};

export default props => (
  <Authorize permission="EditStandingAppointments">
    <OptionsContext.Consumer>
      {({ stylists, blockingComplaints, statuses }) => {
        return (
          <AppointmentStandingDetails
            {...props}
            stylistOptions={stylists}
            blockingComplaintOptions={blockingComplaints}
            statusOptions={statuses}
          />
        );
      }}
    </OptionsContext.Consumer>
  </Authorize>
);
