import React, { Component } from "react";

import { Button, Form, Header, Message } from "semantic-ui-react";
import Input from "../Shared/Input";
import Axios from "axios";
import { getToken } from "../lib/csrfToken";

class ForgotPassword extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: ""
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, message: null });
  handleReset = async () => {
    const { emailAddress } = this.state;
    if (emailAddress === "") {
      this.setState({ message: "Please insert an email." });
      return;
    }
    try {
      let response = await Axios.post(
        "/api/v4/entrance/send-password-recovery-email",
        { emailAddress: emailAddress },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      if (this._isMounted) {
        if (response.status === 200) {
          this.setState({
            message:
              "If the email address matched a login, and email has been sent to that address.",
            isPositive: true
          });
        }
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({
          message: "An error occured trying to send the recovery email.",
          isPositive: false
        });
      }
    }
  };
  render() {
    const { isPositive, message, emailAddress } = this.state;
    return (
      <Form>
        <Header as="h2" color="purple" textAlign="center">
          Password Recovery
        </Header>
        <Input
          placeholder="Email address"
          name="emailAddress"
          type="email"
          isEditing
          value={emailAddress}
          onChange={this.handleChange}
        />
        {message && (
          <Message
            positive={isPositive}
            negative={!isPositive}
            content={message}
          />
        )}
        <Button
          fluid
          color="purple"
          size="large"
          content="Send Email"
          onClick={this.handleReset}
        />
      </Form>
    );
  }
}

export default ForgotPassword;
