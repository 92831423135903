import React, { Component } from "react";

import { Button, Form, Header } from "semantic-ui-react";

import Notification from "../Shared/Notification";
import Input from "../Shared/Input";

import io from "../socketConnection";
import Axios from "axios";

class Login extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: "",
      password: ""
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleLogin = async () => {
    const { notificationMessage, loading, ...rest } = this.state;
    if (!rest.emailAddress || !rest.password) {
      this.setState({
        notificationMessage: "Must enter email and password."
      });
      return;
    }
    this.setState({ loading: true });

    // Use websockets to login so the session is synced to websockets
    let csrf = await Axios.get("/csrfToken", {
      xsrfHeaderName: "X-CSRF-Token"
    });
    if (csrf.error) {
      console.error("Failed to retrieve CSRF Token");
      return;
    }

    io.socket.request(
      {
        method: "put",
        url: "/api/v4/entrance/login",
        data: rest,
        headers: { "X-CSRF-Token": csrf.data._csrf }
      },
      (resData, jwres) => {
        if (this._isMounted) {
          if (jwres.statusCode === 200) {
            this.props.setUser({ ...resData });
          } else {
            this.setState({
              notificationMessage: "Invalid email or password.",
              password: "",
              loading: false
            });
          }
        }
      }
    );
  };

  render() {
    let { password, emailAddress, loading } = this.state;

    return (
      <Form size="large">
        <Header as="h2" color="purple" textAlign="center">
          Log-in to your account
        </Header>
        <Input
          value={emailAddress}
          fluid
          id="login-email"
          name="emailAddress"
          icon="user"
          isEditing
          iconPosition="left"
          label="Email Address"
          onChange={this.handleChange}
        />
        <Input
          fluid
          value={password}
          id="login-password"
          name="password"
          icon="lock"
          isEditing
          iconPosition="left"
          label="Password"
          type="password"
          onChange={this.handleChange}
        />
        <Button
          color="purple"
          fluid
          id="login-button"
          size="large"
          content="Login"
          onClick={this.handleLogin}
          loading={loading}
          disabled={loading}
        />
        {this.state.notificationMessage && (
          <Notification error content={this.state.notificationMessage} />
        )}
      </Form>
    );
  }
}

export default Login;
