import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";
import _ from "lodash";

/**
 * Show group of buttons with or and hides unselected values when not editing
 * @param {object} props
 * @prop {boolean} isEditing
 * @prop {array[objects]} buttons
 * @prop {string} noActiveMessage Message to be displayed on a button when no button is stated as active
 */
function OrButtons(props) {
  // Display all buttons and ors when editing
  if (props.isEditing) {
    return (
      <Button.Group fluid={props.fluid}>
        {props.buttons.map((buttonProps, i) => {
          if (i < props.buttons.length - 1) {
            return (
              <React.Fragment key={buttonProps.content || i}>
                <Button type="button" {...buttonProps} />
                <Button.Or />
              </React.Fragment>
            );
          }
          return (
            <Button
              type="button"
              {...buttonProps}
              key={buttonProps.content || i}
            />
          );
        })}
      </Button.Group>
    );
  } else {
    let activeButton = _.find(props.buttons, { active: true });
    if (activeButton) {
      // Display only the selected button when not editing
      return (
        <Button.Group fluid={props.fluid}>
          <Button type="button" {...activeButton} />
        </Button.Group>
      );
    } else {
      return (
        <Button.Group fluid={props.fluid}>
          <Button type="button" content={props.noActiveMessage} disabled />
        </Button.Group>
      );
    }
  }
}

OrButtons.defaultProps = {
  isEditing: false,
  fluid: false,
  buttons: [],
  noActiveMessage: "Unknown"
};

OrButtons.propTypes = {
  isEditing: PropTypes.bool,
  fluid: PropTypes.bool,
  buttons: PropTypes.array,
  noActiveMessage: PropTypes.string
};

export default OrButtons;
