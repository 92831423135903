import React, { Component } from "react";
import { Segment, Form } from "semantic-ui-react";

import Input from "../Shared/Input";
import { defaultBreed } from "../lib/defaultObjects";
import _ from "lodash";
import OrButtons from "../Shared/OrButtons";

class BreedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultBreed(props.breed)
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state, prevState)) {
      this.props.onUpdate(this.state);
    }
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleDogCat = value => {
    this.setState({
      animalTypeId: value
    });
  };
  handleSave = () => {
    this.props.handleSave(this.state);
  };
  render() {
    let {
      breed,
      animalTypeId,
      minutesToGroom,
      bathBrushPrice,
      bathBrushPrice3Months,
      bathBrushPrice6Months,
      deluxePrice,
      deluxePrice3Months,
      deluxePrice6Months
    } = this.state;
    let { animalTypes, hideSaveButton } = this.props;

    let animalTypeButtons = animalTypes.map(type => {
      return {
        active: animalTypeId === type.ID,
        onClick: () => this.handleDogCat(type.ID),
        color: animalTypeId === type.ID ? "green" : undefined,
        content: _.startCase(type.type)
      };
    });
    return (
      <Segment basic>
        <Form>
          <Form.Group widths={3}>
            <Input
              label="Name"
              required
              value={breed}
              onChange={this.handleChange}
              name="breed"
              isEditing
            />
            <Input
              label="Minutes to Groom"
              isEditing
              value={minutesToGroom}
              onChange={this.handleChange}
              name="minutesToGroom"
              type="number"
              step={1}
              min={0}
            />
            <Form.Field required>
              <label>Animal Type</label>
              <OrButtons
                fluid
                isEditing
                noActiveMessage="Unknown Animal Type"
                buttons={animalTypeButtons}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={3}>
            <Input
              isEditing
              label="B&B Price"
              type="number"
              format="moneyInput"
              placeholder={(0.0).toFixed(2)}
              validation="money"
              value={bathBrushPrice}
              name="bathBrushPrice"
              onChange={this.handleChange}
              // controlChange
            />{" "}
            <Input
              isEditing
              label="B&B Price (3+ Months)"
              type="number"
              format="moneyInput"
              placeholder={(0.0).toFixed(2)}
              validation="money"
              value={bathBrushPrice3Months}
              name="bathBrushPrice3Months"
              onChange={this.handleChange}
              // controlChange
            />{" "}
            <Input
              isEditing
              label="B&B Price (6+ Months)"
              type="number"
              format="moneyInput"
              placeholder={(0.0).toFixed(2)}
              validation="money"
              value={bathBrushPrice6Months}
              name="bathBrushPrice6Months"
              onChange={this.handleChange}
              // controlChange
            />
          </Form.Group>
          <Form.Group widths={3}>
            <Input
              isEditing
              label="Deluxe Price"
              type="number"
              format="moneyInput"
              placeholder={(0.0).toFixed(2)}
              validation="money"
              value={deluxePrice}
              name="deluxePrice"
              onChange={this.handleChange}
              // controlChange
            />{" "}
            <Input
              isEditing
              label="Deluxe Price (3+ Months)"
              type="number"
              format="moneyInput"
              placeholder={(0.0).toFixed(2)}
              validation="money"
              value={deluxePrice3Months}
              name="deluxePrice3Months"
              onChange={this.handleChange}
              // controlChange
            />{" "}
            <Input
              isEditing
              label="Deluxe Price (6+ Months)"
              type="number"
              format="moneyInput"
              placeholder={(0.0).toFixed(2)}
              validation="money"
              value={deluxePrice6Months}
              name="deluxePrice6Months"
              onChange={this.handleChange}
              // controlChange
            />
          </Form.Group>
          {!hideSaveButton && (
            <Form.Button color="green" onClick={this.handleSave}>
              Save
            </Form.Button>
          )}
        </Form>
      </Segment>
    );
  }
}

BreedForm.defaultProps = {
  handleSave: () => {},
  onUpdate: () => {},
  animalTypes: [],
  hideSaveButton: false
};

export default BreedForm;
